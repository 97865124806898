import styled from '@emotion/styled';
import {
  Days,
  EventNames,
  IClassTimeTable,
  analyticsService,
  lessonPlanDB,
} from '@taleemabad/db';
import {
  AddClassScreen,
  AppBar,
  AssignedClasses,
  FtueTimetable,
  FtueTimetableNewUser,
  OverlayeComponent,
  TimetableAssignedSubjectScreen,
} from '@taleemabad/dsm/components';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useLiveQuery } from 'dexie-react-hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const TimetableViewPage = () => {
  const assignedTimetableClass =
    (useLiveQuery(() =>
      lessonPlanDB.getTimetableEntries(),
    ) as IClassTimeTable[]) || [];

  const [overActive, setOverActive] = useState(true);
  const [dayActive, setDayActive] = useState(1);

  const navigation = useNavigate();
  const currentDayId = new Date().getDay();
  return (
    <>
      {assignedTimetableClass.length === 1 &&
        assignedTimetableClass?.filter((data) =>
          data?.daysOfWeek.some((day) => day === currentDayId),
        ).length === 1 &&
        localStorage.getItem(FtueTimetable) === null && (
          <OverlayeComponent
            isActive={overActive}
            onClick={() => {
              setOverActive(false);
              localStorage.setItem(FtueTimetable, JSON.stringify(true));
            }}
          />
        )}

      <StyledContent isTitle={true}>
        <AppBar
          pageTitle="TimeTable"
          leftIcon="import_contacts"
          onLeftIconClick={() => {}}
          hasPadding={false}
          titleFullWidth={true}
          isLeftIconButton={false}
        />
        <TimetableAssignedSubjectScreen
          onFirstItem={() => {
            setOverActive(false);
            localStorage.setItem(FtueTimetable, JSON.stringify(true));
          }}
          assignedClasses={assignedTimetableClass}
          days={Days}
          onSelectedClass={(selectedClass: AssignedClasses) => {
            analyticsService.trackEvent(EventNames.dashboardClassSelected, {
              epClassSelected: selectedClass.schoolClass,
              epDashboardDay: dayActive,
            });
            navigation(`/timetable/view-chapters/${selectedClass.id}`);
          }}
          onDaySelected={(id: number) => {
            setDayActive(id);
          }}
          onShiftSelected={(id: number) => {}}
          onAddClass={() => {
            analyticsService.trackEvent(
              EventNames.dashboardAddClassClicked,
              {},
            );
            navigation('/create-class/grade');
          }}
        />
        {assignedTimetableClass?.length === 0 &&
          localStorage.getItem(FtueTimetableNewUser) === null && (
            <>
              <OverlayeComponent
                isActive={assignedTimetableClass?.length === 0}
              ></OverlayeComponent>
              <AddClassScreen
                onAddClass={() => {
                  localStorage.setItem(
                    FtueTimetableNewUser,
                    JSON.stringify(true),
                  );
                  analyticsService.trackEvent(
                    EventNames.dashboardAddClassClicked,
                    {},
                  );
                  navigation('/create-class/grade');
                }}
              />
            </>
          )}
      </StyledContent>
    </>
  );
};

interface StyledDiv {
  isTitle?: boolean;
}
const StyledContent = styled.div<StyledDiv>`
  padding: ${(props) => (props.isTitle ? '16px 24px 0px 24px' : '0px')};
  display: flex;
  flex-direction: column;
  ${mq(BreakpointSizes.xs)} {
    padding: ${(props) => (props.isTitle ? '16px' : '')};
    gap: 6px;
    height: calc(100vh - 190px);
  }
`;
