import { SyncRecordStatus, SyncRecord, SyncChanges } from '../types/sync-state';
import Dexie, {
  IndexableType,
  PromiseExtended,
  Table,
  Transaction,
} from 'dexie';
import { tlLogger } from '@taleemabad/shared';
import { userCache } from '..';

export abstract class BaseModel extends Dexie {
  constructor(dbName: string) {
    super(dbName);
  }

  updateHook(
    modifications: Object,
    primKey: IndexableType,
    obj: any,
    transaction: Transaction,
  ): any {
    const result = {
      ...obj,
      ...modifications,
    };

    // skip sync update if only 'lastModifiedAt' has changed
    // TODO: make this more generic
    if (
      Object.keys(modifications).length === 1 &&
      'lastModifiedAt' in modifications
    ) {
      return;
    }

    if (
      'syncStatus' in modifications &&
      modifications.syncStatus === SyncRecordStatus.failed
    ) {
      return this.updateSyncRecord(
        SyncRecordStatus.failed,
        result,
        transaction,
      );
    }

    if (
      'syncStatus' in modifications &&
      modifications.syncStatus === SyncRecordStatus.deleted
    ) {
      return this.updateSyncRecord(
        SyncRecordStatus.deleted,
        result,
        transaction,
      );
    }

    if (
      'syncStatus' in modifications &&
      modifications.syncStatus === SyncRecordStatus.synced
    ) {
      return this.updateSyncRecord(
        SyncRecordStatus.synced,
        result,
        transaction,
      );
    }

    return this.updateSyncRecord(
      'syncStatus' in modifications
        ? (modifications.syncStatus as SyncRecordStatus)
        : SyncRecordStatus.updated,
      result,
      transaction,
    );
  }

  deleteHook(primKey: IndexableType, obj: any, transaction: Transaction) {
    return this.updateSyncRecord(SyncRecordStatus.deleted, obj, transaction);
  }

  createHook(primKey: IndexableType, obj: any, transaction: Transaction) {
    const profile = userCache.getUserFromCache();
    if (profile) {
      obj.user = profile.id;
      obj.profile = profile.profiles.filter((p) => p.isSelected)[0].id;
    }
    if ('syncStatus' in obj && obj.syncStatus === SyncRecordStatus.synced) {
      this.updateSyncRecord(SyncRecordStatus.synced, obj, transaction);
    } else {
      this.updateSyncRecord(SyncRecordStatus.created, obj, transaction);
    }
  }

  updateSyncRecord(
    status: SyncRecordStatus,
    obj: any,
    transaction: Transaction,
  ) {
    tlLogger.verbose(
      `${transaction.db.name}/${
        transaction.storeNames
      }/hook:${status}/${JSON.stringify(obj)}`,
    );
    console.log(status);
    obj.syncStatus = status;
    obj.lastLocalModifiedAt = Date.now();
    return obj;
  }
}

export abstract class BaseModelForPush extends BaseModel {
  _updateSyncData(
    model: Table,
    ids: number[],
    status: SyncRecordStatus,
  ): Promise<number> {
    return model
      .where('id')
      .anyOf(ids)
      .modify({ syncStatus: SyncRecordStatus[status].toString() });
  }

  _getSyncData(model: Table): PromiseExtended<SyncRecord[]> {
    return model
      .where('syncStatus')
      .equals(SyncRecordStatus.created.toString())
      .toArray();
  }

  abstract getAllUnsyncedRecords(): Promise<Record<string, SyncRecord[]>>;
  abstract updateRecordsSyncStatus(
    syncChanges: SyncChanges,
    status: SyncRecordStatus,
  ): Promise<number>;
}
