export interface DownloadResponse {
  download: string; // Assuming the "download" property is a JSON string
}

export enum DownloadStateTitle {
  Done = 'Done',
  Download = 'Download',
}

export interface DownloadInfo {
  id: number;
  file: string;
  url: string;
  status: string;
  error: string;
  downloaded: number;
  total: number;
}
export interface DownloadRequest {
  trainingId: number;
  trainingURL: string;
  downloadId?: number;
}

export enum DownloadListener {
  OnAdded = 'onAdded',
  OnProgress = 'onProgress',
  OnError = 'onError',
  OnCompleted = 'onCompleted',
  OnRemoved = 'onRemoved',
  OnDeleted = 'onDeleted',
}

export enum DownloadStatus {
  NONE = 'NONE',
  QUEUED = 'QUEUED',
  DOWNLOADING = 'DOWNLOADING',
  PAUSED = 'PAUSED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  REMOVED = 'REMOVED',
  DELETED = 'DELETED',
  ADDED = 'ADDED',
}
