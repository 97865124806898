import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useBreakpoint } from '@taleemabad/shared/hooks';
import {
  Typography,
  PasswordField,
  Button,
  Logo,
  PhoneFieldResult,
  Spacing,
  isValueEmpty,
  validatePassword,
  Dialog,
} from '@taleemabad/dsm/components';
import { CardContainer } from '../../molecules/card/card-container';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;
const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 68px;
`;
const StyledLargeHeading = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const StyledPopupButton = styled.div`
  margin-top: 24px;
  md-filled-button {
    width: 100%;
  }
`;
export interface CreateNewPasswordProps {
  title: string;
  description: string;
  logo: string;
  showPopup: boolean;
  onSubmit: (data: ForgotPasswordResult) => void;
  closePopup: () => void;
}
export interface ForgotPasswordResult {
  password: string;
  newPassword: string;
}
export const CreateNewPassword = ({
  title,
  description,
  logo,
  showPopup,
  onSubmit,
  closePopup,
}: CreateNewPasswordProps) => {
  const { tablet, mobileHorizontal, mobile } = useBreakpoint();
  const isLowResDevice = tablet || mobileHorizontal || mobile;
  const [formData, setFormData] = useState([
    {
      name: 'password',
      value: '',
      hasError: true,
      errorText: '',
      isVisible: false,
      label: 'Create password',
      placeholder: 'Enter here',
    },
    {
      name: 'confirmPassword',
      value: '',
      hasError: true,
      errorText: '',
      isVisible: false,
      label: 'Confirm password',
      placeholder: 'Enter here',
    },
  ]);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const handleFieldChange = (index: number, data: PhoneFieldResult) => {
    let errorText = '';
    let hasError = false;
    if (index === 0) {
      // only apply validation for 1st password field since we don't want to show the same error message for 2nd confirm password field
      errorText = validatePasswords(data.value);
      hasError = !!errorText;
    }
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index] = {
        ...updatedFormData[index],
        value: data.value,
        hasError: hasError,
        errorText: errorText,
      };
      return updatedFormData;
    });
  };

  const toggleVisibility = (index: number) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index] = {
        ...updatedFormData[index],
        isVisible: !updatedFormData[index].isVisible,
      };
      return updatedFormData;
    });
  };
  const handleSubmit = async () => {
    setHasSubmitted(true);
    const passwordError = validatePasswords(formData[0].value);
    const passwordMatch = formData[0]?.value === formData[1]?.value;
    let errorMessage = '';
    if (passwordError) {
      errorMessage = passwordError;
    } else if (!passwordMatch) {
      errorMessage = 'Passwords do not match';
    }
    setFormData((prevFormData) => [
      {
        ...prevFormData[0],
        hasError: !!passwordError,
        errorText: passwordError,
      },
      {
        ...prevFormData[1],
        hasError: !passwordMatch,
        errorText: !passwordMatch ? 'Passwords do not match' : '',
      },
    ]);

    if (errorMessage) {
      return;
    }
    if (formData) {
      onSubmit({ password: formData[0].value, newPassword: formData[1].value });
    }
  };
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSubmit();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [formData]);
  return (
    <StyledCardContainer variant="outline">
      {isLowResDevice && (
        <StyledImageWrapper>
          <Logo
            altName="outer-layer"
            width={275}
            src={`../../assets/${logo}`}
          />
        </StyledImageWrapper>
      )}
      <Spacing marginBottom="md">
        <Typography
          variant="textHeadlineLargeSurface"
          data-cy="reset-password-choose-password-title"
        >
          {title}
        </Typography>
      </Spacing>
      <StyledLargeHeading>
        <Typography
          variant="textBodyLargeSurface"
          data-cy="reset-password-choose-password-subtitle"
        >
          {description}
        </Typography>
      </StyledLargeHeading>

      {formData.map((field, index) => (
        <StyledCardContent key={index}>
          <PasswordField
            key={field.name}
            name={field.name}
            type={field.isVisible ? 'text' : 'password'}
            variant="outlined"
            label={field.label}
            placeholder={field.placeholder}
            errorText={hasSubmitted ? field.errorText : ''}
            onChange={(data) => handleFieldChange(index, data)}
            onToggleVisibility={() => toggleVisibility(index)}
            data-cy={`reset-password-field-${field.name}`}
          />
        </StyledCardContent>
      ))}
      <Button
        children="Next"
        onClick={handleSubmit}
        data-cy="reset-password-next-button"
      />
      <Dialog
        hasBackdropClick={false}
        open={showPopup}
        title="Password Changed"
        data-cy="password-changed-dialog-tile"
      >
        <Typography
          variant="textBodyMedium"
          data-cy="password-changed-dialog-subtitle"
        >
          Your password has been successfully changed
        </Typography>

        <StyledPopupButton>
          <Button
            children="Login"
            data-cy="password-changed-dialog-login-button"
            onClick={() => {
              closePopup();
            }}
          />
        </StyledPopupButton>
      </Dialog>
    </StyledCardContainer>
  );
};
const validatePasswords = (value: string) => {
  if (isValueEmpty(value)) {
    return 'Please Create password';
  } else if (!validatePassword(value)) {
    return 'Password must be at least 10 characters and have at least 1 number (alpha-numeric) in it';
  } else {
    return '';
  }
};

const StyledCardContainer = styled(CardContainer)`
  display: flex;
  flex-direction: column;
  padding: var(--rk-spacing-xxxxl) var(--rk-spacing-xxxl)
    var(--rk-spacing-xxxxl) var(--rk-spacing-xxxl);
  width: 497px;
  overflow-y: overlay;
  border: --md-sys-color-outline;
  ${mq(BreakpointSizes.xs)} {
    width: 100%;
    max-width: 80%;
    border: none;
  }
`;
