import {
  AppBar,
  IGenericLessonPlanStep,
  LessonPlanViewer,
} from '@taleemabad/dsm/components';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  EventNames,
  FeatureFlagNames,
  analyticsService,
  lessonPlanDB,
  syncStateManager,
  translateStatements,
  userDB,
} from '@taleemabad/db';
import { useLiveQuery } from 'dexie-react-hooks';
import styled from '@emotion/styled';
import { useState } from 'react';
import { decodeHtmlEntities } from '@taleemabad/dsm/utils';

const StyledContent = styled.div`
  padding: var(--rk-spacing-xl);
  display: flex;
  flex-direction: column;
  ${mq(BreakpointSizes.xs)} {
    gap: 6px;
  }
`;

export const LessonPlanViewPage = () => {
  let { lessonPlanId, chapterId } = useParams();
  const [translatedLps, setTranslatedLps] =
    useState<IGenericLessonPlanStep[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showNoInternetPopup, setShowNoInternetPopup] =
    useState<boolean>(false);
  const selectedLessonPlan = parseInt(lessonPlanId || '0');
  const selectedChapter = parseInt(chapterId || '0');

  const lessonDetails: any = useLiveQuery(() =>
    lessonPlanDB.getLessonPlanDetail(selectedLessonPlan),
  );
  const lessonInformation = useLiveQuery(() =>
    lessonPlanDB.getLessonPlanMetaData(selectedLessonPlan),
  );

  const selectedChapterDetail = useLiveQuery(() =>
    lessonPlanDB.getSelectedBookChapter(selectedChapter),
  );
  const translationEnabled = useLiveQuery(() =>
    userDB.getFeatureFlag(FeatureFlagNames.translationEnabled),
  );
  const phases = [
    'Opening',
    'Explain',
    'Practice',
    'IndependentPractice',
    'Conclusion',
  ];
  interface NameForKeys {
    [key: string]: string;
  }
  const nameForKeys: NameForKeys = {
    opening: 'Opening',
    explain: 'Explanation',
    practice: 'Guided Practice',
    independentPractice: 'Independent Practice',
    conclusion: 'Conclusion',
  };
  const lessonArray = phases
    .map((phase) => {
      const phaseKey = phase.charAt(0).toLowerCase() + phase.slice(1);
      const timeKey = `${phaseKey}Time`;
      const stepsKey = `${phaseKey}Steps`;

      if (stepsKey.length > 0) {
        const stepName = nameForKeys[phaseKey];

        return {
          name: stepName,
          time: lessonDetails?.[0]?.[timeKey] || 0,
          stepData: lessonPlanDB.processStatements(
            lessonDetails?.[0]?.[stepsKey] || [],
          ),
        };
      } else {
        return null;
      }
    })
    .filter((phase) => phase?.name);
  const homeWorkExist = lessonDetails?.[0]?.homeworkInstructions?.length > 0;
  if (homeWorkExist) {
    const homework = {
      name: 'Homework',
      time: 0,
      stepData: lessonPlanDB.processStatements(
        lessonDetails?.[0]?.homeworkInstructions || [],
      ),
    };
    lessonArray.push(homework);
  }
  const LessonPlanDetail: any = {
    steps: lessonArray,
    topic: lessonInformation?.[0].topic,
    id: lessonDetails?.[0].id,
  };
  const navigate = useNavigate();
  const translateLanguage = async (
    targetLanguage: string,
    currentStepName: string,
  ) => {
    try {
      const allStatements = LessonPlanDetail.steps.flatMap(
        (step: IGenericLessonPlanStep) =>
          step.stepData.map((data: any) => data.statement),
      );
      if (allStatements.length !== 0) {
        if (!syncStateManager.isConnected) {
          setShowNoInternetPopup(true);
          analyticsService.trackEvent(EventNames.noInternetNoInternetPopup, {});
          return;
        }
        setIsLoading(true);
        const translatedStatements = await translateStatements(
          targetLanguage,
          allStatements,
        );
        analyticsService.trackEvent(EventNames.lessonTranslateClicked, {
          epTranslateLanguage: targetLanguage,
          epLpStatementType: currentStepName,
        });
        let statementIndex = 0;
        const translatedStepData = LessonPlanDetail.steps.map(
          (step: IGenericLessonPlanStep) => {
            const updatedStepData = step.stepData.map((data: any) => ({
              ...data,
              statement: decodeHtmlEntities(
                translatedStatements[statementIndex++],
              ),
            }));
            return {
              ...step,
              stepData: updatedStepData,
            };
          },
        );

        setTranslatedLps({
          ...LessonPlanDetail,
          steps: translatedStepData,
        });
      }
    } catch (error: any) {
      analyticsService.trackEvent(EventNames.lessonTranslateError, {
        epTranslateLanguage: targetLanguage,
        epErrorMessage: error.message,
      });
      console.error('Error translating statements:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleStepChange = (step: string) => {
    analyticsService.trackEvent(EventNames.lessonLessonSection, {
      epLpId: lessonDetails?.[0].id,
      epLpSection: step,
    });
  };
  return (
    <>
      <AppBar
        pageTitle="Close Lesson"
        leftIcon="arrow_back"
        onLeftIconClick={() => {
          if (lessonInformation && selectedChapterDetail) {
            const { book, gradeSubject, type } = lessonInformation[0];
            const { title } = selectedChapterDetail[0];
            analyticsService.trackEvent(EventNames.lessonBackClicked, {
              epGradesubjectId: gradeSubject,
              epBookId: book,
              epChapterName: title,
              epLpType: type,
              epLpId: lessonDetails?.[0].id,
            });
          }
          navigate(-1);
        }}
        hasPadding={false}
        titleFullWidth={true}
        titleMedium={true}
        isTitleClickable={true}
      />
      <StyledContent>
        <LessonPlanViewer
          lpDetail={LessonPlanDetail}
          isTranslationEnabled={translationEnabled}
          translatedLps={translatedLps}
          translateLanguage={translateLanguage}
          showNoInternetPopup={showNoInternetPopup}
          setShowNoInternetPopup={setShowNoInternetPopup}
          isLoading={isLoading}
          onLessonComplete={(): void => {
            if (lessonInformation && selectedChapterDetail) {
              const { book, gradeSubject, type } = lessonInformation[0];
              const { title } = selectedChapterDetail[0];
              analyticsService.trackEvent(EventNames.lessonLessonCompleted, {
                epGradesubjectId: gradeSubject,
                epBookId: book,
                epChapterName: title,
                epLpType: type,
                epLpId: lessonDetails?.[0].id,
              });
            }
            navigate(-2);
          }}
          onSectionChange={handleStepChange}
        />
      </StyledContent>
    </>
  );
};
