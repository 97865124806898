import {
  ICourse,
  ITraining,
  ITrainingQuestion,
  ProgressStatus,
} from '../types/teachertraining';
import {
  ISyncChangesResponse,
  apiService,
  extractSyncResponse,
  teacherTrainingDB,
  SyncRecordStatus,
  bulkImageDownloadFromUrlToBase64,
  downloadManagerService,
} from '..';

// TODO configure logic for updating
interface IGetCoursesResponse {
  timestamp: string;
  courses: ISyncChangesResponse<ICourse>;
  trainings: ISyncChangesResponse<ITraining>;
}

interface IGetQuestionsReponse {
  timestamp: string;
  questions: ISyncChangesResponse<ITrainingQuestion>;
}

const PAGE_SIZE = 100;

// TODO handle errors properly
// TODO handle updates and deletions
// TOOD dynamic page sizing for first time?
// We can also progressively increase page size
export const fetchCourseData = async (
  timestamp: string | null, // timestamp is 2024-02-22T16:58:59.273651Z
  pageNumber: number = 1,
): Promise<boolean> => {
  console.log(`fetchCourses, attempt ${timestamp} ${pageNumber}`);
  const response = await apiService.get<IGetCoursesResponse>(
    `/api/v1/sync-courses/?page_size=${PAGE_SIZE}&page=${pageNumber}&c=${Date.now()}&${
      timestamp ? `last_pulled_at=${timestamp}` : ''
    }`,
  );

  const data = response.data;
  let lastResult = true;

  const models = ['courses', 'trainings'] as const;
  try {
    const addDataToDb = await extractSyncResponse(
      models,
      data,
      teacherTrainingDB,
    );
    const courseData = [
      ...data.courses.created.data,
      ...data.courses.updated.data,
    ];
    const uniqueUrls = [
      ...new Set(courseData.map((item) => item.thumbnailUrl)),
    ];
    bulkImageDownloadFromUrlToBase64(uniqueUrls).then((promises) => {
      const matchingCourses: ICourse[] = [];
      promises.forEach((promise) => {
        let url: string = '';
        if ('value' in promise) {
          url = promise.value.url;
        }
        if ('reason' in promise) {
          url = promise.reason.message;
        }
        let urlToImageData = courseData.filter(
          (item) => item.thumbnailUrl === url,
        );
        if (promise.status === 'fulfilled') {
          urlToImageData = urlToImageData.map((item) => ({
            ...item,
            thumbnailBase64: promise.value.base64,
          }));
        } else {
          urlToImageData = urlToImageData.map((item) => ({
            ...item,
            thumbnailUrlDownloadAttempts: 1,
          }));
        }
        matchingCourses.push(...urlToImageData);
      });
      if (matchingCourses.length) {
        //TODO: use bulkUpdate instead of bulkPut to avoid overwriting existing data when update dexie to (4.0.1)
        teacherTrainingDB.courses.bulkPut(matchingCourses);
      }
    });

    console.log(`Added ${models} in db ${addDataToDb}`);
    await addTrainingStatusInDb(data.trainings.created.data);
  } catch (e) {
    lastResult = false;
    console.error(`Unable to add data for ${models} ${e}`);
  }

  const modelCounts = models.map((model): number => {
    const modelExists = data[model];
    if (!modelExists) return 0;
    return data[model]['created']['count'];
  });
  const maxPages = Math.max(...modelCounts) / PAGE_SIZE;

  if (pageNumber <= maxPages) {
    // fetch the next page.
    lastResult =
      lastResult && (await fetchCourseData(timestamp, pageNumber + 1));
  }
  // sync downloads from native platforms when trainings are added
  downloadManagerService.syncDownloads();
  return lastResult;
};

async function addTrainingStatusInDb(trainingsData: ITraining[]) {
  const trainingsWithStatus = trainingsData.filter(
    (training) => training.trainingStatus,
  );
  const trainingStatusRecords = trainingsWithStatus.map((training) => ({
    training: training.id,
    courseId: training.course,
    status: training.trainingStatus as ProgressStatus,
    syncStatus: SyncRecordStatus.synced,
  }));
  await teacherTrainingDB.trainingStatusV2.bulkAdd(trainingStatusRecords);
}

export const fetchTrainingQuestions = async (
  trainingId: number,
  timestamp: string | null,
  pageNumber: number = 1,
): Promise<boolean> => {
  const response = await apiService.get<IGetQuestionsReponse>(
    `api/v1/sync-training-questions/${trainingId}/?page_size=${PAGE_SIZE}&page=${pageNumber}&c=${Date.now()}&${
      timestamp ? `last_pulled_at=${timestamp}` : ''
    }`,
  );

  const data = response.data;
  let lastResult = true;
  const models = ['questions'] as const;
  try {
    const addDataToDb = await extractSyncResponse(
      models,
      data,
      teacherTrainingDB,
    );
    console.log(`Added ${models} in db ${addDataToDb}`);
  } catch (e) {
    lastResult = false;
    console.error(`Unable to add data for ${models} ${e}`);
  }

  const modelCounts = models.map((model): number => {
    const modelExists = data[model];
    if (!modelExists) return 0;
    return data[model]['created']['count'];
  });
  const maxPages = Math.max(...modelCounts) / PAGE_SIZE;

  if (pageNumber <= maxPages) {
    // fetch the next page.
    lastResult =
      lastResult &&
      (await fetchTrainingQuestions(trainingId, timestamp, pageNumber + 1));
  }

  return lastResult;
};
