import styled from '@emotion/styled';
import { Ripple } from '../../atoms/ripple/ripple';
import { CardMedia } from '../card/card-media';
import { Spacing } from '../../atoms/spacing/spacing';
import { Typography } from '../../atoms/typography/typography';
import { css } from '@emotion/react';
import { Icon } from '../../atoms/icon/icon';
import { CardContainer } from '../card/card-container';
import { TBProgressBar } from '../tb-progress-bar/tb-progress-bar';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { TrainingCourseProgressInfo } from '@taleemabad/db';

const StyledProgressContainer = styled.div`
  display: flex;
  padding: var(--rk-spacing-lg);
  width: 100%;
  ${mq(BreakpointSizes.desktop)} {
    padding: var(--rk-spacing-xl);
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-left: var(--rk-spacing-lg);
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
`;

export interface CourseProgressBannerProps {
  trainingCourseProgressInfo: TrainingCourseProgressInfo;
  onTrainingCourseProgressBannerClick: (
    course: TrainingCourseProgressInfo,
  ) => void;
}

export const CourseProgressBanner = ({
  trainingCourseProgressInfo,
  onTrainingCourseProgressBannerClick,
}: CourseProgressBannerProps) => {
  if (
    !trainingCourseProgressInfo.course ||
    !trainingCourseProgressInfo.training
  ) {
    return <div>Loading Course Progress Info</div>;
  }

  const cardContainerStyles = trainingCourseProgressInfo.allTrainingsCompleted
    ? css`
        // styles for when all trainings are completed
        display: flex;
        min-height: 116px;
        background-image: url('completion-banner.jpg');
        background-size: cover;
        background-position: center;
      `
    : css`
        display: flex;
      `;

  return (
    <CardContainer
      onClick={() =>
        onTrainingCourseProgressBannerClick(trainingCourseProgressInfo)
      }
      variant="filled"
      minHeight="96px"
      emotionCss={cardContainerStyles}
    >
      {trainingCourseProgressInfo.allTrainingsCompleted ? (
        <StyledContent>
          <Spacing marginBottom="md">
            <Typography variant="textHeadlineLargePrimary">
              Congratulations!
            </Typography>
          </Spacing>
          <Typography variant="textLabelLargePrimary">
            You have Completed all Courses
          </Typography>
        </StyledContent>
      ) : (
        <>
          <Ripple />
          <StyledProgressContainer>
            <CardMedia
              src={
                trainingCourseProgressInfo.course.thumbnailBase64
                  ? `data:image/png;base64,${trainingCourseProgressInfo.course.thumbnailBase64}`
                  : trainingCourseProgressInfo.course.thumbnailUrl
              }
              emotionCss={css`
                width: 100px;
                border-radius: var(--md-sys-shape-corner-sm);
              `}
            />
            <StyledContent>
              <Typography variant="textBodySmallNeutral40">
                Course {trainingCourseProgressInfo.course.index} |{' '}
                {trainingCourseProgressInfo.course.gradeGroup
                  ? 'Subject Course'
                  : 'General Course'}
              </Typography>
              <Spacing margin="md none lg none">
                <Typography variant="textTitleLargePrimary">
                  {trainingCourseProgressInfo.training.title}
                </Typography>
              </Spacing>
              {trainingCourseProgressInfo.course.courseCompletionPercentage ? (
                <TBProgressBar
                  progress={Math.round(
                    trainingCourseProgressInfo.course
                      .courseCompletionPercentage,
                  )}
                />
              ) : (
                <StyledFooter>
                  <Typography variant="textLabelLarge">
                    {trainingCourseProgressInfo.cta}
                  </Typography>
                  <Icon
                    name="east"
                    size="tiny"
                    emotionCss={css`
                      margin-left: var(--rk-spacing-sm);
                      color: var(--md-ref-secondary-40);
                    `}
                  />
                </StyledFooter>
              )}
            </StyledContent>
          </StyledProgressContainer>
        </>
      )}
    </CardContainer>
  );
};
