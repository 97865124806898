import { CardContainer } from '../card/card-container';
import { IconText } from '../icon-text/icon-text';
import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { IconButton } from '@taleemabad/dsm/components';

export interface TbExpansionPanelProps extends PropsWithChildren {
  isOpen: boolean;
  onToggle?: () => void;
}
export const TbExpansionPanel = ({
  isOpen = false,
  children,
  onToggle,
}: TbExpansionPanelProps) => {
  return (
    <StyledCardContainer variant="elevated">
      <StyledHeaderWrapper>
        <IconText
          icon="play_circle"
          text="Tap to play video"
          iconColor="--md-sys-color-primary"
          textColor="--md-sys-color-primary"
        ></IconText>
        <ToggleButtonWrapper isOpen={isOpen}>
          <IconButton
            color="--md-sys-color-primary"
            icon="keyboard_arrow_down"
            onClick={() => {
              onToggle?.();
            }}
          />
        </ToggleButtonWrapper>
      </StyledHeaderWrapper>

      {isOpen && <StyledContainer>{children}</StyledContainer>}
    </StyledCardContainer>
  );
};
const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: var(--rk-spacing-xl);
`;

const StyledContainer = styled.div`
  height: fit-content;
  padding: var(--rk-spacing-xl);
`;
interface ToggleButtonProps {
  isOpen: boolean;
}
const ToggleButtonWrapper = styled.div<ToggleButtonProps>`
  rotate: ${(props) => (props.isOpen ? '180deg' : '0deg')};
  transition: all 0.4s ease;
`;
const StyledCardContainer = styled(CardContainer)`
  min-height: fit-content;
  width: 100%;
`;
