import React from 'react';
import styled from '@emotion/styled';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { ILessonPlan, lessonPlanDB } from '@taleemabad/db';
import { useLiveQuery } from 'dexie-react-hooks';
import { Spacing } from '../../atoms/spacing/spacing';
import { List, Typography } from '@taleemabad/dsm/components';
import { formatUtcDate } from '@taleemabad/shared';

interface LpCardListProps {
  lessonPlans: ILessonPlan[];
  onLpSelected?: (lesson: LpSelected) => void;
}

export interface LpSelected {
  data: ILessonPlan;
  index: number;
}
const LpCardListWrapper = styled.div`
  display: grid;
  gap: var(--rk-spacing-xl);
  grid-template-columns: repeat(3, 1fr);

  ${mq(BreakpointSizes.xs)} {
    grid-template-columns: repeat(1, 1fr);
  }
  ${mq(BreakpointSizes.sm)} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const LpCardList: React.FC<LpCardListProps> = ({
  lessonPlans,
  onLpSelected,
}) => {
  // will be removed as we have assigned all the teacher
  const schoolClass =
    useLiveQuery(() => lessonPlanDB.getSchoolClassSubjectGrade()) || [];
  return (
    <Spacing paddingBottom="xl">
      <LpCardListWrapper>
        {lessonPlans.map((data, index) => (
          <List
            key={data.id}
            overlineText={
              schoolClass &&
              schoolClass.length > 0 &&
              (data.lessonPlanStatus ||
                data?.lessonPlanStatusClass?.length > 0) ? (
                <Typography variant="textLabelLargeSecondary">
                  {`Last Open ${formatUtcDate(
                    data?.lessonPlanStatusClass?.length > 0
                      ? (data.lessonPlanStatusClass.find((c) => {
                          const schoolClassList = data.schoolClass as number[];
                          return schoolClassList.find(
                            (e) => e === c.schoolClass,
                          );
                        })?.status as string)
                      : (data.lessonPlanStatus as string),
                  )}`}
                </Typography>
              ) : (
                <Typography variant="textLabelLargeNeutral70">
                  {data.lessonPlanStatus === null && schoolClass?.length > 0
                    ? 'Pending'
                    : ''}
                </Typography>
              )
            }
            supportingText={data.topic}
            heading={`Lesson ${index + 1}`}
            buttonText="Open Lesson"
            buttonEnabled={true}
            onButtonClick={() => {
              onLpSelected?.({ data: data, index: index });
            }}
          />
        ))}
      </LpCardListWrapper>
    </Spacing>
  );
};
