import {
  AppBar,
  LoadingScreen,
  TeacherTrainingCourses,
  Spacing,
} from '@taleemabad/dsm/components';
import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';
import {
  analyticsService,
  CourseRecord,
  ProgressStatus,
  teacherTrainingDB,
  teacherTrainingService,
  TrainingCourseProgressInfo,
  SyncManagerStatus,
  EventNames,
  userDB,
  FeatureFlagNames,
} from '@taleemabad/db';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useSyncManager } from '@taleemabad/shared';

interface CoursePageProps {}

export const CoursePage: FC<CoursePageProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const coursesFromDB = useLiveQuery(() => teacherTrainingDB.getCourses(), []);
  const isTimetableEnabled = useLiveQuery(
    async () => await userDB.getFeatureFlag(FeatureFlagNames.timetableEnabled),
  );
  const [allCourses, setAllCourses] = useState<CourseRecord[]>([]);
  const nextTrainingAndCourse = useLiveQuery(() =>
    teacherTrainingDB.getNextTrainingAndCourse(),
  );
  const syncStatus = useSyncManager(); // Call the custom hook to get the sync status
  useEffect(() => {
    if (location.state === 'training' && isTimetableEnabled) {
      navigate('/timetable/view-timetable');
    }
  }, [isTimetableEnabled]);

  useEffect(() => {
    // we need to show the courses with completion percentage and set status using the trainings status of those courses
    if (coursesFromDB) {
      const fetchCourses = async () => {
        const coursesWithCompletionAndStatus = await Promise.all(
          coursesFromDB.map(async (course) => {
            const courseCompletionPercentage =
              await teacherTrainingService.getCourseCompletionPercentage(
                course.id,
              );
            const trainings = await teacherTrainingDB.getTrainings(course.id);
            let courseStatus: ProgressStatus | null = null;
            if (
              trainings.some(
                (training) =>
                  training.trainingStatus === ProgressStatus.IN_PROGRESS,
              )
            ) {
              courseStatus = ProgressStatus.IN_PROGRESS;
            } else if (
              trainings.every((training) => training.trainingStatus === null)
            ) {
              courseStatus = ProgressStatus.PENDING;
            } else if (courseCompletionPercentage === 100) {
              courseStatus = ProgressStatus.COMPLETED;
            }
            return { ...course, courseCompletionPercentage, courseStatus };
          }),
        );
        setAllCourses(coursesWithCompletionAndStatus);
      };
      fetchCourses();
    }
  }, [coursesFromDB]);

  const completedCourses = allCourses.filter(
    (course) => course.courseStatus === ProgressStatus.COMPLETED,
  ).length;

  const courseClickHandler = (course: CourseRecord) => {
    navigate(`/training/course/${course.id}`);
    analyticsService.trackEvent(EventNames.trainingCourseSelected, {
      epCourseId: course.id,
    });
  };

  const trainingClickHandler = (
    trainingCourseProgressInfo: TrainingCourseProgressInfo,
  ) => {
    if (
      trainingCourseProgressInfo.training &&
      trainingCourseProgressInfo.course
    ) {
      navigate(
        `/training/course/${trainingCourseProgressInfo.course.id}/training/${trainingCourseProgressInfo.training.id}`,
      );
      analyticsService.trackEvent(EventNames.trainingProgressBanner, {
        epTrainingId: trainingCourseProgressInfo.training.id,
      });
    }
  };

  return (
    <StyledContainer>
      <AppBar
        pageTitle="Teacher Training"
        leftIcon="school"
        hasPadding={false}
        titleFullWidth={true}
        isLeftIconButton={false}
      />
      <Spacing marginTop="xl">
        {(syncStatus !== SyncManagerStatus.synced && !allCourses) ||
        allCourses.length === 0 ? (
          <LoadingScreen />
        ) : (
          allCourses &&
          nextTrainingAndCourse && (
            <TeacherTrainingCourses
              allCourses={allCourses}
              courseProgressBanner={nextTrainingAndCourse}
              onTrainingCourseProgressBannerClick={trainingClickHandler}
              progressInformation={{
                title: 'Courses Completed',
                postTitle: `You have completed ${completedCourses} ${completedCourses === 1 ? 'course' : 'courses'}`,
                progressValue:
                  allCourses.length > 0
                    ? (completedCourses / allCourses.length) * 100
                    : 0,
                progressText: `${completedCourses}/${allCourses.length}`,
              }}
              onCourseClick={courseClickHandler}
            />
          )
        )}
      </Spacing>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  padding: var(--rk-spacing-xl);
  ${mq(BreakpointSizes.xs)} {
    margin-bottom: var(--rk-spacing-xxxxxxl);
  }
`;
