import styled from '@emotion/styled';
import { IBookChapter, lessonPlanDB, subjectIcons } from '@taleemabad/db';
import { AppBar, TimeTableChapterScreen } from '@taleemabad/dsm/components';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useLiveQuery } from 'dexie-react-hooks';
import { useNavigate, useParams } from 'react-router-dom';

export const LessonChapterPage = () => {
  const navigate = useNavigate();
  const { gradeSubjectId } = useParams();
  const selectedGradeSubjectId = parseInt(gradeSubjectId as string);
  const chapters = useLiveQuery(() =>
    lessonPlanDB.getChapterByGradeSubjectId(selectedGradeSubjectId),
  );

  const subjects =
    useLiveQuery(() =>
      lessonPlanDB.getTimeTableClass(selectedGradeSubjectId),
    ) || [];
  console.log(subjects);
  return (
    <StyledContent isTitle={true}>
      <AppBar
        pageTitle="Lessons Plans"
        leftIcon="arrow_back"
        onLeftIconClick={() => {
          navigate('/timetable/view-timetable');
        }}
        hasPadding={false}
        titleFullWidth={true}
        isLeftIconButton={true}
      />
      <TimeTableChapterScreen
        selectedSubject={{
          icon: subjectIcons.find((data) => data.id === subjects[0]?.subject)
            ?.icon as string,
          text: `${subjects[0]?.gradeLabel} - ${subjects[0]?.section} ${subjects[0]?.subjectLabel}`,
        }}
        Chapter={chapters || []}
        onSelectedChapter={(chapter: IBookChapter) => {
          navigate(`/lessons/${chapter.id}/grade/${chapter.gradeSubject}`);
        }}
      ></TimeTableChapterScreen>
    </StyledContent>
  );
};

interface StyledDiv {
  isTitle?: boolean;
}
const StyledContent = styled.div<StyledDiv>`
  padding: ${(props) => (props.isTitle ? '16px 24px 0px 24px' : '0px')};
  display: flex;
  flex-direction: column;
  ${mq(BreakpointSizes.xs)} {
    padding: ${(props) => (props.isTitle ? '16px' : '')};
    gap: 6px;
    height: calc(100vh - 115);
    overflow-y: auto;
  }
`;
