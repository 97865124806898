import { FC } from 'react';
import {
  BaseOption,
  BaseOptionCard,
  CardStatus,
  Checkbox,
} from '@taleemabad/dsm/components';

export interface MSQCardProps {
  option: BaseOption;
  status?: CardStatus;
  onSelect?: (option: BaseOption) => void;
}

export const MSQCard: FC<MSQCardProps> = ({
  option,
  status = CardStatus.Unselected,
  onSelect,
}) => {
  const handleSelect = () => {
    onSelect?.(option);
  };

  return (
    <BaseOptionCard option={option} status={status}>
      <Checkbox
        label={option.content}
        disabled={status === CardStatus.Disabled}
        onChange={handleSelect}
      />
    </BaseOptionCard>
  );
};
