import { SignInPage } from '../pages/sign-in-page';
import { SignInPasswordPage } from '../pages/sign-in-password-page';
import { SetPasswordPage } from '../pages/set-password-page';
import { AuthLayout } from 'apps/school-app/src/components/authentication-layout';
import { UnProtectedRoute } from 'apps/school-app/src/providers/unprotected-routes';
import { ProfileDetailsPage } from '../pages/profile-details-page';
import { ProtectedRoute } from '../components/protected-routes';
import { ParentProfile } from '../pages/parent-profile';
import { PageLayout } from 'apps/school-app/src/components/page-layout';
import { UserVerificationPage } from '../pages/user-verification-page';
import { SetUpdatePassword } from '../pages/set-update-password';
import { SetOtpPage } from '../pages/otp-page';

const routes = [
  {
    path: '/login',
    element: (
      <UnProtectedRoute>
        <AuthLayout>
          <SignInPage />
        </AuthLayout>
      </UnProtectedRoute>
    ),
  },
  {
    path: 'set-password',
    element: (
      <UnProtectedRoute>
        <AuthLayout>
          <SignInPasswordPage />
        </AuthLayout>
      </UnProtectedRoute>
    ),
  },
  {
    path: 'create-password',
    element: (
      <UnProtectedRoute>
        <AuthLayout>
          <SetPasswordPage />
        </AuthLayout>
      </UnProtectedRoute>
    ),
  },
  {
    path: 'user-details',
    element: (
      <ProtectedRoute>
        <AuthLayout>
          <ProfileDetailsPage />
        </AuthLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: 'user-verfication',
    element: (
      <UnProtectedRoute>
        <AuthLayout>
          <UserVerificationPage />
        </AuthLayout>
      </UnProtectedRoute>
    ),
  },

  {
    path: 'update-password',
    element: (
      <UnProtectedRoute>
        <AuthLayout>
          <SetUpdatePassword />
        </AuthLayout>
      </UnProtectedRoute>
    ),
  },
  {
    path: 'send-otp',
    element: (
      <UnProtectedRoute>
        <AuthLayout>
          <SetOtpPage />
        </AuthLayout>
      </UnProtectedRoute>
    ),
  },
  {
    path: 'parent-profile',
    element: (
      <ProtectedRoute>
        <PageLayout
          isSidebarVisible={true}
          isNavBarVisible={true}
          scroll={true}
          isProfileScreen={true}
        >
          <ParentProfile />
        </PageLayout>
      </ProtectedRoute>
    ),
  },
];

export const authRoutes = routes;
