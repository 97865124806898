import styled from '@emotion/styled';
import { Typography } from '../../atoms/typography/typography';
import { ProgressWithLabel } from '../../atoms/progress-with-label/progress-with-label';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';

const StyledProgressInformation = styled.div`
  display: flex;
  ${mq(BreakpointSizes.desktop)} {
    flex-direction: column;
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: var(--rk-spacing-lg);
  ${mq(BreakpointSizes.desktop)} {
    margin-left: unset;
    margin-top: var(--rk-spacing-lg);
  }
`;

export interface ProgressInformationProps {
  title: string;
  postTitle: string;
  progressText: string;
  progressValue: number;
}

export const ProgressInformation = ({
  title,
  postTitle,
  progressText,
  progressValue,
}: ProgressInformationProps) => {
  return (
    <StyledProgressInformation>
      <ProgressWithLabel value={progressValue} text={progressText} />
      <StyledContent>
        <Typography variant="textBodyLargeNeutral10">{title}</Typography>
        <Typography variant="textBodyMediumNeutral40">{postTitle}</Typography>
      </StyledContent>
    </StyledProgressInformation>
  );
};
