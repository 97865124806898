import styled from '@emotion/styled';
import { Typography } from '../../atoms/typography/typography';

const StyledWrapper = styled.div`
  margin-top: var(--rk-spacing-xxxl);
`;

export interface CardSupportingTextProps {
  text: string;
}

export const CardSupportingText = ({ text }: CardSupportingTextProps) => {
  return (
    <StyledWrapper>
      <Typography variant="textBodyMedium">{text}</Typography>
    </StyledWrapper>
  );
};
