import styled from '@emotion/styled';
import { Typography } from '../../atoms/typography/typography';
import { ProfileCardList } from '../../molecules/profile-card-list.tsx/profile-card-list';

export interface ParentProfileScreenProps {
  list: ParentProfileCardProps[];
  onProfileSelected: (profile: any) => void;
}

export interface ParentProfileCardProps {
  id: number;
  grade: string;
  name: string;
}

export const ParentProfileScreen = ({
  list,
  onProfileSelected,
}: ParentProfileScreenProps) => {
  return (
    <ParentProfileContainer>
      <HeadingWrapper>
        <Typography variant="textTitleLargeOnSurface">
          Children&apos;s Profiles
        </Typography>
      </HeadingWrapper>
      <ProfileCardList
        studentProfiles={list}
        onProfileSelected={onProfileSelected}
      />
    </ParentProfileContainer>
  );
};

const ParentProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--rk-spacing-xxl);
  margin-bottom: var(--rk-spacing-xxl);
`;
