// @ts-nocheck
import { PropsWithChildren, useState } from 'react';

import '@material/web/select/filled-select.js';
import '@material/web/select/outlined-select.js';
import '@material/web/select/select-option.js';

export interface SelectOption {
  id?: number;
  value: string;
  content: string;
}

interface SelectOptionProps {
  isSelected: boolean;
  option: SelectOption;
  onSelect: (option: SelectOption) => void;
}

const SelectOption = ({ isSelected, option, onSelect }: SelectOptionProps) => {
  const props = {};
  if (isSelected) {
    props.selected = isSelected;
  }
  return (
    <md-select-option
      {...props}
      value={option.value}
      onClick={() => onSelect(option)}
    >
      {option.content}
    </md-select-option>
  );
};

export interface SelectProps extends PropsWithChildren {
  variant?: 'filled' | 'outlined';
  disabled?: boolean;
  required?: boolean;
  options: SelectOption[];
  defaultValue?: string;
  label?: string;
  onChange?: (option: SelectOption) => void;
}

export const Select = ({
  variant = 'filled',
  disabled = false,
  required,
  options,
  defaultValue = null,
  label,
  onChange,
}: SelectProps) => {
  const [selectedOption, setSelectedOption] = useState(() => {
    if (defaultValue !== null) {
      return defaultValue;
    }
  });

  const selectProps = {};
  if (required) {
    selectedOption.required = required;
  }
  if (disabled) {
    selectProps.disabled = disabled;
  }
  if (label) {
    selectProps.label = label;
  }

  const selectChangeHandler = (option: SelectOption) => {
    setSelectedOption(option.value);
    onChange(option);
  };

  if (variant === 'filled') {
    return (
      <md-filled-select {...selectProps}>
        {options.map((option, idx) => (
          <SelectOption
            key={idx}
            isSelected={selectedOption === option.value}
            option={option}
            onSelect={selectChangeHandler}
          />
        ))}
      </md-filled-select>
    );
  } else {
    return (
      <md-outlined-select {...selectProps}>
        {options.map((option, idx) => (
          <SelectOption
            key={idx}
            option={option}
            isSelected={selectedOption === option.value}
            onSelect={selectChangeHandler}
          />
        ))}
      </md-outlined-select>
    );
  }
};
