import posthog from 'posthog-js';
import { shutdownIntercom } from '@taleemabad/shared';
import {
  syncedDBModel,
  apiService,
  teacherTrainingDB,
  lessonPlanDB,
  userDB,
  syncStateManager,
} from '..';

export interface UserToken {
  id: number;
  access: string;
  refresh: string;
  refreshExpiry: string;
}

/**
 * id is the user id.
 * recordId is the id of the record in the local db.
 */
export interface BaseUserProfile {
  recordId: number;
  id: number;
  username: string;
  name: string;
  featureFlags: FeatureFlags;
  url: string;
}

export interface UserProfile extends BaseUserProfile {
  profiles: TeacherProfile[];
}

export interface GenericProfile {
  id: number;
  isActive: boolean;
  // is set to check which profile is currently locally active.
  isSelected: boolean;
  role: string;
}

export interface TeacherProfile extends GenericProfile {
  cnic: string;
  joiningDate: string;
  school: number;
  schoolName: string;
  emis: number;
  yearsOfService: number;
}
export interface TBConfig {
  appLogoPath: string;
  appLogoWidthNavbar: string;
  appLogoWidthLanding: string;
  app: string; // taleemabad | niete to check if this is the deployment for NIETE.
}

export interface FeatureFlags {
  [key: string]: any;
}
const configKey = 'config';

const config: TBConfig = {
  appLogoPath: 'niete-logo.svg',
  appLogoWidthNavbar: '240',
  appLogoWidthLanding: '550',
  app: 'niete',
};

const handleResposne = (data: any) => {
  if (data.isPasswordSet === true) {
    return { route: '/set-password' };
  }
  return { route: '/create-password' };
};

export const validateUserEndpoint = async (phoneNumber: string) => {
  const body = {
    username: phoneNumber,
  };
  const response = await apiService.post('/users/state/', body);
  return handleResposne(response.data);
};

export const verifyUser = async (phoneNumber: string) => {
  const body = {
    username: phoneNumber,
  };
  const response = await apiService.post('/users/state/', body);
  return response.data;
};

export const loginUser = async (phoneNumber: string, password: string) => {
  const body = {
    username: phoneNumber,
    password: password,
  };

  const response = await apiService.post('/users/login/', body);
  await userDB.setToken(response.data);
  syncStateManager.checkChanges();
  return response.data;
};

/**
 * Do not call this in the view, it is called in background sync.
 */
export const userDetails = async () => {
  const response = await apiService.get(`api/users/`);
  userDB.setUserProfile(response.data[0]);
  return response.data[0];
};

export const createPassword = async (
  phoneNumber: string,
  password: string,
  confirmPassword: string,
) => {
  const body = {
    username: phoneNumber,
    password: password,
    confirmPassword: confirmPassword,
  };
  const response = await apiService.post('users/create-password/', body);
  // wait here until its set or next call will fail.
  await userDB.setToken(response.data);
  syncStateManager.checkChanges();
  return response.data;
};
export const updatePassword = async (
  phoneNumber: string,
  password: string,
  confirmPassword: string,
) => {
  const body = {
    username: phoneNumber,
    password: password,
    confirmPassword: confirmPassword,
  };
  const response = await apiService.post('users/create-password/', body);
  return response.data;
};

export const sendOtp = async (phone: string) => {
  const body = { username: phone, delivery_method: 'SMS' };
  const response = await apiService.post('users/send-otp/', body);
  return response.data;
};
export const verifyOtp = async (phone: string, otp: string) => {
  const body = { username: phone, otp: otp };
  const response = await apiService.post('users/otp-verification/', body);
  return response.data;
};
export const logout = () => {
  // TODO handle this better so that
  // we don't have to clear all the data
  // esp if its just a token expiry.
  shutdownIntercom();
  userDB.resetToken();
  syncedDBModel.resetSyncRecord();
  lessonPlanDB.clearData();
  teacherTrainingDB.clearData();
  //localStorage.clear(); // todo when logging out, its also clear config data and posthog identify call is dependent on it if relogin without refresh, config data is only set on initial page load
  const keyToKeep = ['config', 'ftue-timetable-new-user', 'ftue-timetable'];
  for (let i = localStorage.length - 1; i >= 0; i--) {
    const key = localStorage.key(i)!;
    if (!keyToKeep.includes(key)) {
      localStorage.removeItem(key);
    }
  }
  posthog.reset(true);
};

export const resetTrainingAndLessonPlan = () => {
  teacherTrainingDB.clearData();
  lessonPlanDB.clearData();
  syncedDBModel.resetSyncRecord();
};

export const getWhiteLabelConfig = async () => {
  const response = await apiService.get('api/v1/organization/1/configuration/');
  return response.data;
};

export const setConfig = (config: TBConfig) => {
  localStorage.setItem(configKey, JSON.stringify(config));
};
export const getConfig = () => {
  const configStored = JSON.parse(
    localStorage.getItem(configKey) || 'null',
  ) as TBConfig;
  return configStored;
};

export const deactivateUser = async () => {
  const response = await apiService.post('users/deactivate/');
  return response.data;
};
