import {
  ISyncChangesResponse,
  apiService,
  extractSyncResponse,
  lessonPlanDB,
} from '..';
import {
  IBookChapter,
  IGradeSubject,
  IGrades,
  ILessonPlan,
  ILessonPlanDetail,
  ISchoolClass,
  ISchoolClassSubject,
  ISubject,
  ISchoolClassTimeTable,
} from '../types/lessonplan';

interface IGetBookChaptersResponse {
  timestamp: string;
  chapters: ISyncChangesResponse<IBookChapter>;
  gradeSubjects: ISyncChangesResponse<IGradeSubject>;
  lessonPlans: ISyncChangesResponse<ILessonPlan>;
  schoolClasses: ISyncChangesResponse<ISchoolClass>;
  schoolClassSubjects: ISyncChangesResponse<ISchoolClassSubject>;
}

interface IGetBookChaptersResponseV2 {
  timestamp: string;
  chapters: ISyncChangesResponse<IBookChapter>;
  gradeSubjects: ISyncChangesResponse<IGradeSubject>;
  lessonPlans: ISyncChangesResponse<ILessonPlan>;
  schoolClasses: ISyncChangesResponse<ISchoolClass>;
  schoolClassSubjects: ISyncChangesResponse<ISchoolClassSubject>;
  grades: ISyncChangesResponse<IGrades>;
  subjects: ISyncChangesResponse<ISubject>;
  schoolClassTimetables: ISyncChangesResponse<ISchoolClassTimeTable>;
}
interface IGetLessonPlanDetail {
  timestamp: string;
  lessonPlans: ISyncChangesResponse<ILessonPlanDetail>;
}
interface TranslationsArray {
  translatedText: string;
  detectedSourceLanguage: string;
}
[];
const PAGE_SIZE = 100;

export const fetchBookChapters = async (
  timestamp: string | null,
  pageNumber: number = 1,
): Promise<boolean> => {
  const response = await apiService.get<IGetBookChaptersResponse>(
    `/api/v1/sync-book-chapters/?page_size=${PAGE_SIZE}&page=${pageNumber}&c=${Date.now()}&${
      timestamp ? `last_pulled_at=${timestamp}` : ''
    }`,
  );

  const data = response.data;
  let lastResult = true;
  const models = [
    'lessonPlans',
    'chapters',
    'gradeSubjects',
    'schoolClasses',
    'schoolClassSubjects',
  ] as const;

  try {
    const addDataToDb = await extractSyncResponse(models, data, lessonPlanDB);
    console.log(`Added ${models} in db ${addDataToDb}`);
  } catch (e) {
    lastResult = false;
    console.error(`Unable to add data for ${models} ${e}`);
  }

  const modelCounts = models.map((model): number => {
    const modelExists = data[model];
    if (!modelExists) return 0;
    return data[model]['created']['count'];
  });
  const maxPages = Math.max(...modelCounts) / PAGE_SIZE;

  if (pageNumber <= maxPages) {
    // fetch the next page.
    lastResult =
      lastResult && (await fetchBookChapters(timestamp, pageNumber + 1));
  }

  return lastResult;
};

export const fetchBookChaptersV2 = async (
  timestamp: string | null,
  pageNumber: number = 1,
): Promise<boolean> => {
  const response = await apiService.get<IGetBookChaptersResponseV2>(
    `/api/v2/sync-school-class-timetable/?page_size=${PAGE_SIZE}&page=${pageNumber}&c=${Date.now()}&${
      timestamp ? `last_pulled_at=${timestamp}` : ''
    }`,
  );

  const data = response.data;
  let lastResult = true;
  const models = [
    'lessonPlans',
    'chapters',
    'gradeSubjects',
    'schoolClasses',
    'schoolClassSubjects',
    'grades',
    'subjects',
    'schoolClassTimetables',
  ] as const;

  try {
    const addDataToDb = await extractSyncResponse(models, data, lessonPlanDB);
    console.log(`Added ${models} in db ${addDataToDb}`);
  } catch (e) {
    lastResult = false;
    console.error(`Unable to add data for ${models} ${e}`);
  }

  const modelCounts = models.map((model): number => {
    const modelExists = data[model];
    if (!modelExists) return 0;
    return data[model]['created']['count'];
  });
  const maxPages = Math.max(...modelCounts) / PAGE_SIZE;

  if (pageNumber <= maxPages) {
    // fetch the next page.
    lastResult =
      lastResult && (await fetchBookChaptersV2(timestamp, pageNumber + 1));
  }

  return lastResult;
};

export const fetchLessonPlanDetail = async (
  lessonPlanIds: number[],
  timestamp: string | null,
  pageNumber: number = 1,
): Promise<boolean> => {
  const response = await apiService.get<IGetBookChaptersResponse>(
    `/api/v1/sync-book-chapters/?ids=${lessonPlanIds.join(
      ',',
    )}&page_size=${PAGE_SIZE}&page=${pageNumber}&c=${Date.now()}&${
      timestamp ? `last_pulled_at=${timestamp}` : ''
    }`,
  );

  const data = response.data;
  let lastResult = true;
  const models = ['lessonPlans'] as const;

  try {
    const addDataToDb = await extractSyncResponse(models, data, lessonPlanDB);
    console.log(`Updated ${models} in db ${addDataToDb}`);
  } catch (e) {
    lastResult = false;
    console.error(`Unable to update data for ${models} ${e}`);
  }

  const modelCounts = models.map((model): number => {
    const modelExists = data[model];
    if (!modelExists) return 0;
    return data[model]['created']['count'];
  });
  const maxPages = Math.max(...modelCounts) / PAGE_SIZE;

  if (pageNumber <= maxPages) {
    // fetch the next page.
    lastResult =
      lastResult &&
      (await fetchLessonPlanDetail(lessonPlanIds, timestamp, pageNumber + 1));
  }

  return lastResult;
};

export const fetchSelectedLessonPlan = async (
  lessonPlanId: number[],
  timestamp: string | null,
  pageNumber: number = 1,
): Promise<boolean> => {
  const response = await apiService.get<IGetLessonPlanDetail>(
    `/api/v1/sync-lesson-plans-detail/?c=${Date.now()}&ids=${lessonPlanId?.join(',')}`,
  );
  const updateObject = {
    lastPulledAt: response.data.timestamp,
    lessonPlanDetail: response.data.lessonPlans,
  };
  let lastResult = true;

  const models = ['lessonPlanDetail'] as const;
  try {
    const addDataToDb = await extractSyncResponse(
      models,
      updateObject,
      lessonPlanDB,
    );
    console.log(`Added ${models} in db ${addDataToDb}`);
  } catch (e) {
    lastResult = false;
    console.log(models);
    console.error(`Unable to add data for ${models} ${e}`);
  }

  const modelCounts = models.map((model): number => {
    const modelExists = updateObject[model];
    if (!modelExists) return 0;
    return updateObject[model]['created']['count'];
  });
  const maxPages = Math.max(...modelCounts) / PAGE_SIZE;

  if (pageNumber <= maxPages) {
    // fetch the next page.
    lastResult =
      lastResult &&
      (await fetchSelectedLessonPlan(lessonPlanId, timestamp, pageNumber + 1));
  }

  return lastResult;
};

export const fetchSelectedChapters = async (
  chaptersIds: number[],
  timestamp: string | null,
  pageNumber: number = 1,
): Promise<boolean> => {
  const response = await apiService.get<IGetBookChaptersResponse>(
    `/api/v1/sync-lp-explore/${chaptersIds?.join(',')}/?c=${Date.now()}&page_size=${PAGE_SIZE}&page=${pageNumber}&c=${Date.now()}&${
      timestamp ? `last_pulled_at=${timestamp}` : ''
    }`,
  );
  const data = response.data;

  let lastResult = true;

  const models = ['lessonPlans', 'chapters'] as const;
  try {
    const fetchedChapters = await lessonPlanDB.getChapterByGradeSubjectId(
      chaptersIds[0],
    );
    if (fetchedChapters.length > 0) {
      lessonPlanDB.updateChapterData(data);
    } else {
      const addDataToDb = await extractSyncResponse(models, data, lessonPlanDB);
      console.log(`Added ${models} in db ${addDataToDb}`);
    }
  } catch (e) {
    lastResult = false;
    console.log(models);
    console.error(`Unable to add data for ${models} ${e}`);
  }

  const modelCounts = models.map((model): number => {
    const modelExists = data[model];
    if (!modelExists) return 0;
    return data[model]['created']['count'];
  });
  const maxPages = Math.max(...modelCounts) / PAGE_SIZE;

  if (pageNumber <= maxPages) {
    // fetch the next page.
    lastResult =
      lastResult &&
      (await fetchSelectedChapters(chaptersIds, timestamp, pageNumber + 1));
  }

  return lastResult;
};

export const translateStatements = async (
  targetLanguage: string,
  statements: string[],
): Promise<string[]> => {
  try {
    const response = await apiService.post(
      `${process.env.NX_TRANSLATE_API_URL}${process.env.NX_TRANSLATE_API_KEY}`,
      {
        q: statements,
        target: targetLanguage,
      },
    );
    const translations = response.data.data.translations.map(
      (translation: TranslationsArray) => translation.translatedText,
    );
    return translations;
  } catch (error) {
    console.error('Error translating statements:', error);
    throw error;
  }
};
