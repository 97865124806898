import { Checkbox, Divider } from '@taleemabad/dsm/components';
import styled from '@emotion/styled';
import { useState } from 'react';

export interface SelectionProps {
  id: number;
  day: string;
  isChecked: boolean;
  onChange: (isChecked: ListItem) => void;
}
export interface ListItem {
  id: number;
  day: string;
  isChecked: boolean;
}
export const DaySelection = ({
  id,
  day,
  isChecked,
  onChange,
}: SelectionProps) => {
  return (
    <StyledDaySelection>
      <Checkbox
        label={day}
        defaultChecked={isChecked}
        onChange={(isChecked: boolean) => {
          onChange({ id, day, isChecked });
        }}
      />
    </StyledDaySelection>
  );
};

export interface DaySelectionListProps {
  days: ListItem[];
  onSelection: (day: ListItem[]) => void;
}
export const SelectionList = ({ days, onSelection }: DaySelectionListProps) => {
  const [selectedDays, setSelectedDays] = useState<ListItem[]>(days);

  const handleSelectionChange = (index: number) => {
    const newSelectedItems = [...selectedDays];
    newSelectedItems[index] = {
      ...selectedDays[index],
      isChecked: !selectedDays[index].isChecked,
    };
    setSelectedDays(newSelectedItems);
    onSelection(newSelectedItems);
  };

  return (
    <div>
      {days.map((day, index) => (
        <>
          <DaySelection
            key={index}
            day={day.day}
            isChecked={day.isChecked}
            onChange={() => handleSelectionChange(index)}
            id={day.id}
          />
          {index < days.length - 1 && <Divider />}
        </>
      ))}
    </div>
  );
};

const StyledDaySelection = styled.div`
  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }
`;
