import styled from '@emotion/styled';
import { CardContainer } from '../card/card-container';
import { css } from '@emotion/react';
import { IconButton } from '@taleemabad/dsm/components';
import { Dialog } from '../../atoms/dialog/dialog';
import { useState } from 'react';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';

export interface LessonPlanMedia {
  src: string;
  onExpand?: () => void;
  onClose?: () => void;
}

const StyledLpImageWrapper = styled.div`
  display: flex;
  padding: var(--rk-spacing-lg);
  position: relative;
  min-height: inherit;
  justify-content: center;
  align-items: center;
  img {
    object-fit: contain;
    width: 100%;
  }
`;
export const LpImage: React.FC<LessonPlanMedia> = ({ src }) => {
  const [show, setShow] = useState(false);
  return (
    <CardContainer
      emotionCss={css`
        width: 349px;
        height: auto;
        ${mq(BreakpointSizes.xs)} {
          width: 100%;
        }
      `}
      variant="elevated"
    >
      <ImageWrapperComponent
        src={src}
        onExpand={() => {
          setShow(true);
        }}
      />
      <Dialog
        emotionCss={css`
          padding: 0;
          display: flex;
          min-height: 232px;
          display: flex;
          justify-content: center;
        `}
        open={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <StyledDialogContainer>
          <StyledCloseIconWrapper>
            <IconButton
              icon="close"
              color="--md-sys-color-on-surface"
              onClick={() => {
                setShow(false);
              }}
            />
          </StyledCloseIconWrapper>
          <img src={src} />
        </StyledDialogContainer>
      </Dialog>
    </CardContainer>
  );
};
const StyledExpanButtonWrapper = styled.div`
  position: absolute;
  bottom: var(--rk-spacing-xl);
  right: var(--rk-spacing-xl);
`;
const ImageWrapperComponent: React.FC<LessonPlanMedia> = ({
  src,
  onExpand,
}) => {
  return (
    <StyledLpImageWrapper>
      <img src={src} alt="Lesson Plan Image" />
      <StyledExpanButtonWrapper>
        <IconButton
          icon="fullscreen"
          color="--md-sys-color-on-surface"
          onClick={() => {
            onExpand?.();
          }}
        />
      </StyledExpanButtonWrapper>
    </StyledLpImageWrapper>
  );
};

const StyledDialogContainer = styled.div`
  display: flex;
  img {
    width: 100%;
    object-fit: contain;
  }
`;
const StyledCloseIconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: var(--rk-spacing-xl);
  right: var(--rk-spacing-xl);
`;
