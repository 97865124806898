import { MSQCard, MSQCardProps } from '../msq-card/msq-card';
import { FC } from 'react';
import styled from '@emotion/styled';
import { BaseOption } from '@taleemabad/dsm/components';

export interface MSQCardListProps {
  options: MSQCardProps[];
  onSelect?: (option: BaseOption) => void;
}

export const MSQCardList: FC<MSQCardListProps> = ({ options, onSelect }) => {
  return (
    <MSQCardListContainer>
      {options.map((option, index) => (
        <MSQCard key={option.option.value} {...option} onSelect={onSelect} />
      ))}
    </MSQCardListContainer>
  );
};

const MSQCardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--rk-spacing-lg);
`;
