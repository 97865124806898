import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';

export interface AudioProps {
  src: string;
  onPlay?: () => void;
  onPause?: () => void;
  onStop?: () => void;
  isPlaying?: boolean;
}
const StyledAudioContainer = styled.div`
  audio::-webkit-media-controls-mute-button {
    display: none !important;
  }
  audio::-internal-media-controls-overflow-button {
    display: none;
  }
`;
export const LpAudio = ({ src, isPlaying, onPlay, onPause }: AudioProps) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying]);
  return (
    <StyledAudioContainer>
      <audio
        ref={audioRef}
        src={src}
        onPlay={() => onPlay?.()}
        onPause={() => onPause?.()}
        controls
        controlsList="noplaybackrate nodownload"
      ></audio>
    </StyledAudioContainer>
  );
};
