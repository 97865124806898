import {
  TBConfig,
  getConfig,
  getWhiteLabelConfig,
  setConfig,
} from '@taleemabad/db';
import { PropsWithChildren, createContext, useEffect, useState } from 'react';
const config: TBConfig = {
  app: 'niete',
  appLogoPath: 'niete-logo.svg',
  appLogoWidthNavbar: '240',
  appLogoWidthLanding: '550',
};
export const ConfigContext = createContext<TBConfig>(config);

export const ConfigProvider = ({ children }: PropsWithChildren) => {
  const data = getConfig();
  const [configuration, setConfiguration] = useState<TBConfig>(
    data ? data : config,
  );
  useEffect(() => {
    const fetchData = async () => {
      if (!data) {
        try {
          const res = await getWhiteLabelConfig();
          setConfiguration(res.configuration);
          setConfig(res.configuration);
        } catch (error) {}
      }
    };

    fetchData();
  }, []);
  return (
    <ConfigContext.Provider value={configuration}>
      {children}
    </ConfigContext.Provider>
  );
};
