import styled from '@emotion/styled';
import { Select, SelectOption } from '../../atoms/select/select';
import { Spacing } from '../../atoms/spacing/spacing';
import { Typography } from '../../atoms/typography/typography';
import { useContext, useEffect, useMemo, useState } from 'react';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import React from 'react';
import { ClassSubjectContext } from '@taleemabad/providers';

export interface LpExploreProps {
  selectedClassSubject?: (data: selectClassSubject) => void;
}

const subjectList = [
  { id: 1, content: 'English', value: 'english' },
  { id: 2, content: 'Math', value: 'math' },
  { id: 3, content: 'Urdu', value: 'urdu' },
  { id: 4, content: 'Waqfiyat Amaa', value: 'waqfiyatAmaa' },
  { id: 5, content: 'Islamiyat', value: 'islamiyat' },
  { id: 6, content: 'Literacy Urdu', value: 'literacyUrdu' },
  { id: 7, content: 'Literacy English', value: 'literacyEnglish' },
  { id: 8, content: 'Numeracy', value: 'numeracy' },
];
const gradeSubjects = [
  {
    id: 1,
    content: 'Prep',
    value: 'prep',
    subjects: [
      { id: 1, content: 'English', value: 'english' },
      { id: 2, content: 'Math', value: 'maths' },
      { id: 3, content: 'Urdu', value: 'urdu' },
    ],
  },
  {
    id: 2,
    content: 'Grade One',
    value: 'grade1',
    subjects: subjectList,
  },
  {
    id: 3,
    content: 'Grade Two',
    value: 'grade2',
    subjects: subjectList,
  },
  {
    id: 4,
    content: 'Grade Three',
    value: 'grade3',
    subjects: subjectList,
  },
  {
    id: 5,
    content: 'Grade Four',
    value: 'grade4',
    subjects: [
      { id: 1, content: 'English', value: 'english' },
      { id: 2, content: 'Math', value: 'maths' },
      { id: 3, content: 'Urdu', value: 'urdu' },
      { id: 4, content: 'General Science', value: 'generalScience' },
      { id: 5, content: 'Social Studies', value: 'socialStudies' },
      { id: 6, content: 'Islamiyat', value: 'islamiyat' },
      { id: 7, content: 'Literacy Urdu', value: 'literacyUrdu' },
      { id: 8, content: 'Literacy English', value: 'literacyEnglish' },
      { id: 9, content: 'Numeracy', value: 'numeracy' },
    ],
  },
  {
    id: 6,
    content: 'Grade Five',
    value: 'grade5',
    subjects: [
      { id: 1, content: 'English', value: 'english' },
      { id: 2, content: 'Math', value: 'maths' },
      { id: 3, content: 'Urdu', value: 'urdu' },
      { id: 4, content: 'General Science', value: 'generalScience' },
      { id: 5, content: 'Social Studies', value: 'socialStudies' },
      { id: 6, content: 'Islamiyat', value: 'islamiyat' },
      { id: 7, content: 'Literacy Urdu', value: 'literacyUrdu' },
      { id: 8, content: 'Literacy English', value: 'literacyEnglish' },
      { id: 9, content: 'Numeracy', value: 'numeracy' },
    ],
  },
];

interface GradeSubjectSelection extends SelectOption {
  subjects: SelectOption[];
}
export interface selectClassSubject {
  classId: SelectOption | undefined;
  subjectId: SelectOption | undefined;
}

export const LpExploreMenu = ({ selectedClassSubject }: LpExploreProps) => {
  const { selectedOptions, setSelectedOptions } =
    useContext(ClassSubjectContext);
  const [selectGradeSubjects, setSelectGradeSubjects] =
    useState<GradeSubjectSelection>();

  useEffect(() => {
    if (selectedOptions.classId) {
      setSelectGradeSubjects(
        gradeSubjects?.find((item) => item.id === selectedOptions.classId?.id),
      );
    }
    if (selectedOptions.classId && selectedOptions.subjectId) {
      selectedClassSubject?.(selectedOptions);
    }
    if (selectedOptions.classId && !selectedOptions.subjectId) {
      selectedClassSubject?.({
        classId: undefined,
        subjectId: undefined,
      });
    }
  }, [selectedOptions]);

  // for reset component on when menu item is clicked
  const SelectMemo = useMemo(
    () => React.memo(Select),
    [selectedOptions.classId?.value],
  );

  return (
    <div>
      <Spacing marginBottom="xxl">
        <Typography variant={'textTitleMedium'}>
          Select a class and subject
        </Typography>
      </Spacing>
      <SelectionWrapper>
        <SelectMenu>
          <Select
            defaultValue={selectedOptions.classId?.value}
            options={
              gradeSubjects?.map(({ id, content, value }) => ({
                id,
                content,
                value,
              })) as SelectOption[]
            }
            onChange={(option) => {
              setSelectedOptions((prevOptions: any) => ({
                ...prevOptions,
                classId: option,
                subjectId: undefined,
              }));
              setSelectGradeSubjects(
                gradeSubjects?.find((item) => item.id === option.id),
              );
            }}
            variant="outlined"
            label="Select Class"
          />
        </SelectMenu>
        <SelectMenu>
          <SelectMemo
            defaultValue={selectedOptions.subjectId?.value}
            options={(selectGradeSubjects?.subjects as SelectOption[]) || []}
            onChange={(option) =>
              setSelectedOptions((prevOptions) => ({
                ...prevOptions,
                subjectId: option,
              }))
            }
            variant="outlined"
            disabled={!selectedOptions.classId}
            label="Select Subject"
          />
        </SelectMenu>
      </SelectionWrapper>
    </div>
  );
};

const SelectMenu = styled.div`
  md-outlined-select {
    min-width: 302px;
    ${mq(BreakpointSizes.xs)} {
      min-width: 100%;
    }
  }
`;
const SelectionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  ${mq(BreakpointSizes.xs)} {
    flex-direction: column;
  }
  gap: var(--rk-spacing-xxl);
`;
