import { OtpScreen } from '@taleemabad/dsm/components';
import { tempLSPhoneKey } from './sign-in-page';
import {
  EventNames,
  analyticsService,
  sendOtp,
  syncStateManager,
  verifyOtp,
} from '@taleemabad/db';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfigContext } from 'apps/school-app/src/providers/config-provider';

const otpLength = 4;
export const SetOtpPage = () => {
  const { appLogoPath } = useContext(ConfigContext);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const from = location.state?.from;
    if (from !== '/user-verfication') {
      navigate('/login', { replace: true });
    }
  }, []);
  const verifyEnteredOtp = async (otpValue: string) => {
    try {
      setErrorMessage('');
      await verifyOtp(localStorage.getItem(tempLSPhoneKey) ?? '', otpValue);
      analyticsService.trackEvent(EventNames.resetPasswordOTPVerified, {
        epOTPService: 'SMS',
      });
      navigate('/update-password', {
        state: { from: location.pathname },
        replace: true,
      });
    } catch (e: any) {
      if (
        syncStateManager.isConnected &&
        'otp' in e.response.data &&
        e.response.status === 400
      ) {
        setErrorMessage(e.response.data.otp);
      }
      if (
        syncStateManager.isConnected &&
        'detail' in e.response.data &&
        e.response.status === 429
      ) {
        setErrorMessage('Attempts limit reached. Try again after 1 hour');
      }
    }
  };
  const resendOtp = async () => {
    try {
      await sendOtp(localStorage.getItem(tempLSPhoneKey) ?? '');
      setErrorMessage('');
      analyticsService.trackEvent(EventNames.resetPasswordResendOTPClicked, {
        epOTPService: 'SMS',
      });
    } catch (error: any) {
      if (
        syncStateManager.isConnected &&
        'nonFieldErrors' in error.response.data &&
        error.response.status === 400
      ) {
        setErrorMessage(error.response.data.nonFieldErrors[0]);
      }

      if (
        syncStateManager.isConnected &&
        'detail' in error.response.data &&
        error.response.status === 429
      ) {
        setErrorMessage('OTP Limit Reached, Try again after 1 hour');
      }
    }
  };
  return (
    <OtpScreen
      otpLength={otpLength}
      errorMessage={errorMessage}
      onOtpValueChange={(value: string) => {
        verifyEnteredOtp(value);
      }}
      onResendOtp={async () => {
        resendOtp();
      }}
      phoneNumber={localStorage.getItem(tempLSPhoneKey) ?? ''}
      logo={appLogoPath}
    ></OtpScreen>
  );
};
