import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '../../atoms/typography/typography';
import { LpCardList, LpSelected, TitleText } from '@taleemabad/dsm/components';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { ILessonPlan } from '@taleemabad/db';

interface LessonPlanChapterTopicsProps {
  topicName: string;
  topicIndex: number;
  className: string;
  lessonPlans: ILessonPlan[];
  onSingleLpSelected?: (data: LpSelected) => void;
}

const LessonPlanChapterTopicsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleTextContainer = styled.div`
  margin: var(--rk-spacing-xl) 0 var(--rk-spacing-xxl) 0;
  ${mq(BreakpointSizes.xs)} {
    margin: var(--rk-spacing-md) 0 var(--rk-spacing-xxl) 0;
  }
`;

export const LessonPlanChapterTopics: React.FC<
  LessonPlanChapterTopicsProps
> = ({ topicName, topicIndex, className, lessonPlans, onSingleLpSelected }) => {
  return (
    <LessonPlanChapterTopicsContainer>
      <Typography variant="textHeadlineMediumPrimary">{topicName}</Typography>
      <TitleTextContainer>
        <TitleText
          leftText={`Topic ${topicIndex}`}
          seperatorIconName="circle"
          rightText={className}
        ></TitleText>
      </TitleTextContainer>
      <LpCardList
        onLpSelected={(data) => {
          onSingleLpSelected?.(data);
        }}
        lessonPlans={lessonPlans}
      ></LpCardList>
    </LessonPlanChapterTopicsContainer>
  );
};
