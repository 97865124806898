// @ts-nocheck
import { PropsWithChildren } from 'react';

import '@material/web/chips/chip-set.js';

export interface ChipSetProps extends PropsWithChildren {}

export const ChipSet = ({ children }: ChipSetProps) => {
  return <md-chip-set>{children}</md-chip-set>;
};
