import styled from '@emotion/styled';
import { FC } from 'react';
import { MCQCard, MCQCardProps } from '../mcq-card/mcq-card';
import { BaseOption } from '@taleemabad/dsm/components';

interface MCQCardListProps {
  options: MCQCardProps[];
  onSelect?: (option: BaseOption) => void;
}

export const MCQCardListComponent: FC<MCQCardListProps> = ({
  options,
  onSelect,
}) => {
  return (
    <MCQCardListContainer>
      {options.map((option, index) => (
        <MCQCard key={option.option.value} {...option} onSelect={onSelect} />
      ))}
    </MCQCardListContainer>
  );
};

const MCQCardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--rk-spacing-lg);
`;
