import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { SerializedStyles } from '@emotion/react';

export interface CardContainerProps extends PropsWithChildren {
  variant: 'filled' | 'outline' | 'filledWithBorder' | 'elevated';
  disabled?: boolean;
  onClick?: () => void;
  emotionCss?: SerializedStyles;
  className?: string;
  role?: string;
  minHeight?: string;
  width?: string;
}

export const CardContainer = ({
  variant,
  disabled,
  onClick,
  className,
  emotionCss,
  children,
  role,
  minHeight = '',
  width = '',
}: CardContainerProps) => {
  let Container;
  switch (variant) {
    case 'filled':
      Container = StyledFilledContainer;
      break;
    case 'outline':
      Container = StyledOutlineContainer;
      break;
    case 'filledWithBorder':
      Container = StyledFilledContainerWithBorder;
      break;
    case 'elevated':
      Container = StyledElevatedContainer;
      break;
    default:
      Container = StyledFilledContainer;
  }

  return (
    <Container
      role={role}
      disabled={disabled}
      css={emotionCss}
      pointer={!!onClick}
      className={`rk-card${className ? ` ${className}` : ''}`}
      onClick={onClick}
      minHeight={minHeight}
      width={width}
    >
      {children}
    </Container>
  );
};

interface StyledFilledContainerProps {
  disabled?: boolean;
  pointer?: boolean;
  minHeight?: string;
  width?: string;
}

const StyledFilledContainer = styled.div<StyledFilledContainerProps>`
  position: relative;
  background-color: ${(props) =>
    props.disabled
      ? 'var(--md-sys-color-surface-variant)'
      : 'var(--md-sys-color-surface-container-highest)'};
  border-radius: var(--md-sys-shape-corner-lg);
  box-shadow: var(--md-sys-color-shadow);
  min-height: ${(props) => props.minHeight || '200px'};
  width: ${(props) => props.width || '100%'};
  opacity: ${(props) => (props.disabled ? 0.38 : 1)};
  overflow: hidden;
  ${(props) => props.pointer && { cursor: 'pointer' }}
`;
const StyledElevatedContainer = styled(StyledFilledContainer)`
  box-shadow: var(--rk-shadow-default);
  border: none;
  background-color: unset;
`;

const StyledFilledContainerWithBorder = styled(StyledFilledContainer)`
  border: 1px solid var(--md-sys-color-outline-variant);
`;

interface StyledOutlinedContainerProps {
  disabled?: boolean;
  pointer?: boolean;
  minHeight?: string;
  width?: string;
}

const StyledOutlineContainer = styled.div<StyledOutlinedContainerProps>`
  position: relative;
  background-color: var(--md-sys-color-surface);
  border-radius: var(--md-sys-shape-corner-lg);
  box-shadow: var(--md-sys-color-shadow);
  min-height: ${(props) => props.minHeight || '200px'};
  width: ${(props) => props.width};
  opacity: ${(props) => (props.disabled ? 0.12 : 1)};
  overflow: hidden;
  ${(props) => props.pointer && { cursor: 'pointer' }}
  border: 1px solid var(
    ${(props) =>
    props.disabled
      ? '--md-sys-color-outline'
      : '--md-sys-color-outline-variant'}
  );
`;
