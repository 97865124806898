import { FC } from 'react';
import { IconText, Typography } from '@taleemabad/dsm/components';
import styled from '@emotion/styled';

export interface ItemInfoProps {
  itemIcon: string;
  itemText: string;
  itemValue: string;
}
export const ItemInfo: FC<ItemInfoProps> = ({
  itemIcon,
  itemText,
  itemValue,
}) => {
  return (
    <ItemInfoContainer>
      <IconText
        iconColor="--md-sys-color-on-surface-variant"
        textVariant="textBodyLargeOnSurfaceVariant"
        icon={itemIcon}
        text={itemText}
      />
      <Typography variant="textBodyLargeSurface">{itemValue}</Typography>
    </ItemInfoContainer>
  );
};

const ItemInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--rk-spacing-md);
`;
