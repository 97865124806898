import React, { useEffect, useState } from 'react';
import {
  PhoneFieldResult,
  TextField,
  TextFieldProps,
} from '../../atoms/text-field/text-field';
import { Icon } from '../../atoms/icon/icon';

export interface PhoneFieldProps extends TextFieldProps {
  onChange?: (data: PhoneFieldResult) => void;
}

export const PhoneField = ({ onChange, ...props }: PhoneFieldProps) => {
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    props.errorText ?? '',
  );
  const handleTyping = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(/\s/g, '');
    setInputValue(newValue);
    if (onChange) {
      onChange({
        name: props.name ?? '',
        value: newValue,
        hasError: hasError,
      });
    }
  };
  useEffect(() => {
    setHasError(props.errorText !== '');
    setErrorMessage(props.errorText ?? '');
  }, [inputValue, hasError, errorMessage, props.errorText]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const isNumericKey = /^\d$/.test(event.key);
    const inputValueLength = inputValue?.length || 0;

    if (!isNumericKey) {
      event.preventDefault();
    }
    if (
      inputValue?.startsWith('3') &&
      isNumericKey &&
      inputValueLength <= 10 &&
      inputValueLength >= 10
    ) {
      event.preventDefault();
    } else if (
      inputValue?.startsWith('0') &&
      isNumericKey &&
      inputValueLength <= 11 &&
      inputValueLength >= 11
    ) {
      event.preventDefault();
    } else if (inputValueLength >= 11) {
      event.preventDefault();
    }
  };
  return (
    <TextField
      onInput={handleTyping}
      error={!hasError ? undefined : true}
      errorText={errorMessage}
      value={inputValue}
      variant={props.variant}
      onKeyPress={handleKeyPress}
      type={'tel'}
      {...props}
      children={
        hasError && (
          <Icon
            color="var( --md-sys-color-error)"
            slot="trailing-icon"
            name="error"
          />
        )
      }
    />
  );
};
export const validatePhone = (value: string): boolean => {
  const validPhoneNumberRegex = /^(03|3)[0-9]{9}$/;
  return validPhoneNumberRegex.test(value);
};
export const isValueEmpty = (value: string): boolean => {
  return value.trim() === '';
};
export const nonInitials = (value: string): boolean => {
  const numberStart = /^[03]/;
  return value !== '' ? numberStart.test(value) : true;
};
