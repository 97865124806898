// @ts-nocheck
import { PropsWithChildren } from 'react';

import '@material/web/ripple/ripple.js';

export interface RippleProps extends PropsWithChildren {
  disabled?: boolean;
  attachWithId?: string;
}

export const Ripple = ({ disabled, attachWithId }: RippleProps) => {
  const props = {};
  if (disabled) {
    props.disabled = true;
  }
  if (attachWithId) {
    props.for = attachWithId;
  }

  return <md-ripple {...props} />;
};
