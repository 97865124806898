import styled from '@emotion/styled';
import { Button } from '../../atoms/button/button';
import { Spacing } from '../../atoms/spacing/spacing';
import { Typography } from '../../atoms/typography/typography';
import {
  PhoneField,
  isValueEmpty,
  nonInitials,
  validatePhone,
} from '../../molecules/input-fields/phone';
import { PhoneFieldResult } from '../../atoms/text-field/text-field';
import { useEffect, useState } from 'react';
import { CardContainer } from '../../molecules/card/card-container';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useBreakpoint } from '@taleemabad/shared';
import { Dialog, Logo } from '@taleemabad/dsm/components';

export interface ForgotPasswordScreenProps {
  title: string;
  description: string;
  logo: string;
  showPopup: boolean;
  error: string;
  onPhoneChange: (data: PhoneFieldResult) => void;
  closePopup: () => void;
  backToLogin: () => void;
}
const StyledPopupButton = styled.div`
  margin-top: 24px;
  md-filled-button {
    width: 100%;
  }
`;
const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--rk-spacing-xxxxl);
`;
const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 68px;
`;
const tempLSPhoneKey = 'tempLSPhoneKey';
export const ForgotPasswordScreen: React.FC<ForgotPasswordScreenProps> = ({
  title,
  description,
  logo,
  showPopup,
  error,
  closePopup,
  onPhoneChange,
  backToLogin,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { tablet, mobileHorizontal, mobile } = useBreakpoint();
  const isLowResDevice = tablet || mobileHorizontal || mobile;
  const [formData, setFormData] = useState<PhoneFieldResult>({
    hasError: false,
    value: localStorage.getItem(tempLSPhoneKey) ?? '',
    name: 'phone',
  });
  const handleSubmit = () => {
    if (!formData.hasError) {
      onPhoneChange(formData);
    }
  };

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);
  return (
    <>
      <StyledCardContainer variant={'outline'}>
        {isLowResDevice && (
          <StyledImageWrapper>
            <Logo
              altName="outer-layer"
              width={275}
              src={`../../assets/${logo}`}
            />
          </StyledImageWrapper>
        )}
        <Typography
          variant={'textHeadlineLarge'}
          data-cy="forgot-password-verify-account-title"
        >
          {title}
        </Typography>
        <Spacing margin="md none xl none">
          <Typography
            variant={'textBodyLarge'}
            data-cy="forgot-password-verify-account-subtitle"
          >
            {description}
          </Typography>
        </Spacing>
        <PhoneField
          name="phone"
          type="tel"
          variant="outlined"
          label="Phone Number"
          placeholder="Enter here"
          value={formData.value}
          readOnly={true}
          id="phoneNumber"
          errorText={errorMessage}
          data-cy="verify-account-phone-number-field"
        />
        <ActionButtonWrapper>
          <Button
            width="100%"
            type="submit"
            children="Confirm"
            onClick={handleSubmit}
            data-cy="verify-account-confirm-button"
          />
        </ActionButtonWrapper>
        <Spacing style={{ textAlign: 'center' }} marginTop="xxl">
          <StyledBacktoLogin
            data-cy="verify-account-back-to-login-button"
            variant="textLabelLargePrimary"
            onClick={() => {
              backToLogin();
            }}
          >
            Back to Login
          </StyledBacktoLogin>
        </Spacing>
      </StyledCardContainer>
    </>
  );
};

const validatePhoneNumber = (value: string) => {
  if (!nonInitials(value)) {
    return 'Phone number must be start 3 or 0';
  } else if (isValueEmpty(value)) {
    return 'Please enter your phone number';
  } else if (!validatePhone(value)) {
    return 'Please enter a valid phone number';
  } else {
    return '';
  }
};

const StyledBacktoLogin = styled(Typography)`
  cursor: pointer;
`;
const StyledCardContainer = styled(CardContainer)`
  display: flex;
  flex-direction: column;
  padding: var(--rk-spacing-xxxxl) var(--rk-spacing-xxxl)
    var(--rk-spacing-xxxxl) var(--rk-spacing-xxxl);
  width: 497px;
  border: --md-sys-color-outline;
  overflow-y: overlay;
  ${mq(BreakpointSizes.xs)} {
    width: 100%;
    max-width: 80%;
    border: none;
  }
`;
