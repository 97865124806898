import { Spacing } from '../../atoms/spacing/spacing';
import { Typography } from '../../atoms/typography/typography';

export const LpNoChapter = () => {
  return (
    <>
      <Spacing margin="xxxl none xs none">
        <Typography variant="textHeadlineSmallSurface">Coming Soon!</Typography>
      </Spacing>
      <Typography variant="textBodyLargeOnSurface">
        Lesson Plans of this class & subject is under development. Stay tuned.
      </Typography>
    </>
  );
};
