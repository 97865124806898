import Dexie, { Table } from 'dexie';
import { tlLogger } from '@taleemabad/shared';
import { v4 as uuidv4 } from 'uuid';
import { userCache } from '../utils/user-cache';
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';

const SCHEMA = {
  synced: 'id',
};

const SCHEMA_VERSION = 1;

export interface SyncStateRecord {
  id: number;
  lastSyncedAt: number | null; // TimeStamp - update when we sync changes with server
  lastChangedAt: number | null; // TimeStamp - update whenever any record in the DB changes
  deviceId: string | null;
  // TimeStamp - update whenever a sync is started (to lock out add) additonal ones.
}

// An arbitary ID for the sync record.
// TODO May be we should be using the version number instead?
const SyncRecordId = 12343;

export class SyncedModel extends Dexie {
  synced!: Table<SyncStateRecord>;

  constructor() {
    super('taleemabad');
    this.version(SCHEMA_VERSION).stores(SCHEMA);
    this.createSyncRecord();
  }

  async deviceId() {
    return (await this.synced.get(SyncRecordId))?.deviceId;
  }

  async generateDeviceId() {
    if (Capacitor.getPlatform() !== 'web') {
      const device = await Device.getId();
      if (device && device.identifier) {
        return device.identifier;
      }
    }
    return uuidv4();
  }

  async createSyncRecord() {
    const method = 'SyncedModel/createSyncRecord';
    tlLogger.start(method);
    try {
      const dbRecord = await this.synced.get(SyncRecordId);
      if (dbRecord) {
        userCache.setDeviceId(dbRecord.deviceId || null);
        return;
      }

      const record: SyncStateRecord = {
        id: SyncRecordId,
        lastChangedAt: null,
        lastSyncedAt: null,
        deviceId: uuidv4(),
      };
      userCache.setDeviceId(record.deviceId || null);
      tlLogger.end(method);
      return this.synced.add(record);
    } catch (err) {
      tlLogger.error(method, { err });
    }
  }

  /**
   * Instead of clearing the cache we want to
   * save the device uuid between logouts.
   */
  async resetSyncRecord() {
    const method = 'SyncedModel/resetSyncRecord';
    tlLogger.start(method);
    const record = await this.synced.get(SyncRecordId);
    if (record) {
      record.lastSyncedAt = null;
      record.lastChangedAt = null;
      await this.synced.update(SyncRecordId, record);
    }
    tlLogger.end(method);
  }

  async updateChangedAt() {
    const method = 'SyncedModel/updateChangedAt';
    tlLogger.start(method);
    await this.createSyncRecord();
    this.synced.update(SyncRecordId, {
      lastChangedAt: Date.now(),
    });
    tlLogger.end(method);
  }

  async updateSyncedAt() {
    const method = 'SyncedModel/updateSyncedAt';
    tlLogger.start(method);
    this.synced.update(SyncRecordId, {
      lastSyncedAt: Date.now(),
    });
    tlLogger.end(method);
  }

  async getUpdateSyncedAt(): Promise<string | null> {
    const method = 'SyncedModel/getUpdateSyncedAt';
    tlLogger.start(method);
    const record = await this.synced.get(SyncRecordId);
    tlLogger.end(method);
    if (!record || !record.lastSyncedAt) return null;
    // return in the format needed for the backend timestamp
    return new Date(record.lastSyncedAt).toISOString();
  }

  async hasUnsyncedChanges(): Promise<boolean> {
    const method = 'SyncedModel/hasUnsyncedChanges';
    tlLogger.start(method);
    const record = await this.synced.get(SyncRecordId);

    if (!record) {
      tlLogger.log('First time sync - no record found.');
      return true;
    }
    // DB never changed and never synced
    if (!record.lastSyncedAt && !record.lastChangedAt) {
      tlLogger.log('First time sync - props are null.');
      return true;
    }
    // DB never changed but synced
    if (record.lastSyncedAt && !record.lastChangedAt) {
      tlLogger.log('DB has not changed but synced before.');
      return false;
    }
    // DB changed but never synced
    if (record.lastChangedAt && !record.lastSyncedAt) {
      tlLogger.log('Db has changed but has never synced.');
      return true;
    }

    // If last synced at is under 5 minutes then wait.
    if (
      record.lastSyncedAt &&
      Date.now() - record.lastSyncedAt < 60 * 5 * 1000
    ) {
      tlLogger.log('DB last sync completed less than 5 mins before.');
      return false;
    }

    const result =
      !!record.lastChangedAt &&
      !!record.lastSyncedAt &&
      record.lastChangedAt > record.lastSyncedAt;
    tlLogger.log(method, { message: 'result check.', record, result });
    return result;
  }
}

export const syncedDBModel = new SyncedModel();
