import { FC, useEffect, useState } from 'react';
import { Icon, Typography } from '@taleemabad/dsm/components';
import styled from '@emotion/styled';

interface TimerProps {
  startTime: number;
  pause?: boolean;
  showIcon?: boolean;
  retakeCount: number;
  onTimeUp?: () => void;
}

export const Timer: FC<TimerProps> = ({
  startTime,
  pause = false,
  showIcon = true,
  retakeCount,
  onTimeUp,
}) => {
  const [timeLeft, setTimeLeft] = useState(startTime);

  useEffect(() => {
    setTimeLeft(startTime);
  }, [retakeCount]);

  useEffect(() => {
    if (!timeLeft) {
      if (onTimeUp) {
        onTimeUp();
      }
      return;
    }

    if (!pause) {
      const timerId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [timeLeft, pause]);

  return (
    <TimerContainer>
      {showIcon && (
        <Icon
          name="schedule"
          size="regular"
          color={
            timeLeft === 0
              ? '--md-sys-color-error'
              : '--md-sys-color-on-surface-variant'
          }
        />
      )}
      <Typography
        variant={
          timeLeft === 0
            ? 'textTitleLargeLightError'
            : 'textTitleLargeOnSurface'
        }
      >
        {timeLeft === 0
          ? 'Times Up!'
          : `00:${timeLeft < 10 ? `0${timeLeft}` : timeLeft}`}
      </Typography>{' '}
    </TimerContainer>
  );
};

const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--rk-spacing-md);
`;
