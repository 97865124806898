import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '../../atoms/typography/typography';
import { Icon } from '../../atoms/icon/icon';

interface TitleTextProps {
  leftText: string;
  rightText: string;
  seperatorIconName?: string;
}

const TitleTextContainer = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  column-gap: var(--rk-spacing-md);
`;

export const TitleText: React.FC<TitleTextProps> = ({
  leftText,
  rightText,
  seperatorIconName,
}) => {
  return (
    <TitleTextContainer>
      <Typography variant="textLabelLargeNeutral40">{leftText}</Typography>
      {seperatorIconName && (
        <Icon
          name={seperatorIconName}
          size="tiniest"
          color="--md-ref-neutral-40"
          iconFill={true}
        ></Icon>
      )}
      <Typography variant="textLabelLargeNeutral40">{rightText}</Typography>
    </TitleTextContainer>
  );
};
