// @ts-nocheck
import { PropsWithChildren } from 'react';

import '@material/web/fab/fab.js';
import { Icon } from '../icon/icon';

export interface FabButtonProps extends PropsWithChildren {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'surface';
  size?: 'small' | 'medium' | 'large';
  label?: string;
  lowered?: boolean;
  icon: string;
  onClick?: () => void;
}

export const FabButton = ({
  variant = 'surface',
  size = 'medium',
  label,
  lowered,
  icon,
  onClick,
}: FabButtonProps) => {
  const props = {};
  if (onClick) {
    props.onClick = onClick;
  }
  if (label) {
    props.label = label;
  }
  if (lowered) {
    props.lowered = lowered;
  }

  return (
    <md-fab variant={variant} size={size} {...props}>
      <Icon name={icon} slot="icon" />
    </md-fab>
  );
};
