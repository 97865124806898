import { FC } from 'react';
import styled from '@emotion/styled';
import { Typography } from '../typography/typography';
import { StyledDiv } from '../../common/components/styled-div';
import { Spacing } from '../spacing/spacing';

interface ProgressWithLabelProps {
  value: number;
  text: string;
  size?: 'sm' | 'md' | 'lg';
}

interface StyledProgressDiv {
  value: number;
  size: 'sm' | 'md' | 'lg';
}

const getSize = (size: StyledProgressDiv['size']) => {
  switch (size) {
    case 'sm':
      return '48px';
    case 'md':
      return '64px';
    case 'lg':
      return '96px';
    default:
      return '64px'; // default size if none is provided
  }
};
const StyledProgress = styled.div<StyledProgressDiv>`
  width: ${({ size }) => getSize(size)};
  height: ${({ size }) => getSize(size)};
  border-radius: 50%;
  background: radial-gradient(closest-side, white 79%, transparent 80%),
    conic-gradient(
      var(--md-ref-secondary-40) ${(props) => props.value}%,
      var(--md-ref-secondary-90) 0
    );
`;

const StyledProgressLabelContent = styled.div`
  position: relative;
  display: inline-flex;
`;

export const ProgressWithLabel: FC<ProgressWithLabelProps> = ({
  value,
  text,
  size = 'md',
}) => {
  return (
    <StyledDiv alignItem="center">
      <Spacing marginRight="sm">
        <StyledProgressLabelContent>
          <StyledProgress value={value} size={size} />
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: getSize(size),
              width: getSize(size),
            }}
          >
            <Typography variant="textLabelLargeNeutral10">{text}</Typography>
          </div>
        </StyledProgressLabelContent>
      </Spacing>
    </StyledDiv>
  );
};
