import { FC, useEffect, useState } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  LoadingScreen,
  AlertDialog,
  TrainingVideo,
  Typography,
} from '@taleemabad/dsm/components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  analyticsService,
  EventNames,
  syncStateManager,
  teacherTrainingDB,
  TrainingQuestionRecord,
  TrainingRecord,
} from '@taleemabad/db';
import { fetchTrainingQuestions } from '@taleemabad/db';
import styled from '@emotion/styled';

const perQuestionMarks: number = 10;

export const TrainingVideoPage: FC = () => {
  const [showNoInternetPopup, setShowNoInternetPopup] =
    useState<boolean>(false);
  const [showRetryErrorPopup, setRetryErrorPopup] = useState<boolean>(false);
  const [questions, setQuestions] = useState<TrainingQuestionRecord[]>([]);
  const [isAPILoading, setIsAPILoading] = useState<boolean>(false);

  const { courseId, trainingId } = useParams();
  const selectedTrainingId = parseInt(trainingId || '0');
  const selectedCourseId = parseInt(courseId || '0');
  const navigate = useNavigate();
  const location = useLocation();
  const [training, setTraining] = useState<TrainingRecord>();

  const handleLoadQuestions = async (id: number) => {
    setIsAPILoading(true);
    const questions = await teacherTrainingDB.getQuestions(id);
    if (!syncStateManager.isConnected) {
      if (questions.length === 0) {
        setShowNoInternetPopup(true);
        analyticsService.trackEvent(EventNames.noInternetNoInternetPopup, {});
      } else {
        setQuestions(questions);
        navigate(`${location.pathname}/quiz`);
      }
    } else {
      try {
        await fetchTrainingQuestions(id, null);
        const fetchedQuestions = await teacherTrainingDB.getQuestions(id);
        setQuestions(fetchedQuestions);
        navigate(`${location.pathname}/quiz`);
      } catch (e: any) {
        setRetryErrorPopup(true);
        analyticsService.trackEvent(EventNames.dataLoadingDataLoadingFailed, {
          ep_screenName: 'training_trainingid_quiz',
        });
      } finally {
        setIsAPILoading(false);
      }
    }
  };

  const buttonClickHandler = async () => {
    await handleLoadQuestions(selectedTrainingId);
    if (training) {
      analyticsService.trackEvent(EventNames.trainingQuizStarted, {
        epCourseId: training?.course,
        epTrainingId: training?.id,
      });
    }
  };

  useEffect(() => {
    if (!trainingId || !courseId) {
      navigate('/training');
    }
  }, [trainingId, courseId, navigate]);

  useEffect(() => {
    (async () => {
      await teacherTrainingDB.setTrainingStatusToInProgress(
        selectedTrainingId,
        selectedCourseId,
      );
    })();
  }, [selectedCourseId, selectedTrainingId]);

  useEffect(() => {
    const fetchTraining = async () => {
      const trainingData =
        await teacherTrainingDB.getTraining(selectedTrainingId);
      setTraining(trainingData);
    };

    fetchTraining();
  }, []);

  if (!training) {
    return <LoadingScreen />;
  }

  const calculateScoreText = () => {
    if (training.assessment) {
      return `${training.assessment.score}/${training.assessment.totalScore}`;
    }
    const defaultQuestionCount = 10;
    const questionCount =
      questions?.filter((item) => ['mcq', 'msq'].includes(item.type)).length ??
      defaultQuestionCount;
    return `0/${questionCount * perQuestionMarks}`;
  };

  return (
    <>
      <AppBar
        pageTitle="Back"
        leftIcon="arrow_back"
        onLeftIconClick={() => navigate(-1)}
        hasPadding={true}
        titleFullWidth={true}
        titleMedium={true}
        isTitleClickable={true}
      />
      <TrainingVideo
        training={training}
        trainingScoreText={calculateScoreText()}
        buttonClickHandler={buttonClickHandler}
        isAPILoading={isAPILoading}
      ></TrainingVideo>
      <AlertDialog
        title="No Internet Connection"
        description="Seems like your internet is not connected. Connect and try again."
        showPopup={showNoInternetPopup}
        onClosePopup={() => setShowNoInternetPopup(false)}
      />

      <Dialog
        open={showRetryErrorPopup}
        title="Error Occurred"
        onClose={() => {
          setRetryErrorPopup(false);
        }}
      >
        <Typography variant="textBodyMedium">
          Seems like you ran into some error.
        </Typography>
        <StyledPopupErrorButton>
          <Button
            children="Retry"
            onClick={() => {
              setRetryErrorPopup(false);
              analyticsService.trackEvent(
                EventNames.retryDataRetryDataClicked,
                {},
              );
              handleLoadQuestions(selectedTrainingId);
            }}
          />
        </StyledPopupErrorButton>
        <StyledPopupErrorButton>
          <Button
            variant="outlined"
            children="Close"
            onClick={() => {
              setRetryErrorPopup(false);
            }}
          />
        </StyledPopupErrorButton>
      </Dialog>
    </>
  );
};

const StyledPopupErrorButton = styled.div`
  md-filled-button {
    width: 100%;
  }
  md-outlined-button {
    width: 100%;
  }
`;
