import { FC, useContext, useState } from 'react';
import {
  Button,
  LoadingScreen,
  Spacing,
  UserProfile,
} from '@taleemabad/dsm/components';
import { useLiveQuery } from 'dexie-react-hooks';
import {
  analyticsService,
  downloadManagerService,
  deactivateUser,
  EventNames,
  logout,
  resetTrainingAndLessonPlan,
  syncStateManager,
  TeacherProfile,
  userCache,
  userDB,
  FeatureFlagNames,
} from '@taleemabad/db';
import styled from '@emotion/styled';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { useNavigate } from 'react-router-dom';
import {
  ClassSubjectContext,
  ClassSubjectChipContext,
} from '@taleemabad/providers';

interface UserInfoMapping {
  [key: string]: {
    itemIcon: string;
    itemText: string;
  };
}

const userInfoMapping: UserInfoMapping = {
  name: { itemIcon: 'person', itemText: 'Name' },
  username: { itemIcon: 'phone', itemText: 'Phone number' },
  cnic: { itemIcon: 'portrait', itemText: 'CNIC' },
  role: { itemIcon: 'card_travel', itemText: 'Designation' },
  schoolName: { itemIcon: 'business_center', itemText: 'School' },
};

export const UserInfoPage: FC = () => {
  const userInfo = useLiveQuery(() => userDB.getUserProfileFromDB());
  const [appVersion, setAppVersion] = useState<string>();

  const { resetSelectedOptions } = useContext(ClassSubjectContext);
  const { resetSelectedItem } = useContext(ClassSubjectChipContext);

  const navigate = useNavigate();

  if (!userInfo) {
    return <LoadingScreen />;
  }
  const profile = userInfo.profiles[0];

  const items = Object.keys(userInfoMapping).map((key) => {
    let itemValue;
    // name and username are in the userInfo object not in the profile object
    if (key === 'name' || key === 'username') {
      itemValue = userInfo[key];
    } else {
      itemValue = profile[key as keyof typeof profile];
    }

    return {
      itemIcon: userInfoMapping[key].itemIcon,
      itemText: userInfoMapping[key].itemText,
      itemValue: String(itemValue),
    };
  });

  if (Capacitor.isNativePlatform()) {
    App.getInfo().then((info) => {
      setAppVersion(info.version);
    });
  }

  const handleLogout = () => {
    analyticsService.trackEvent(EventNames.profileScreenLogout);
    logout();
    resetSelectedOptions();
    resetSelectedItem();
    navigate('/login');
  };

  const handleResetApp = async () => {
    analyticsService.trackEvent(EventNames.refreshDataRefreshDataClicked);
    resetTrainingAndLessonPlan();
    const isEnabled = await userDB.getFeatureFlag(
      FeatureFlagNames.timetableEnabled,
    );
    navigate(isEnabled === true ? '/timetable/view-timetable' : '/training');
    handleRefreshApp(true);
  };

  const handleRefreshApp = (isForceRefresh: boolean = false) => {
    syncStateManager.checkChanges(isForceRefresh);
    // sync downloads from native platforms when performing sync on frontend
    downloadManagerService.syncDownloads();
  };

  const handleDeactivateAccount = async () => {
    const selectedProfile = userCache.getActiveProfile() as TeacherProfile;

    try {
      await deactivateUser();
      analyticsService.trackEvent(EventNames.userProfileDelete, {
        schoolID: selectedProfile.school,
        schoolEmis: selectedProfile.emis,
      });
      handleLogout();
    } catch (e) {}
  };

  return (
    <UserProfileContainer>
      <UserProfile
        items={items}
        appVersion={appVersion}
        onLogoutClick={handleLogout}
        onResetAppClick={handleResetApp}
        onDeactivateAccountClick={handleDeactivateAccount}
      />
      {process.env.NX_ENV === 'local' && (
        <Spacing margin="xxxl">
          <Button
            children="Force Refresh"
            variant="outlined"
            type="button"
            onClick={handleRefreshApp}
          ></Button>
        </Spacing>
      )}
    </UserProfileContainer>
  );
};

const UserProfileContainer = styled.div`
  padding: var(--rk-spacing-lg);
`;
