import styled from '@emotion/styled';

type SpacingSize =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | 'xxxl'
  | 'xxxxl'
  | 'xxxxxl'
  | 'xxxxxxl'
  | 'none';

type SpacingValue =
  | `${SpacingSize} ${SpacingSize} ${SpacingSize} ${SpacingSize}`
  | `${SpacingSize} ${SpacingSize}`
  | SpacingSize;

interface SpacingProps {
  padding?: SpacingValue;
  paddingTop?: SpacingSize;
  paddingRight?: SpacingSize;
  paddingBottom?: SpacingSize;
  paddingLeft?: SpacingSize;
  margin?: SpacingValue;
  marginTop?: SpacingSize;
  marginRight?: SpacingSize;
  marginBottom?: SpacingSize;
  marginLeft?: SpacingSize;
}

const sizeMap: Record<SpacingSize, string> = {
  xs: 'var(--rk-spacing-xs)',
  sm: 'var(--rk-spacing-sm)',
  md: 'var(--rk-spacing-md)',
  lg: 'var(--rk-spacing-lg)',
  xl: 'var(--rk-spacing-xl)',
  xxl: 'var(--rk-spacing-xxl)',
  xxxl: 'var(--rk-spacing-xxxl)',
  xxxxl: 'var(--rk-spacing-xxxxl)',
  xxxxxl: 'var(--rk-spacing-xxxxxl)',
  xxxxxxl: 'var(--rk-spacing-xxxxxxl)',
  none: '0px',
};

const parseSpacingProp = (prop: SpacingValue = 'none'): string =>
  prop
    .split(' ')
    .map((size) => sizeMap[size as SpacingSize])
    .join(' ');

export const Spacing = styled.div<SpacingProps>`
  padding: ${({ padding }) => (padding ? parseSpacingProp(padding) : '')};
  padding-top: ${({ paddingTop }) => (paddingTop ? sizeMap[paddingTop] : '')};
  padding-right: ${({ paddingRight }) =>
    paddingRight ? sizeMap[paddingRight] : ''};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? sizeMap[paddingBottom] : ''};
  padding-left: ${({ paddingLeft }) =>
    paddingLeft ? sizeMap[paddingLeft] : ''};
  margin: ${({ margin }) => (margin ? parseSpacingProp(margin) : '')};
  margin-top: ${({ marginTop }) => (marginTop ? sizeMap[marginTop] : '')};
  margin-right: ${({ marginRight }) =>
    marginRight ? sizeMap[marginRight] : ''};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? sizeMap[marginBottom] : ''};
  margin-left: ${({ marginLeft }) => (marginLeft ? sizeMap[marginLeft] : '')};
`;
