import { ElementType, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { isUrduText } from '@taleemabad/dsm/utils';

interface StyledTypographyProps {
  styleType: string;
  desktopSize: string;
  mobileSize: string;
  fullWidth?: boolean;
  colorVariant?: string;
  ellipseLine?: number;
  inline?: boolean;
  cursor?: string;
}

export const StyledTypography = styled.text<StyledTypographyProps>`
  // desktop font config
  font-family: var(
    --md-sys-typescale-${(props) => props.styleType}-${(props) =>
        props.desktopSize}-font
  );
  font-size: var(
    --md-sys-typescale-${(props) => props.styleType}-${(props) =>
        props.desktopSize}-size
  );
  font-weight: var(
    --md-sys-typescale-${(props) => props.styleType}-${(props) =>
        props.desktopSize}-weight
  );
  line-height: var(
    --md-sys-typescale-${(props) => props.styleType}-${(props) =>
        props.desktopSize}-line-height
  );
  letter-spacing: var(
    --md-sys-typescale-${(props) => props.styleType}-${(props) =>
        props.desktopSize}-letter-spacing
  );

  // mobile font config
  ${mq(BreakpointSizes.mobile)} {
    font-family: var(
      --md-sys-typescale-${(props) => props.styleType}-${(props) =>
          props.mobileSize}-font
    );
    font-size: var(
      --md-sys-typescale-${(props) => props.styleType}-${(props) =>
          props.mobileSize}-size
    );
    font-weight: var(
      --md-sys-typescale-${(props) => props.styleType}-${(props) =>
          props.mobileSize}-weight
    );
    line-height: var(
      --md-sys-typescale-${(props) => props.styleType}-${(props) =>
          props.mobileSize}-line-height
    );
    letter-spacing: var(
      --md-sys-typescale-${(props) => props.styleType}-${(props) =>
          props.mobileSize}-letter-spacing
    );
  }
  cursor: ${(props) => props.cursor || 'default'};
  margin: 0;
  ${(props) => (props.fullWidth ? 'flex: 1' : '')};
  color: var(
    ${(props) =>
      props.colorVariant
        ? props.colorVariant
        : '--md-sys-color-on-surface-variant'}
  );

  ${(props) =>
    props.ellipseLine &&
    `
        display: -webkit-box;
        -webkit-line-clamp: ${props.ellipseLine};
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 100%;
      `}
  ${(props) =>
    props.inline &&
    `
      display: inline;
    `}
`;

export type TypographyVariant =
  | 'textHeadlineLarge'
  | 'textHeadlineLargePrimary'
  | 'textHeadlineLargeSurface'
  | 'textHeadlineMedium'
  | 'textHeadlineMediumError'
  | 'textHeadlineMediumPrimary'
  | 'textHeadlineMediumPrimary40'
  | 'textHeadlineMediumNeutral40'
  | 'textHeadlineMediumOnSurface'
  | 'textHeadlineMediumLightOnSurface'
  | 'textHeadlineSmall'
  | 'textHeadlineSmallSurface'
  | 'textHeadlineSmallPrimary'
  | 'textDisplayLarge'
  | 'textDisplayMedium'
  | 'textDisplaySmall'
  | 'textDisplaySmallLightSurface'
  | 'textTitleLarge'
  | 'textTitleLargeLightError'
  | 'textTitleLargePrimary'
  | 'textTitleLargePrimary40'
  | 'textTitleLargeOnSurface'
  | 'textTitleLargeNeutral10'
  | 'textTitleLargeNeutral40'
  | 'textTitleMedium'
  | 'textTitleMediumNeutral10'
  | 'textTitleMediumNeutral50'
  | 'textTitleMediumPrimary'
  | 'textTitleMediumPrimaryFixed'
  | 'textTitleMediumSurface'
  | 'textTitleMediumSurfaceVariant'
  | 'textTitleMediumSecondary'
  | 'textTitleMediumSecondaryFixed'
  | 'textTitleSmall'
  | 'textTitleSmallonSurface'
  | 'textBodyLarge'
  | 'textBodyLargeLightPrimary'
  | 'textBodyLargeUtilityGrayBlue500'
  | 'textBodyLargeNeutral10'
  | 'textBodyLargeNeutral40'
  | 'textBodyLargeNeutral50'
  | 'textBodyLargePrimary40'
  | 'textBodyLargePrimaryContainer'
  | 'textBodyLargeSurface'
  | 'textBodyLargeOnSurface'
  | 'textBodyLargeOnSurfaceVariant'
  | 'textBodyLargeLightOnSurface'
  | 'textBodyLargeSecondary'
  | 'textBodyLargeError50'
  | 'textBodyLargeError60'
  | 'textBodyMedium'
  | 'textBodyMediumNeutral40'
  | 'textBodyMediumSurface'
  | 'textBodyMediumOnSurfaceVariant'
  | 'textBodySmall'
  | 'textBodySmallNeutral40'
  | 'textBodySmallOnSurface'
  | 'textBodySmallSchemesError'
  | 'textLabelLarge'
  | 'textLabelLargePrimary'
  | 'textLabelLargeOnPrimary'
  | 'textLabelLargePrimary30'
  | 'textLabelLargeNeutral10'
  | 'textLabelLargeNeutral40'
  | 'textLabelLargeNeutral70'
  | 'textLabelLargePrimary60'
  | 'textLabelLargeLightPrimary'
  | 'textLabelLargeSecondary'
  | 'textLabelLargeSuccess'
  | 'textLabelMedium'
  | 'textLabelMediumSurface'
  | 'textLabelMediumSurfaceVariant'
  | 'textLabelSmall';

export interface TypographyProps extends PropsWithChildren {
  variant: TypographyVariant;
  fullWidth?: boolean;
  ellipseLine?: number;
  inline?: boolean;
  onClick?: () => void;
  color?: string;
  cursor?: string;
}

interface TypographyStyle {
  styleType: string;
  desktopSize: string;
  mobileSize: string;
  elemType: ElementType;
  colorVariant?: string;
}

const typographyStyleMap: Record<TypographyVariant, TypographyStyle> = {
  // We will be following these rules
  // if the desktop size is large the mobilesize will be medium
  // if desktop is medium then mobilesize will be medium
  // if desktop is small then mobilesize will be small

  textDisplayLarge: {
    styleType: 'display',
    desktopSize: 'large',
    mobileSize: 'medium',
    elemType: 'h1',
  },
  textDisplayMedium: {
    styleType: 'display',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'h2',
  },
  textDisplaySmall: {
    styleType: 'display',
    desktopSize: 'small',
    mobileSize: 'small',
    elemType: 'h3',
  },
  textDisplaySmallLightSurface: {
    styleType: 'display',
    desktopSize: 'small',
    mobileSize: 'small',
    elemType: 'h3',
    colorVariant: '--md-sys-color-light-surface',
  },
  textHeadlineLarge: {
    styleType: 'headline',
    desktopSize: 'large',
    mobileSize: 'medium',
    elemType: 'h1',
  },
  textHeadlineLargePrimary: {
    styleType: 'headline',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'h1',
    colorVariant: '--md-sys-color-primary',
  },
  textHeadlineLargeSurface: {
    styleType: 'headline',
    desktopSize: 'large',
    mobileSize: 'medium',
    elemType: 'h1',
    colorVariant: '--md-sys-color-on-surface',
  },
  textHeadlineMedium: {
    styleType: 'headline',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'h2',
  },
  textHeadlineMediumError: {
    styleType: 'headline',
    desktopSize: 'medium',
    mobileSize: 'small',
    elemType: 'h2',
    colorVariant: '--md-sys-color-error',
  },
  textHeadlineMediumPrimary: {
    styleType: 'headline',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'h2',
    colorVariant: '--md-sys-color-primary',
  },
  textHeadlineMediumPrimary40: {
    styleType: 'headline',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'h2',
    colorVariant: '--md-ref-primary-40',
  },
  textHeadlineMediumNeutral40: {
    styleType: 'headline',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'h2',
    colorVariant: '--md-ref-neutral-40',
  },
  textHeadlineMediumOnSurface: {
    styleType: 'headline',
    desktopSize: 'medium',
    mobileSize: 'small',
    elemType: 'h2',
    colorVariant: '--md-sys-color-on-surface',
  },
  textHeadlineMediumLightOnSurface: {
    styleType: 'headline',
    desktopSize: 'medium',
    mobileSize: 'small',
    elemType: 'h2',
    colorVariant: '--md-sys-color-light-on-surface',
  },
  textHeadlineSmall: {
    styleType: 'headline',
    desktopSize: 'small',
    mobileSize: 'small',
    elemType: 'h3',
  },
  textHeadlineSmallSurface: {
    styleType: 'headline',
    desktopSize: 'small',
    mobileSize: 'small',
    elemType: 'h3',
    colorVariant: '--md-sys-color-on-surface',
  },
  textHeadlineSmallPrimary: {
    styleType: 'headline',
    desktopSize: 'small',
    mobileSize: 'small',
    elemType: 'h3',
    colorVariant: '--md-sys-color-primary',
  },
  textBodyLarge: {
    styleType: 'body',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'p',
  },
  textBodyLargeLightPrimary: {
    styleType: 'body',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'p',
    colorVariant: '--md-sys-color-light-primary',
  },
  textBodyLargeUtilityGrayBlue500: {
    styleType: 'body',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'p',
    colorVariant: '--md-sys-utility-gray-blue-500',
  },
  textBodyLargeNeutral10: {
    styleType: 'body',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'p',
    colorVariant: '--md-ref-neutral-10',
  },
  textBodyLargePrimaryContainer: {
    styleType: 'body',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'p',
    colorVariant: '--md-sys-color-on-primary-container',
  },
  textBodyLargeSurface: {
    styleType: 'body',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'p',
    colorVariant: '--md-sys-color-on-surface',
  },
  textBodyLargeOnSurface: {
    styleType: 'body',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'p',
    colorVariant: '--md-sys-color-on-surface',
  },
  textBodyLargeLightOnSurface: {
    styleType: 'body',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'p',
    colorVariant: '--md-sys-color-light-on-surface',
  },
  textBodyLargeOnSurfaceVariant: {
    styleType: 'body',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'p',
    colorVariant: '--md-sys-color-on-surface-variant',
  },
  textBodyLargeNeutral40: {
    styleType: 'body',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'p',
    colorVariant: '--md-ref-neutral-40',
  },
  textBodyLargeNeutral50: {
    styleType: 'body',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'p',
    colorVariant: '--md-ref-neutral-50',
  },
  textBodyLargePrimary40: {
    styleType: 'body',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'p',
    colorVariant: '--md-ref-primary-40',
  },
  textBodyLargeSecondary: {
    styleType: 'body',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'p',
    colorVariant: '--md-sys-color-secondary',
  },
  textBodyLargeError50: {
    styleType: 'body',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'p',
    colorVariant: '--md-ref-error-50',
  },
  textBodyLargeError60: {
    styleType: 'body',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'p',
    colorVariant: '--md-ref-error-60',
  },
  textBodyMedium: {
    styleType: 'body',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'p',
  },

  textBodyMediumNeutral40: {
    styleType: 'body',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'p',
    colorVariant: '--md-ref-neutral-40',
  },
  textBodyMediumSurface: {
    styleType: 'body',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'p',
    colorVariant: '--md-sys-color-on-surface',
  },
  textBodyMediumOnSurfaceVariant: {
    styleType: 'body',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'p',
    colorVariant: '--md-sys-color-on-surface-variant',
  },
  textBodySmall: {
    styleType: 'body',
    desktopSize: 'small',
    mobileSize: 'small',
    elemType: 'p',
  },
  textBodySmallNeutral40: {
    styleType: 'body',
    desktopSize: 'small',
    mobileSize: 'small',
    elemType: 'p',
    colorVariant: '--md-ref-neutral-40',
  },
  textBodySmallOnSurface: {
    styleType: 'body',
    desktopSize: 'small',
    mobileSize: 'small',
    elemType: 'p',
    colorVariant: '--md-sys-color-on-surface',
  },
  textBodySmallSchemesError: {
    styleType: 'body',
    desktopSize: 'small',
    mobileSize: 'small',
    elemType: 'p',
    colorVariant: '--md-sys-color-schemes-error',
  },
  textTitleLarge: {
    styleType: 'title',
    desktopSize: 'large',
    mobileSize: 'medium',
    elemType: 'h1',
  },
  textTitleLargeLightError: {
    styleType: 'title',
    desktopSize: 'large',
    mobileSize: 'medium',
    elemType: 'h1',
    colorVariant: '--md-sys-color-error',
  },
  textTitleLargeOnSurface: {
    styleType: 'title',
    desktopSize: 'large',
    mobileSize: 'medium',
    elemType: 'h1',
    colorVariant: '--md-sys-color-on-surface-variant',
  },
  textTitleLargeNeutral10: {
    styleType: 'title',
    desktopSize: 'large',
    mobileSize: 'medium',
    elemType: 'h1',
    colorVariant: '--md-ref-neutral-10',
  },
  textTitleLargeNeutral40: {
    styleType: 'title',
    desktopSize: 'large',
    mobileSize: 'medium',
    elemType: 'h1',
    colorVariant: '--md-ref-neutral-40',
  },
  textTitleLargePrimary: {
    styleType: 'title',
    desktopSize: 'large',
    mobileSize: 'medium',
    elemType: 'h1',
    colorVariant: '--md-sys-color-primary',
  },
  textTitleLargePrimary40: {
    styleType: 'title',
    desktopSize: 'large',
    mobileSize: 'medium',
    elemType: 'h1',
    colorVariant: '--md-ref-primary-40',
  },
  textTitleMedium: {
    styleType: 'title',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'h1',
  },
  textTitleMediumNeutral10: {
    styleType: 'title',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'h2',
    colorVariant: '--md-ref-neutral-10',
  },
  textTitleMediumNeutral50: {
    styleType: 'title',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'h2',
    colorVariant: '--md-ref-neutral-50',
  },
  textTitleMediumPrimary: {
    styleType: 'title',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'h1',
    colorVariant: '--md-sys-color-primary',
  },
  textTitleMediumPrimaryFixed: {
    styleType: 'title',
    desktopSize: 'large',
    mobileSize: 'medium',
    elemType: 'h1',
    colorVariant: '--md-sys-color-on-primary-fixed',
  },
  textTitleMediumSurfaceVariant: {
    styleType: 'title',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'h1',
    colorVariant: '--md-sys-color-on-surface-variant',
  },
  textTitleMediumSurface: {
    styleType: 'title',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'h1',
    colorVariant: '--md-sys-color-on-surface',
  },
  textTitleMediumSecondary: {
    styleType: 'title',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'h1',
    colorVariant: '--md-sys-color-secondary',
  },
  textTitleMediumSecondaryFixed: {
    styleType: 'title',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'h1',
    colorVariant: '--md-sys-color-on-secondary-fixed',
  },
  textTitleSmall: {
    styleType: 'title',
    desktopSize: 'small',
    mobileSize: 'small',
    elemType: 'h1',
  },
  textTitleSmallonSurface: {
    styleType: 'title',
    desktopSize: 'small',
    mobileSize: 'small',
    elemType: 'h1',
    colorVariant: '--md-sys-color-on-surface',
  },
  textLabelLarge: {
    styleType: 'label',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'label',
  },
  textLabelLargePrimary: {
    styleType: 'label',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'label',
    colorVariant: '--md-sys-color-primary',
  },
  textLabelLargeOnPrimary: {
    styleType: 'label',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'label',
    colorVariant: '--md-sys-color-on-primary',
  },
  textLabelLargePrimary30: {
    styleType: 'label',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'label',
    colorVariant: '--md-ref-primary-30',
  },
  textLabelLargeNeutral10: {
    styleType: 'label',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'label',
    colorVariant: '--md-ref-neutral-10',
  },
  textLabelLargeNeutral40: {
    styleType: 'label',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'label',
    colorVariant: '--md-ref-neutral-40',
  },
  textLabelLargeNeutral70: {
    styleType: 'label',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'label',
    colorVariant: '--md-ref-neutral-70',
  },
  textLabelLargePrimary60: {
    styleType: 'label',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'label',
    colorVariant: '--md-ref-primary-60',
  },
  textLabelLargeLightPrimary: {
    styleType: 'label',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'label',
    colorVariant: '--md-sys-light-primary',
  },
  textLabelLargeSecondary: {
    styleType: 'label',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'label',
    colorVariant: '--md-sys-color-secondary',
  },
  textLabelLargeSuccess: {
    styleType: 'label',
    desktopSize: 'large',
    mobileSize: 'large',
    elemType: 'label',
    colorVariant: '--md-sys-color-on-success',
  },
  textLabelMedium: {
    styleType: 'label',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'label',
  },
  textLabelMediumSurface: {
    styleType: 'label',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'label',
    colorVariant: '--md-sys-color-on-surface',
  },
  textLabelMediumSurfaceVariant: {
    styleType: 'label',
    desktopSize: 'medium',
    mobileSize: 'medium',
    elemType: 'label',
    colorVariant: '--md-sys-color-on-surface-variant',
  },
  textLabelSmall: {
    styleType: 'label',
    desktopSize: 'small',
    mobileSize: 'small',
    elemType: 'label',
  },
};

export const Typography = ({
  variant,
  children,
  fullWidth = false,
  ellipseLine,
  inline,
  onClick = () => {},
  ...otherProps
}: TypographyProps) => {
  const typographyProps = typographyStyleMap[variant];
  const isUrdu = typeof children === 'string' && isUrduText(children);
  const className = isUrdu ? 'urdu-font' : '';

  return (
    <StyledTypography
      as={typographyProps.elemType}
      {...typographyProps}
      fullWidth={fullWidth}
      ellipseLine={ellipseLine}
      inline={inline}
      onClick={() => onClick && onClick()}
      className={className}
      {...otherProps}
    >
      {children}
    </StyledTypography>
  );
};
