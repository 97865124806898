import styled from '@emotion/styled';
import { FC, useState } from 'react';
import {
  CourseRecord,
  ICourse,
  ProgressStatus,
  TrainingCourseProgressInfo,
} from '@taleemabad/db';
import { CourseList } from '../../../molecules/course-list/course-list';
import {
  CourseProgressBanner,
  ProgressInformation,
  ProgressInformationProps,
  Tab,
  Tabs,
  Spacing,
  IconText,
  Typography,
} from '@taleemabad/dsm/components';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { ChipFilterWithContainer } from '../../../atoms/chip/chip-filter';

interface TeacherTrainingCoursesProps {
  allCourses: ICourse[];
  courseProgressBanner: TrainingCourseProgressInfo;
  onTrainingCourseProgressBannerClick: (
    course: TrainingCourseProgressInfo,
  ) => void;
  progressInformation: ProgressInformationProps;
  onCourseClick: (course: ICourse) => void;
}
export const TeacherTrainingCourses: FC<TeacherTrainingCoursesProps> = ({
  allCourses,
  courseProgressBanner,
  onTrainingCourseProgressBannerClick,
  progressInformation,
  onCourseClick,
}) => {
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const generalCourses = allCourses.filter(
    (course) => course.gradeGroup === null,
  );
  const subjectCourses = allCourses.filter(
    (course) => course.gradeGroup !== null,
  );

  const handleTabChange = (tabKey: number) => {
    setActiveTabKey(tabKey);
  };

  const handleFilterChange = (filter: string) => {
    if (filter !== selectedFilter) {
      setSelectedFilter(filter);
    } else {
      setSelectedFilter('All');
    }
  };

  const filters = ['All', 'Completed', 'In Progress', 'Pending'];

  const chipFilters = filters.map((filter) => (
    <ChipFilterWithContainer
      key={filter}
      label={filter}
      onClick={() => handleFilterChange(filter)}
      selected={selectedFilter === filter}
    />
  ));

  const tabs: Tab[] = [
    {
      key: 0,
      label: 'General Courses',
    },
    {
      key: 1,
      label: 'Subject Courses',
    },
  ];

  const filteredCourses = (courses: CourseRecord[]) => {
    switch (selectedFilter) {
      case 'Completed':
        return courses.filter(
          (course) => course.courseStatus === ProgressStatus.COMPLETED,
        );
      case 'In Progress':
        return courses.filter(
          (course) => course.courseStatus === ProgressStatus.IN_PROGRESS,
        );
      case 'Pending':
        return courses.filter(
          (course) => course.courseStatus === ProgressStatus.PENDING,
        );
      default:
        return courses;
    }
  };

  const renderCourses = (courses: CourseRecord[]) => {
    const filtered = filteredCourses(courses);
    if (filtered.length === 0) {
      let message = '';
      switch (selectedFilter) {
        case 'Completed':
          message = 'No courses completed yet';
          break;
        case 'In Progress':
          message = 'No courses in progress yet';
          break;
        case 'Pending':
          message = 'No courses pending yet';
          break;
        default:
          message = 'No courses found';
      }
      return <Typography variant="textBodyLarge">{message}</Typography>;
    } else {
      return <CourseList courses={filtered} onCourseClick={onCourseClick} />;
    }
  };

  return (
    <TeacherTrainingCourseContainer>
      {!courseProgressBanner.allTrainingsCompleted && (
        <Typography variant="textTitleLargeNeutral10">
          {courseProgressBanner.progressText}
        </Typography>
      )}
      <ProgressContainer>
        <CourseProgressBanner
          trainingCourseProgressInfo={courseProgressBanner}
          onTrainingCourseProgressBannerClick={
            onTrainingCourseProgressBannerClick
          }
        ></CourseProgressBanner>
        <ProgressInformationContainer>
          <ProgressInformation {...progressInformation}></ProgressInformation>
        </ProgressInformationContainer>
      </ProgressContainer>
      <Spacing marginTop="xxl">
        <StickyContainer>
          <HeadlineContainer>
            <IconText
              icon="import_contacts"
              text="Courses"
              size="large"
              textVariant="textHeadlineMediumPrimary40"
              iconColor="--md-ref-primary-40"
            ></IconText>
            <ChipFilterContainer>{chipFilters}</ChipFilterContainer>
          </HeadlineContainer>
          <Tabs
            activeTabKey={activeTabKey}
            tabs={tabs}
            onChange={handleTabChange}
          />
        </StickyContainer>

        {activeTabKey === 0 && renderCourses(generalCourses)}
        {activeTabKey === 1 && renderCourses(subjectCourses)}
      </Spacing>
    </TeacherTrainingCourseContainer>
  );
};

const TeacherTrainingCourseContainer = styled.div``;

const ProgressContainer = styled.div`
  display: flex;
  background-color: var(--md-sys-color-surface-container-highest);
  margin-top: var(--rk-spacing-xl);
  ${mq(BreakpointSizes.xs)} {
    flex-direction: column;
    background-color: unset;
  }
`;

const ProgressInformationContainer = styled.div`
  padding: var(--rk-spacing-xl);
  width: 40%;
  border-left: 1px solid;
  border-image: linear-gradient(
      to bottom,
      transparent,
      var(--md-ref-neutral-90),
      transparent
    )
    1;
  ${mq(BreakpointSizes.xs)} {
    padding: unset;
    width: unset;
    border-left: unset;
    margin-top: var(--rk-spacing-xl);
  }
`;

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  background: var(--md-sys-color-on-primary);
`;

const ChipFilterContainer = styled.div`
  display: flex;
  gap: var(--rk-spacing-md);
  overflow-y: auto;
  white-space: nowrap;
  height: 40px;
  margin-top: var(--rk-spacing-lg);
`;

const HeadlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--rk-spacing-xl);
  ${mq(BreakpointSizes.xs)} {
    flex-direction: column;
  }
`;
