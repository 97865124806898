// @ts-nocheck
import '@material/web/dialog/dialog.js';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { Typography } from '../typography/typography';
import { SerializedStyles } from '@emotion/react';
import { StyledDiv } from '../../common/components/styled-div';

export interface DialogProps extends PropsWithChildren {
  open: boolean;
  title?: string;
  emotionCss?: SerializedStyles;
  hasBackdropClick?: boolean;
  onClose?: () => void;
}

export const Dialog = ({
  open,
  title,
  emotionCss,
  children,
  hasBackdropClick = true,
  onClose,
  ...otherProps
}: DialogProps) => {
  const dialogRef = useRef(null);

  useEffect(() => {
    const dialogElement = dialogRef.current;
    const handleClose = (event: Event) => {
      if (hasBackdropClick || !open) {
        onClose();
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    };
    if (dialogElement) {
      dialogElement.addEventListener('close', handleClose);
    }
    return () => {
      if (dialogElement) {
        dialogElement.removeEventListener('close', handleClose);
      }
    };
  }, [hasBackdropClick, onClose, open]);

  const props = { ref: dialogRef };
  if (open) {
    props.open = true;
  }

  return (
    <md-dialog {...otherProps} {...props} type="basic">
      <StyledDiv
        slot="content"
        css={emotionCss}
        flexDirection="column"
        gap="20"
      >
        {title && (
          <Typography {...otherProps} variant="textHeadlineSmall">
            {title}
          </Typography>
        )}
        {children}
      </StyledDiv>
    </md-dialog>
  );
};
