import { FC } from 'react';
import {
  CourseRecord,
  DownloadStateTitle,
  TrainingRecord,
} from '@taleemabad/db';
import {
  ChipAssist,
  IconText,
  Spacing,
  TrainingCardList,
  Typography,
} from '@taleemabad/dsm/components';
import styled from '@emotion/styled';

interface CourseTrainingsProps {
  course: CourseRecord;
  trainings: TrainingRecord[];
  downloadStateTitle: DownloadStateTitle;
  downloadState?: boolean;
  downloadEnabled?: boolean;
  onTrainingSelected: (item: TrainingRecord) => void;
  onDownloadStateChange: () => void;
  onDownloadIconClick: (item: TrainingRecord) => void;
}

export const CourseTrainings: FC<CourseTrainingsProps> = ({
  course,
  trainings,
  downloadStateTitle,
  downloadState = false,
  downloadEnabled = false,
  onTrainingSelected,
  onDownloadStateChange,
  onDownloadIconClick,
}) => {
  return (
    <>
      <Typography variant="textHeadlineMediumPrimary">
        {course.title}
      </Typography>
      {course.gradeGroup === null && (
        <CourseDetailContainer>
          <Typography variant="textTitleMediumNeutral10">
            About this Course
          </Typography>
          <Typography variant="textBodyLargeNeutral50">
            {course.description}
          </Typography>
          <Typography variant="textTitleMediumNeutral10">
            Skills you will gain
          </Typography>
          <StyledListBox>
            {course?.keywords.map((keyword: string) => (
              <ChipAssist key={keyword} label={keyword} />
            ))}
          </StyledListBox>
        </CourseDetailContainer>
      )}
      <Spacing marginTop="xxl" marginBottom="lg">
        <TrainingHeader>
          <IconText
            icon="play_circle"
            iconColor="--md-ref-primary-40"
            text="Trainings"
            textVariant="textTitleLargePrimary40"
          ></IconText>
          {downloadEnabled && (
            <Typography
              variant="textBodyLargePrimary40"
              onClick={onDownloadStateChange}
            >
              {downloadStateTitle}
            </Typography>
          )}
        </TrainingHeader>
      </Spacing>
      <TrainingCardList
        trainings={trainings}
        downloadUIState={downloadState}
        onTrainingCardClick={onTrainingSelected}
        onTrainingCardIconClick={onDownloadIconClick}
      />
    </>
  );
};

const CourseDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--rk-spacing-md);
  margin-top: var(--rk-spacing-xl);
`;

const StyledListBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--rk-spacing-md);
`;

const TrainingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
