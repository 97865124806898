import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useBreakpoint } from '@taleemabad/shared/hooks';
import { AuthCardContainer } from '../components/auth-card';
import {
  Typography,
  PasswordField,
  Button,
  Logo,
  PhoneFieldResult,
  Spacing,
} from '@taleemabad/dsm/components';
import { validatePasswords } from 'apps/school-app/src/utils/utils';
import { ConfigContext } from 'apps/school-app/src/providers/config-provider';
import { EventNames, analyticsService, createPassword } from '@taleemabad/db';

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;
const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 68px;
`;
const StyledLargeHeading = styled.div`
  display: flex;
  margin-bottom: 24px;
`;
export const SetPasswordPage = () => {
  const navigate = useNavigate();
  const { appLogoPath } = useContext(ConfigContext);
  const { tablet, mobileHorizontal, mobile } = useBreakpoint();
  const isLowResDevice = tablet || mobileHorizontal || mobile;
  const [formData, setFormData] = useState([
    {
      name: 'password',
      value: '',
      hasError: true,
      errorText: '',
      isVisible: false,
      label: 'Create password',
      placeholder: 'Enter here',
    },
    {
      name: 'confirmPassword',
      value: '',
      hasError: true,
      errorText: '',
      isVisible: false,
      label: 'Confirm password',
      placeholder: 'Enter here',
    },
  ]);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const usersPhone = localStorage.getItem('tempLSPhoneKey') || '';
  if (!usersPhone) {
    navigate('/login', { replace: true });
  }

  const handleFieldChange = (index: number, data: PhoneFieldResult) => {
    let errorText = '';
    let hasError = false;

    if (index === 0) {
      // only apply validation for 1st password field since we don't want to show the same error message for 2nd confirm password field
      errorText = validatePasswords(data.value);
      hasError = !!errorText;
    }
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index] = {
        ...updatedFormData[index],
        value: data.value,
        hasError: hasError,
        errorText: errorText,
      };
      return updatedFormData;
    });
  };

  const toggleVisibility = (index: number) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index] = {
        ...updatedFormData[index],
        isVisible: !updatedFormData[index].isVisible,
      };
      return updatedFormData;
    });
  };
  const handleSubmit = async () => {
    setHasSubmitted(true);
    const passwordError = validatePasswords(formData[0].value);
    const passwordMatch = formData[0].value === formData[1].value;

    let errorMessage = '';
    if (passwordError) {
      errorMessage = passwordError;
    } else if (!passwordMatch) {
      errorMessage = 'Passwords do not match';
    }

    setFormData((prevFormData) => [
      {
        ...prevFormData[0],
        hasError: !!passwordError,
        errorText: passwordError,
      },
      {
        ...prevFormData[1],
        hasError: !passwordMatch,
        errorText: !passwordMatch ? 'Passwords do not match' : '',
      },
    ]);

    if (errorMessage) {
      analyticsService.trackEvent(EventNames.signupPasswordError, {
        ep_errorMessage: errorMessage,
      });
      return;
    }

    const data = await createPassword(
      usersPhone,
      formData[0].value,
      formData[1].value,
    );
    // TODO if there is no data then what happens?
    if (data) {
      analyticsService.trackEvent(EventNames.signupPasswordComplete);
      navigate('/user-details');
    }
  };
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSubmit();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [formData]);
  return (
    <AuthCardContainer>
      {isLowResDevice && (
        <StyledImageWrapper>
          <Logo
            altName="outer-layer"
            width={275}
            src={`../../assets/${appLogoPath}`}
          />
        </StyledImageWrapper>
      )}
      {!isLowResDevice ? (
        <Spacing marginBottom="md">
          <Typography variant="textHeadlineLargeSurface">
            Choose Password
          </Typography>
        </Spacing>
      ) : (
        <Spacing marginBottom="md">
          <Typography variant="textHeadlineMediumOnSurface">
            Choose Password
          </Typography>
        </Spacing>
      )}
      <StyledLargeHeading>
        <Typography
          variant="textBodyLargeSurface"
          data-cy="reset-password-choose-password-subtitle"
        >
          Must be at least 10 characters.{' '}
        </Typography>
      </StyledLargeHeading>
      {formData.map((field, index) => (
        <StyledCardContent key={index}>
          <PasswordField
            key={index}
            name={field.name}
            type={field.isVisible ? 'text' : 'password'}
            variant="outlined"
            label={field.label}
            placeholder={field.placeholder}
            errorText={hasSubmitted ? field.errorText : ''}
            onChange={(data) => handleFieldChange(index, data)}
            onToggleVisibility={() => toggleVisibility(index)}
            data-cy={`password-field-${field.name}`}
          />
        </StyledCardContent>
      ))}
      <Button
        children="Next"
        onClick={handleSubmit}
        data-cy="sign-up-set-password-next-button"
      />
    </AuthCardContainer>
  );
};
