import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { SelectOption } from '@taleemabad/dsm/components';

interface SelectedOptions {
  classId: undefined | SelectOption;
  subjectId: undefined | SelectOption;
}

interface ClassSubjectState {
  selectedOptions: SelectedOptions;
  setSelectedOptions: Dispatch<SetStateAction<SelectedOptions>>;
  resetSelectedOptions: () => void;
}

export const ClassSubjectContext = createContext<ClassSubjectState>(null!);

export const ClassSubjectProvider = ({ children }: PropsWithChildren) => {
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({
    classId: undefined,
    subjectId: undefined,
  });

  const resetSelectedOptions = () => {
    setSelectedOptions({
      classId: undefined,
      subjectId: undefined,
    });
  };

  const value = useMemo(
    () => ({ selectedOptions, setSelectedOptions, resetSelectedOptions }),
    [selectedOptions, setSelectedOptions],
  );

  return (
    <ClassSubjectContext.Provider value={value}>
      {children}
    </ClassSubjectContext.Provider>
  );
};
