import { useEffect, useState } from 'react';
import { ILessonPlanStep } from '@taleemabad/db';
import {
  Button,
  Typography,
  LpStatementList,
  TbLpStepsSwitcher,
  Spacing,
  AlertDialog,
} from '@taleemabad/dsm/components';
import styled from '@emotion/styled';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import React from 'react';
import { isUrduText } from '@taleemabad/dsm/utils';
import { ChipFilter } from '../../atoms/chip/chip-filter';
import { LoadingScreen } from '@taleemabad/dsm/components';

export interface IGenericLessonPlanStep {
  name: string;
  time: number;
  stepData: ILessonPlanStep[];
}

interface LessonDetailWithSteps {
  steps: IGenericLessonPlanStep[];
  id: number;
  topic: string;
  type: string;
  index: number;
  bookChapter: string;
  schoolClass: string;
  gradeSubject: string;
  book: number;
  videos: string[];
  lpSlo: string[];
  classroomSetupInstructions: ILessonPlanStep[];
  // ? should this be in the main step?
  homeworkInstructions: ILessonPlanStep[];
  summary: ILessonPlanStep[];
  resourcesNeeded: string[];
}

interface LessonPlanViewerProps {
  lpDetail: LessonDetailWithSteps;
  translatedLps?: any;
  isLoading?: boolean;
  showNoInternetPopup?: boolean;
  isTranslationEnabled?: boolean;
  onLessonComplete: () => void;
  setShowNoInternetPopup: (value: boolean) => void;
  translateLanguage: (language: string, currentStepName: string) => void;
  onSectionChange?: (section: string) => void;
  // TODO: add other properties, bookID, chapterID, etc for analytics
}

const StickyTopContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  padding-bottom: var(--rk-spacing-xxl);
  background-color: var(--md-sys-color-on-primary);
`;
const ChipFilterContainer = styled.div`
  margin: var(--rk-spacing-xl);
  display: flex;
  align-items: center;
`;
const ChipFilterWrapper = styled.div`
  margin: 0 var(--rk-spacing-md) 0 var(--rk-spacing-md);
`;
interface SwitcherContainerProps {
  isScrolling: boolean;
}

const SwitcherContainer = styled.div<SwitcherContainerProps>`
  padding-bottom: var(--rk-spacing-xxl);
  // only apply padding top if is scrolling is true, since it sticks to the top when scrolling
  padding-top: ${({ isScrolling }) =>
    isScrolling ? 'var(--rk-spacing-lg)' : ''};
`;

interface ButtonContainerProps {
  isLpCompleted: boolean;
  currentStepIndex: number;
}

const ButtonContainer = styled.div<ButtonContainerProps>`
  display: flex;
  justify-content: ${(props) =>
    props.currentStepIndex > 0 && !props.isLpCompleted
      ? 'space-between'
      : 'flex-end'};
  margin-top: var(--rk-spacing-xxxl);
  margin-bottom: var(--rk-spacing-xxxl);

  md-filled-button {
    ${mq(BreakpointSizes.xs)} {
      width: ${({ isLpCompleted }) => (isLpCompleted ? '100%' : '')};
    }
  }
`;
const LpStatementMemorized = React.memo(LpStatementList);

export const LessonPlanViewer: React.FC<LessonPlanViewerProps> = ({
  lpDetail,
  onLessonComplete,
  translateLanguage,
  translatedLps,
  isLoading,
  showNoInternetPopup = false,
  isTranslationEnabled = false,
  setShowNoInternetPopup,
  onSectionChange,
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [defaultLanguage, setDefaultLanguage] = useState<string>();
  const [language, setLanguage] = useState<string>('');
  // when next or previous button is clicked, we want to scroll the top of the page
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const determineLanguageAndDirection = () => {
    const isUrdu = isUrduText(lpDetail.topic);
    return {
      language: isUrdu ? 'ur' : 'en',
      isRtl: isUrdu,
    };
  };

  useEffect(() => {
    const { language, isRtl } = determineLanguageAndDirection();
    setDefaultLanguage(language);
    setLanguage(language);
    setIsRtl(isRtl);
  }, [lpDetail.topic]);

  useEffect(() => {
    language === 'ur' ? setIsRtl(true) : setIsRtl(false);
    if (!translatedLps && language !== defaultLanguage) {
      translateLanguage(language, currentStep.name);
    }
  }, [language]);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleNextStep = () => {
    setCurrentStepIndex((prevIndex) => {
      // using math.min to prevent index from going out of bounds, technically it shouldn't happen because the next button is disabled at last step
      // but this adds an extra layer of safety
      const newIndex = Math.min(prevIndex + 1, lpDetail.steps.length - 1);

      scrollToTop();
      return newIndex;
    });
  };
  const handlePreviousStep = () => {
    setCurrentStepIndex((prevIndex) => {
      const newIndex = Math.max(prevIndex - 1, 0);
      scrollToTop();
      return newIndex;
    });
  };

  const currentStep = lpDetail.steps[currentStepIndex];
  const isLastStep = currentStepIndex === lpDetail.steps.length - 1;
  const translatedStep = translatedLps?.steps[currentStepIndex];
  const handleChangeStep = (direction: string) => {
    if (direction === 'next') {
      handleNextStep();
      onSectionChange?.(lpDetail.steps[currentStepIndex + 1].name);
    } else if (direction === 'prev') {
      handlePreviousStep();
      onSectionChange?.(lpDetail.steps[currentStepIndex - 1]?.name);
    }
  };

  const handleButtonClick = () => {
    if (isLastStep) {
      onLessonComplete();
    } else {
      handleNextStep();
    }
    onSectionChange?.(lpDetail.steps[currentStepIndex + 1]?.name);
  };

  return (
    <div>
      {isLoading && <LoadingScreen />}
      <AlertDialog
        title="No Internet Connection"
        description="Seems like your internet is not connected. Connect and try again."
        showPopup={showNoInternetPopup}
        onClosePopup={() => {
          setLanguage(defaultLanguage || 'en');
          setShowNoInternetPopup(false);
        }}
      />

      {!showNoInternetPopup && (
        <>
          {isTranslationEnabled && (
            <ChipFilterContainer>
              <Typography variant="textBodyLargeOnSurface">
                Translate in:{' '}
              </Typography>
              <Spacing margin="none md none md">
                <ChipFilter
                  label={'English'}
                  selected={language === 'en'}
                  onClick={() => setLanguage('en')}
                  alwaysFocusable={true}
                />
              </Spacing>
              <Spacing margin="none md none md">
                <ChipFilter
                  label={'Urdu'}
                  selected={language === 'ur'}
                  onClick={() => setLanguage('ur')}
                />
              </Spacing>
            </ChipFilterContainer>
          )}
          <StickyTopContainer>
            <SwitcherContainer isScrolling={isScrolling}>
              <TbLpStepsSwitcher
                stepName={currentStep.name}
                stepTime={currentStep.time}
                isLeftIconActive={currentStepIndex > 0}
                isRightIconActive={currentStepIndex < lpDetail.steps.length - 1}
                isScrolling={isScrolling}
                onChange={handleChangeStep}
              />
            </SwitcherContainer>
            <Typography variant="textHeadlineSmallPrimary">
              {lpDetail.topic}
            </Typography>
          </StickyTopContainer>
          <LpStatementMemorized
            id={lpDetail.id}
            statements={
              language === defaultLanguage
                ? currentStep.stepData
                : translatedStep?.stepData
            }
            isRtl={isRtl}
          />
          <ButtonContainer
            currentStepIndex={currentStepIndex}
            isLpCompleted={isLastStep}
          >
            {currentStepIndex > 0 && !isLastStep && (
              <Button
                variant="outlined"
                onClick={() => handleChangeStep('prev')}
              >
                Back
              </Button>
            )}
            <Button variant="filled" onClick={handleButtonClick}>
              {isLastStep ? 'Lesson Complete' : 'Next'}
            </Button>
          </ButtonContainer>
        </>
      )}
    </div>
  );
};
