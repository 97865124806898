import styled from '@emotion/styled';
import {
  Icon,
  Logo,
  Spacing,
  TimeTableCardList,
  TimeTableGradeCardProps,
  TimetabelViewContainerScreen,
  Typography,
} from '@taleemabad/dsm/components';

export interface TimetableGradeSelectionScreenProps {
  items: TimeTableGradeCardProps[];
  heading: string;
  onGradeSelect: (id: number) => void;
  onClose?: () => void;
}
export const StyledTimetabelGradeSelectionScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledCardContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: var(--rk-spacing-xxl);
`;
export const TimetableGradeSelectionScreen = ({
  items,
  heading,
  onGradeSelect,
  onClose,
}: TimetableGradeSelectionScreenProps) => {
  return (
    <>
      <StyledCloseIcon>
        <Icon
          color="var(--md-sys-color-on-surface-variant)"
          name={'close'}
          onClick={() => onClose?.()}
        />
      </StyledCloseIcon>
      <TimetabelViewContainerScreen
        steps={[
          { step: 1, isActive: true, isCompleted: false },
          { step: 2, isActive: false, isCompleted: false },
          { step: 3, isActive: false, isCompleted: false },
          { step: 4, isActive: false, isCompleted: false },
        ]}
      ></TimetabelViewContainerScreen>
      <StyledTimetabelGradeSelectionScreen>
        <TimetableHeading heading={heading} />
        <Spacing marginTop="xxl">
          <TimeTableCardList items={items} onSelect={onGradeSelect} />
        </Spacing>
      </StyledTimetabelGradeSelectionScreen>
    </>
  );
};
const StyledCloseIcon = styled.div`
  position: fixed;
  top: var(--rk-spacing-xxl);
  right: var(--rk-spacing-xl);
`;

export interface TimetableHeadingProps {
  heading: string;
  grade?: string;
  subject?: SelectedSubject;
}
export interface SelectedSubject {
  text: string;
  icon: string;
}
export const TimetableHeading = ({
  heading,
  grade,
  subject,
}: TimetableHeadingProps) => {
  return (
    <>
      {heading && (
        <Typography variant="textBodyLargeOnSurfaceVariant">
          {heading}
        </Typography>
      )}
      {grade && (
        <Spacing marginTop="md">
          <Typography variant="textTitleLargeOnSurface">{grade}</Typography>
        </Spacing>
      )}
      {subject && (
        <StyledSubjectHeading marginTop="md">
          <Spacing marginRight="md">
            <Logo
              height={26}
              width={26}
              src={subject?.icon}
              altName={'selectedSubject'}
            />
          </Spacing>

          <Typography variant="textTitleLargeOnSurface">
            {subject?.text}
          </Typography>
        </StyledSubjectHeading>
      )}
    </>
  );
};

const StyledSubjectHeading = styled(Spacing)`
  display: flex;
`;
