import { ItemInfo, ItemInfoProps } from '../item-info/item-info';
import { FC } from 'react';
import styled from '@emotion/styled';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';

interface ItemInfoListProps {
  items: ItemInfoProps[];
}

export const ItemInfoList: FC<ItemInfoListProps> = ({ items }) => {
  return (
    <UserInfoListContainer>
      {items.map((item, index) => (
        <ItemInfo key={item.itemValue} {...item} />
      ))}
    </UserInfoListContainer>
  );
};

const UserInfoListContainer = styled.div`
  display: grid;
  gap: var(--rk-spacing-xxl);
  grid-template-columns: repeat(3, 1fr);
  ${mq(BreakpointSizes.xs)} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mq(BreakpointSizes.sm)} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
