import { PageLayout } from '../../../components/page-layout';
import { ProtectedRoute } from '../../authentication/components/protected-routes';
import { LessonPage } from '../pages/chapter-list';
import { GradeSelectionPage } from '../pages/ftue-timetable/select-grade';
import { TimetableViewPage } from '../pages/ftue-timetable/timetable-page';
import { WelcomeTimetablePage } from '../pages/ftue-timetable/welcome-timetable';
import { LessonPlanListPage } from '../pages/lesson-list';
import { LessonPlanPrepPage } from '../pages/lesson-prep';
import { LessonPlanViewPage } from '../pages/lesson-viewer';
import { SelectSectionPage } from '../pages/ftue-timetable/select-section';
import { DaysSelectionPage } from '../pages/ftue-timetable/select-day';
import { SelectSubjectPage } from '../pages/ftue-timetable/select-subject';
import { LessonChapterPage } from '../pages/ftue-timetable/lesson-chapter';

const routes = [
  {
    path: '/lessons',
    element: (
      <ProtectedRoute>
        <PageLayout>
          <LessonPage />
        </PageLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/lessons/:chapterId/grade/:gradeSubjectId/',
    element: (
      <ProtectedRoute>
        <PageLayout isNavBarVisible={false} scroll={true}>
          <LessonPlanListPage />
        </PageLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: 'lessons/:chapterId/lesson-preparation/:lessonPlanId/lesson-number/:lessonNumber/',
    element: (
      <ProtectedRoute>
        <PageLayout isNavBarVisible={false}>
          <LessonPlanPrepPage />
        </PageLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: 'lessons/lesson-view/:lessonPlanId/:chapterId',
    element: (
      <ProtectedRoute>
        <PageLayout
          isSidebarVisible={false}
          isNavBarVisible={false}
          scroll={false}
        >
          <LessonPlanViewPage />
        </PageLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: 'timetable',
    element: (
      <ProtectedRoute>
        <PageLayout
          isSidebarVisible={false}
          isNavBarVisible={false}
          scroll={false}
        >
          <WelcomeTimetablePage />
        </PageLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: 'create-class/grade',
    element: (
      <ProtectedRoute>
        <PageLayout
          isSidebarVisible={false}
          isNavBarVisible={false}
          scroll={false}
        >
          <GradeSelectionPage />
        </PageLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: 'create-class/section/:gradeId',
    element: (
      <ProtectedRoute>
        <PageLayout
          isSidebarVisible={false}
          isNavBarVisible={false}
          scroll={false}
        >
          <SelectSectionPage />
        </PageLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: 'create-class/subject/:gradeId/:sectionId/:shiftId',
    element: (
      <ProtectedRoute>
        <PageLayout
          isSidebarVisible={false}
          isNavBarVisible={false}
          scroll={false}
        >
          <SelectSubjectPage />
        </PageLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: 'create-class/days/:gradeId/:sectionId/:subjectId/:shiftId',
    element: (
      <ProtectedRoute>
        <PageLayout
          isSidebarVisible={false}
          isNavBarVisible={false}
          scroll={false}
        >
          <DaysSelectionPage />
        </PageLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: 'timetable/view-timetable',
    element: (
      <ProtectedRoute>
        <PageLayout
          isSidebarVisible={true}
          isNavBarVisible={true}
          scroll={false}
        >
          <TimetableViewPage />
        </PageLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: 'timetable/view-chapters/:gradeSubjectId',
    element: (
      <ProtectedRoute>
        <PageLayout
          isSidebarVisible={true}
          isNavBarVisible={true}
          scroll={true}
        >
          <LessonChapterPage />
        </PageLayout>
      </ProtectedRoute>
    ),
  },
];

export const lessonPlanRoutes = routes;
