import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import Dexie from 'dexie';
import { userDB } from '../models/user';
import { logout } from '../api/auth';
import { userCache } from './user-cache';
import { EventNames, analyticsService } from '../api/analytics';
import { Buffer } from 'buffer';
import { SyncRecordStatus } from '../types/sync-state';

/**
 * Ideally this should be inside the shared module
 * however since its using the user model we are
 * keeping it in the db module.
 */

export interface ISyncChangesData<T> {
  count: number;
  data: T[];
}

export interface ISyncChangesResponse<T> {
  created: ISyncChangesData<T>;
  updated: ISyncChangesData<T>;
  deleted: { count: number; data: string[] };
}

export const apiService = applyCaseMiddleware(
  axios.create({
    baseURL: "https://qcloudtesting.taleemabad.com",
  }),
);

apiService.interceptors.request.use(async (config) => {
  const token = userCache.getTokenFromCache();
  // This is excluded since the Google Translate API does not require a bearer token for API calls.
  // If we pass token then it returns unauthorized error in response
  const excludedUrlsPattern: any = /googleapis\.com/;
  if (
    token &&
    token.access &&
    config.url !== '/users/token/refresh/' &&
    !excludedUrlsPattern.test(config.url)
  ) {
    config.headers.setAuthorization(`Bearer ${token.access}`);
  }
  return config;
});

apiService.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    // this block handles the tracking of backend errors for analytics, there are two main conditions under which we track the error:
    // 1. when the error status is 401 and the request has already been retried.
    //    The assumption here is that a 401 error is typically due to token expiry,
    //    in such cases, we attempt a retry, if the retry fails, it indicates
    //    an unresolved authentication issue, and we track this as an error event.
    //    if the retry succeeds, we do not track it as an error.
    // 2. for other types of errors (status codes 400 and above, excluding 401 since these are handled through a retry mechanism.),
    //    we always track these as error events.
    if (
      (error.response?.status === 401 && originalRequest._retry) ||
      (error.response?.status >= 400 && error.response?.status !== 401)
    ) {
      analyticsService.trackEvent(EventNames.systemBackendError, {
        ep_path: error.config.url,
        ep_errorDetails: JSON.stringify(error.response.data),
      });
    }
    // if user cache is not available that means the user is not logged in yet and hence we should not attempt any retries on 401
    const isUserCacheAvailable = userCache.getUserFromCache();
    if (
      error.response?.status === 401 &&
      isUserCacheAvailable &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return updateToken().then((data) => {
        if (data && data.access) {
          error.config.headers['Authorization'] = `Bearer ${data.access}`;
          return axios.request(originalRequest);
        }
      });
    }
    return Promise.reject(error);
  },
);

export const updateToken = async () => {
  try {
    const token = userCache.getTokenFromCache();
    if (!token || !token.refresh) {
      throw new Error('Refresh token not found.');
    }
    const response = await apiService.post('/users/token/refresh/', {
      refresh: token.refresh,
    });
    userDB.setToken(response.data);
    return response.data;
  } catch (error) {
    // if the refresh endpoint fails,
    // then log the user out, reload to
    // force exit the app
    console.log('Unable to refresh token due to', error);
    logout();
    window.location.reload();
  }
};

const downloadImage = async (url: string) => {
  return axios
    .get(url, {
      responseType: 'arraybuffer',
      // https://stackoverflow.com/questions/76669136/axios-get-from-s3-no-access-control-allow-origin
      headers: { 'Cache-Control': 'no-cache' },
    })
    .then((response) =>
      Buffer.from(response.data, 'binary').toString('base64'),
    );
};

export const bulkImageDownloadFromUrlToBase64 = (urls: string[]) => {
  return Promise.allSettled(
    urls.map(async (url) => {
      try {
        const base64 = await downloadImage(url);
        return { url, base64 };
      } catch (e) {
        return Promise.reject(new Error(url));
      }
    }),
  );
};

/**
 * Asynchronously extracts data from a server response and updates the local database accordingly.
 *
 * @param {any[]} models - An array of model names to be processed.
 * @param {any} response - The server response containing the data to be extracted.
 * @param {Dexie} db - The Dexie instance representing the local database.
 *
 * @returns {Promise<any[]>} - A Promise that resolves with an array of results from the database operations.
 *
 * The function performs the following steps:
 * 1. For each model in the provided array, it checks if the model exists in the server response.
 * 2. If the model does not exist in the response, it resolves a Promise with true.
 * 3. If the model does exist, it maps over the keys 'created', 'updated', and 'deleted'.
 * 4. For each key, it extracts the corresponding data from the server response.
 * 5. If there is no data for the key, it resolves a Promise with true.
 * 6. If there is data and the key is 'deleted', it modifies the corresponding records in the database to mark them as deleted.
 * 7. If the key is 'updated', it updates the corresponding records in the database with the new data.
 * 8. If the key is 'created', it adds the new records to the database.
 * 9. It returns a Promise that resolves when all the database operations have completed.
 *
 * Note: This function assumes that the model names in the server response match the table names in the Dexie database.
 */

export const extractSyncResponse = async (
  models: any,
  response: any,
  db: Dexie,
): Promise<any[]> => {
  return Promise.all(
    models.flatMap((model: string) => {
      console.log(`Extracting data for model: ${model}`);
      const modelExists = response[model] !== undefined;
      if (!modelExists) {
        return Promise.resolve(true);
      }
      return ['created', 'updated', 'deleted'].map((key) => {
        const modelData = response[model][key]['data'];
        if (!modelData.length) return Promise.resolve(true);
        console.log(`Extracting data for model: ${model} with key: ${key}`);
        if (key === SyncRecordStatus.deleted) {
          // @ts-expect-error - we need to ensure the model name in response and db is the same.
          return db[model]
            .where('id')
            .anyOf(modelData.map(Number))
            .modify({ syncStatus: SyncRecordStatus.deleted });
        }
        if (key === SyncRecordStatus.updated) {
          // @ts-expect-error - we need to ensure the model name in response and db is the same.
          return db[model].bulkPut(modelData);
        }
        if (key === SyncRecordStatus.created) {
          // @ts-expect-error - we need to ensure the model name in response and db is the same.
          return db[model].bulkAdd(modelData);
        }
      });
    }),
  );
};
