import { css } from '@emotion/react';
import { CardContainer } from '../card/card-container';
import { Typography } from '../../atoms/typography/typography';
import styled from '@emotion/styled';
import { IconText } from '../icon-text/icon-text';
import { IconButton } from '@taleemabad/dsm/components';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';

interface TbLpStepsSwitcherProps {
  stepName: string;
  stepTime: number;
  isLeftIconActive: boolean;
  isRightIconActive: boolean;
  isScrolling: boolean;
  onChange: (arg: string) => void;
}

interface StyledStepsContainerProps {
  isScrolling: boolean;
}

const StyledStepsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--rk-spacing-xxxl);
  ${mq(BreakpointSizes.xs)} {
    width: 100%;
    justify-content: space-around;
  }
`;

const TextWrapper = styled.div`
  text-align: center;
`;

const StyledStepsContainer = styled.div<StyledStepsContainerProps>`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-height: inherit;
  padding: ${({ isScrolling }) => (isScrolling ? '' : 'var(--rk-spacing-xl)')};
`;

const IconTextContainer = styled.div`
  margin-top: var(--rk-spacing-md);
`;

export const TbLpStepsSwitcher = ({
  stepName,
  stepTime,
  isRightIconActive,
  isLeftIconActive,
  isScrolling,
  onChange,
}: TbLpStepsSwitcherProps) => {
  return (
    <CardContainer
      emotionCss={css`
        min-height: unset;
      `}
      variant="filled"
    >
      <StyledStepsContainer isScrolling={isScrolling}>
        <StyledStepsWrapper>
          <IconButton
            color={
              isLeftIconActive
                ? '--md-sys-color-primary'
                : '--md-sys-color-surface-dim'
            }
            icon="chevron_left"
            type="button"
            onClick={() => {
              isLeftIconActive && onChange('prev');
            }}
          />
          <TextWrapper>
            <Typography
              variant={
                isScrolling ? 'textTitleMediumPrimary' : 'textTitleLargePrimary'
              }
            >
              {stepName}
            </Typography>
          </TextWrapper>
          <IconButton
            color={
              isRightIconActive
                ? '--md-sys-color-primary'
                : '--md-sys-color-surface-dim'
            }
            icon="chevron_right"
            type="button"
            onClick={() => {
              isRightIconActive && onChange('next');
            }}
          />
        </StyledStepsWrapper>
        {!isScrolling && stepTime > 0 && (
          <IconTextContainer>
            <IconText
              size="tiny"
              icon="schedule"
              text={`${stepTime} mins`}
            ></IconText>
          </IconTextContainer>
        )}
      </StyledStepsContainer>
    </CardContainer>
  );
};
