export interface LogoInterface {
  src: string;
  altName: string;
  width?: number;
  height?: number;
}
export const Logo = ({
  src,
  altName,
  height,
  width,
  ...otherProps
}: LogoInterface) => {
  return (
    <img
      src={src}
      alt={altName}
      height={height}
      width={width}
      {...otherProps}
    />
  );
};
