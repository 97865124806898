import { PageLayout } from '../../../components/page-layout';
import { CoursePage } from '../pages/course-page';
import { TrainingVideoPage } from '../pages/training-video-page';
import { Navigate } from 'react-router-dom';
import { TrainingQuizPage } from '../pages/training-quiz';
import { ProtectedRoute } from '../../authentication/components/protected-routes';
import { UserInfoPage } from '../../authentication/pages/user-info-page';
import { CourseTrainingsPage } from '../pages/course-trainings-page';

const routes = [
  {
    path: 'profile',
    element: (
      <ProtectedRoute>
        <PageLayout>
          <UserInfoPage />
        </PageLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/training',
    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            <PageLayout scroll={true}>
              <CoursePage />
            </PageLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: 'course',
        element: <Navigate to="/training" />,
      },
      {
        path: 'course/:courseId',
        element: (
          <ProtectedRoute>
            <PageLayout isNavBarVisible={false} scroll={true}>
              <CourseTrainingsPage />
            </PageLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: 'course/:courseId/training/:trainingId',
        element: (
          <ProtectedRoute>
            <PageLayout isNavBarVisible={false} scroll={true}>
              <TrainingVideoPage />
            </PageLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: 'course/:courseId/training/:trainingId/quiz',
        element: (
          <ProtectedRoute>
            <PageLayout isNavBarVisible={false} scroll={true}>
              <TrainingQuizPage />
            </PageLayout>
          </ProtectedRoute>
        ),
      },
    ],
  },
];

export const teachersRoutes = routes;
