import styled from '@emotion/styled';
import { Button, Dialog, Typography } from '@taleemabad/dsm/components';
export interface NoInternetDialogProps {
  title: string;
  description: string;
  showPopup: boolean;
  secondaryButton?: boolean;
  primarButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
  onClosePopup: () => void;
}

export const AlertDialog = ({
  title,
  description,
  showPopup,
  secondaryButton = false,
  primarButtonText = 'Got It',
  secondaryButtonText = 'Cancel',
  onPrimaryButtonClick,
  onClosePopup,
}: NoInternetDialogProps) => {
  return (
    <Dialog
      open={showPopup}
      title={title}
      onClose={() => {
        onClosePopup();
      }}
    >
      <Typography variant="textBodyMedium">{description} </Typography>
      <StyledPopupButton>
        {secondaryButton && (
          <Button
            variant="outlined"
            children={secondaryButtonText}
            onClick={() => {
              onClosePopup();
            }}
          />
        )}
        <Button
          children={primarButtonText}
          onClick={() => {
            onPrimaryButtonClick?.();
            onClosePopup();
          }}
        />
      </StyledPopupButton>
    </Dialog>
  );
};

const StyledPopupButton = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: end;
  gap: var(--rk-spacing-xl);
  justify-content: flex-end;
  md-filled-button {
    width: 100%;
  }
  md-outlined-button {
    width: 100%;
  }
`;
