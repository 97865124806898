import { PropsWithChildren, useContext } from 'react';
import styled from '@emotion/styled';
import { useBreakpoint } from '@taleemabad/shared/hooks';
import { AppBar, Logo } from '@taleemabad/dsm/components';
import { useLocation, useNavigate } from 'react-router-dom';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { ConfigContext } from '../providers/config-provider';
const StyledLandingPageContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
`;
const StyledLandingPageDetails = styled.div`
  display: inline-flex;
  width: 40%;
  background-color: var(--md-sys-color-surface-container-highest);
  align-items: center;
  justify-content: center;
`;
interface props {
  isMobile: boolean;
  isTopbarExist: boolean;
}
const StyledRouterContainer = styled.div<props>`
  flex: 1;
  display: inline-flex;
  padding-top: ${(props) =>
    props.isMobile && props.isTopbarExist ? '82px' : '16px'};
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledAppBarWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 40%;
  ${mq(BreakpointSizes.xs)} {
    left: 0;
    width: calc(100% - 48px);
    padding: 0px;
  }
  ${mq(BreakpointSizes.sm)} {
    left: 0;
    width: calc(100% - 48px);
    padding: 24px;
  }
  width: 60%;
  padding: 24px;
`;
export const AuthLayout = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { appLogoPath, appLogoWidthLanding } = useContext(ConfigContext);

  const pathArray = ['/set-password', '/create-password'];
  const isExist = pathArray.includes(location.pathname) ? true : false;
  const { tablet, mobileHorizontal, mobile } = useBreakpoint();
  const isLowResDevice = tablet || mobileHorizontal || mobile;
  return (
    <StyledLandingPageContainer>
      {!isLowResDevice && (
        <StyledLandingPageDetails>
          <Logo
            altName="outer-layer"
            width={parseInt(appLogoWidthLanding)}
            src={`../../assets/${appLogoPath}`}
            data-cy="sign-in-web-logo"
          ></Logo>
        </StyledLandingPageDetails>
      )}

      <StyledRouterContainer isTopbarExist={isExist} isMobile={isLowResDevice}>
        {isExist && (
          <StyledAppBarWrapper>
            <AppBar
              pageTitle={isLowResDevice ? '' : 'Back'}
              leftIcon="arrow_back"
              onLeftIconClick={() => {
                navigate(-1);
              }}
              hasPadding={false}
              titleFullWidth={true}
              titleMedium={true}
              isTitleClickable={true}
            />
          </StyledAppBarWrapper>
        )}
        {children}
      </StyledRouterContainer>
    </StyledLandingPageContainer>
  );
};
