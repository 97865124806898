import { PageLayout } from 'apps/school-app/src/components/page-layout';
import { ProtectedRoute } from '../../authentication/components/protected-routes';
import { DownloadsPage } from '../page/downloads-page';

const routes = [
  {
    path: '/downloads',
    element: (
      <ProtectedRoute>
        <PageLayout scroll={true}>
          <DownloadsPage />
        </PageLayout>
      </ProtectedRoute>
    ),
  },
];
export { routes as downloadsRoutes };
