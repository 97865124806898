import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { AuthCardContainer } from '../components/auth-card';
import { validatePasswords } from 'apps/school-app/src/utils/utils';
import { useBreakpoint } from '@taleemabad/shared/hooks';
import {
  Typography,
  PhoneField,
  PasswordField,
  Button,
  Logo,
  PhoneFieldResult,
  Dialog,
  Spacing,
} from '@taleemabad/dsm/components';
import { ConfigContext } from 'apps/school-app/src/providers/config-provider';
import { EventNames, analyticsService, loginUser } from '@taleemabad/db';
import { tempLSPhoneKey } from './sign-in-page';

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;
const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 68px;
`;
const StyledLargeHeading = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const StyledSpacing = styled(Spacing)`
  display: flex;
  justify-content: flex-end;
`;
export const SignInPasswordPage = () => {
  const navigate = useNavigate();
  const { appLogoPath } = useContext(ConfigContext);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const { tablet, mobileHorizontal, mobile } = useBreakpoint();
  const isLowResDevice = tablet || mobileHorizontal || mobile;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const usersPhone = localStorage.getItem(tempLSPhoneKey) || '';

  if (!usersPhone) {
    navigate('/login', { replace: true });
  }

  // TODO why is this using PhoneFieldResult
  const [formData, setFormData] = useState<PhoneFieldResult>({
    hasError: true,
    value: '',
    name: 'password',
  });

  const handleIncorrectCredentialsError = () => {
    setShowPopup(true);
  };

  const handleAccountDetail = async (formData: PhoneFieldResult) => {
    if (!formData.hasError) {
      try {
        const data = await loginUser(usersPhone, formData.value);
        if (data) {
          analyticsService.trackEvent(EventNames.signupLogin);
          navigate('/training', { replace: true, state: 'training' });
        }
      } catch (error: any) {
        'nonFieldErrors' in error.response.data &&
          handleIncorrectCredentialsError();
      }
    }
  };

  const keyDownHandler = (e: any) => {
    if (e.key === 'Enter') {
      submitButtonClick();
    }
  };

  const submitButtonClick = () => {
    handleAccountDetail(formData);
    setErrorMessage(validatePasswords(formData.value));
    setHasSubmitted(true);
  };

  const StyledPopupButton = styled.div`
    margin-top: 24px;
    md-filled-button {
      width: 100%;
    }
  `;
  return (
    <AuthCardContainer>
      {isLowResDevice && (
        <StyledImageWrapper>
          <Logo
            altName="outer-layer"
            width={275}
            src={`../../assets/${appLogoPath}`}
          />
        </StyledImageWrapper>
      )}
      {/** TODO Clean up all this responsiveness code */}
      {!isLowResDevice ? (
        <Spacing marginBottom="md">
          <Typography
            variant="textHeadlineLargeSurface"
            data-cy="sign-in-welcome-back-title"
          >
            Welcome Back
          </Typography>
        </Spacing>
      ) : (
        <Spacing marginBottom="md">
          <Typography
            variant="textHeadlineMediumOnSurface"
            data-cy="sign-in-welcome-back-title"
          >
            Welcome Back
          </Typography>
        </Spacing>
      )}
      <StyledLargeHeading>
        <Typography
          variant="textBodyLargeSurface"
          data-cy="sign-in-enter-password-title"
        >
          Enter your password
        </Typography>
      </StyledLargeHeading>

      <StyledCardContent>
        <PhoneField
          name="phone"
          type="tel"
          variant="outlined"
          value={usersPhone}
          label="Phone Number"
          placeholder="Enter here"
          errorText=""
          readOnly={true}
          data-cy="filled-phone-number-field"
        />
      </StyledCardContent>
      <StyledCardContent>
        <PasswordField
          id="password"
          name="password"
          type={passwordVisible ? 'text' : 'password'}
          variant="outlined"
          label="Password"
          placeholder="Enter here"
          data-cy="sign-in-password-field"
          errorText={hasSubmitted ? errorMessage : ''}
          onChange={(data): void => {
            data.hasError = !!validatePasswords(data.value);
            setErrorMessage(validatePasswords(data.value));
            setFormData(data);
          }}
          onToggleVisibility={() => {
            setPasswordVisible((prev) => !prev);
          }}
          onKeyPress={keyDownHandler}
        />
        <StyledSpacing marginTop="xl">
          <Typography
            onClick={() => {
              analyticsService.trackEvent(
                EventNames.resetPasswordForgetPasswordClicked,
              );
              navigate('/user-verfication', { replace: true });
            }}
            variant="textLabelLargePrimary"
            data-cy="forget-password"
          >
            Forget Password?
          </Typography>
        </StyledSpacing>
      </StyledCardContent>
      <Dialog
        open={showPopup}
        title="Incorrect Credentials"
        onClose={() => setShowPopup(false)}
        data-cy="incorrect-credentials-dialoge-title"
      >
        <Typography
          variant="textBodyMedium"
          data-cy="incorrect-credentials-dialoge-subtitle"
        >
          Please check your number and password again{' '}
        </Typography>

        <StyledPopupButton>
          <Button
            children="Got It"
            onClick={() => {
              setShowPopup(false);
            }}
            data-cy="incorrect-credentials-got-it-button"
          />
        </StyledPopupButton>
      </Dialog>
      <Button
        children="Log In"
        type="submit"
        onClick={submitButtonClick}
        data-cy="sign-in-log-in-button"
      />
    </AuthCardContainer>
  );
};
