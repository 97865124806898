import { useEffect, useState } from 'react';
import { ConnectionStatus, Network } from '@capacitor/network';

export interface IUseNetworkStatus {
  isConnected: boolean;
}

export function useNetworkStatus(): IUseNetworkStatus {
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    (async () => {
      const status = await Network.getStatus();
      setIsConnected(status.connected);
      Network.addListener('networkStatusChange', onChange);
    })();

    return () => {
      Network.removeAllListeners();
    };
  }, []);

  const onChange = (connectionStatus: ConnectionStatus) => {
    setIsConnected(connectionStatus.connected);
  };

  return {
    isConnected,
  };
}
