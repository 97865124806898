// @ts-nocheck
import { PropsWithChildren } from 'react';

import '@material/web/radio/radio.js';
import { Typography } from '../typography/typography';
import styled from '@emotion/styled';

export interface RadioOption {
  value: string;
  content: string;
}

const StyledLabel = styled.label`
  display: flex;
  align-items: center;

  md-radio {
    flex-shrink: 0;
  }
`;

export interface RadioProps extends PropsWithChildren {
  disabled?: boolean;
  name?: string;
  option: RadioOption;
  onSelect?: (option: RadioOption) => void;
  checked?: boolean;
}

export const Radio = ({
  disabled = false,
  option,
  name,
  onSelect = (option: RadioOption) => {},
  checked = false,
}: RadioProps) => {
  const props = {};
  if (disabled) {
    props.disabled = true;
  }
  if (name) {
    props.name = name;
  }
  if (checked) {
    props.checked = checked;
  }

  return (
    <StyledLabel>
      <md-radio
        touch-target="wrapper"
        {...props}
        onClick={() => !disabled && onSelect(option)}
      ></md-radio>
      <Typography variant="textTitleSmall">{option.content}</Typography>
    </StyledLabel>
  );
};

export interface RadioGroupProps extends PropsWithChildren {
  direction?: 'row' | 'column';
  options: RadioOption[];
  name: string;
  onChange: (option: RadioOption) => void;
}

export const RadioGroup = ({
  direction = 'row',
  options,
  name,
  onChange,
}: RadioGroupProps) => {
  const GroupContainer = styled.div`
    display: flex;
    flex-direction: ${direction};
  `;

  return (
    <GroupContainer role="radiogroup">
      {options.map((option, idx) => (
        <Radio key={idx} name={name} option={option} onSelect={onChange} />
      ))}
    </GroupContainer>
  );
};
