import { FC, useState } from 'react';
import {
  AppBar,
  CourseTrainings,
  LoadingScreen,
} from '@taleemabad/dsm/components';
import { useNavigate, useParams } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';
import {
  analyticsService,
  downloadManagerService,
  DownloadStateTitle,
  EventNames,
  FeatureFlagNames,
  teacherTrainingDB,
  TrainingRecord,
  userDB,
} from '@taleemabad/db';
import styled from '@emotion/styled';
import { Capacitor } from '@capacitor/core';

export const CourseTrainingsPage: FC = () => {
  const [downloadState, setDownloadState] = useState(false);
  const downloadStateTitle = downloadState
    ? DownloadStateTitle.Done
    : DownloadStateTitle.Download;

  const navigate = useNavigate();
  const { courseId } = useParams();
  const selectedCourseId = parseInt(courseId || '', 10);

  const course = useLiveQuery(() =>
    teacherTrainingDB.getCourse(selectedCourseId),
  );
  const trainings = useLiveQuery(() =>
    teacherTrainingDB.getTrainings(selectedCourseId),
  );

  const downloadEnabled =
    (useLiveQuery(() =>
      userDB.getFeatureFlag(FeatureFlagNames.downloadEnabled),
    ) &&
      Capacitor.isNativePlatform()) ||
    false;

  if (isNaN(selectedCourseId)) {
    navigate('/training');
    return null;
  }

  if (!course || !trainings) {
    return <LoadingScreen />;
  }

  const handleTrainingSelected = (training: TrainingRecord) => {
    handleNavigation(training);
  };

  const handleDownloadIconClick = (training: TrainingRecord) => {
    if (!downloadState) {
      handleNavigation(training);
      return;
    }
    downloadManagerService.downloadMediaAsset([
      {
        trainingId: training.id,
        trainingURL: training.mediaAsset.url,
        downloadId: training.id,
      },
    ]);
    analyticsService.trackEvent(EventNames.trainingDownloadStarted, {
      ep_training_id: training.id,
    });
  };

  const handleDownloadStateChange = () => {
    setDownloadState(!downloadState);
    analyticsService.trackEvent(EventNames.trainingDownloadClicked, {});
  };

  const handleNavigation = (training: TrainingRecord) => {
    navigate(`/training/course/${training.course}/training/${training.id}`);
    analyticsService.trackEvent(EventNames.trainingTrainingSelected, {
      epCourseId: training.course,
      epTrainingId: training.id,
    });
  };

  return (
    <>
      <AppBar
        pageTitle="Back"
        leftIcon="arrow_back"
        onLeftIconClick={() => navigate(`/training`)}
        hasPadding={false}
        titleFullWidth={true}
        titleMedium={true}
        isTitleClickable={true}
      />
      <CourseTrainingsContainer>
        <CourseTrainings
          course={course}
          trainings={trainings}
          downloadState={downloadState}
          downloadStateTitle={downloadStateTitle}
          downloadEnabled={downloadEnabled}
          onTrainingSelected={handleTrainingSelected}
          onDownloadStateChange={handleDownloadStateChange}
          onDownloadIconClick={handleDownloadIconClick}
        ></CourseTrainings>
      </CourseTrainingsContainer>
    </>
  );
};

const CourseTrainingsContainer = styled.div`
  padding: var(--rk-spacing-xl);
`;
