import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  Button,
  HorizontalCard,
  LoadingScreen,
  ProgressWithLabel,
  Spacing,
  TbdPlayer,
  Typography,
} from '@taleemabad/dsm/components';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { EventNames, ITraining, analyticsService } from '@taleemabad/db';
import { css } from '@emotion/react';
import { downloadManagerService } from '@taleemabad/db';

interface TrainingVideoProps {
  training: ITraining;
  trainingScoreText: string;
  isAPILoading: boolean;
  buttonClickHandler: () => void;
}

export const TrainingVideo: FC<TrainingVideoProps> = ({
  training,
  trainingScoreText,
  isAPILoading,
  buttonClickHandler,
}) => {
  const [videoFinished, setVideoFinished] = useState<boolean>(false);
  const [autoplay, setAutoplay] = useState<boolean>(true);
  const [offlineVideoUrl, setOfflineVideoUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchVideoUrl = async () => {
      setIsLoading(true);
      const url = await downloadManagerService.getVideoURL(training);
      setOfflineVideoUrl(url);
      setIsLoading(false);
      if (url) {
        analyticsService.trackEvent(EventNames.trainingDownloadVideoPlayed, {
          ep_training_id: training.id,
        });
      }
    };
    fetchVideoUrl();
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  const getButtonProps = () => {
    const isRetake = training?.assessment;
    return {
      text: isRetake ? 'Retake Quiz' : 'Start Quiz',
      disabled: !isRetake && !videoFinished,
    };
  };
  const { text, disabled } = getButtonProps();

  const handleVideoEnd = () => {
    setAutoplay(false);
    setVideoFinished(true);
  };

  const calculateScorePercentage = () => {
    if (!training.assessment) {
      return 0;
    }
    return (training.assessment?.score / training.assessment?.totalScore) * 100;
  };

  return (
    <TrainingVideoContainer>
      <TrainingVideoHeaderContainer>
        <HeadlineTextWrapper>
          <Typography variant="textHeadlineMediumPrimary">
            {training.title}
          </Typography>
        </HeadlineTextWrapper>
        {training.assessment && (
          <HorizontalCard
            variant="filled"
            maxWidth={356}
            title="Quiz Score"
            titleContentCss={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
            `}
            tailComponent={
              <ProgressWithLabel
                value={calculateScorePercentage()}
                text={trainingScoreText}
              />
            }
          />
        )}
      </TrainingVideoHeaderContainer>
      <TrainingVideoWrapper>
        <TbdPlayer
          url={training.mediaAsset.url}
          offlineUrl={offlineVideoUrl}
          hlsUrl={training.mediaAsset.hlsVideoMainManifestUrl}
          featureName="training"
          onVideoEnd={handleVideoEnd}
          autoplay={autoplay}
          eventData={{
            epMediaId: training.mediaAsset.id,
            epCourseId: training.course,
            epTrainingId: training.id,
          }}
        />
      </TrainingVideoWrapper>
      <Spacing marginLeft="xl">
        <Spacing margin="md none">
          <Typography variant="textTitleMediumNeutral10">Overview</Typography>
        </Spacing>
        <Spacing marginBottom="xl">
          <Typography variant="textTitleMediumNeutral50">
            {training.description}
          </Typography>
        </Spacing>
        <Button
          onClick={buttonClickHandler}
          disabled={disabled || isAPILoading}
        >
          {text}
        </Button>
      </Spacing>
    </TrainingVideoContainer>
  );
};

const TrainingVideoContainer = styled.div``;

const TrainingVideoHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--rk-spacing-xl);

  ${mq(BreakpointSizes.mobile)} {
    justify-content: center;
    padding: unset;
  }
`;

const HeadlineTextWrapper = styled.div`
  ${mq(BreakpointSizes.mobile)} {
    display: none;
  }
`;

const TrainingVideoWrapper = styled.div`
  padding: var(--rk-spacing-lg);
  margin: var(--rk-spacing-xl) 0;
  ${mq(BreakpointSizes.mobile)} {
    padding: unset;
  }
`;
