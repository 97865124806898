import styled from '@emotion/styled';

interface OverLayerProps {
  isActive?: boolean;
}
export const OverlayeComponent = styled.div<OverLayerProps>`
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  display: ${(props) => (props.isActive ? 'block' : 'none')};
`;
