import {
  Spacing,
  StyledTimetabelGradeSelectionScreen,
  TimeTableCardList,
  TimetabelViewContainerScreen,
  TimetableHeading,
  TimetableSectionSelectionScreenProps,
} from '@taleemabad/dsm/components';

export const SubjectSelectionScreen = ({
  selectedGrade,
  heading,
  items,
  selectedSubject,
  onGradeSelect,
}: TimetableSectionSelectionScreenProps) => {
  const props: any = {};
  if (selectedSubject) {
    props.subject = { icon: selectedSubject.icon, text: selectedSubject.text };
  }
  return (
    <>
      <TimetabelViewContainerScreen
        steps={[
          { step: 1, isActive: true, isCompleted: true },
          { step: 2, isActive: true, isCompleted: true },
          { step: 3, isActive: true, isCompleted: false },
          { step: 4, isActive: false, isCompleted: false },
        ]}
      ></TimetabelViewContainerScreen>
      <StyledTimetabelGradeSelectionScreen>
        <TimetableHeading heading={heading} grade={selectedGrade} {...props} />
        <Spacing marginTop="xxl">
          <TimeTableCardList
            height="148px"
            width="122px"
            items={items}
            onSelect={onGradeSelect}
          />
        </Spacing>
      </StyledTimetabelGradeSelectionScreen>
    </>
  );
};
