// @ts-nocheck
import { PropsWithChildren, useState } from 'react';
import '@material/web/checkbox/checkbox.js';
import { Typography } from '../typography/typography';
import styled from '@emotion/styled';

const StyledLabel = styled.label`
  display: flex;
  align-items: center;

  md-checkbox {
    flex-shrink: 0;

    &::part(focus-ring) {
      display: none;
    }
  }
`;

export interface CheckboxProps extends PropsWithChildren {
  disabled?: boolean;
  defaultChecked?: boolean;
  indeterminate?: boolean;
  stateLess?: boolean;
  label?: string;
  onChange: (isChecked: boolean) => void;
}

export const Checkbox = ({
  disabled = false,
  defaultChecked = false,
  indeterminate = false,
  label = '',
  onChange,
  stateLess = false,
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(() => defaultChecked);
  const props = {};
  if (disabled) {
    props.disabled = true;
  }
  if (stateLess) {
    if (defaultChecked) {
      props.checked = defaultChecked;
    }
  } else {
    if (isChecked) {
      props.checked = isChecked;
    }
  }
  if (indeterminate) {
    props.indeterminate = true;
  }

  const onChangeHandler = () => {
    if (stateLess) {
      onChange(!defaultChecked);
    } else {
      setIsChecked(!isChecked);
      onChange(!isChecked);
    }
  };

  if (label) {
    return (
      <StyledLabel>
        <md-checkbox
          touch-target="wrapper"
          {...props}
          onClick={onChangeHandler}
        ></md-checkbox>
        <Typography variant="textTitleSmall">{label}</Typography>
      </StyledLabel>
    );
  }
  return <md-checkbox touch-target="wrapper" {...props}></md-checkbox>;
};
