import { PromiseExtended, Table } from 'dexie';
import { BaseModelForPush } from './base-model';
import { AnalyticsEvent, TeacherProfile, userCache, userDB } from '..';
import { SyncChanges, SyncRecord, SyncRecordStatus } from '../types/sync-state';
import { syncedDBModel } from './synced';

const SCHEMA_VERSION = 1;
const SCHEMA = {
  events: '++id, user, profile, syncStatus',
};

export interface AnalyticsEventRecord extends AnalyticsEvent, SyncRecord {}

export class AnalyticsDB extends BaseModelForPush {
  events!: Table<AnalyticsEventRecord>;

  constructor() {
    super('analtyicsdb');
    this.version(SCHEMA_VERSION).stores(SCHEMA);

    this.events.hook('updating', this.updateHook.bind(this));
    this.events.hook('deleting', this.deleteHook.bind(this));
    this.events.hook('creating', this.createHook.bind(this));
  }

  /**
   * ! Do not use this method directly. Use `AnalyticsService` instead.
   */
  async trackEvent(event: AnalyticsEvent) {
    // TODO what is a better way to manage this? Should we put this
    // as part of the update hook in the base model?
    // TODO we should wrap this in a transaction?
    if (process.env.NX_ENV !== 'production') console.log(event);
    syncedDBModel.updateChangedAt();
    return this.events.add(event as AnalyticsEventRecord);
  }

  async getNewEventRecord(): Promise<PromiseExtended<SyncRecord[]>> {
    const selectedProfile = userCache.getActiveProfile() as TeacherProfile;
    const data = this.events
      .where('syncStatus')
      .equals(SyncRecordStatus.created.toString())
      .toArray();
    const updatedRecord = (await data).map((record) => ({
      ...record,
      school: selectedProfile?.school,
    }));
    return updatedRecord;
  }

  async getAllUnsyncedRecords(): Promise<Record<string, SyncRecord[]>> {
    const records = {
      analyticEvents: await this.getNewEventRecord(),
    };
    return records;
  }

  async updateRecordsSyncStatus(
    syncChanges: SyncChanges,
    status: SyncRecordStatus,
  ): Promise<number> {
    const ids = syncChanges.analyticEvents?.created.map(
      (record: { id: number }) => record.id,
    );
    return this._updateSyncData(this.events, ids, status);
  }
}

export const analyticsDB = new AnalyticsDB();
