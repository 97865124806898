import { DownloadManager } from 'capacitor-download-manager';
import { teacherTrainingDB } from '../models/teachertraining';
import {
  DownloadInfo,
  DownloadListener,
  DownloadRequest,
  DownloadResponse,
  DownloadStatus,
} from '../types/downloads';
import { Capacitor } from '@capacitor/core';
import { ITraining } from '../types/teachertraining';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { downloadRequestModel } from '../models/downloads';
import { Toast } from '@capacitor/toast';

export function downloadParser(download: { result: string }): DownloadInfo {
  const jsonString = JSON.stringify(download);
  const downloadResponse: DownloadResponse = JSON.parse(jsonString);
  return JSON.parse(downloadResponse.download);
}

export function downloadListParser(response: {
  value: string;
}): DownloadInfo[] {
  const jsonString = JSON.stringify(response);
  const downloadResponse: DownloadResponse = JSON.parse(jsonString);
  return JSON.parse(downloadResponse.download);
}

class DownloadManagerService {
  private static instance: DownloadManagerService;

  public constructor() {
    if (Capacitor.isNativePlatform()) {
      this.addOnListener(DownloadListener.OnAdded);
      this.addOnListener(DownloadListener.OnProgress);
      this.addOnListener(DownloadListener.OnCompleted);
      this.addOnListener(DownloadListener.OnError);
      this.addOnListener(DownloadListener.OnRemoved);
      this.addOnListener(DownloadListener.OnDeleted);
    }
  }

  public static getInstance(): DownloadManagerService {
    if (!DownloadManagerService.instance) {
      DownloadManagerService.instance = new DownloadManagerService();
    }
    return DownloadManagerService.instance;
  }

  public async getVideoURL(training: ITraining): Promise<string | null> {
    if (training.mediaAsset.download?.status === DownloadStatus.COMPLETED) {
      try {
        const filename = training.mediaAsset.download.url.substring(
          training.mediaAsset.download.url.lastIndexOf('/') + 1,
        );
        const fileUri = await Filesystem.getUri({
          path: filename,
          directory: Directory.Library,
        });
        return Capacitor.convertFileSrc(fileUri.uri);
      } catch (e) {
        console.log('Error getting video URL :: ' + e);
        return null;
      }
    }
    return null;
  }

  public async downloadMediaAsset(request: DownloadRequest[]): Promise<void> {
    request.forEach((req) => {
      downloadRequestModel.addDownloadRequest(req);
    });

    const showToast = async () => {
      await Toast.show({
        text: 'Added to queue.',
        duration: 'long',
        position: 'bottom',
      });
    };
    showToast();

    await Promise.all(
      request.map(async (req) => {
        try {
          await DownloadManager.startDownload({ url: [req.trainingURL] });
        } catch (error) {
          console.log('Error while downloading file');
        }
      }),
    );
  }

  public async deleteDownload(request: DownloadRequest[]) {
    request.forEach((req) => {
      downloadRequestModel.addDownloadRequest(req);
    });
    await Promise.all(
      request.map(async (req) => {
        try {
          if (req.downloadId) {
            await DownloadManager.removeDownloads({
              value: [req.downloadId?.toString()],
            });
          } else console.log('No download ID found!');
        } catch (error) {
          console.log('Error while deleting download!');
        }
      }),
    );
  }

  private async getDownloadsFromNative(): Promise<void> {
    try {
      await DownloadManager.getDownloadList().then(async (response) => {
        const downloadList = downloadListParser(response);
        if (downloadList.length > 0) {
          await teacherTrainingDB
            .updateTrainingsWithDownloads(downloadList)
            .then(() => {
              this.resumePendingDownloads();
            });
        } else console.log('No files downloaded!');
      });
    } catch (error) {
      console.log('Error getting download list :: ' + error);
      throw error;
    }
  }

  private async resumePendingDownloads(): Promise<void> {
    try {
      await DownloadManager.resumeDownloads();
    } catch (error) {
      console.log('Error resuming download list :: ' + error);
      throw error;
    }
  }

  public async syncDownloads(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      // sync downloads from native platforms
      this.getDownloadsFromNative();
      console.log('Sync downloads from native platforms');
    }
  }
  private async handleDownload(
    download: { result: string },
    listener: DownloadListener,
  ) {
    const downloadInfo = downloadParser(download);
    const request = await downloadRequestModel.getDownloadRequestByTrainingURL(
      downloadInfo.url,
    );
    if (request) {
      if (
        listener === DownloadListener.OnRemoved ||
        listener === DownloadListener.OnDeleted
      ) {
        teacherTrainingDB.deleteDownloadFromMediaAssets(request);
      } else {
        teacherTrainingDB.updateIndexTrainingMediaAssets(request, downloadInfo);
      }
    }
  }

  private addOnListener(listener: DownloadListener) {
    DownloadManager.addListener(listener, async (download) => {
      this.handleDownload(download, listener);
    });
  }
}

export const downloadManagerService = DownloadManagerService.getInstance();
