import {
  Button,
  Spacing,
  TimeTableCardList,
  TimeTableGradeCardProps,
  TimetabelViewContainerScreen,
} from '@taleemabad/dsm/components';
import {
  SelectedSubject,
  StyledTimetabelGradeSelectionScreen,
  TimetableHeading,
} from './timetable-grade-selection';
import styled from '@emotion/styled';
export interface TimetableSectionSelectionScreenProps {
  selectedGrade: string;
  heading: string;
  items: TimeTableGradeCardProps[];
  selectedSubject?: SelectedSubject;
  onGradeSelect: (id: number) => void;
  onNoSectionSelect?: () => void;
}
export const TimetableSectionSelectionScreen = ({
  selectedGrade,
  heading,
  items,
  onGradeSelect,
  onNoSectionSelect,
}: TimetableSectionSelectionScreenProps) => {
  return (
    <>
      <TimetabelViewContainerScreen
        steps={[
          { step: 1, isActive: true, isCompleted: true },
          { step: 2, isActive: true, isCompleted: false },
          { step: 3, isActive: false, isCompleted: false },
          { step: 4, isActive: false, isCompleted: false },
        ]}
      ></TimetabelViewContainerScreen>
      <StyledTimetabelGradeSelectionScreen>
        <TimetableHeading heading={heading} grade={selectedGrade} />
        <Spacing marginTop="xxl">
          <TimeTableCardList items={items} onSelect={onGradeSelect} />
        </Spacing>
        <StyledButtonWrapper>
          <Button
            variant="outlined"
            children="No Section"
            onClick={() => onNoSectionSelect?.()}
          />
        </StyledButtonWrapper>
      </StyledTimetabelGradeSelectionScreen>
    </>
  );
};

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: var(--rk-spacing-xxl);
`;
