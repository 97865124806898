import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import { Progress } from '../../atoms/progress/progress';
import { Typography } from '../../atoms/typography/typography';

const StyledTBProgressBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export interface TBProgressBarProps {
  progress: number;
  emotionCss?: SerializedStyles;
}

export const TBProgressBar = ({ progress, emotionCss }: TBProgressBarProps) => {
  return (
    <StyledTBProgressBarContainer css={emotionCss}>
      <Progress
        type="linear"
        value={progress / 100}
        emotionCss={css`
          flex: 1;
          margin-right: var(--rk-spacing-md);
        `}
      />
      <Typography variant="textBodyMedium">{progress}%</Typography>
    </StyledTBProgressBarContainer>
  );
};
