import styled from '@emotion/styled';
import {
  EventNames,
  analyticsService,
  lessonPlanDB,
  sections,
  subjectIcons,
} from '@taleemabad/db';
import {
  AppBar,
  Button,
  Dialog,
  Spacing,
  SubjectSelectionScreen,
  Typography,
} from '@taleemabad/dsm/components';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useLiveQuery } from 'dexie-react-hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const SelectSubjectPage = () => {
  const { gradeId, sectionId, shiftId } = useParams();
  const [openPopup, setOpenPopup] = useState(false);
  const selectedGrades = parseInt(gradeId || '0');
  const selectedSection = parseInt(sectionId || '0');
  const [subjectId, setSubjectId] = useState(1);
  const navigate = useNavigate();
  const subjects =
    useLiveQuery(() => lessonPlanDB.getSubject(selectedGrades)) || [];
  const grade =
    useLiveQuery(() => lessonPlanDB.getSelectedGrade(selectedGrades)) || [];
  const onSubjectSelect = async (id: number) => {
    if (shiftId) {
      const isDuplicate = await lessonPlanDB.checkTimetableEntryExists(
        selectedGrades,
        id,
        sections.find((data) => data.id === selectedSection)?.label as string,
        shiftId,
      );
      if (isDuplicate > 0) {
        setOpenPopup(true);
        return;
      }
      navigate(
        `/create-class/days/${selectedGrades}/${selectedSection}/${id}/${shiftId}`,
      );
    }
  };
  {
    return (
      grade.length > 0 && (
        <StyledContent isTitle={true}>
          <AppBar
            pageTitle="Back"
            leftIcon="arrow_back"
            onLeftIconClick={() => {
              navigate(-1);
            }}
            hasPadding={true}
            titleFullWidth={true}
            titleMedium={true}
            isTitleClickable={true}
          />
          <SubjectSelectionScreen
            selectedGrade={`${grade[0].label} - ${sections.find((data) => data.id === selectedSection)?.label} (${shiftId})`}
            heading="Select the subject you teach in"
            items={subjects.map((subject) => {
              return {
                label: subject.label,
                id: subject.id,
                icon: subjectIcons.find((data) => data.id === subject.id)?.icon,
              };
            })}
            onGradeSelect={(id: number) => {
              analyticsService.trackEvent(EventNames.dashboardSubjectSelected, {
                epSubjectSelected: id,
              });
              setSubjectId(id);
              onSubjectSelect(id);
            }}
          />
          <Dialog
            open={openPopup}
            onClose={() => {
              setOpenPopup(false);
            }}
          >
            <Typography variant="textHeadlineMediumError">
              Duplicate Class
              <br />
            </Typography>
            <Spacing marginTop="xl">
              <Typography variant="textBodyLargeOnSurface">
                {`${grade[0].label} - ${sections.find((data) => data.id === selectedSection)?.label} (${shiftId}) ${
                  subjects.find((data) => data.id === subjectId)?.label
                }`}
              </Typography>
            </Spacing>

            <Spacing marginTop="xl">
              <Typography variant="textBodyLargeOnSurface">
                This class already exists on your Timetable. Add a different
                class.
              </Typography>
            </Spacing>
            <StyledPopupButton>
              <Button
                variant="text"
                children="Cancel"
                onClick={() => {
                  setOpenPopup(false);
                }}
              />
              <Button
                variant="text"
                children="Add Another Class"
                onClick={() => {
                  setOpenPopup(false);
                  navigate('/create-class/grade');
                }}
              />
            </StyledPopupButton>
          </Dialog>
        </StyledContent>
      )
    );
  }
};

const StyledPopupButton = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
  md-filled-button {
    width: 100%;
  }
`;
interface StyledDiv {
  isTitle?: boolean;
}
const StyledContent = styled.div<StyledDiv>`
  padding: ${(props) => (props.isTitle ? '16px 24px 0px 24px' : '0px')};
  display: flex;
  flex-direction: column;
  ${mq(BreakpointSizes.xs)} {
    padding: ${(props) => (props.isTitle ? '16px' : '')};
    gap: 6px;
  }
`;
