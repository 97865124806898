// @ts-nocheck
import { PropsWithChildren, useState } from 'react';

import '@material/web/switch/switch.js';
import { Typography } from '../typography/typography';
import styled from '@emotion/styled';

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  p {
    margin-right: 8px;
  }
`;

export interface SwitchProps extends PropsWithChildren {
  disabled?: boolean;
  defaultValue?: boolean;
  label?: string;
  onChange: (isActive: boolean) => void;
}

export const Switch = ({
  disabled = false,
  defaultValue = false,
  label = '',
  onChange,
}: SwitchProps) => {
  const [isActive, setIsActive] = useState(() => defaultValue);
  const props = {};
  if (disabled) {
    props.disabled = true;
  }
  if (defaultValue) {
    props.selected = true;
  }

  const switchChangeHandler = () => {
    setIsActive(!isActive);
    onChange(!isActive);
  };

  if (label) {
    return (
      <StyledLabel>
        <Typography variant="textBodySmall">{label}</Typography>
        <md-switch
          touch-target="wrapper"
          {...props}
          onClick={switchChangeHandler}
        ></md-switch>
      </StyledLabel>
    );
  }

  return (
    <md-switch
      touch-target="wrapper"
      {...props}
      onClick={switchChangeHandler}
    ></md-switch>
  );
};
