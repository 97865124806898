import { tlLogger } from '@taleemabad/shared';
import { syncManager } from '../utils/sync-manager';
import { ConnectionStatus, Network } from '@capacitor/network';
import { EventNames, analyticsService } from '../api/analytics';
import { userCache } from './user-cache';
import { downloadManagerService } from './download-manager';
import { Toast } from '@capacitor/toast';

export enum SyncManagerStatus {
  failed = 'failed',
  synced = 'synced',
  needSync = 'needSync',
  syncing = 'syncing',
}
const SYNC_PERIOD = 1000 * 60 * 5; // 60 minutes unless network changed?

// Worker is causing some depedancy issues right now
// Will be diving into it later.
// let syncWorker: Worker | null = null;

// if (window.Worker) {
//   syncWorker = useMemo(
//     () => new Worker(new URL('./worker.ts', import.meta.url)),
//     [],
//   );
// }

// useEffect(() => {
//   if (window.Worker && syncWorker) {
//     syncWorker.onmessage = (e: MessageEvent<string>) => {
//       console.log('got result back...', e.data);
//       syncStatus = SyncManagerStatus.synced;
//     };
//   }
// }, [syncWorker]);

export class SyncStateManager {
  syncStatus: SyncManagerStatus;
  timerId: any;
  isConnected: boolean;
  subscribers: ((status: SyncManagerStatus) => void)[] = [];

  constructor() {
    this.syncStatus = SyncManagerStatus.synced;
    this.timerId = setInterval(() => {
      // TODO add logic here to
      // see if state is syncing
      // and how long ago it was started.
      // if it was more than SYNC_PERIOD then reset it?
      const token = userCache.getTokenFromCache();
      if (!token) return;
      tlLogger.log('useSyncManager/syncTimer', {
        message: 'Starting timely sync check.',
        timestamp: new Date(),
      });
      this.checkChanges();
    }, SYNC_PERIOD);

    this.isConnected = false;
    Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
      tlLogger.log('syncStateManager/networkStatusChange', {
        message: 'Network status changed',
        status,
      });
      if (!status.connected) {
        const showToast = async () => {
          await Toast.show({
            text: 'You are offline.',
            duration: 'long',
            position: 'bottom',
          });
        };
        showToast();
      }
      this.isConnected = status.connected;
      // sync downloads from native platforms when network is back
      if (this.isConnected) downloadManagerService.syncDownloads();
    });
    this.init();
  }

  async init() {
    this.isConnected = (await Network.getStatus()).connected;
  }

  subscribe(callback: (status: SyncManagerStatus) => void) {
    this.subscribers.push(callback);
  }
  unsubscribe(callback: (status: SyncManagerStatus) => void) {
    const index = this.subscribers.indexOf(callback);
    if (index !== -1) {
      this.subscribers.splice(index, 1);
    }
  }
  checkChanges = async (forceSync: boolean = false) => {
    const method = 'syncStateManager/checkChanges';
    if (this.syncStatus === SyncManagerStatus.syncing.toString()) return;
    if (!this.isConnected) {
      return tlLogger.error(method, {
        message: 'Sync cancelled, device is offline.',
      });
    }
    this.syncStatus = SyncManagerStatus.syncing;
    tlLogger.start(method);
    const hasChanges = await syncManager.hasUnsyncedChanges();
    if (hasChanges || forceSync) {
      await this.performSync();
    } else {
      this.syncStatus = SyncManagerStatus.synced;
    }
    tlLogger.end(method);
  };

  performSync = async () => {
    const method = 'syncStateManager/performSync';
    tlLogger.start(method);
    try {
      await syncManager.performSync(null);
      this.syncStatus = SyncManagerStatus.synced;
    } catch (e) {
      this.syncStatus = SyncManagerStatus.failed;
      analyticsService.trackEvent(EventNames.syncDataSyncDataFailedError, {});
      tlLogger.error(method, { context: 'Unable to sync data due to', e });
    }
    tlLogger.end(method);
    this.subscribers.forEach((subscriber) => subscriber(this.syncStatus));
  };
}

export const syncStateManager = new SyncStateManager();
