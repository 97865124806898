import styled from '@emotion/styled';
import { EventNames, analyticsService, lessonPlanDB } from '@taleemabad/db';
import {
  AppBar,
  TimetableGradeSelectionScreen,
} from '@taleemabad/dsm/components';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useLiveQuery } from 'dexie-react-hooks';
import { useNavigate } from 'react-router-dom';

export const GradeSelectionPage = () => {
  const grades: any = useLiveQuery(() => lessonPlanDB.getGrades()) || [];
  const navigate = useNavigate();
  return (
    <StyledContent isTitle={true}>
      <AppBar
        pageTitle="Back"
        leftIcon="arrow_back"
        onLeftIconClick={() => {
          navigate('/');
        }}
        hasPadding={false}
        titleFullWidth={true}
        titleMedium={true}
        isTitleClickable={true}
      />
      <TimetableGradeSelectionScreen
        onClose={() => {
          navigate(-1);
        }}
        items={grades}
        heading="Select a grade you teach"
        onGradeSelect={(id: number) => {
          analyticsService.trackEvent(EventNames.dashboardGradeSelected, {
            epGradeSelected: id,
          });
          navigate(`/create-class/section/${id}`);
        }}
      />
    </StyledContent>
  );
};

interface StyledDiv {
  isTitle?: boolean;
}
const StyledContent = styled.div<StyledDiv>`
  padding: ${(props) => (props.isTitle ? '16px 24px 0px 24px' : '0px')};
  display: flex;
  flex-direction: column;
  ${mq(BreakpointSizes.xs)} {
    padding: ${(props) => (props.isTitle ? '16px' : '')};
    gap: 6px;
  }
`;
