import styled from '@emotion/styled';
import { FC } from 'react';
import { ICourse } from '@taleemabad/db';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { TBStackedCard } from '@taleemabad/dsm/components';
import { useWindowSize } from '@taleemabad/shared';
import PlaceHolderImage from '../../../../static/Placeholder.jpg';

export interface CourseListProps {
  courses: ICourse[];
  onCourseClick: (course: ICourse) => void;
}

export const CourseList: FC<CourseListProps> = ({ courses, onCourseClick }) => {
  const [width] = useWindowSize();

  return (
    <CourseListContainer>
      {courses.map((course) => (
        <TBStackedCard
          maxWidth={width < 575 ? '320px' : '357px'}
          key={course.id}
          title={course.title}
          progress={course.courseCompletionPercentage}
          trainings={course.trainingsCount}
          totalMinutes={course.timeDuration}
          imageSrc={
            course.thumbnailBase64
              ? 'data:image/png;base64,' + course.thumbnailBase64
              : PlaceHolderImage
          }
          onClick={() => onCourseClick(course)}
        />
      ))}
    </CourseListContainer>
  );
};

const CourseListContainer = styled.div`
  display: grid;
  gap: var(--rk-spacing-xl);
  grid-template-columns: repeat(4, 1fr);
  ${mq(BreakpointSizes.xs)} {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
  ${mq(BreakpointSizes.sm)} {
    grid-template-columns: repeat(1, 1fr);
  }
  ${mq(BreakpointSizes.md)} {
    grid-template-columns: repeat(1, 1fr);
  }
  ${mq(BreakpointSizes.lg)} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mq(BreakpointSizes.xl)} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
