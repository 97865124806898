import React from 'react';
import styled from '@emotion/styled';
import { CardContainer } from '../card/card-container';
import { css } from '@emotion/react';
import { Typography, Icon } from '@taleemabad/dsm/components';

interface ChapterCardProps {
  preTitle?: string;
  title: string;
  variant?: 'outline' | 'filledWithBorder';
  onClick?: () => void;
}

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--rk-card-padding);
`;

const TextWrapper = styled.div`
  display: flex;
  gap: var(--rk-spacing-md);
  align-items: baseline;
`;

const TextBodySpacingWrapper = styled.div`
  margin-right: var(--rk-spacing-md);
  white-space: nowrap;
`;

const IconWrapper = styled.div`
  margin-left: var(--rk-spacing-md);
`;

export const ChapterCard: React.FC<ChapterCardProps> = ({
  preTitle,
  title,
  variant = 'outline',
  onClick,
}) => {
  const cardClickedStyle = css`
    cursor: pointer;
  `;

  const cardStyle = css`
    cursor: pointer;
  `;
  return (
    //TODO : borders are cutting on right edge
    <div css={cardStyle}>
      <CardContainer
        minHeight="52px"
        onClick={() => {
          onClick?.();
        }}
        variant={variant}
      >
        <ContentWrapper>
          <TextWrapper>
            {preTitle && (
              <TextBodySpacingWrapper>
                <Typography variant="textBodyLarge">{preTitle}</Typography>
              </TextBodySpacingWrapper>
            )}
            <Typography variant="textBodyLarge">{title}</Typography>
          </TextWrapper>
          <IconWrapper>
            <Icon emotionCss={cardClickedStyle} name="keyboard_arrow_right" />
          </IconWrapper>
        </ContentWrapper>
      </CardContainer>
    </div>
  );
};
