// @ts-nocheck
import '@material/web/menu/menu.js';
import '@material/web/menu/menu-item.js';
import { Typography } from '../typography/typography';
import styled from '@emotion/styled';
import { useEffect } from 'react';

interface menuProps {
  menuItems: menuItems[];
  open: boolean;
  anchorCorner?: 'end-start' | 'end-end' | 'start-start' | 'start-end';
  menuCorner?: 'end-start' | 'end-end' | 'start-start' | 'start-end';
  defaultFocus?: 'none' | 'list-root' | 'first-item' | 'last-item';
  positioning?: 'absolute' | 'fixed' | 'document' | 'popover';
  onSelectedItem?: (data: menuItems) => void;
  onClose?: () => void;
}

interface menuItems {
  id: number;
  name: string;
  value?: string;
}

export const Menu: React.FC<menuProps> = ({
  menuItems,
  open = false,
  anchorCorner = 'start-start',
  menuCorner = 'start-start',
  defaultFocus = 'none',
  positioning = 'fixed',
  onSelectedItem,
  onClose,
}) => {
  const props = {};
  if (open) {
    props.open = open;
  }
  if (anchorCorner) {
    props.anchorCorner = anchorCorner;
  }
  if (menuCorner) {
    props.menuCorner = menuCorner;
  }
  if (defaultFocus) {
    props.defaultFocus = defaultFocus;
  }
  if (positioning) {
    props.positioning = positioning;
  }
  useEffect(() => {
    const closeMenu = (event: MouseEvent) => {
      if (open) {
        const menu = document.getElementById('menu-anchor');
        if (menu && !menu.contains(event.target)) {
          onClose?.();
        }
      }
    };
    document.addEventListener('click', closeMenu);
    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, [open, onClose]);

  return (
    <md-menu
      id="menu-anchor"
      style={menuToken}
      anchor="menu-anchor"
      xOffset={-20}
      yOffset={0}
      tabindex={0}
      {...props}
    >
      {menuItems.map((item) => (
        <md-menu-item type="menuitem" key={item.name} style={listItemToken}>
          <StyledTypography
            slot="headline"
            onClick={() => {
              onSelectedItem?.(item);
            }}
            variant="textBodyLarge"
          >
            {item.name}
          </StyledTypography>
        </md-menu-item>
      ))}
    </md-menu>
  );
};

const StyledTypography = styled(Typography)`
  text-align: left;
`;
const menuToken = {
  minWidth: '280px',
};
const listItemToken = {
  textAlign: 'left',
};
