import styled from '@emotion/styled';
import { IconText } from '../icon-text/icon-text';
import { Profile, ProfileProps } from '@taleemabad/dsm/components';
import { css } from '@emotion/react';

export interface DesktopBarProps {
  pageTitle: string;
  pageIcon: string;
  profile?: ProfileProps;
  backgroundColor?: string;
}

export const DesktopBar = ({
  pageTitle,
  pageIcon,
  profile,
  backgroundColor = undefined,
}: DesktopBarProps) => {
  return (
    <StyledDesktopHeaderContainer
      css={css`
        background-color: var(${backgroundColor}, transparent);
      `}
    >
      <IconText
        text={pageTitle}
        textVariant="textHeadlineMediumPrimary40"
        icon={pageIcon}
        iconFontSize="30px !important"
        iconColor="--md-ref-primary-40"
      />
      {profile && (
        <Profile
          noContainer={true}
          name={profile.name}
          subhead={profile.subhead}
          avatarSrc={profile.avatarSrc}
        />
      )}
    </StyledDesktopHeaderContainer>
  );
};

const StyledDesktopHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
`;
