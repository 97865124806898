import { IBookChapter } from '@taleemabad/db';
import {
  ChapterCard,
  IconText,
  SelectedSubject,
  Spacing,
} from '@taleemabad/dsm/components';

export interface TimeTableChapterProps {
  selectedSubject: SelectedSubject;
  Chapter: IBookChapter[];
  onSelectedChapter: (chapter: IBookChapter) => void;
}
export const TimeTableChapterScreen = ({
  selectedSubject,
  Chapter,
  onSelectedChapter,
}: TimeTableChapterProps) => {
  return (
    <>
      <Spacing marginBottom="lg">
        <IconText
          icon=""
          imgStyle={{
            height: '32px',
            width: '32px',
          }}
          img={selectedSubject.icon}
          text={selectedSubject.text}
          textVariant="textTitleLargePrimary40"
        />
      </Spacing>
      {Chapter.map((chapter, index) => (
        <Spacing marginBottom="md">
          <ChapterCard
            variant="filledWithBorder"
            key={chapter.title}
            title={`Topic ${index + 1}   ${chapter.title}`}
            onClick={() => onSelectedChapter(chapter)}
          />
        </Spacing>
      ))}
    </>
  );
};
