import { PageLayout } from 'apps/school-app/src/components/page-layout';
import { ProtectedRoute } from '../../authentication/components/protected-routes';
import { ReportCard } from '../page/report-card';

const routes = [
  {
    path: '/report-cards',
    element: (
      <ProtectedRoute>
        <PageLayout scroll={true}>
          <ReportCard />
        </PageLayout>
      </ProtectedRoute>
    ),
  },
];
export { routes as reportCardRoutes };
