import { IBaseApiResponse } from '@taleemabad/shared';
import { CourseRecord, TrainingRecord } from '../models/teachertraining';
import { DownloadInfo } from './downloads';

interface IGetTeachersBaseResponse {
  test: string;
}

export type IGetTeachersApiResponse =
  IBaseApiResponse<IGetTeachersBaseResponse>;

export enum CourseType {
  general = 'general',
  subject = 'subject',
}

export enum QuestionType {
  MCQ = 'mcq',
  MSQ = 'msq',
  OpenEnded = 'open-ended',
}

export interface ICourse {
  title: string;
  id: number;
  index: number;
  description: string;
  keywords: string[];
  thumbnailUrl: string;
  // the thumbnail is stored only in the local database
  // is not returned from the backend.
  thumbnailBase64?: string;
  thumbnailUrlDownloadAttempts?: number;
  timeDuration: number;
  trainingsCount: number;
  subject: number | null;
  gradeGroup: number | null;
  courseCompletionPercentage: number;
  courseStatus: ProgressStatus | null;
}

export interface IMediaAsset {
  id: number;
  created: string;
  modified: string;
  uuid: string;
  name: string;
  type: string;
  category: string[];
  url: string;
  tempUrl: string;
  hlsVideoMainManifestUrl: string;
  description: string;
  status: string;
  isActive: boolean;
  download?: DownloadInfo;
}

export interface ITraining {
  id: number;
  index: number;
  title: string;
  description: string;
  mediaAsset: IMediaAsset;
  isGrandAssessment: boolean;
  course: number;
  trainingStatus: null | ProgressStatus;
  assessment: null | IAssessment;
}

export interface ITrainingQuestion {
  id: number;
  index: number;
  type: QuestionType;
  questionStatement: string;
  options: string[];
  answers: number[];
  hint: string;
  training: number;
}

export enum ProgressStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export interface ITrainingStatus {
  training: number;
  courseId: number;
  status: ProgressStatus;
}

export interface ISubmission {
  training: number;
  answer: string;
  question: number;
  isCorrect: boolean;
  questionType: string;
  questionCopy: ITrainingQuestion;
  score: number;
}

export interface IAssessment {
  training: number;
  score: number;
  totalScore: number;
}

export interface TrainingCourseProgressInfo {
  cta: string | null;
  progressText: string | null;
  allTrainingsCompleted: boolean;
  training: TrainingRecord | null;
  course: CourseRecord | null;
}
