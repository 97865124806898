import styled from '@emotion/styled';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-quality-selector-hls';

import { EventNames, analyticsService } from '@taleemabad/db';

export const TbVideoPlayer = (props: any) => {
  const videoRef = useRef(null) as any;
  const playerRef = useRef(null) as any;
  const { options, onReady, isPlaying, showQualitySelector } = props;

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');
      videoRef.current.appendChild(videoElement);
      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady?.(player);
      }));
      if (showQualitySelector) {
        (player as any).qualitySelectorHls({
          vjsIconClass: 'vjs-icon-cog',
        });

        //@ts-ignore
        player.qualityLevels().on('addqualitylevel', function (event) {
          const qualityLevel = event.qualityLevel;
          // Set the default quality to 288p
          if (qualityLevel.height !== 288) {
            qualityLevel.enabled = false;
          }
        });
      }
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef, isPlaying]);
  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

interface TbdPlayerProps {
  autoplay?: boolean;
  url: string;
  offlineUrl?: string | null;
  hlsUrl?: string;
  isPlaying?: boolean;
  featureName?: string;
  eventData?: any;
  onPlay?: () => void;
  onVideoEnd?: () => void;
}
export const TbdPlayer = ({
  autoplay = false,
  url,
  offlineUrl,
  hlsUrl,
  isPlaying,
  featureName,
  eventData,
  onPlay,
  onVideoEnd,
}: TbdPlayerProps) => {
  const getVideoSource = () => {
    if (offlineUrl) {
      return { src: offlineUrl, type: 'video/mp4' };
    } else if (hlsUrl) {
      return { src: hlsUrl, type: 'application/x-mpegURL' };
    } else {
      return { src: url, type: 'video/mp4' };
    }
  };
  // we don't show quality selector for offline videos
  const showQualitySelector = !!hlsUrl && !offlineUrl;
  const videoPlayerRef = useRef(null);
  const videoJsOptions = {
    autoplay: autoplay,
    controls: true,
    fill: true,
    playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75],
    userActions: {
      doubleClick: false,
    },
    sources: [getVideoSource()],
    controlBar: {
      pictureInPictureToggle: false,
      skipButtons: {
        forward: 10,
        backward: 10,
      },
      progressControl: {
        seekBar: true,
      },
    },
  };

  const forward = (player: any) => {
    seek(10, player);
  };

  const backward = (player: any) => {
    seek(-10, player);
  };

  const seek = (secs: number, player: any) => {
    let time = player.currentTime() + secs;
    if (time < 0) {
      time = 0;
    }
    player.currentTime(time);
  };
  let segmentArray: number[] = [];
  let lastSelectedIndex = -1;

  const calculateVideoCheckpoints = (duration: number): number[] => {
    let timeSegmentArray: number[] = [];
    const timeSegmentsSize = duration / 10;
    for (let i = 0; i < 10; i++) {
      timeSegmentArray.push(Math.round(timeSegmentsSize * i + 1));
    }
    return timeSegmentArray;
  };

  const handlePlayerReady = (player: any) => {
    if (isPlaying && autoplay) {
      player.play();
    } else {
      player.pause();
      player.currentTime(0);
    }

    videoPlayerRef.current = player;
    //  event handling for all the events
    let hasStarted = false; // todo: Custom flag to prevent re-triggering. Refactoring required that why this useEffect triggers twice.
    player.on('play', () => {
      if (player.currentTime() === 0 && !hasStarted) {
        analyticsService.trackEvent(
          `${featureName}VideoStarted` as EventNames,
          eventData,
        );
        hasStarted = true;
      }
    });
    player.on('dispose', () => {});
    analyticsService.trackEvent(
      `${featureName}VideoImpression` as EventNames,
      eventData,
    );
    player.on('pause', () => {
      analyticsService.trackEvent(
        `${featureName}VideoPause` as EventNames,
        eventData,
      );
    });
    player.on('ended', () => {
      onVideoEnd?.();
      analyticsService.trackEvent(
        `${featureName}VideoCompleted` as EventNames,
        eventData,
      );
      hasStarted = false;
    });

    player.on('seeking', () => {
      lastSelectedIndex = -1;
    });

    player.on('timeupdate', () => {
      const selectedIndex = segmentArray.indexOf(
        Math.round(player.currentTime()),
      );
      if (selectedIndex !== -1 && selectedIndex !== lastSelectedIndex) {
        analyticsService.trackEvent(
          `${featureName}VideoInProgress` as EventNames,
          {
            ...eventData,
            epVideoProgress: selectedIndex + 1,
          },
        );
        lastSelectedIndex = selectedIndex;
      }
    });

    player.on('loadedmetadata', () => {
      segmentArray = calculateVideoCheckpoints(parseInt(player.duration()));
    });

    player.on('error', (e: any) => {
      analyticsService.trackEvent(`${featureName}VideoError` as EventNames, {
        ...eventData,
        epErrorMessage: e.target.outerText,
      });
    });
    player.on('dblclick', (e: any) => {
      const id = document.getElementById(player.id_.toString());
      if (id) {
        // for double click to seek forward or backward by splitting screen into two parts
        e.offsetX < id?.clientWidth / 2 ? backward(player) : forward(player);
      }
    });
  };
  return (
    <StyledVideoPlayerContainer>
      <TbVideoPlayer
        showQualitySelector={showQualitySelector}
        options={videoJsOptions}
        isPlaying={isPlaying}
        onReady={handlePlayerReady}
      />
    </StyledVideoPlayerContainer>
  );
};

const StyledVideoPlayerContainer = styled.div`
  .video-js {
    width: 100%;
    height: 500px;
    min-height: 500px;
    ${mq(BreakpointSizes.xs)} {
      height: 246px;
      min-height: 246px;
    }
  }
  // on smaller devices, the control bar has too many elements which goes out of bounds and the user can't see them, hence we need to reduce the width of the control bar
  .vjs-control {
    ${mq(BreakpointSizes.mobile)} {
      width: 35px;
    }
  }
  // to prevent scroll on the items in control bar e.g the quality selector and playback rate selector we need to increase the max height
  .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    max-height: 16em;
  }
`;
