import React, { useContext, useEffect } from 'react';
import { Typography } from '../../atoms/typography/typography';
import { CardContainer } from '../card/card-container';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ChipFilterWithContainer } from '../../atoms/chip/chip-filter';
import { ClassSubjectChipContext } from '@taleemabad/providers';

interface TbChipSelectorProps {
  title: string;
  items?: ChipItem[];
  noItemsText?: string;
  selected?: boolean;
  onChipClick?: (arg: ChipItem) => void;
}

export interface ChipItem {
  id: number;
  gradeShortCode: string;
  schoolClass: number;
  grade: number;
  section: string;
  subjectLabel: string;
  lastLocalModifiedAt: number;
  syncStatus: string;
  gradeSubject: number;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--rk-spacing-md);
  margin-top: var(--rk-spacing-lg);
`;

const ContainerWrapper = styled.div`
  padding: var(--rk-spacing-md);
`;

export const TbChipSelector: React.FC<TbChipSelectorProps> = ({
  title,
  items = [],
  noItemsText = 'No items found.',
  onChipClick,
}) => {
  const { selectedItem, setSelectedItem } = useContext(ClassSubjectChipContext);

  useEffect(() => {
    if (selectedItem && onChipClick) {
      onChipClick(selectedItem);
    }
  }, []);

  return (
    <div>
      <CardContainer
        variant="filled"
        emotionCss={css`
          min-height: 50px;
        `}
      >
        <ContainerWrapper>
          <Typography variant="textTitleMediumPrimaryFixed">{title}</Typography>

          <StyledWrapper>
            {items.length > 0 ? (
              items.map((data, index) => (
                <ChipFilterWithContainer
                  selected={selectedItem?.id === data.id}
                  onClick={() => {
                    if (onChipClick) {
                      onChipClick(data);
                    }
                    setSelectedItem(data);
                  }}
                  key={index}
                  label={`${data.gradeShortCode}-${data.section}-${data.subjectLabel}`}
                  variant="blue"
                />
              ))
            ) : (
              <Typography variant="textBodyMediumNeutral40">
                {noItemsText}
              </Typography>
            )}
          </StyledWrapper>
        </ContainerWrapper>
      </CardContainer>
    </div>
  );
};
