import { FC, useState } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  IconText,
  AlertDialog,
  Spacing,
  Typography,
} from '@taleemabad/dsm/components';
import { ItemInfoProps } from '../../molecules/item-info/item-info';
import { ItemInfoList } from '../../molecules/user-info-list/item-info-list';
import styled from '@emotion/styled';
import { analyticsService, EventNames, syncStateManager } from '@taleemabad/db';
import { Menu } from '../../atoms/menu/menu';

interface UserProfileProps {
  items: ItemInfoProps[];
  appVersion?: string;
  onLogoutClick: () => void;
  onResetAppClick: () => void;
  onDeactivateAccountClick: () => void;
}
const menuData = [
  { id: 1, name: 'Logout', value: 'logout' },
  { id: 2, name: 'Delete Account', value: 'delete' },
];
export const UserProfile: FC<UserProfileProps> = ({
  items,
  appVersion,
  onLogoutClick,
  onResetAppClick,
  onDeactivateAccountClick,
}) => {
  const [showLogoutPopup, setShowLogoutPopup] = useState<boolean>(false);
  const [showResetPopup, setShowResetPopup] = useState<boolean>(false);
  const [showNetworkError, setShowNetworkError] = useState<boolean>(false);
  const [showDeactivatePopup, setShowDeactivatePopup] =
    useState<boolean>(false);

  const [openMenu, setOpenMenu] = useState(false);

  const handleResetButtonClicked = () => {
    if (syncStateManager.isConnected) {
      setShowResetPopup(true);
    } else {
      setShowNetworkError(true);
      analyticsService.trackEvent(EventNames.noInternetNoInternetPopup, {});
    }
  };

  const HandleDecactivate = () => {
    if (syncStateManager.isConnected) {
      onDeactivateAccountClick?.();
      setShowDeactivatePopup(false);
    } else {
      setShowDeactivatePopup(false);
      setShowNetworkError(true);
      analyticsService.trackEvent(EventNames.noInternetNoInternetPopup, {});
    }
  };

  return (
    <>
      <Spacing marginBottom="xxxl">
        <AppBar
          pageTitle="Profile Settings"
          leftIcon="person"
          titleFullWidth={true}
          isLeftIconButton={false}
          rightIcon="more_vert"
          onRightIconClick={() => {
            setOpenMenu(!openMenu);
          }}
        >
          <Menu
            open={openMenu}
            menuCorner="end-end"
            anchorCorner="end-end"
            menuItems={menuData}
            onSelectedItem={(data) => {
              if (data.value === 'logout') {
                setShowLogoutPopup(true);
                setOpenMenu(false);
              }
              if (data.value === 'delete') {
                setShowDeactivatePopup(true);
                setOpenMenu(false);
              }
            }}
            onClose={() => {
              setOpenMenu(false);
            }}
          />
        </AppBar>
      </Spacing>
      <ItemInfoList items={items} />
      <Spacing marginTop="xxxxxxl" marginBottom="xxxl">
        <IconText
          text="To update your profile details contact your NIETE agent"
          textVariant="textBodyLargeLightPrimary"
          icon="error"
          iconColor="--md-sys-color-light-primary"
        />
      </Spacing>

      {appVersion && (
        <Typography variant="textTitleSmallonSurface">
          App Version: {appVersion}
        </Typography>
      )}

      <Spacing margin="xxxl">
        <Button
          children="Refresh App"
          variant="outlined"
          type="button"
          onClick={handleResetButtonClicked}
        ></Button>
      </Spacing>
      {/*dialogs*/}
      <Dialog
        open={showLogoutPopup}
        onClose={() => {
          setShowLogoutPopup(false);
        }}
        title="Logout?"
      >
        <Typography variant="textBodyMedium">
          Are you sure you want to logout?
        </Typography>
        <ActionButtonContainer>
          <Button
            variant="outlined"
            children="Cancel"
            onClick={() => {
              setShowLogoutPopup(false);
            }}
          />
          <Button
            children="Logout"
            onClick={() => {
              setShowLogoutPopup(false);
              onLogoutClick();
            }}
          />
        </ActionButtonContainer>
      </Dialog>
      <Dialog
        open={showResetPopup}
        onClose={() => {
          setShowResetPopup(false);
        }}
        title="Refresh App"
      >
        <Typography variant="textBodyMedium">
          Are you sure you want to refresh your app?
        </Typography>
        <ActionButtonContainer>
          <Button
            variant="outlined"
            children="Cancel"
            onClick={() => {
              setShowResetPopup(false);
            }}
          />
          <Button
            children="Refresh App"
            onClick={() => {
              setShowResetPopup(false);
              onResetAppClick();
            }}
          />
        </ActionButtonContainer>
      </Dialog>
      <AlertDialog
        title="No Internet Connection"
        description="Seems like your internet is not connected. Connect and try again."
        showPopup={showNetworkError}
        onClosePopup={() => setShowNetworkError(false)}
      />

      <AlertDialog
        title="Delete Account"
        description="Are you sure you want to delete your NIETE account? You will no longer have access to NIETE."
        showPopup={showDeactivatePopup}
        onPrimaryButtonClick={() => {
          HandleDecactivate();
        }}
        onClosePopup={() => setShowDeactivatePopup(false)}
        secondaryButton={true}
        secondaryButtonText="Cancel"
        primarButtonText="Delete"
      />
    </>
  );
};

const ActionButtonContainer = styled.div`
  display: flex;
  gap: var(--rk-spacing-md);
  md-filled-button {
    width: 50%;
  }
  md-outlined-button {
    width: 50%;
  }
`;
