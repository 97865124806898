import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { CardContainer } from '../card/card-container';
import { IconText } from '../icon-text/icon-text';
import { Typography } from '../../atoms/typography/typography';

interface ContentCardProps extends PropsWithChildren {
  iconName?: string;
  title: string;
}

const ContentCardStyledWrapper = styled.div`
  padding: var(--rk-spacing-xl);
`;

const ChildrenStyledWrapper = styled.div`
  margin-top: var(--rk-spacing-lg);
`;

export const ContentCard: React.FC<ContentCardProps> = ({
  iconName,
  title,
  children,
}) => {
  return (
    <CardContainer
      variant="filled"
      emotionCss={css`
        min-height: unset;
      `}
    >
      <ContentCardStyledWrapper>
        {iconName ? (
          <IconText
            icon={iconName}
            text={title}
            size="normal"
            textVariant="textTitleMediumPrimary"
            iconColor="--md-sys-color-primary"
          />
        ) : (
          <Typography variant="textTitleMediumPrimary">{title}</Typography>
        )}
        <ChildrenStyledWrapper>{children}</ChildrenStyledWrapper>
      </ContentCardStyledWrapper>
    </CardContainer>
  );
};
