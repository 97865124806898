import posthog from 'posthog-js';
import { userCache } from './user-cache';
import { getConfig, TeacherProfile } from '../api/auth';
import { ConnectionStatus, Network } from '@capacitor/network';

let listener: boolean = false;

export const posthogInit = () => {
  posthog.init(process.env.NX_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NX_PUBLIC_POSTHOG_HOST,
    autocapture: false,
    capture_pageview: false,
    disable_session_recording: true,
  });
};

const identify = () => {
  const user = userCache.getUserFromCache();
  const selectedProfile = userCache.getActiveProfile() as TeacherProfile;
  const config = getConfig();
  if (!user || !selectedProfile || !config) {
    return;
  }
  posthog.identify(user.username, {
    role: selectedProfile.role,
    profile_id: selectedProfile.id,
    joining_date: selectedProfile.joiningDate,
    school: selectedProfile.school,
    years_of_experience: selectedProfile.yearsOfService,
    organization: config.app,
    user_id: user.id,
  });
};

const stopRecording = () => {
  if (posthog.sessionRecordingStarted()) {
    posthog.stopSessionRecording();
  }
};

const startRecording = () => {
  if (!posthog.sessionRecordingStarted()) {
    posthog.startSessionRecording();
  }
};

const handleNetworkChange = async (status: ConnectionStatus) => {
  if (status.connected) {
    startRecording();
  } else {
    stopRecording();
  }
};

export const posthogIdentify = async () => {
  if (!listener) {
    listener = true;
    Network.addListener(
      'networkStatusChange',
      async (status: ConnectionStatus) => {
        handleNetworkChange(status);
      },
    );
  }

  if (!posthog.__loaded) {
    posthogInit();
  }

  //this is the case when the user logged out and relogin without refresh, so we need to stop previous recording which is still running without user identification and start a new one.
  if (!posthog._isIdentified() && posthog.sessionRecordingStarted()) {
    stopRecording();
    posthog.reset();
    identify();
    startRecording();
    return;
  }
  //this is the case when user is logged in and session recording is already started.
  if (posthog._isIdentified() && posthog.sessionRecordingStarted()) {
    return;
  }
  if (!posthog._isIdentified()) {
    identify();
  }

  //this is the case when user is not logged in but we need to start recording for sessions, it will also handle the case when user is logged in and we need to start recording.
  if (!posthog._isIdentified() || !posthog.sessionRecordingStarted()) {
    return startRecording();
  }
};
