import styled from '@emotion/styled';
import { SerializedStyles } from '@emotion/react';

const StyledImg = styled.img`
  width: 100%;
  object-fit: cover;
  max-height: 200px;
`;

export interface CardMediaProps {
  src: string;
  alt?: string;
  emotionCss?: SerializedStyles;
}

export const CardMedia = ({
  src,
  alt = 'media',
  emotionCss,
}: CardMediaProps) => {
  return <StyledImg src={src} alt={alt} css={emotionCss} />;
};
