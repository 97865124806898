import { FC, useEffect, useState } from 'react';
import {
  BaseOption,
  Button,
  CardContainer,
  CardStatus,
  ProgressWithLabel,
  Spacing,
  Timer,
  Typography,
} from '@taleemabad/dsm/components';
import styled from '@emotion/styled';

import { MCQCardListComponent } from '../../../molecules/mcq-card-list/mcq-card-list';
import { MSQCardList } from '../../../molecules/msq-card-list/msq-card-list';
import { ITrainingQuestion, QuestionType } from '@taleemabad/db';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';

interface QuizProps {
  trainingTitle: string;
  startTime?: number;
  question: ITrainingQuestion;
  totalQuestions: number;
  // we cant use the index in question since they are all 1 (something to fix in our db)
  currentQuestionIndex: number;
  scorePercentage: number;
  scoreText: string;
  retakeCount: number;
  onAnswerCheck?: (isCorrect: boolean, answer: string) => void;
  onNext?: () => void;
}

export const Quiz: FC<QuizProps> = ({
  trainingTitle,
  startTime = 60,
  question: originalQuestion,
  totalQuestions,
  currentQuestionIndex,
  scorePercentage,
  scoreText,
  retakeCount,
  onAnswerCheck,
  onNext,
}) => {
  // adjust the indices in the question.answers array, the originalQuestion.answers array is 1-indexed, but mcq/msq options arrays are 0-indexed.
  // therefore, we subtract 1 from each element in the originalQuestion.answers array to align the indices.
  const question = {
    ...originalQuestion,
    answers: originalQuestion.answers.map((answer) => answer - 1),
  };
  const [selectedMCQOption, setSelectedMCQOption] = useState<BaseOption | null>(
    null,
  );
  const [selectedMSQOptions, setSelectedMSQOptions] = useState<
    Array<BaseOption>
  >([]);

  const [isAnswerChecked, setIsAnswerChecked] = useState(false);
  const [isTimeUp, setIsTimeUp] = useState(false);

  useEffect(() => {
    // we need to reset the state when the user retakes quiz, this logic is needed for trainings with only one question
    setSelectedMCQOption(null);
    setSelectedMSQOptions([]);
    setIsAnswerChecked(false);
    setIsTimeUp(false);
  }, [retakeCount]);

  const handleMCQSelect = (option: BaseOption) => {
    setSelectedMCQOption(option);
  };

  const handleMSQSelect = (option: BaseOption) => {
    setSelectedMSQOptions((prevOptions) => {
      if (prevOptions.find((prevOption) => prevOption.value === option.value)) {
        return prevOptions.filter(
          (prevOption) => prevOption.value !== option.value,
        );
      } else {
        return [...prevOptions, option];
      }
    });
  };

  const checkAnswer = () => {
    setIsAnswerChecked(true);
    let isCorrect = false;
    let answer = '';
    if (question.type === QuestionType.MCQ) {
      isCorrect = selectedMCQOption
        ? question.answers.includes(Number(selectedMCQOption.value))
        : false;
      answer = selectedMCQOption ? selectedMCQOption.content : 'none';
    } else if (question.type === QuestionType.MSQ) {
      isCorrect =
        selectedMSQOptions.length === question.answers.length &&
        selectedMSQOptions.every((selectedOption) =>
          question.answers.includes(Number(selectedOption.value)),
        );
      answer =
        selectedMSQOptions.length > 0
          ? selectedMSQOptions.map((option) => option.content).join(', ')
          : 'none';
    }
    onAnswerCheck?.(isCorrect, answer);
  };

  const handleTimeUp = () => {
    if (isAnswerChecked) {
      return;
    }
    setIsTimeUp(true);
    checkAnswer();
  };

  const handleButtonClick = () => {
    if (isAnswerChecked) {
      onNext?.();
    } else {
      checkAnswer();
    }
  };

  const createOption = (
    option: string,
    index: number,
    isSelectedFunc: (index: number) => boolean,
    groupName: string = '',
  ) => {
    const isSelected = isSelectedFunc(index);
    const isCorrect = question.answers.includes(index);
    let status;
    if (isAnswerChecked) {
      if (isSelected && isCorrect) {
        status = CardStatus.Correct;
      } else if (isSelected) {
        status = CardStatus.Incorrect;
      } else if (isCorrect) {
        status = CardStatus.Correct;
      } else {
        status = CardStatus.Disabled;
      }
    } else {
      status = isSelected ? CardStatus.Selected : CardStatus.Unselected;
    }
    return {
      option: { value: index.toString(), content: option },
      groupName,
      status,
    };
  };

  const createMCQOption = (option: string, index: number) => {
    return createOption(
      option,
      index,
      (index) => Number(selectedMCQOption?.value) === index,
      `group-${question.id}`,
    );
  };

  const createMSQOption = (option: string, index: number) => {
    return createOption(
      option,
      index,
      (index) =>
        !!selectedMSQOptions.find(
          (selectedOption) => Number(selectedOption.value) === index,
        ),
    );
  };

  const renderQuestionType = () => {
    switch (question.type) {
      case QuestionType.MCQ:
        return (
          <MCQCardListComponent
            options={question.options.map(createMCQOption)}
            onSelect={handleMCQSelect}
          />
        );
      case QuestionType.MSQ:
        return (
          <MSQCardList
            options={question.options.map(createMSQOption)}
            onSelect={handleMSQSelect}
          />
        );
      default:
        return null;
    }
  };

  const renderQuestionTypeText = (type: QuestionType) => {
    switch (type) {
      case QuestionType.MCQ:
        return 'Multiple Choice Questions';
      case QuestionType.MSQ:
        return 'Multiple Select Questions';
      default:
        return 'Question';
    }
  };

  const getButtonText = () => {
    if (isAnswerChecked && currentQuestionIndex === totalQuestions - 1) {
      return 'Finish';
    } else if (isAnswerChecked) {
      return 'Next';
    } else {
      return 'Check';
    }
  };

  const isButtonDisabled = () => {
    return (
      !isTimeUp &&
      ((question.type === QuestionType.MCQ && !selectedMCQOption) ||
        (question.type === QuestionType.MSQ && selectedMSQOptions.length === 0))
    );
  };

  return (
    <QuizContainer>
      <HeadingContainer>
        <Typography variant="textTitleLargePrimary">{trainingTitle}</Typography>
        <Timer
          startTime={startTime}
          retakeCount={retakeCount}
          pause={isAnswerChecked}
          onTimeUp={handleTimeUp}
        ></Timer>
      </HeadingContainer>
      <QuestionInfoContainer>
        <QuestionTypeContainer>
          <Typography variant="textTitleMediumSecondary">
            {renderQuestionTypeText(question.type)}
          </Typography>
          <Spacing marginTop="sm">
            <Typography variant="textTitleMediumSurface">
              QUESTION {currentQuestionIndex + 1} OF {totalQuestions}
            </Typography>
          </Spacing>
        </QuestionTypeContainer>
        <ProgressWithLabel value={scorePercentage} text={scoreText} />
      </QuestionInfoContainer>
      <CardContainer variant="filled" minHeight="unset">
        <Spacing padding="xl">
          <Typography variant="textTitleMediumPrimary">
            {question.questionStatement}
          </Typography>
        </Spacing>
      </CardContainer>
      <Spacing marginTop="xxl" marginBottom="xxxxxxl">
        {renderQuestionType()}
      </Spacing>
      <ButtonContainer>
        <Button
          icon={isAnswerChecked ? '' : 'check'}
          width="100%"
          disabled={isButtonDisabled()}
          onClick={handleButtonClick}
        >
          {getButtonText()}
        </Button>
      </ButtonContainer>
    </QuizContainer>
  );
};

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const QuestionInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--rk-spacing-xl);
  margin-bottom: var(--rk-spacing-xxl);
`;

const QuestionTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuizContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  ${mq(BreakpointSizes.desktop)} {
    align-self: flex-end;
    width: fit-content;
  }
`;
