import React from 'react';
import { IconText } from '../../molecules/icon-text/icon-text';
import styled from '@emotion/styled';
import {
  ChapterCardList,
  ChipItem,
  LoadingScreen,
  TbChipSelector,
} from '@taleemabad/dsm/components';
import { useWindowSize } from '@taleemabad/shared';
import { IBookChapter, syncStateManager } from '@taleemabad/db';
import {
  LpExploreMenu,
  selectClassSubject,
} from '../../molecules/lp-explore/lp-explore';
import { LpNoChapter } from '../../molecules/lp-no-chapter/lp-no-chapter';

interface LessonPlanChaptersProps {
  tbChipSelectorTitle: string;
  tbChipSelectorItems: ChipItem[];
  tbChipSelectorNoItemsText: string;
  iconTextIcon: string;
  iconTextText: string;
  chapters: IBookChapter[];
  isExplore?: boolean;
  isLoading?: boolean;
  onSubjectSelected?: (arg: ChipItem) => void;
  onChapterSelected?: (arg: IBookChapter) => void;
  onClassSubjectSelected?: (arg: selectClassSubject) => void;
}

const LessonPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconTextContainer = styled.div`
  margin: var(--rk-spacing-xl) 0;
`;
export const LessonPlanChapters: React.FC<LessonPlanChaptersProps> = ({
  tbChipSelectorTitle,
  tbChipSelectorItems,
  tbChipSelectorNoItemsText,
  iconTextText = 'All Lessons',
  iconTextIcon = 'import_contacts',
  chapters,
  isExplore,
  isLoading,
  onSubjectSelected,
  onChapterSelected,
  onClassSubjectSelected,
}) => {
  const [width] = useWindowSize();

  const [isSelected, setIsSelected] = React.useState(false);

  return (
    <LessonPlanContainer>
      {isExplore ? (
        <LpExploreMenu
          selectedClassSubject={(data) => {
            onClassSubjectSelected?.(data);
            if (data.classId && data.subjectId) {
              setIsSelected(true);
            } else {
              setIsSelected(false);
            }
          }}
        />
      ) : (
        <TbChipSelector
          title={tbChipSelectorTitle}
          items={tbChipSelectorItems}
          noItemsText={tbChipSelectorNoItemsText}
          onChipClick={(data) => {
            onSubjectSelected?.(data);
          }}
        />
      )}
      {isLoading && syncStateManager.isConnected && <LoadingScreen />}
      {!isLoading && chapters.length === 0 && isExplore && isSelected && (
        <LpNoChapter />
      )}
      {chapters.length > 0 && (
        <>
          <IconTextContainer>
            <IconText
              icon={iconTextIcon}
              text={iconTextText}
              size={width > 575 ? 'large' : 'normal'}
              textVariant="textTitleLargePrimary40"
              iconColor="--md-ref-primary-40"
            />
          </IconTextContainer>
          <ChapterCardList
            chapters={chapters}
            onChapterClick={(data: IBookChapter) => {
              onChapterSelected && onChapterSelected(data);
            }}
          />
        </>
      )}
    </LessonPlanContainer>
  );
};
