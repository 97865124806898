// @ts-nocheck
import { PropsWithChildren } from 'react';

import '@material/web/chips/assist-chip.js';

import { ChipSet } from './chip-set';
import { Icon } from '../icon/icon';

export interface ChipAssistProps extends PropsWithChildren {
  elevated?: boolean;
  disabled?: boolean;
  alwaysFocusable?: boolean;
  label: string;
  icon?: string;
  onClick?: () => void;
}

export const ChipAssist = ({
  elevated = false,
  alwaysFocusable = false,
  label,
  disabled = false,
  icon,
  onClick,
}: ChipAssistProps) => {
  const props = {};
  if (elevated) {
    props.elevated = true;
  }
  if (alwaysFocusable) {
    props['always-focusable'] = alwaysFocusable;
  }
  if (label) {
    props.label = label;
  }
  if (disabled) {
    props.disabled = disabled;
  }
  if (onClick) {
    props.onClick = onClick;
  }

  return (
    <md-assist-chip {...props}>
      {icon && <Icon name={icon} slot="icon" />}
    </md-assist-chip>
  );
};

export const ChipAssistWithContainer = (props: ChipAssistProps) => {
  return (
    <ChipSet>
      <ChipAssist {...props} />
    </ChipSet>
  );
};
