import { SyncChanges, SyncRecord, SyncRecordStatus } from '../types/sync-state';
import {
  IBookChapter,
  IGradeSubject,
  IGrades,
  ILessonPlan,
  ILessonPlanDetail,
  ILessonPlanStatus,
  ILessonPlanStep,
  IMedia,
  ISchoolClass,
  ISchoolClassSubject,
  ISchoolClassTimeTable,
  ISubject,
} from '../types/lessonplan';
import { PromiseExtended, Table } from 'dexie';
import { BaseModelForPush } from './base-model';
import { userCache } from '../utils/user-cache';

const SCHEMA_VERSION = 2;
const SCHEMA = {
  lessonPlans: 'id, index, schoolClass, bookChapter',
  chapters: 'id, chapterNumber, schoolClass',
  gradeSubjects: 'id',
  schoolClassSubjects: 'id',
  schoolClasses: 'id',
  lessonPlanDetail: 'id, index, schoolClass, bookChapter',
  lessonPlanStatus: '++id, [lessonPlan+profile+schoolClass], syncStatus',
  grades: 'id, syncStatus',
  subjects: 'id, syncStatus',
  schoolClassTimetables: '++id, syncStatus',
};

export const subjectIcons = [
  { id: 3, label: 'English', icon: '../../assets/english.svg' },
  { id: 1, label: 'Maths', icon: '../../assets/math.svg' },
  { id: 4, label: 'General Science', icon: '../../assets/science.svg' },
  {
    id: 5,
    label: 'General Knowledge',
    icon: '../../assets/general-knowledge.svg',
  },
  { id: 2, label: 'Social Studies', icon: '../../assets/social-studies.svg' },
  { id: 11, label: 'Islamiyat', icon: '../../assets/islamiat.svg' },
  { id: 9, label: 'Urdu', icon: '../../assets/urdu.svg' },
  {
    id: 7,
    label: 'Physical Education',
    icon: '../../assets/physical-education.svg',
  },
  {
    id: 8,
    label: 'Extra Curricular',
    icon: '../../assets/extra-curricular.svg',
  },
];

export const Days = [
  { id: 1, name: 'Monday', initials: 'M' },
  { id: 2, name: 'Tuesday', initials: 'T' },
  { id: 3, name: 'Wednesday', initials: 'W' },
  { id: 4, name: 'Thursday', initials: 'T' },
  { id: 5, name: 'Friday', initials: 'F' },
  { id: 6, name: 'Saturday', initials: 'S' },
];
export const sections = [
  {
    label: 'A',
    id: 1,
  },
  {
    label: 'B',
    id: 2,
  },
  {
    id: 3,
    label: 'C',
  },
  {
    id: 4,
    label: 'D',
  },
];
export interface LessonPlanRecord extends ILessonPlan, SyncRecord {
  title: string;
}
export interface BookChapterRecord extends IBookChapter, SyncRecord {}
export interface GradeSubjectRecord extends IGradeSubject, SyncRecord {}
export interface SchoolClassRecord extends ISchoolClass, SyncRecord {}
export interface lessonPlanDetailRecord extends ILessonPlanDetail, SyncRecord {}
export interface LessonPlanStatusRecord extends ILessonPlanStatus, SyncRecord {}
export interface LessonPlanGradeRecord extends IGrades, SyncRecord {}
export interface LessonPlanSubjectRecord extends ISubject, SyncRecord {}
export interface LeesonPlanTimetableRecord
  extends ISchoolClassTimeTable,
    SyncRecord {}

export interface SchoolClassSubjectRecord
  extends ISchoolClassSubject,
    SyncRecord {}

export class LessonPlanDB extends BaseModelForPush {
  lessonPlans!: Table<LessonPlanRecord>;
  chapters!: Table<BookChapterRecord>;
  gradeSubjects!: Table<GradeSubjectRecord>;
  schoolClasses!: Table<SchoolClassRecord>;
  schoolClassSubjects!: Table<SchoolClassSubjectRecord>;
  lessonPlanDetail!: Table<lessonPlanDetailRecord>;
  lessonPlanStatus!: Table<LessonPlanStatusRecord>;
  grades!: Table<LessonPlanGradeRecord>;
  subjects!: Table<LessonPlanSubjectRecord>;
  schoolClassTimetables!: Table<LeesonPlanTimetableRecord>;

  constructor() {
    super('lessonplandb');
    this.version(SCHEMA_VERSION).stores(SCHEMA);

    this.lessonPlans.hook('updating', this.updateHook.bind(this));
    this.lessonPlans.hook('deleting', this.deleteHook.bind(this));
    this.lessonPlans.hook('creating', this.createHook.bind(this));

    this.chapters.hook('updating', this.updateHook.bind(this));
    this.chapters.hook('deleting', this.deleteHook.bind(this));
    this.chapters.hook('creating', this.createHook.bind(this));

    this.gradeSubjects.hook('updating', this.updateHook.bind(this));
    this.gradeSubjects.hook('deleting', this.deleteHook.bind(this));
    this.gradeSubjects.hook('creating', this.createHook.bind(this));

    this.schoolClasses.hook('updating', this.updateHook.bind(this));
    this.schoolClasses.hook('deleting', this.deleteHook.bind(this));
    this.schoolClasses.hook('creating', this.createHook.bind(this));

    this.schoolClassSubjects.hook('updating', this.updateHook.bind(this));
    this.schoolClassSubjects.hook('deleting', this.deleteHook.bind(this));
    this.schoolClassSubjects.hook('creating', this.createHook.bind(this));

    this.lessonPlanDetail.hook('updating', this.updateHook.bind(this));
    this.lessonPlanDetail.hook('deleting', this.deleteHook.bind(this));
    this.lessonPlanDetail.hook('creating', this.createHook.bind(this));

    this.lessonPlanStatus.hook('updating', this.updateHook.bind(this));
    this.lessonPlanStatus.hook('deleting', this.deleteHook.bind(this));
    this.lessonPlanStatus.hook('creating', this.createHook.bind(this));

    this.grades.hook('updating', this.updateHook.bind(this));
    this.grades.hook('deleting', this.deleteHook.bind(this));
    this.grades.hook('creating', this.createHook.bind(this));

    this.subjects.hook('updating', this.updateHook.bind(this));
    this.subjects.hook('deleting', this.deleteHook.bind(this));
    this.subjects.hook('creating', this.createHook.bind(this));

    this.schoolClassTimetables.hook('updating', this.updateHook.bind(this));
    this.schoolClassTimetables.hook('deleting', this.deleteHook.bind(this));
    this.schoolClassTimetables.hook('creating', this.createHook.bind(this));
  }

  clearData() {
    this.lessonPlans.clear();
    this.chapters.clear();
    this.gradeSubjects.clear();
    this.schoolClasses.clear();
    this.schoolClassSubjects.clear();
    this.lessonPlanDetail.clear();
    this.lessonPlanStatus.clear();
    this.grades.clear();
    this.subjects.clear();
    this.schoolClassTimetables.clear();
  }

  getBookChapters(
    schoolClass: number,
    gradeSubject: number,
  ): PromiseExtended<BookChapterRecord[]> {
    return this.chapters
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.schoolClass === schoolClass &&
          record.gradeSubject === gradeSubject,
      )
      .sortBy('chapterNumber');
  }

  getSelectedBookChapter(
    chapterId: number,
  ): PromiseExtended<BookChapterRecord[]> {
    return this.chapters
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.id === chapterId,
      )
      .sortBy('chapterNumber');
  }

  getSelectedGradeSubject(
    gradeSubjectId: number,
  ): PromiseExtended<GradeSubjectRecord[]> {
    return this.gradeSubjects
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.id === gradeSubjectId,
      )
      .sortBy('id');
  }

  getSelectedGradeSubjectByLabels(
    gradeLabel: string,
    subjectLabel: string,
  ): PromiseExtended<GradeSubjectRecord[]> {
    return this.gradeSubjects
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.subjectLabel === subjectLabel &&
          record.gradeLabel === gradeLabel,
      )
      .sortBy('id');
  }

  getChapterByGradeSubjectId(id: number): PromiseExtended<BookChapterRecord[]> {
    return this.chapters
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.gradeSubject === id,
      )
      .sortBy('chapterNumber');
  }

  getSchoolClassSubjectGrade(): PromiseExtended<ISchoolClassSubject[]> {
    return this.schoolClassSubjects
      .filter((record) => record.syncStatus !== SyncRecordStatus.deleted)
      .sortBy('gradeSubject');
  }
  async getGradeSubjectClass(): Promise<PromiseExtended> {
    const gradeSubject = await this.gradeSubjects
      .filter((record) => record.syncStatus !== SyncRecordStatus.deleted)
      .sortBy('id');
    const schoolClassSubject = await this.schoolClassSubjects
      .filter((record) => record.syncStatus !== SyncRecordStatus.deleted)
      .sortBy('gradeSubject');
    const schoolClass = await this.schoolClasses
      .filter((record) => record.syncStatus !== SyncRecordStatus.deleted)
      .sortBy('schoolClass');

    const subjectClass = gradeSubject.map((subject) => {
      const matchedClassSubject = schoolClassSubject.find(
        (classSubjectItem) => classSubjectItem.gradeSubject === subject.id,
      );
      return {
        ...matchedClassSubject,
        ...subject,
      };
    });

    const schoolClassData = subjectClass.map((subjectItem) => {
      const matchedSchoolClass = schoolClass.find(
        (schoolClass) => schoolClass.id === subjectItem.schoolClass,
      );
      return {
        ...matchedSchoolClass,
        ...subjectItem,
      };
    });
    return schoolClassSubject.length > 0 ? schoolClassData : [];
  }

  getLessonPlans(bookChapter: number): PromiseExtended<LessonPlanRecord[]> {
    return this.lessonPlans
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.bookChapter.toString() === bookChapter.toString(),
      )
      .sortBy('index');
  }

  getGradeSubjects(): PromiseExtended<GradeSubjectRecord[]> {
    return this.gradeSubjects
      .filter((record) => record.syncStatus !== SyncRecordStatus.deleted)
      .sortBy('id');
  }
  getLessonPlanMetaData(
    lessonPlanId: number,
  ): PromiseExtended<LessonPlanRecord[]> {
    return this.lessonPlans
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.id.toString() === lessonPlanId.toString(),
      )
      .sortBy('index');
  }

  getLessonPlanDetailCount(lessonPlanId: number): PromiseExtended<number> {
    return this.lessonPlanDetail
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.id.toString() === lessonPlanId.toString(),
      )
      .count();
  }

  getLessonPlanDetail(
    lessonPlanId: number,
  ): PromiseExtended<lessonPlanDetailRecord[]> {
    return this.lessonPlanDetail
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.id.toString() === lessonPlanId.toString(),
      )
      .sortBy('id');
  }

  processStatements(statements: ILessonPlanStep[]): ILessonPlanStep[] {
    const uniqueLinks = new Set<string>();
    const processedStatements = statements.map(
      (statement: ILessonPlanStep, index: number) => {
        const isAdjacent = index > 0 && statements[index - 1] === statement;
        if (statement.media && statement.media.length > 0 && !isAdjacent) {
          statement.media = statement.media.filter((media: IMedia) => {
            if (!uniqueLinks.has(media.link)) {
              uniqueLinks.add(media.link);
              return true;
            }
            return false;
          });
        }
        return statement;
      },
    );
    return processedStatements;
  }
  private async getLessonPlanSyncData(
    model: Table,
  ): Promise<PromiseExtended<SyncRecord[]>> {
    const records = await model
      .where('syncStatus')
      .anyOf([SyncRecordStatus.created, SyncRecordStatus.updated])
      .toArray();

    return records.map((record) => {
      if (record.syncStatus === SyncRecordStatus.updated) {
        return { ...record, syncStatus: SyncRecordStatus.created };
      }
      return record;
    });
  }

  getNewCreatedTimetableRecord(): PromiseExtended<SyncRecord[]> {
    return this.schoolClassTimetables
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.schoolClassSubject === undefined,
      )
      .toArray();
  }
  async getAllUnsyncedRecords(): Promise<Record<string, SyncRecord[]>> {
    const records = {
      lessonPlanStatus: await this.getLessonPlanSyncData(this.lessonPlanStatus),
      schoolClassTimetables: await this.getNewCreatedTimetableRecord(),
    };
    return records;
  }
  async updateRecordsSyncStatus(
    syncChanges: SyncChanges,
    status: SyncRecordStatus,
  ): Promise<number> {
    let updates = 0;
    const idsToMarkSynced = {
      lessonPlanStatus: syncChanges.lessonPlanStatus?.created.map(
        (record: { id: number }) => record.id,
      ),
    };
    for (const [model, ids] of Object.entries(idsToMarkSynced)) {
      updates += await this._updateSyncData(
        // @ts-ignore
        this[model],
        ids,
        status,
      );
    }
    return 1;
  }

  async updateLessonPlanStatus(
    chapterId: number,
    lessonPlanId: number,
  ): Promise<void> {
    const user = userCache.getUserFromCache();
    const teacherId = user?.profiles.filter((user) => user.role === 'Teacher');
    return this.getLessonPlans(chapterId).then(async (lessonPlans) => {
      lessonPlans.forEach(async (lessonPlan) => {
        if (lessonPlan.id === lessonPlanId) {
          lessonPlanDB.lessonPlans.update(lessonPlan.id, {
            ...lessonPlan,
            lessonPlanStatus: new Date().toISOString(),
          });

          const isExist = await this.lessonPlanStatus
            .where({
              lessonPlan: lessonPlan.id,
              profile: teacherId?.[0].id,
              schoolClass: lessonPlan.schoolClass,
            })
            .first();
          if (!isExist) {
            this.lessonPlanStatus.add({
              lessonPlan: lessonPlan.id,
              schoolClass: lessonPlan.schoolClass,
              created: new Date().toISOString(),
            } as LessonPlanStatusRecord);
          } else {
            this.lessonPlanStatus
              .where({
                lessonPlan: lessonPlan.id,
                profile: teacherId?.[0].id,
                schoolClass: lessonPlan.schoolClass,
              })
              .modify({
                created: new Date().toISOString(),
                syncStatus: SyncRecordStatus.updated,
              });
          }
        }
      });
    });
  }

  async updateLessonPlanStatusClass(
    chapterId: number,
    lessonPlanId: number,
    Class: number[],
  ): Promise<void> {
    const schoolClass = Class[0];
    const user = userCache.getUserFromCache();
    const teacherId = user?.profiles.filter((user) => user.role === 'Teacher');
    return this.getLessonPlans(chapterId).then(async (lessonPlans) => {
      lessonPlans.forEach(async (lessonPlan) => {
        if (lessonPlan.id === lessonPlanId) {
          const index = lessonPlan.lessonPlanStatusClass.findIndex(
            (item) => item.schoolClass === schoolClass,
          );

          if (index !== -1) {
            lessonPlan.lessonPlanStatusClass[index] = {
              schoolClass: schoolClass,
              status: new Date().toISOString(),
            };
          } else {
            lessonPlan.lessonPlanStatusClass.push({
              schoolClass: schoolClass,
              status: new Date().toISOString(),
            });
          }
          lessonPlanDB.lessonPlans.update(lessonPlan.id, {
            ...lessonPlan,
            lessonPlanStatusClass: lessonPlan.lessonPlanStatusClass,
          });

          const isExist = await this.lessonPlanStatus
            .where({
              lessonPlan: lessonPlan.id,
              profile: teacherId?.[0].id,
              schoolClass: schoolClass,
            })
            .first();
          if (!isExist) {
            this.lessonPlanStatus.add({
              lessonPlan: lessonPlan.id,
              schoolClass: schoolClass,
              created: new Date().toISOString(),
            } as LessonPlanStatusRecord);
          } else {
            this.lessonPlanStatus
              .where({
                lessonPlan: lessonPlan.id,
                profile: teacherId?.[0].id,
                schoolClass: schoolClass,
              })
              .modify({
                created: new Date().toISOString(),
                syncStatus: SyncRecordStatus.updated,
              });
          }
        }
      });
    });
  }

  updateChapterData(data: any) {
    lessonPlanDB.chapters.bulkPut(data.chapters.created.data);
    lessonPlanDB.lessonPlans.bulkPut(data.lessonPlans.created.data);
  }
  getGrades(): PromiseExtended<LessonPlanGradeRecord[]> {
    return this.grades
      .filter((record) => record.syncStatus !== SyncRecordStatus.deleted)
      .sortBy('id');
  }

  getSubjects(): PromiseExtended<LessonPlanSubjectRecord[]> {
    return this.subjects
      .filter((record) => record.syncStatus !== SyncRecordStatus.deleted)
      .sortBy('id');
  }

  getSubject(subjectId: number): PromiseExtended<LessonPlanSubjectRecord[]> {
    return this.subjects
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.grades.includes(subjectId),
      )

      .sortBy('id');
  }

  addRecordLessonPlanTimetable(timetable: ISchoolClassTimeTable) {
    this.schoolClassTimetables.add({
      shift: timetable.shift,
      daysOfWeek: timetable.daysOfWeek,
      grade: timetable.grade,
      subject: timetable.subject,
      section: timetable.section,
    } as LeesonPlanTimetableRecord);
  }

  getDays() {
    return Days;
  }
  getSelectedGrade(gradeId: number) {
    return this.grades
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.id === gradeId,
      )
      .sortBy('id');
  }

  getLessonPlaStatusCount(lessonPlanId: number): PromiseExtended<number> {
    return this.lessonPlanStatus
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.lessonPlan === lessonPlanId,
      )
      .count();
  }
  async removeTimetableEntriesWithSchoolClass() {
    await this.schoolClassTimetables
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.schoolClassSubject === undefined,
      )
      .delete();
  }

  async getTimetableCreatedRecords() {
    return await this.schoolClassTimetables
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.schoolClassSubject !== undefined,
      )
      .sortBy('id');
  }

  async getTimetableEntries() {
    const timetableEntries = await this.schoolClassTimetables
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.schoolClassSubject !== undefined,
      )
      .sortBy('id');
    const filteredSchoolClassSubjects = await this.schoolClassSubjects
      .filter((record) => record.syncStatus !== SyncRecordStatus.deleted)
      .sortBy('id');

    const timetableWithGradeSubject = filteredSchoolClassSubjects.filter(
      (schoolClass) => {
        return timetableEntries.some(
          (timetableentries) =>
            schoolClass.id === timetableentries.schoolClassSubject,
        );
      },
    );
    const schoolClassSections = await this.schoolClasses
      .filter((record) => record.syncStatus !== SyncRecordStatus.deleted)
      .sortBy('id');

    const gradeSubject = await this.gradeSubjects
      .filter((record) => record.syncStatus !== SyncRecordStatus.deleted)
      .sortBy('id');
    const timetableWithSectionAndShift = timetableWithGradeSubject.map(
      (record) => ({
        ...record,
        section: schoolClassSections.find((data) => {
          return data.id === record.schoolClass;
        })?.section,
        shift: schoolClassSections.find((data) => {
          return data.id === record.schoolClass;
        })?.shift,
        gradeLabel: gradeSubject.find((data) => {
          return data.id === record.gradeSubject;
        })?.gradeLabel,
        subjectLabel: gradeSubject.find((data) => {
          return data.id === record.gradeSubject;
        })?.subjectLabel,
        grade: gradeSubject.find((data) => {
          return data.id === record.gradeSubject;
        })?.grade,
        subject: gradeSubject.find((data) => {
          return data.id === record.gradeSubject;
        })?.subject,
        daysOfWeek: timetableEntries.find((data) => {
          return data.schoolClassSubject === record.id;
        })?.daysOfWeek,
      }),
    );

    return timetableWithSectionAndShift;
  }

  async getTimeTableClass(id: number) {
    return (await this.getTimetableEntries()).filter(
      (record) => record?.gradeSubject === id,
    );
  }
  async checkTimetableEntryExists(
    grade: number,
    subject: number,
    section: string,
    shift: string,
  ) {
    return (await this.getTimetableEntries()).filter(
      (record: any) =>
        record.syncStatus !== SyncRecordStatus.deleted &&
        record.grade === grade &&
        record.subject === subject &&
        record.section === section &&
        record.shift === shift.toUpperCase(),
    ).length;
  }
}

export const lessonPlanDB = new LessonPlanDB();
