import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Spacing, Typography } from '@taleemabad/dsm/components';
import { CardContainer } from '../card/card-container';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { Monogram } from '../../atoms/monogram/monogram';

export interface ProfileCardListProps {
  studentProfiles: IProfile[];
  onProfileSelected: (profile: any) => void;
}

interface IProfile {
  id: number;
  grade: string;
  name: string;
}

export const ProfileCardList = ({
  studentProfiles = [],
  onProfileSelected,
}: ProfileCardListProps) => {
  return (
    <ProfileCardListWrapper>
      {studentProfiles.map((profile) => (
        <ClickableContainer
          key={profile.id}
          onClick={() => onProfileSelected?.(profile)}
        >
          <CardContainer
            variant="outline"
            width="144px"
            emotionCss={css`
              min-height: 160px;
              max-width: 144px;
              background-color: var(--md-sys-color-light-outline-variant);
              ${mq(BreakpointSizes.xs)} {
                min-height: 142px;
                max-width: 134px;
              }
            `}
          >
            <ContainerWrapper>
              <Monogram name={profile.name} />
              <Spacing marginTop="lg">
                <Typography
                  variant="textBodyLargeLightOnSurface"
                  ellipseLine={1}
                >
                  {profile.name}
                </Typography>
              </Spacing>
              <Typography variant="textBodyMediumOnSurfaceVariant">
                {profile.grade}
              </Typography>
            </ContainerWrapper>
          </CardContainer>
        </ClickableContainer>
      ))}
    </ProfileCardListWrapper>
  );
};
const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--rk-spacing-xl);
`;
const ClickableContainer = styled.div`
  cursor: pointer;
  overflow-wrap: anywhere;
`;
const ProfileCardListWrapper = styled.div`
  display: grid;
  gap: var(--rk-spacing-xl);
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  ${mq(BreakpointSizes.xs)} {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
  ${mq(BreakpointSizes.sm)} {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
`;
