export enum SyncRecordStatus {
  created = 'created',
  updated = 'updated',
  deleted = 'deleted',
  synced = 'synced',
  failed = 'failed',
}

export interface SyncRecord {
  // (maintain by local sync manager)
  syncStatus?: SyncRecordStatus;
  // TimeStamp (maintain by local sync manager)
  lastLocalModifiedAt?: number;
  // TimeStamp (maintain by Server)
  lastModifiedAt?: number;
  // Both these are added to help identify the right profile
  // the data belongs to, esp in case the user logs out
  // and logs in using a different profile.
  user?: number;
  profile?: number;
}

export interface SyncChanges {
  [table_name: string]: {
    created: Record<string, any>;
    // TODO make them compulsory once the update sync
    // implementation is ready.
    updated?: Record<string, any>;
    deleted?: string[];
  };
}
