import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { Typography } from '../../atoms/typography/typography';
import { Icon } from '../../atoms/icon/icon';
import { css } from '@emotion/react';
import { Ripple } from '@taleemabad/dsm/components';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';

export interface NavBarItem {
  label: string;
  icon: string;
  key: number;
  path?: string;
  analyticsKey: string;
  visible?: boolean;
}

export interface NavBarProps {
  items: NavBarItem[];
  activeItemKey: number;
  fixed?: boolean;
  onChange: (item: NavBarItem) => void;
}

export const NavBar = ({
  items,
  activeItemKey,
  fixed,
  onChange,
}: NavBarProps) => {
  return (
    <StyledNavBarContainer css={fixed && fixedStyled}>
      {items.map((item) => {
        if (item.visible === false) {
          return null;
        }
        const isActive = activeItemKey === item.key;
        return (
          <NavBarItemContainer
            key={item.key}
            className={`rk-nav-bar-item-container${isActive ? ' active' : ''}`}
            onClick={() => onChange(item)}
          >
            <Ripple />
            <StyledActiveIndicator isActive={isActive}>
              <NavBarItemIcon isActive={isActive} name={item.icon} />
            </StyledActiveIndicator>
            <NavBarItemLabel isActive={isActive}>{item.label}</NavBarItemLabel>
          </NavBarItemContainer>
        );
      })}
    </StyledNavBarContainer>
  );
};

const fixedStyled = css`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const StyledNavBarContainer = styled.div`
  display: none;
  flex-direction: row;
  width: 100%;
  ${mq(BreakpointSizes.xs)} {
    display: flex;
    position: fixed;
  }
`;

interface NavBarItemContainerProps extends PropsWithChildren {
  className?: string;
  onClick: () => void;
}

const NavBarItemContainer = ({
  children,
  className = '',
  onClick,
}: NavBarItemContainerProps) => {
  return (
    <StyledNavBarItemContainer className={className} onClick={onClick}>
      {children}
    </StyledNavBarItemContainer>
  );
};

const StyledNavBarItemContainer = styled.div`
  background-color: var(--md-sys-color-surface-container);
  height: 80px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

interface NavBarItemLabelProps extends PropsWithChildren {
  isActive?: boolean;
}

const NavBarItemLabel = ({
  children,
  isActive = false,
}: NavBarItemLabelProps) => {
  return (
    <Typography
      variant={
        isActive ? 'textLabelMediumSurface' : 'textLabelMediumSurfaceVariant'
      }
    >
      {children}
    </Typography>
  );
};

interface StyledActiveIndicatorProps {
  isActive?: boolean;
}

const StyledActiveIndicator = styled.div<StyledActiveIndicatorProps>`
  height: 32px;
  width: 64px;
  border-radius: 16px;
  background-color: ${(props) =>
    !props.isActive
      ? 'transparent'
      : 'var(--md-sys-color-secondary-container)'};
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface NavBarItemIconProps {
  name: string;
  isActive?: boolean;
}

const NavBarItemIcon = ({ name, isActive = false }: NavBarItemIconProps) => {
  return (
    <StyledNavBarItemIcon
      name={name}
      emotionCss={css`
        color: ${isActive
          ? 'var(--md-sys-color-on-secondary-container)'
          : 'var(--md-sys-color-on-surface-variant)'};
      `}
    />
  );
};

const StyledNavBarItemIcon = styled(Icon)``;
