import { StartScreen } from '@taleemabad/dsm/components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const WelcomeTimetablePage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const time = setTimeout(() => {
      navigate('/timetable/view-timetable', { replace: true });
    }, 2500);
    return () => clearTimeout(time);
  });
  return <StartScreen />;
};
