import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Card, TBProgressBar } from '@taleemabad/dsm/components';
import { IconText } from '../icon-text/icon-text';

export interface TBStackedCardProps {
  title: string;
  progress?: number; // 1 to 100
  trainings: number;
  totalMinutes: number;
  imageSrc: string;
  maxWidth?: string;
  onClick?: () => void;
}

export const TBStackedCard = ({
  title,
  progress,
  trainings,
  totalMinutes,
  imageSrc,
  maxWidth = '357px',
  onClick = undefined,
}: TBStackedCardProps) => {
  return (
    <Card
      variant="outline"
      title={title}
      imageSrc={imageSrc}
      onClick={onClick}
      className="tb-stacked-card"
      width="100%"
      emotionCss={css`
        max-width: ${maxWidth};
      `}
      preTitle={
        progress !== null && progress !== undefined ? (
          <TBProgressBar
            progress={progress}
            emotionCss={css`
              margin-bottom: var(--rk-spacing-lg);
            `}
          />
        ) : (
          <></>
        )
      }
      postTitle={
        <StyledPostTitleContainer>
          <IconText
            icon="play_circle"
            text={`${trainings} Training${trainings > 1 ? 's' : ''}`}
            size="regular"
          />
          <IconText
            icon="schedule"
            text={`${totalMinutes} Min${totalMinutes > 1 ? 's' : ''}`}
            size="regular"
          />
        </StyledPostTitleContainer>
      }
    />
  );
};

const StyledPostTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  .rk-icon-text {
    margin-right: 18px;
  }
`;
