import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  AppBar,
  Button,
  Dialog,
  LoadingScreen,
  Quiz,
  Spacing,
  Typography,
} from '@taleemabad/dsm/components';
import { useLiveQuery } from 'dexie-react-hooks';
import {
  analyticsService,
  EventNames,
  teacherTrainingDB,
} from '@taleemabad/db';
import { useNavigate, useParams } from 'react-router-dom';

interface TrainingQuizPageProps {}

export const TrainingQuizPage: FC<TrainingQuizPageProps> = () => {
  const navigate = useNavigate();
  const { trainingId, courseId } = useParams();
  const selectedTrainingId = parseInt(trainingId || '0');
  const selectedCourseId = parseInt(courseId || '0');
  const training = useLiveQuery(() =>
    teacherTrainingDB.getTraining(selectedTrainingId),
  );
  const questions = useLiveQuery(() =>
    teacherTrainingDB.getQuestions(selectedTrainingId),
  );
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [showExitQuizPopup, setShowExitQuizPopup] = useState(false);
  const [showFinishPopup, setShowFinishPopup] = useState(false);
  const [retakeCount, setRetakeCount] = useState(0);

  useEffect(() => {
    if (!trainingId || !courseId) {
      navigate('/training');
    }
  }, [trainingId, courseId, navigate]);

  useEffect(() => {
    window.history.pushState('/quiz', '', window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [showExitQuizPopup]);

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    if (!showExitQuizPopup) {
      setShowExitQuizPopup(true);
    }
  };
  if (!training || !questions) {
    return <LoadingScreen></LoadingScreen>;
  }

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      analyticsService.trackEvent(EventNames.trainingQuizComplete, {
        epCourseId: training.course,
        epTrainingId: training.id,
      });
      setShowFinishPopup(true);
      teacherTrainingDB
        .setTrainingStatusToComplete(selectedTrainingId, selectedCourseId)
        .then(() => {
          teacherTrainingDB.setTrainingAssessment(
            selectedTrainingId,
            score,
            questions.length * 10,
          );
        });
    }
  };

  const handleRetakeQuiz = () => {
    analyticsService.trackEvent(EventNames.trainingRetakePopup, {
      epCourseId: training.course,
      epTrainingId: training.id,
    });
    setCurrentQuestionIndex(0);
    setScore(0);
    setShowFinishPopup(false);
    setRetakeCount((prevCount) => prevCount + 1);
  };

  const handleAnswerCheck = (isCorrect: boolean, answer: string) => {
    if (isCorrect) {
      setScore((prevScore) => prevScore + 10);
    }
    teacherTrainingDB.setSubmission(
      selectedTrainingId,
      answer,
      questions[currentQuestionIndex].id,
      isCorrect,
      questions[currentQuestionIndex].type,
      questions[currentQuestionIndex],
      isCorrect ? 10 : 0,
    );
  };

  const handleExitQuiz = () => {
    for (let i = window.history.length - 1; i >= 0; i--) {
      const historyState = window.history.state;
      if (historyState === '/quiz') {
        window.history.pushState(null, '', '');
        break;
      }
    }
    setShowExitQuizPopup(false);
    analyticsService.trackEvent(EventNames.trainingQuizIncomplete, {
      epCourseId: training.course,
      epTrainingId: training.id,
      epQuizProgress: currentQuestionIndex,
    });
    navigate(`/training/course/${courseId}`);
  };

  return (
    <>
      <TrainingQuizContainer>
        <AppBar
          pageTitle="Back"
          leftIcon="arrow_back"
          onLeftIconClick={() => setShowExitQuizPopup(true)}
          hasPadding={false}
          titleFullWidth={true}
          titleMedium={true}
          isTitleClickable={true}
        />
        <Spacing padding="lg" marginTop="xl">
          <Quiz
            key={currentQuestionIndex}
            trainingTitle={training?.title}
            question={questions[currentQuestionIndex]}
            totalQuestions={questions.length}
            currentQuestionIndex={currentQuestionIndex}
            scorePercentage={(score / (questions.length * 10)) * 100}
            scoreText={`${score}/${questions.length * 10}`}
            retakeCount={retakeCount}
            onNext={handleNext}
            onAnswerCheck={handleAnswerCheck}
          ></Quiz>
        </Spacing>
      </TrainingQuizContainer>

      {/*dialogs*/}
      <Dialog
        open={showExitQuizPopup}
        onClose={() => {
          setShowExitQuizPopup(false);
        }}
        title="Exit Quiz"
      >
        <Typography variant="textBodyMedium">
          You have not completed the quiz. Do you want to quit anyway?
        </Typography>
        <ActionButtonContainer>
          <Button
            variant="outlined"
            children="Exit Quiz"
            onClick={handleExitQuiz}
          />
          <Button
            children="Resume Quiz"
            onClick={() => {
              setShowExitQuizPopup(false);
            }}
          />
        </ActionButtonContainer>
      </Dialog>
      <Dialog
        open={showFinishPopup}
        onClose={() => {
          setShowFinishPopup(false);
        }}
        hasBackdropClick={false}
        title={`Score: ${score}/${questions.length * 10}`}
      >
        <Typography variant="textBodyMedium">
          {score / (questions.length * 10) >= 0.5
            ? 'Nice Job! You scored good in quiz.'
            : 'Retake quiz to improve your score.'}
        </Typography>{' '}
        <ActionButtonContainer>
          {score / (questions.length * 10) < 0.5 && (
            <Button
              variant="outlined"
              children="Retake Quiz"
              onClick={handleRetakeQuiz}
            />
          )}
          <Button
            width="100%"
            children="Go to Trainings"
            onClick={handleExitQuiz}
          />
        </ActionButtonContainer>
      </Dialog>
    </>
  );
};

const TrainingQuizContainer = styled.div``;

const ActionButtonContainer = styled.div`
  display: flex;
  gap: var(--rk-spacing-md);
  md-filled-button {
    width: 50%;
  }
  md-outlined-button {
    width: 50%;
  }
`;
