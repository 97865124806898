import styled from '@emotion/styled';
import {
  Button,
  Dialog,
  Logo,
  PhoneField,
  Typography,
  PhoneFieldResult,
  Spacing,
} from '@taleemabad/dsm/components';
import { validatePhoneNumber } from 'apps/school-app/src/utils/utils';
import { useContext, useEffect, useState } from 'react';
import { useBreakpoint } from '@taleemabad/shared/hooks';
import { AuthCardContainer } from '../components/auth-card';
import { useNavigate } from 'react-router-dom';
import { ConfigContext } from 'apps/school-app/src/providers/config-provider';
import {
  EventNames,
  analyticsService,
  validateUserEndpoint,
} from '@taleemabad/db';

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;
`;
const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 68px;
`;
const StyledLargeHeading = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const StyledPopupButton = styled.div`
  margin-top: 24px;
  md-filled-button {
    width: 100%;
  }
`;

export const tempLSPhoneKey = 'tempLSPhoneKey';
export const hasVisitedSignIn = 'hasVisitedSignIn';

export const SignInPage = () => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const { appLogoPath } = useContext(ConfigContext);
  const navigate = useNavigate();
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const { tablet, mobileHorizontal, mobile } = useBreakpoint();
  const isLowResDevice = tablet || mobileHorizontal || mobile;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [formData, setFormData] = useState<PhoneFieldResult>({
    hasError: !localStorage.getItem(tempLSPhoneKey),
    value: localStorage.getItem(tempLSPhoneKey) || '',
    name: 'phone',
  });
  const trackLoginErrorEvent = (errorMessage: string) => {
    analyticsService.trackEvent(EventNames.signupLoginError, {
      ep_errorMessage: errorMessage,
    });
  };
  const handleAccountDoesNotExistError = () => {
    setShowPopup(true);
  };
  const handleSubmit = async () => {
    analyticsService.trackEvent(EventNames.signupPhoneNumberEntered, {
      ep_phoneNumberEntered: formData.value,
    });
    if (!formData.hasError) {
      try {
        localStorage.setItem(tempLSPhoneKey, formData.value);
        const data = await validateUserEndpoint(formData.value);
        if (data) {
          navigate(data.route, { replace: true });
        }
      } catch (error: any) {
        if ('detail' in error.response.data && error.response.status === 404) {
          handleAccountDoesNotExistError();
        }
      }
    } else if (!errorMessage) {
      setErrorMessage(validatePhoneNumber(formData.value));
      trackLoginErrorEvent(errorMessage);
    } else {
      trackLoginErrorEvent(errorMessage);
    }
    setHasSubmitted(true);
  };

  useEffect(() => {
    const hasVisited = localStorage.getItem(hasVisitedSignIn);
    if (!hasVisited) {
      analyticsService.trackEvent(EventNames.signupStarted);
      localStorage.setItem(hasVisitedSignIn, 'true');
    }
  }, []);
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSubmit();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [formData]);

  return (
    <AuthCardContainer>
      {isLowResDevice && (
        <StyledImageWrapper>
          <Logo
            altName="outer-layer"
            width={275}
            src={`../../assets/${appLogoPath}`}
            data-cy="sign-in-app-logo"
          />
        </StyledImageWrapper>
      )}
      <StyledCardContent>
        {!isLowResDevice ? (
          <Spacing marginBottom="md">
            <Typography
              variant="textHeadlineLargeSurface"
              data-cy="sign-in-get-started"
            >
              Get Started
            </Typography>
          </Spacing>
        ) : (
          <Spacing marginBottom="md">
            <Typography
              variant="textHeadlineMediumOnSurface"
              data-cy="sign-in-get-started"
            >
              Get Started
            </Typography>
          </Spacing>
        )}
        <StyledLargeHeading>
          <Typography
            variant="textBodyLargeSurface"
            data-cy="sign-in-phone-number-text"
          >
            Please enter your phone number.
          </Typography>
        </StyledLargeHeading>
        <PhoneField
          name="phone"
          type="tel"
          variant="outlined"
          label="Phone Number"
          placeholder="Enter here"
          value={formData.value}
          id="phoneNumber"
          data-cy="phone-number-field"
          errorText={hasSubmitted ? errorMessage : ''}
          onChange={(data: PhoneFieldResult): void => {
            data.hasError = !!validatePhoneNumber(data.value);
            setErrorMessage(validatePhoneNumber(data.value));
            setFormData(data);
          }}
        />
      </StyledCardContent>
      <Dialog
        open={showPopup}
        title="Account Doesn't Exist"
        onClose={() => setShowPopup(false)}
        data-cy="account-does-not-exist-dialog"
      >
        <Typography variant="textBodyMedium" data-cy="sign-in-dialog-text">
          Your account does not exist. Please contact your NIETE Agent
        </Typography>

        <StyledPopupButton>
          <Button
            data-cy="dialog-got-it-button"
            children="Got It"
            onClick={() => {
              setShowPopup(false);
            }}
          />
        </StyledPopupButton>
      </Dialog>
      <Button
        children="Begin"
        type="submit"
        onClick={handleSubmit}
        data-cy="begin-button"
      />
    </AuthCardContainer>
  );
};
