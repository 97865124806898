// @ts-nocheck
import { PropsWithChildren, ReactNode } from 'react';
import '@material/web/tabs/primary-tab.js';
import '@material/web/tabs/secondary-tab.js';
import '@material/web/tabs/tabs.js';
import { Typography } from '../../atoms/typography/typography';
import { Spacing } from '../../atoms/spacing/spacing';
import { Icon } from '../../atoms/icon/icon';
import styled from '@emotion/styled';

export interface Tab {
  key: number;
  label: string;
  content?: string | ReactNode;
  icon?: string;
}
interface TabContentProps {
  tabId: number;
  content: string | ReactNode;
}

const TabContent = ({ tabId, content }: TabContentProps) => {
  return (
    <div id={tabId} aria-controls={tabId}>
      {content}
    </div>
  );
};

export interface TabsProps extends PropsWithChildren {
  variant?: 'primary' | 'secondary';
  activeTabKey: number;
  tabs: Tab[];
  onChange: (tabKey: number) => void;
}

export const Tabs = ({
  activeTabKey,
  tabs,
  variant = 'primary',
  onChange,
}: TabsProps) => {
  const tabChangeHandler = (e: MouseEvent<HTMLElement>) => {
    const { target } = e;
    const tabKey = parseInt(target.id, 10);
    onChange(tabKey);
  };

  const TabComponent =
    variant === 'primary' ? 'md-primary-tab' : 'md-secondary-tab';

  return (
    <>
      <md-tabs activeTabIndex={activeTabKey}>
        {tabs.map((tab, idx) => (
          <TabComponent
            inlineIcon={true}
            key={idx}
            id={tab.key}
            aria-controls={tab.key}
            onClick={tabChangeHandler}
          >
            {tab?.icon && (
              <Icon
                color={
                  activeTabKey.toString() === tab.key.toString()
                    ? '--md-sys-color-primary'
                    : '--md-sys-color-on-surface-variant'
                }
                name={tab.icon}
              />
            )}
            <Typography
              variant={
                activeTabKey.toString() === tab.key.toString()
                  ? 'textTitleMediumPrimary'
                  : 'textTitleMediumSurfaceVariant'
              }
            >
              {tab.label}
            </Typography>
          </TabComponent>
        ))}
      </md-tabs>
      <Spacing marginTop="xxl">
        {tabs.map(
          (tab, idx) =>
            +tab.key === +activeTabKey && (
              <TabContent key={idx} tabId={tab.key} content={tab.content} />
            ),
        )}
      </Spacing>
    </>
  );
};
