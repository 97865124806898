// @ts-nocheck
import React, { PropsWithChildren } from 'react';
import '@material/web/textfield/filled-text-field.js';
import '@material/web/textfield/outlined-text-field.js';

export interface PhoneFieldResult {
  value: string;
  hasError: boolean;
  name: string;
}
export interface TextFieldProps extends PropsWithChildren {
  variant?: 'filled' | 'outlined';
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  label?: string;
  required?: boolean;
  value?: string | null;
  prefixText?: string;
  suffixText?: string;
  supportingText?: string;
  textDirection?: string;
  rows?: number;
  cols?: number;
  max?: string;
  maxLength?: number;
  min?: string;
  minLength?: number;
  placeholder?: string;
  readOnly?: boolean;
  step?: number;
  type?:
    | 'text'
    | 'textarea'
    | 'email'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'url';
  name?: string;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  width?: string;
  id?: string;
}
export const TextField = ({
  variant = 'filled',
  disabled = false,
  errorText = '',
  error = false,
  label = '',
  required = false,
  value = null,
  prefixText,
  suffixText,
  supportingText,
  textDirection,
  rows,
  cols,
  min = null,
  max = null,
  minLength,
  maxLength,
  placeholder,
  readOnly = false,
  step = null,
  type = 'text',
  name,
  onInput,
  onKeyPress,
  children,
  width,
  id = '',
  ...otherProps
}: TextFieldProps) => {
  const props = {
    value,
    type,
  };

  if (label) {
    props.label = label;
  }
  if (disabled) {
    props.disabled = disabled;
  }
  if (placeholder) {
    props.placeholder = placeholder;
  }
  if (minLength) {
    props.minLength = minLength;
  }
  if (maxLength) {
    props.maxLength = maxLength;
  }
  if (min !== null) {
    props.min = min;
  }
  if (max !== null) {
    props.max = max;
  }
  if (step !== null) {
    props.max = max;
  }
  if (rows) {
    props.rows = rows;
  }
  if (cols) {
    props.cols = cols;
  }
  if (name) {
    props.name = name;
  }
  if (prefixText) {
    props['prefix-text'] = prefixText;
  }
  if (suffixText) {
    props['suffix-text'] = suffixText;
  }
  if (supportingText) {
    props['supporting-text'] = supportingText;
  }
  if (textDirection) {
    props['text-direction'] = textDirection;
  }
  if (errorText) {
    props['error-text'] = errorText;
  }
  if (readOnly) {
    props.readOnly = readOnly;
  }
  if (required) {
    props.required = required;
  }
  if (error) {
    props.error = error;
  }
  if (onInput) {
    props.onInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      onInput(event);
    };
  }
  if (onKeyPress) {
    props.onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyPress(event);
    };
  }

  if (id) {
    props.id = id;
  }

  if (type) {
    props.inputmethod = ['number', 'tel'].includes(type)
      ? 'numeric'
      : type === 'email'
        ? 'email'
        : 'text';
  }

  if (width) {
    props.style = { width };
  }

  if (variant === 'filled') {
    return (
      <md-filled-text-field {...otherProps} {...props}>
        {children}
      </md-filled-text-field>
    );
  } else {
    return (
      <md-outlined-text-field {...otherProps} {...props}>
        {children}
      </md-outlined-text-field>
    );
  }
};
