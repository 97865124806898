import {
  EventNames,
  analyticsService,
  sendOtp,
  syncStateManager,
  verifyUser,
} from '@taleemabad/db';
import {
  ForgotPasswordScreen,
  PhoneFieldResult,
} from '@taleemabad/dsm/components';
import { ConfigContext } from 'apps/school-app/src/providers/config-provider';
import { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { tempLSPhoneKey } from './sign-in-page';

export const UserVerificationPage = () => {
  const { appLogoPath } = useContext(ConfigContext);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();

  const checkUserState = async (data: PhoneFieldResult) => {
    try {
      const userData = await verifyUser(data.value);
      if (!userData.isPasswordSet) {
        navigate('/create-password');
      } else if (userData.isPasswordSet) {
        localStorage.setItem(tempLSPhoneKey, data.value);
        await sendOtptoUser(data.value);
      }
    } catch (error: any) {
      if (
        syncStateManager.isConnected &&
        'detail' in error.response.data &&
        error?.response?.status === 404
      ) {
        setShowPopup(true);
      }
    }
  };

  const sendOtptoUser = async (phone: string) => {
    try {
      setErrorMessage('');
      await sendOtp(phone);
      analyticsService.trackEvent(
        EventNames.resetPasswordAccountVerificationNextClicked,
        { epOTPService: 'SMS' },
      );
      navigate('/send-otp', {
        state: { from: location.pathname },
        replace: true,
      });
    } catch (error: any) {
      if (
        syncStateManager.isConnected &&
        'nonFieldErrors' in error.response.data &&
        error.response.status === 400
      ) {
        setErrorMessage(error.response.data.nonFieldErrors[0]);
      }
      if (
        syncStateManager.isConnected &&
        'detail' in error.response.data &&
        error.response.status === 429
      ) {
        setErrorMessage('OTP Limit Reached, Try again after 1 hour');
      }
    }
  };
  return (
    <ForgotPasswordScreen
      error={errorMessage}
      logo={appLogoPath}
      title="Verify Account"
      description="Confirm phone number to receive the OTP."
      onPhoneChange={(data: PhoneFieldResult) => {
        checkUserState(data);
      }}
      showPopup={showPopup}
      closePopup={() => {
        setShowPopup(false);
      }}
      backToLogin={() => {
        navigate('/login', { replace: true });
      }}
    />
  );
};
