import React from 'react';
import styled from '@emotion/styled';
import {
  Button,
  Typography,
  ContentCard,
  TitleText,
  LpStatementList,
} from '@taleemabad/dsm/components';
import { ILessonPlanDetail } from '@taleemabad/db';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';

interface LessonPlanPreparationProps {
  lpDetail: ILessonPlanDetail;
  chapterIndex: number;
  startLessonPlan: () => void;
}

const LessonPlanPreparationContainer = styled.div`
  overflow-y: overlay;
  height: calc(100vh - 100px);
  ${mq(BreakpointSizes.xs)} {
    height: calc(100vh - 80px);
  }
`;

const TitleTextContainer = styled.div`
  margin: var(--rk-spacing-md) 0 var(--rk-spacing-xl) 0;
`;

const ContentCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--rk-spacing-xl);
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: var(--rk-spacing-xxxl);
`;

export const LessonPlanPreparation: React.FC<LessonPlanPreparationProps> = ({
  lpDetail,
  chapterIndex,
  startLessonPlan,
}) => {
  const renderSlo = () =>
    lpDetail.lpSlo?.length > 0 && (
      <ContentCard
        iconName="import_contacts"
        title="Student Learning Objective"
      >
        {lpDetail.lpSlo.map((sloItem) => (
          <Typography variant="textBodyLargeNeutral40" key={sloItem}>
            {sloItem}
          </Typography>
        ))}
      </ContentCard>
    );

  const renderSummary = () =>
    lpDetail.summary?.length > 0 && (
      <ContentCard iconName="list_alt" title="Lesson Summary">
        <LpStatementList statements={lpDetail.summary} />
      </ContentCard>
    );

  const renderResourcesNeeded = () =>
    lpDetail.resourcesNeeded?.length > 0 && (
      <ContentCard iconName="file_present" title="Resources">
        {lpDetail.resourcesNeeded.map((resource, index) => (
          <Typography variant="textBodyLargeNeutral40" key={resource}>
            {resource}
          </Typography>
        ))}
      </ContentCard>
    );

  const renderClassroomSetupInstructions = () =>
    lpDetail.classroomSetupInstructions?.length > 0 && (
      <ContentCard iconName="list_alt" title="Classroom Setup">
        <LpStatementList statements={lpDetail.classroomSetupInstructions} />
      </ContentCard>
    );

  return (
    <LessonPlanPreparationContainer>
      <Typography variant="textHeadlineMediumNeutral40">
        {lpDetail.topic}
      </Typography>

      <TitleTextContainer>
        <TitleText
          leftText={`Topic ${chapterIndex}`}
          seperatorIconName="circle"
          rightText={`Lesson ${lpDetail.index}`}
        />
      </TitleTextContainer>
      <ContentCardContainer>
        {renderSlo()}
        {renderSummary()}
        {renderResourcesNeeded()}
        {renderClassroomSetupInstructions()}
      </ContentCardContainer>
      <StyledButtonContainer>
        <Button variant="filled" onClick={startLessonPlan}>
          Go To Lesson
        </Button>
      </StyledButtonContainer>
    </LessonPlanPreparationContainer>
  );
};
