import {
  DownloadCardList,
  Typography,
  AppBar,
  LoadingScreen,
  Button,
  Dialog,
} from '@taleemabad/dsm/components';
import styled from '@emotion/styled';
import { FC, useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { useNavigate } from 'react-router-dom';
import {
  analyticsService,
  downloadManagerService,
  DownloadRequest,
  EventNames,
  teacherTrainingDB,
  TrainingRecord,
} from '@taleemabad/db';

interface DownloadsProps {}

enum DownloadStateTitle {
  Done = 'Done',
  Edit = 'Edit',
}
const StyledContainer = styled.div`
  padding: var(--rk-spacing-xl);
  margin-bottom: var(--rk-spacing-xxxxxxl);
`;

const StyledContainerRight = styled.div`
  padding: var(--rk-spacing-sm);
  display: flex;
  justify-content: end;
`;
const ActionButtonContainer = styled.div`
  display: flex;
  gap: var(--rk-spacing-md);
  md-filled-button {
    width: 40%;
  }
  md-outlined-button {
    width: 60%;
  }
`;
const AppBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--md-sys-color-surface);
  padding-bottom: var(--rk-spacing-xl);
`;
const NoDownloadsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;

export const DownloadsPage: FC<DownloadsProps> = () => {
  const navigate = useNavigate();
  const trainings = useLiveQuery(() =>
    teacherTrainingDB.getDownloadedTrainings(),
  );
  const [selectedTraining, setSelectedTraining] =
    useState<TrainingRecord | null>(null);
  const [editState, setEditState] = useState(false);
  const editStateTitle = editState
    ? DownloadStateTitle.Done
    : DownloadStateTitle.Edit;
  const [deleteDownloadDialog, setDeleteDownloadDialog] =
    useState<boolean>(false);
  const [deleteAllDialog, setDeleteAllDialog] = useState<boolean>(false);

  if (!trainings) {
    return <LoadingScreen />;
  }

  const showDeleteDownloadDialog = (training: TrainingRecord) => {
    if (editState) {
      setSelectedTraining(training);
      setDeleteDownloadDialog(true);
    } else {
      handleTrainingClick(training);
    }
  };
  const handleDeleteIconClick = () => {
    setDeleteDownloadDialog(false);
    if (selectedTraining?.mediaAsset.download) {
      downloadManagerService.deleteDownload([
        {
          trainingId: selectedTraining.id,
          trainingURL: selectedTraining.mediaAsset.url,
          downloadId: selectedTraining.mediaAsset.download.id,
        },
      ]);
      setSelectedTraining(null);
      analyticsService.trackEvent(EventNames.trainingDownloadDelete, {
        ep_training_id: selectedTraining.id,
      });
    }
  };

  const handleDeleteAllClick = () => {
    setDeleteAllDialog(false);
    const deletedTrainings: DownloadRequest[] = trainings.reduce((acc, t) => {
      if (t.mediaAsset.download) {
        acc.push({
          trainingId: t.id,
          trainingURL: t.mediaAsset.url,
          downloadId: t.mediaAsset.download.id,
        });
      }
      return acc;
    }, [] as DownloadRequest[]);
    downloadManagerService.deleteDownload(deletedTrainings);
    analyticsService.trackEvent(EventNames.trainingDownloadDeleteAll, {});
  };

  const handleTrainingClick = (training: TrainingRecord) => {
    if (!editState) {
      navigate(`/training/course/${training.course}/training/${training?.id}`);
    }
  };

  return (
    <StyledContainer>
      <AppBarContainer>
        <AppBar
          pageTitle="Downloads"
          leftIcon="download"
          hasPadding={false}
          titleFullWidth={true}
          isLeftIconButton={false}
        />
        {trainings.length > 0 && (
          <Typography
            variant="textTitleMedium"
            color="primary"
            onClick={() => setEditState(!editState)}
          >
            {editStateTitle}
          </Typography>
        )}
      </AppBarContainer>
      {editState && trainings.length > 0 && (
        <StyledContainerRight>
          <Typography
            variant="textTitleMedium"
            onClick={() => setDeleteAllDialog(true)}
          >
            Delete All
          </Typography>
        </StyledContainerRight>
      )}
      {trainings.length === 0 && (
        <NoDownloadsWrapper>
          <Typography variant="textTitleMedium">
            You have no downloads.
          </Typography>
        </NoDownloadsWrapper>
      )}
      <DownloadCardList
        trainings={trainings}
        editUIState={editState}
        onCardClicked={handleTrainingClick}
        onIconClicked={showDeleteDownloadDialog}
      />
      <DeleteDialog
        open={deleteDownloadDialog}
        onClose={() => setDeleteDownloadDialog(false)}
        title="Delete Downloads?"
        message="Are you sure you want to delete selected downloads?"
        onConfirm={handleDeleteIconClick}
      />
      <DeleteDialog
        open={deleteAllDialog}
        onClose={() => setDeleteAllDialog(false)}
        title="Delete All?"
        message="Are you sure you want to delete all downloads?"
        onConfirm={handleDeleteAllClick}
      />
    </StyledContainer>
  );
};

interface DeleteDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  message: string;
  onConfirm: () => void;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  open,
  onClose,
  title,
  message,
  onConfirm,
}) => {
  return (
    <Dialog open={open} onClose={onClose} title={title}>
      <Typography variant="textBodyMedium">{message}</Typography>
      <ActionButtonContainer>
        <Button variant="outlined" onClick={onClose}>
          Go Back
        </Button>
        <Button variant="filled" onClick={onConfirm}>
          Delete
        </Button>
      </ActionButtonContainer>
    </Dialog>
  );
};
