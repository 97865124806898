import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Typography } from '../../atoms/typography/typography';
import { IconButton } from '@taleemabad/dsm/components';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { Icon } from '../../atoms/icon/icon';
import { PropsWithChildren } from 'react';

export interface AppBarProps extends PropsWithChildren {
  pageTitle: string;
  leftIcon?: string;
  onLeftIconClick?: () => void;
  rightIcon?: string;
  onRightIconClick?: () => void;
  elevated?: boolean;
  fixed?: boolean;
  hasPadding?: boolean;
  titleFullWidth?: boolean;
  isLeftIconButton?: boolean;
  titleMedium?: boolean;
  isTitleClickable?: boolean;
}

interface StyledDivProps {
  gap?: number;
  marginTop?: number;
  alignItem?: string;
  flexDirection?: string;
}

const StyledDiv = styled.div<StyledDivProps>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.alignItem
      ? css`
          align-items: ${props.alignItem};
        `
      : ''}
  ${(props) =>
    props.flexDirection
      ? css`
          flex-direction: ${props.flexDirection};
        `
      : ''}
  ${(props) =>
    props.gap
      ? css`
          gap: ${props.gap}px;
        `
      : ''}
`;
interface CursorWrapperProp {
  cursorType?: string;
}

const CursorWrapper = styled.div<CursorWrapperProp>`
  ${(props) =>
    props.cursorType &&
    css`
      cursor: ${props.cursorType};
    `}
`;

export const AppBar = ({
  pageTitle,
  leftIcon,
  isLeftIconButton = true,
  onLeftIconClick,
  rightIcon,
  onRightIconClick,
  elevated = false,
  fixed = false,
  hasPadding = true,
  titleFullWidth = false,
  isTitleClickable = false,
  children,
}: AppBarProps) => {
  return (
    <StyledAppBarContainer
      className="rk-app-bar"
      elevated={elevated}
      fixed={fixed}
      css={fixed && fixedStyled}
      hasPadding={hasPadding}
    >
      {titleFullWidth ? (
        <StyledDiv gap={8}>
          {leftIcon &&
            (isLeftIconButton ? (
              <IconButton
                icon={leftIcon}
                iconEmotionCss={css`
                  color: var(--md-sys-color-on-surface);
                `}
                onClick={onLeftIconClick}
              />
            ) : (
              <Icon
                name={leftIcon}
                slot="selected"
                size={window.screen.width > 575 ? 'large' : 'normal'}
                emotionCss={css`
                  color: var(--md-sys-color-on-surface);
                  cursor: default;
                `}
              />
            ))}
          {!leftIcon && <StyledEmptyBox />}
          <CursorWrapper cursorType={isTitleClickable ? 'pointer' : 'default'}>
            <Typography
              variant="textHeadlineMediumOnSurface"
              onClick={() =>
                isTitleClickable
                  ? onLeftIconClick && onLeftIconClick()
                  : () => {}
              }
            >
              {pageTitle}
            </Typography>
          </CursorWrapper>
        </StyledDiv>
      ) : (
        <StyledDiv flexDirection="column">
          {leftIcon &&
            (isLeftIconButton ? (
              <IconButton
                icon={leftIcon}
                iconEmotionCss={css`
                  color: var(--md-sys-color-on-surface);
                `}
                onClick={onLeftIconClick}
              />
            ) : (
              <Icon
                name={leftIcon}
                slot="selected"
                size={window.screen.width > 575 ? 'large' : 'normal'}
                emotionCss={css`
                  color: var(--md-sys-color-on-surface);
                  cursor: default;
                `}
              />
            ))}
          {!leftIcon && <StyledEmptyBox />}

          <Typography
            variant="textHeadlineMediumOnSurface"
            fullWidth={titleFullWidth}
            onClick={() =>
              isTitleClickable ? onLeftIconClick && onLeftIconClick() : () => {}
            }
          >
            {pageTitle}
          </Typography>
        </StyledDiv>
      )}
      {rightIcon && (
        <div id="menu-anchor">
          <IconButton
            icon={rightIcon}
            iconEmotionCss={css`
              color: var(--md-sys-color-on-surface-variant);
            `}
            onClick={onRightIconClick}
          />
        </div>
      )}
      {!rightIcon && <StyledEmptyBox />}
      {children}
    </StyledAppBarContainer>
  );
};

const fixedStyled = css`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
`;

interface StyledAppBarContainerProps {
  elevated: boolean;
  fixed: boolean;
  hasPadding?: boolean;
}

const StyledAppBarContainer = styled.div<StyledAppBarContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: ${(props) => (props.fixed ? 'fixed' : 'relative')};
  align-items: center;
  min-height: 48px;
  background-color: var(--md-sys-color-surface);
  ${(props) => (props.hasPadding ? 'padding: 8px 0px' : '')};
  box-shadow: ${(props) => (props.elevated ? 'var(--rk-shadow-xs)' : 'none')};
  gap: 8px;
  ${mq(BreakpointSizes.xs)} {
    padding: ${(props) => (props.hasPadding ? '8px 0px' : '')};
  }
`;

const StyledEmptyBox = styled.div`
  width: 48px;
  height: 100%;
`;
