import { css } from '@emotion/react';
import { CardContainer } from 'libs/dsm/components/src/lib/molecules/card/card-container';
import { useBreakpoint } from '@taleemabad/shared/hooks';
import { ReactNode } from 'react';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';

interface AuthCardInterface {
  children: ReactNode;
}
export const AuthCardContainer = ({ children }: AuthCardInterface) => {
  const { tablet, mobileHorizontal, mobile } = useBreakpoint();
  const isLowResDevice = tablet || mobileHorizontal || mobile;
  return (
    <CardContainer
      role="form"
      variant="outline"
      emotionCss={css`
        display: flex;
        flex-direction: column;
        overflow-y: overlay;
        padding: 42px 32px 42px 32px;
        width: ${isLowResDevice ? 'inherit' : '497px'};
        border: ${!isLowResDevice ? '--md-sys-color-outline' : 'none'};
        ${mq(BreakpointSizes.xs)} {
          width: 100%;
          max-width: 80%;
        }
      `}
    >
      {children}
    </CardContainer>
  );
};
