import {
  EventNames,
  SyncStateManager,
  UserProfile,
  analyticsService,
  syncStateManager,
  userCache,
  userDB,
} from '@taleemabad/db';

export const loadIntercom = async (user: UserProfile) => {
  const data = await userDB.getUserProfileFromDB();
  userCache.getActiveProfile();
  // @ts-ignore
  window.intercomSettings = {
    app_id: `${process.env.NX_INTERCOM_APP_ID}`,
    horizontal_padding: 20,
    vertical_padding: 90,
  };
  if (!syncStateManager.isConnected) {
    // @ts-ignore
    window.Intercom('hide');
  } else {
    // @ts-ignore
    window.Intercom('boot', {
      app_id: `${process.env.NX_INTERCOM_APP_ID}`,
      name: user?.name,
      email: `${user?.username}@niete.com`,
      user_id: userCache.getActiveProfile().id, // active profile id.
      role: userCache.getActiveProfile().role, // active profile role.
    });
  }
  // @ts-ignore
  window.Intercom('onShow', () => {
    const url = location.href;
    analyticsService.trackEvent(EventNames.chatWidgetClicked, {
      epUrl: url,
    });
  });
  // @ts-ignore
  Intercom('onHide', () => {});
};

export const shutdownIntercom = () => {
  // @ts-ignore
  window.Intercom('shutdown');
};
export const hideIntercom = () => {
  // @ts-ignore
  window.Intercom('hide');
};
