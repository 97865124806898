import { useEffect, useState } from 'react';
import {
  ParentProfileScreen,
  Profile,
  Spacing,
} from '@taleemabad/dsm/components';
import {
  IStudentProfile,
  IUserProfile,
  SchoolClass,
  userDB,
} from '@taleemabad/db';
import styled from '@emotion/styled';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useLiveQuery } from 'dexie-react-hooks';

interface StyledDiv {
  isTitle?: boolean;
}

interface StudentProfile {
  id: number;
  grade: string;
  name: string;
  role: string;
  schoolClass: SchoolClass;
}

export const ParentProfile = () => {
  const [filteredProfiles, setFilteredProfiles] = useState<StudentProfile[]>(
    [],
  );
  const [parentProfile, setParentProfile] = useState<IUserProfile>({
    id: 0,
    name: '',
    username: '',
    profiles: [],
  });
  const onProfileSelected = (user: StudentProfile) => {
    console.log('selected user', user);
  };

  const userProfiles: IUserProfile | undefined = useLiveQuery(() =>
    userDB.getUserProfileFromDB(),
  );

  useEffect(() => {
    if (userProfiles) {
      setParentProfile(userProfiles);
      const studentProfiles = userProfiles.profiles.filter(
        (item: IStudentProfile) => item.role === 'Student',
      );
      const convertedProfiles = studentProfiles
        .map((profile: IStudentProfile) => {
          if (profile.role === 'Student' && profile.schoolClass) {
            return {
              id: profile.id,
              name: profile.name ? profile.name : '',
              role: profile.role ? profile.role : '',
              grade: profile.schoolClass.grade.label || 'Unknown Grade',
              schoolClass: profile.schoolClass,
            };
          }
          return null;
        })
        .filter(Boolean) as StudentProfile[];
      setFilteredProfiles(convertedProfiles);
    }
  }, [userProfiles]);

  return (
    <>
      <StyledContent>
        <Spacing margin="xl">
          <Profile
            name={parentProfile.name}
            subhead={parentProfile.username}
            variant="outline"
            noContainer={true}
          />
        </Spacing>
        <ParentProfileScreen
          list={filteredProfiles}
          onProfileSelected={onProfileSelected}
        />{' '}
      </StyledContent>
      {/* TODO : create a logout component which we can reuse */}
    </>
  );
};

const StyledContent = styled.div<StyledDiv>`
  padding: ${(props) =>
    props.isTitle
      ? 'var(--rk-spacing-xl) var(--rk-spacing-xxl) 0 var(--rk-spacing-xxl)'
      : '0px'};
  display: flex;
  flex-direction: column;
  ${mq(BreakpointSizes.xs)} {
    padding: ${(props) => (props.isTitle ? '16px' : '')};
    gap: 6px;
  }
`;
