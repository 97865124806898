import { FC } from 'react';
import { TrainingRecord, ProgressStatus, DownloadStatus } from '@taleemabad/db';
import {
  HorizontalCard,
  Typography,
  TypographyVariant,
} from '@taleemabad/dsm/components';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useWindowSize } from '@taleemabad/shared';

const TrainingCardListWrapper = styled.div`
  display: grid;
  gap: var(--rk-spacing-xl);
  grid-template-columns: repeat(3, 1fr);
  ${mq(BreakpointSizes.xs)} {
    grid-template-columns: repeat(1, 1fr);
  }
  ${mq(BreakpointSizes.sm)} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

interface TrainingCardListProps {
  trainings: TrainingRecord[];
  downloadUIState: boolean;
  onTrainingCardClick: (item: TrainingRecord) => void;
  onTrainingCardIconClick: (item: TrainingRecord) => void;
}

enum Icon {
  DONE = 'download_done',
  DOWNLOAD = 'download',
  PLAY = 'play_circle',
}

export const TrainingCardList: FC<TrainingCardListProps> = ({
  trainings,
  downloadUIState,
  onTrainingCardClick,
  onTrainingCardIconClick,
}) => {
  const [width] = useWindowSize();

  const getTrainingStatusTypography = (trainingStatus: ProgressStatus) => {
    let variant;
    let text;

    switch (trainingStatus) {
      case ProgressStatus.IN_PROGRESS:
        variant = 'textLabelLargePrimary30';
        text = 'In Progress';
        break;
      case ProgressStatus.COMPLETED:
        variant = 'textLabelLargeSuccess';
        text = 'Completed';
        break;
      default:
        variant = 'textLabelLargeNeutral70';
        text = 'Pending';
        break;
    }
    return (
      <Typography variant={variant as TypographyVariant}>{text}</Typography>
    );
  };

  const getCurrentProgress = (training: TrainingRecord): number | undefined => {
    if (training.mediaAsset.download?.status === DownloadStatus.FAILED) {
      return undefined;
    } else if (training.mediaAsset.download) {
      return Math.floor(
        (training.mediaAsset.download.downloaded /
          training.mediaAsset.download.total) *
          100,
      );
    } else return undefined;
  };

  const setCardIcon = (training: TrainingRecord): string | undefined => {
    if (training.mediaAsset.download?.status === DownloadStatus.COMPLETED) {
      return Icon.DONE;
    } else if (downloadUIState) {
      return Icon.DOWNLOAD;
    } else if (!downloadUIState) {
      return Icon.PLAY;
    }
  };

  return (
    <TrainingCardListWrapper>
      {trainings?.map((training: TrainingRecord, index) => (
        <HorizontalCard
          key={training.id}
          title={training.title}
          postTitle={getTrainingStatusTypography(
            training.trainingStatus as ProgressStatus,
          )}
          variant="outline"
          icon={setCardIcon(training)}
          iconSize={width > 575 ? 'largest' : 'large'}
          titleContentCss={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
          `}
          onCardClick={() => onTrainingCardClick(training)}
          onIconClick={() => onTrainingCardIconClick(training)}
          downloadProgress={getCurrentProgress(training)}
          downloadUIState={downloadUIState}
        />
      ))}
    </TrainingCardListWrapper>
  );
};
