import { PromiseExtended, Table } from 'dexie';
import { SyncChanges, SyncRecord, SyncRecordStatus } from '../types/sync-state';
import { BaseModelForPush } from './base-model';
import {
  IAssessment,
  ICourse,
  ISubmission,
  ITraining,
  ITrainingQuestion,
  ITrainingStatus,
  ProgressStatus,
  QuestionType,
  TrainingCourseProgressInfo,
} from '../types/teachertraining';
import { userDB } from './user';
import {
  DownloadInfo,
  DownloadRequest,
  DownloadStatus,
} from '../types/downloads';
import { teacherTrainingService } from '../services/teachertraining';
import { downloadRequestModel } from './downloads';

// Increment SchemaVersion whenever schema is changed
const SCHEMA = {
  courses: 'id, index',
  trainings: 'id, index, course',
  questions: 'id, index, training',
  trainingStatusV2: '++id, [training+courseId+profile], status, syncStatus',
  submissionsV2: '++id, training, syncStatus, score',
  assessmentsV2: '++id, training, syncStatus, score, totalScore',
};

const SCHEMA_VERSION = 2;

export interface CourseRecord extends ICourse, SyncRecord {}
export interface TrainingRecord extends ITraining, SyncRecord {}
export interface TrainingQuestionRecord extends ITrainingQuestion, SyncRecord {}
export interface TrainingStatusRecord extends ITrainingStatus, SyncRecord {}
export interface SubmissionRecord extends ISubmission, SyncRecord {}
export interface AssessmentRecord extends IAssessment, SyncRecord {}

class TeacherTrainingDB extends BaseModelForPush {
  courses!: Table<CourseRecord>;
  trainings!: Table<TrainingRecord>;
  questions!: Table<TrainingQuestionRecord>;
  trainingStatusV2!: Table<TrainingStatusRecord>;
  submissionsV2!: Table<SubmissionRecord>;
  assessmentsV2!: Table<AssessmentRecord>;

  constructor() {
    super('teachertrainingdb');
    this.version(SCHEMA_VERSION).stores(SCHEMA);

    // connect hooks
    this.courses.hook('updating', this.updateHook.bind(this));
    this.courses.hook('deleting', this.deleteHook.bind(this));
    this.courses.hook('creating', this.createHook.bind(this));

    this.trainings.hook('updating', this.updateHook.bind(this));
    this.trainings.hook('deleting', this.deleteHook.bind(this));
    this.trainings.hook('creating', this.createHook.bind(this));

    this.questions.hook('updating', this.updateHook.bind(this));
    this.questions.hook('deleting', this.deleteHook.bind(this));
    this.questions.hook('creating', this.createHook.bind(this));

    this.trainingStatusV2.hook('updating', this.updateHook.bind(this));
    this.trainingStatusV2.hook('deleting', this.deleteHook.bind(this));
    this.trainingStatusV2.hook('creating', this.createHook.bind(this));

    this.submissionsV2.hook('updating', this.updateHook.bind(this));
    this.submissionsV2.hook('deleting', this.deleteHook.bind(this));
    this.submissionsV2.hook('creating', this.createHook.bind(this));

    this.assessmentsV2.hook('updating', this.updateHook.bind(this));
    this.assessmentsV2.hook('deleting', this.deleteHook.bind(this));
    this.assessmentsV2.hook('creating', this.createHook.bind(this));
  }

  clearData() {
    this.courses.clear();
    this.trainings.clear();
    this.questions.clear();
    this.trainingStatusV2.clear();
    this.assessmentsV2.clear();
    this.submissionsV2.clear();
  }

  getCourses(): PromiseExtended<CourseRecord[]> {
    return this.courses
      .filter((record) => record.syncStatus !== SyncRecordStatus.deleted)
      .sortBy('index');
  }

  getCourse(id: number): PromiseExtended<CourseRecord | undefined> {
    return this.courses.get(id);
  }

  getTraining(id: number): PromiseExtended<TrainingRecord | undefined> {
    return this.trainings.get(id);
  }

  getTrainings(courseId: number): PromiseExtended<TrainingRecord[]> {
    return this.trainings
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.course === courseId,
      )
      .sortBy('index');
  }

  getDownloadedTrainings(): PromiseExtended<TrainingRecord[]> {
    return this.trainings
      .filter(
        (record) =>
          record.mediaAsset?.download?.status === DownloadStatus.COMPLETED,
      )
      .sortBy('index');
  }

  async updateTrainingMediaAssets(downloadInfo: DownloadInfo) {
    const trainings = await this.trainings.toArray();
    trainings.forEach(async (training) => {
      if (training.mediaAsset && training.mediaAsset.url === downloadInfo.url) {
        const mediaAsset = training.mediaAsset;
        mediaAsset.download = downloadInfo;
        training.mediaAsset = mediaAsset;
        this.trainings.update(training.id, training);
      }
    });
  }

  async updateIndexTrainingMediaAssets(
    request: DownloadRequest,
    downloadInfo: DownloadInfo,
  ) {
    try {
      const training = await this.trainings.get(request.trainingId);
      if (training) {
        const mediaAsset = training.mediaAsset;
        mediaAsset.download = downloadInfo;
        training.mediaAsset = mediaAsset;
        this.trainings.update(training.id, training);
      } else {
        console.log('Training not found');
      }
    } catch (e) {
      console.log(e);
    }
  }

  async deleteDownloadFromMediaAssets(request: DownloadRequest) {
    try {
      console.log(
        'Deleting download from media assets :: ' + request.toString(),
      );
      const training = await this.trainings.get(request.trainingId);
      if (training) {
        delete training.mediaAsset.download;
        this.trainings.update(request.trainingId, training);
      } else {
        console.log('Training not found');
      }
    } catch (e) {
      console.log(e);
    }
  }

  async updateTrainingsWithDownloads(downloads: DownloadInfo[]) {
    // Creating a map of downloads by URL for efficient lookup
    const downloadMap = new Map(
      downloads.map((download) => [download.url, download]),
    );

    // Update each training's mediaAsset.download with the corresponding download
    const trainings = await this.trainings.toArray();
    trainings.forEach(async (training) => {
      const download = downloadMap.get(training.mediaAsset.url);
      if (download) {
        downloadRequestModel.addDownloadRequest({
          trainingId: training.id,
          trainingURL: training.mediaAsset.url,
          downloadId: download.id,
        });
        const mediaAsset = training.mediaAsset;
        mediaAsset.download = download;
        training.mediaAsset = mediaAsset;
        this.trainings.update(training.id, training);
      }
    });
  }

  getQuestionsCount(trainingId: number): PromiseExtended<number> {
    return this.questions
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.training === trainingId,
      )
      .count();
  }

  getQuestions(trainingId: number): PromiseExtended<TrainingQuestionRecord[]> {
    return this.questions
      .filter(
        (record) =>
          record.syncStatus !== SyncRecordStatus.deleted &&
          record.training === trainingId &&
          // we are only catering to MCQ and MSQ questions for now
          (record.type === QuestionType.MCQ ||
            record.type === QuestionType.MSQ),
      )
      .sortBy('index');
  }

  async setSubmission(
    training: number,
    answer: string,
    question: number,
    isCorrect: boolean,
    questionType: string,
    questionCopy: ITrainingQuestion,
    score: number,
  ) {
    const newSubmission: SubmissionRecord = {
      training,
      answer,
      question,
      isCorrect,
      questionType,
      questionCopy,
      score,
    };
    await this.submissionsV2.add(newSubmission);
  }

  async setTrainingAssessment(
    training: number,
    score: number,
    totalScore: number,
  ) {
    const newAssessment: AssessmentRecord = {
      training: training,
      score: score,
      totalScore: totalScore,
    };

    // this id is different from the one generated on backend, on frontend we have auto increment which will clash from backend.
    // this means the id changes when the record is created on the backend
    const assessmentId = await this.assessmentsV2.add(newAssessment);
    const addedAssessment = await this.assessmentsV2.get(assessmentId);
    const currentTraining = await this.trainings.get(training);

    // only update the training record if the new assessment has a higher score
    if (
      !currentTraining?.assessment ||
      score > currentTraining.assessment.score
    ) {
      await this.trainings.update(training, {
        assessment: addedAssessment,
      });
    }
  }

  /**
   * sets the training status for a specific training and course.
   * the function performs the following steps:
   * 1. fetches the TrainingStatusRecord for the given trainingId and courseId.
   * 2. if the TrainingStatusRecord exists and its status is either PENDING or COMPLETED, the function returns without making any changes.
   * 3. if the TrainingStatusRecord does not exist, it creates a new TrainingStatusRecord with status set to IN_PROGRESS and updates the corresponding TrainingRecord's trainingStatus to IN_PROGRESS.
   * does not make any changes if the status is already IN_PROGRESS or COMPLETED.
   */
  async setTrainingStatusToInProgress(trainingId: number, courseId: number) {
    const trainingStatusRecord = await this.getTrainingStatusRecord(
      trainingId,
      courseId,
    );
    if (
      trainingStatusRecord &&
      (trainingStatusRecord.status === ProgressStatus.IN_PROGRESS ||
        trainingStatusRecord.status === ProgressStatus.COMPLETED)
    ) {
      await this.trainingStatusV2.update(trainingStatusRecord, {
        // setting it here so we know what the last opened training was by the user
        lastModifiedAt: Date.now(),
      });
      return;
    }
    if (!trainingStatusRecord) {
      await this.trainingStatusV2.add({
        training: trainingId,
        courseId,
        status: ProgressStatus.IN_PROGRESS,
        lastModifiedAt: Date.now(),
      });
      await this.updateTrainingStatusInTrainingRecord(
        trainingId,
        ProgressStatus.IN_PROGRESS,
      );
    }
  }

  /**
   * sets the training status for a specific training and course to "COMPLETED".
   * the function performs the following steps:
   * 1. fetches the TrainingStatusRecord for the given trainingId and courseId.
   * 2. if the TrainingStatusRecord exists and its status is COMPLETED, the function returns without making any changes.
   * 3. if the TrainingStatusRecord does not exist or its status is not COMPLETED, it creates/updates a TrainingStatusRecord with status set to COMPLETED
   * and updates the corresponding TrainingRecord's trainingStatus to "COMPLETED".
   */
  async setTrainingStatusToComplete(trainingId: number, courseId: number) {
    const trainingStatusRecord = await this.getTrainingStatusRecord(
      trainingId,
      courseId,
    );
    if (
      trainingStatusRecord &&
      trainingStatusRecord.status === ProgressStatus.COMPLETED
    ) {
      return;
    }
    if (!trainingStatusRecord) {
      await this.trainingStatusV2.add({
        training: trainingId,
        courseId,
        status: ProgressStatus.COMPLETED,
      });
    } else {
      await this.trainingStatusV2.update(trainingStatusRecord, {
        status: ProgressStatus.COMPLETED,
        // TODO: we shouldn't be passing syncStatus here, it should be handled by the hook.
        // have to set this manually since the logic in update hook checks if syncStatus is `synced`, and then does not set the syncStatus to `updated` if it is.
        // the object we are updating might be already 'synced' at the time of updating the record.
        syncStatus: SyncRecordStatus.updated,
      });
    }
    await this.updateTrainingStatusInTrainingRecord(
      trainingId,
      ProgressStatus.COMPLETED,
    );
  }

  async getCourseCompletionPercentage(courseId: number): Promise<number> {
    const trainings = await this.trainings
      .where({ course: courseId })
      .toArray();
    const completedTrainings = trainings.filter(
      (t) => t.trainingStatus === ProgressStatus.COMPLETED,
    ).length;
    const percentage =
      trainings.length > 0 ? (completedTrainings / trainings.length) * 100 : 0;
    return Math.round(percentage);
  }

  /**
   * determines the next training and course for a user based on their progress.
   *
   * - First Time User: if no training statuses are present, it indicates a new user.
   *   fetches the first training of the first course. Sets CTA as "Start Training"
   *   and progress text as "Let’s start your first training!". indicates all
   *   trainings are not completed.
   *
   * - Existing User with IN_PROGRESS Training: if there are training statuses,
   *   fetches the latest based on the `lastModifiedAt` field. if the latest training
   *   status is IN_PROGRESS, retrieves the corresponding course and training.
   *   Sets CTA as "Resume Training" and progress text as "Let’s continue where
   *   you left!". Indicates all trainings are not completed.
   *
   * - User Who Completed Latest Training: ff the latest training is completed,
   *   finds the next incomplete training in the current course. Sets CTA as
   *   "Resume Training" and progress text as before.
   *
   * - All Trainings in Current Course Completed: if all trainings in the current
   *   course are done, moves to the next course to find the first incomplete
   *   training. If the current course is a general course, it finds the next general course.
   *   If it's a subject course, it finds the next subject course. If all trainings in the
   *   current group (general or subject) are completed, it moves to the other group.
   *   Sets CTA and progress text as in the previous case.
   *
   * - All Courses and Trainings Completed: if all courses and their trainings are
   *   completed, returns an indication that all trainings are completed.
   *
   */
  async getNextTrainingAndCourse(): Promise<TrainingCourseProgressInfo | null> {
    return teacherTrainingService.getNextTrainingAndCourse();
  }

  private async getTrainingStatusRecord(
    trainingId: number,
    courseId: number,
  ): Promise<TrainingStatusRecord | undefined> {
    // don't get user from `userCache` since it might not be initialized, and index db will throw an error in that case
    const user = await userDB.getUserProfileFromDB();
    const teacherId = user?.profiles.filter((user) => user.role === 'Teacher');
    return this.trainingStatusV2.get({
      training: trainingId,
      courseId,
      profile: teacherId?.[0].id,
    });
  }

  private async updateTrainingStatusInTrainingRecord(
    trainingId: number,
    status: ProgressStatus,
  ) {
    const trainingRecord = await this.trainings.get(trainingId);
    if (trainingRecord) {
      await this.trainings.update(trainingRecord.id, {
        trainingStatus: status,
      });
    }
  }

  private async getTeacherTrainingSyncData(
    model: Table,
  ): Promise<SyncRecord[]> {
    const records = await model
      .where('syncStatus')
      .anyOf([SyncRecordStatus.created, SyncRecordStatus.updated])
      .toArray();

    return records.map((record) => {
      if (record.syncStatus === SyncRecordStatus.updated) {
        return { ...record, syncStatus: SyncRecordStatus.created };
      }
      return record;
    });
  }

  async getAllUnsyncedRecords(): Promise<Record<string, SyncRecord[]>> {
    const records = {
      assessment: await this._getSyncData(this.assessmentsV2),
      submission: await this._getSyncData(this.submissionsV2),
      teacherTrainingStatus: await this.getTeacherTrainingSyncData(
        this.trainingStatusV2,
      ),
    };
    return records;
  }

  async updateRecordsSyncStatus(
    syncChanges: SyncChanges,
    status: SyncRecordStatus,
  ): Promise<number> {
    let updates = 0;
    const idsToMarkSynced = {
      assessmentsV2: syncChanges.assessment?.created.map(
        (record: { id: number }) => record.id,
      ),
      submissionsV2: syncChanges.submission?.created.map(
        (record: { id: number }) => record.id,
      ),
      trainingStatusV2: syncChanges.teacherTrainingStatus?.created.map(
        (record: { id: number }) => record.id,
      ),
    };

    for (const [model, ids] of Object.entries(idsToMarkSynced)) {
      if (!ids) {
        continue;
      }
      updates += await this._updateSyncData(
        // @ts-ignore
        this[model],
        ids,
        status,
      );
    }

    return 1;
  }
}

export const teacherTrainingDB = new TeacherTrainingDB();

// Below is example code we can use in the future.
// Add this above incase you want to use it: teachers!: Table<TeacherRecord>;
// export interface TeacherRecord extends SyncRecord {
//   name: string;
//   age: number;
//   isActive: boolean;
// }

// get(id: string) {
//   const method = 'TeacherTrainingDB/get';
//   tlLogger.start(method);
//   const result = this.teachers.get(id);
//   tlLogger.end(method);
//   return result;
// }

// getAll() {
//   const method = 'TeacherTrainingDB/getAll';
//   tlLogger.start(method);
//   const result = this.teachers
//     .filter((record) => record.syncStatus !== SyncRecordStatus.deleted)
//     .toArray();

//   tlLogger.end(method);
//   return result;
// }

// async add(teacher: TeacherRecord) {
//   const method = 'TeacherTrainingDB/add';
//   tlLogger.start(method);
//   const result = await this.teachers.add(teacher);

//   tlLogger.end(method, { result: 'Added' });
//   return result;
// }

// async update(id: string, changes: Partial<TeacherRecord>) {
//   const method = 'TeacherTrainingDB/update';
//   tlLogger.start(method);
//   const result = await this.teachers.update(id, changes);

//   tlLogger.end(method, { result: 'Updated' });
//   return result;
// }

// async remove(id: string) {
//   const method = 'TeacherTrainingDB/remove';
//   tlLogger.start(method);
//   const result = await this.teachers.delete(id);

//   tlLogger.end(method, { result: 'Removed' });
//   return result;
// }

// getAllUnsyncedRecords(): PromiseExtended<TeacherRecord[]> {
//   const method = 'TeacherTrainingDB/getAllUnsyncedRecords';
//   tlLogger.start(method);
//   const result = this.teachers
//     .filter((teacher) => teacher.syncStatus !== SyncRecordStatus.synced)
//     .toArray();

//   tlLogger.end(method);
//   return result;
// }

// getAllSyncedRecords(): PromiseExtended<TeacherRecord[]> {
//   const method = 'TeacherTrainingDB/getAllSyncedRecords';
//   tlLogger.start(method);
//   const result = this.teachers
//     .filter((teacher) => teacher.syncStatus === SyncRecordStatus.synced)
//     .toArray();

//   tlLogger.end(method);
//   return result;
// }

// async markRecordsSync(ids: string[]) {
//   const method = 'TeacherTrainingDB/markRecordsSync';
//   tlLogger.start(method);
//   const result = this.teachers
//     .where('id')
//     .anyOf(ids)
//     .modify({ syncStatus: SyncRecordStatus.synced })
//     .then((data) => {
//       return Promise.resolve(!!data);
//     });

//   tlLogger.end(method);
//   return result;
// }
