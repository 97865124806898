import styled from '@emotion/styled';
import { useState, useEffect } from 'react';
import { Typography } from '../../atoms/typography/typography';
import { Spacing } from '../../atoms/spacing/spacing';
import { Button } from '../../atoms/button/button';
import { Otp } from '../../molecules/otp/otp';
import { CardContainer } from '../../molecules/card/card-container';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { Logo } from '../../atoms/logo/logo';
import { useBreakpoint } from '@taleemabad/shared';

export interface OtpScreenProps {
  code?: string;
  phoneNumber: string;
  otpLength?: number;
  errorMessage: string;
  logo: string;
  onOtpValueChange: (value: string) => void;
  onResendOtp: () => void;
}
const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 68px;
`;
export const OtpScreen: React.FC<OtpScreenProps> = ({
  code,
  phoneNumber,
  otpLength = 4,
  errorMessage,
  logo,
  onResendOtp,
  onOtpValueChange,
}) => {
  const [time, setTime] = useState<number>(30);
  const [error, setError] = useState<string | null>(null);
  const [codeValue, setCodeValue] = useState<string>('');

  const { tablet, mobileHorizontal, mobile } = useBreakpoint();
  const isLowResDevice = tablet || mobileHorizontal || mobile;

  useEffect(() => {
    if (time > 0) {
      const timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [time]);
  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  const handleChange = (otp: string) => {
    setError(null);
    if (otp.length === otpLength) {
      handleValidation(otp);
    }
  };

  const handleSubmit = (code: string) => {
    handleValidation(code);
  };

  const handleResendClick = () => {
    setTime(30);
    onResendOtp();
  };

  const handleValidation = (otp: string) => {
    if (otp.length !== otpLength) {
      setError('Please enter the OTP');
      return;
    }
    const isExpired = false;
    if (isExpired) {
      setError('Expired OTP. Request a new one to proceed');
      return;
    }
    setError(null);
    onOtpValueChange(otp);
  };
  const props: any = {};
  if (code) {
    props.code = code;
  }
  return (
    <StyledCardContainer variant="outline">
      {isLowResDevice && (
        <StyledImageWrapper>
          <Logo
            altName="outer-layer"
            width={275}
            src={`../../assets/${logo}`}
          />
        </StyledImageWrapper>
      )}
      <Typography
        variant="textHeadlineMediumLightOnSurface"
        data-cy="forgot-password-one-time-password-title"
      >
        One Time Password
      </Typography>
      <Spacing margin="lg none">
        <Typography
          variant="textBodyLargeLightOnSurface"
          data-cy="forgot-password-one-time-password-subtitle"
        >
          Enter {otpLength} digit code sent on your Phone number{' '}
          <Typography variant="textLabelLargeLightPrimary">
            {phoneNumber}
          </Typography>
        </Typography>
      </Spacing>
      <Spacing marginTop="xl" marginBottom="xxxl" data-cy="send-otp-input-box">
        <Otp
          {...props}
          otpLength={otpLength}
          data-cy={`otp-input-${otpLength}`}
          handleChange={(data) => {
            handleChange(data);
            setCodeValue(data);
          }}
          error={error}
        />
      </Spacing>
      <Button
        children="Next"
        variant="filled"
        width="100%"
        onClick={() => handleSubmit(codeValue)}
        data-cy="verify-otp-next=button"
      />
      <StyledDiv>
        {time === 0 ? (
          <Button
            children="Resend OTP"
            variant="outlined"
            width="100%"
            onClick={handleResendClick}
            data-cy="verify-otp-resend-otp-button"
          />
        ) : (
          <Typography variant="textLabelLargePrimary60">
            Resend OTP in {time} seconds
          </Typography>
        )}
      </StyledDiv>
    </StyledCardContainer>
  );
};

const StyledCardContainer = styled(CardContainer)`
  display: flex;
  flex-direction: column;
  padding: var(--rk-spacing-xxxxl) var(--rk-spacing-xxxl)
    var(--rk-spacing-xxxxl) var(--rk-spacing-xxxl);
  width: 497px;
  overflow-y: overlay;
  border: --md-sys-color-outline;
  ${mq(BreakpointSizes.xs)} {
    width: 100%;
    max-width: 80%;
    border: none;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: var(--rk-spacing-xl) 0 var(--rk-spacing-lg) 0;
`;
