import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ILessonPlanStep, IMedia } from '@taleemabad/db';
import {
  LpAudio,
  LpImage,
  LpStatement,
  TbdPlayer,
} from '@taleemabad/dsm/components';

interface LpStatementListProps {
  statements: ILessonPlanStep[];
  id?: number;
  isRtl?: boolean;
}

const LpStatementListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--rk-spacing-xl);
`;

export const LpStatementList: React.FC<LpStatementListProps> = ({
  statements,
  id,
  isRtl = false,
}) => {
  const [activePlaying, setActivePlaying] = useState<number | null>(null);

  const handleMediaPlay = (index: number) => {
    setActivePlaying(index);
  };
  return (
    <LpStatementListContainer>
      {statements?.map((statement, index) => (
        <React.Fragment key={index}>
          <LpStatement
            index={statement.index}
            statement={statement.statement}
            type={statement.type}
          />
          {statement.media && (
            <MediaWrapperComponent
              lpId={id}
              media={statement.media}
              onMediaPlay={() => handleMediaPlay(index)}
              isPlaying={activePlaying === index}
              isRtl={isRtl}
            />
          )}
        </React.Fragment>
      ))}
    </LpStatementListContainer>
  );
};

interface MediaWrapperProps {
  media: IMedia[];
  onMediaPlay: () => void;
  isPlaying?: boolean;
  lpId?: number;
  isRtl?: boolean;
}

const MediaWrapperComponent: React.FC<MediaWrapperProps> = ({
  media,
  onMediaPlay,
  isPlaying,
  lpId,
  isRtl = false,
}) => {
  return (
    <MediaWrapperStyled isUrdu={isRtl}>
      {media.map((media, index) => {
        switch (media.type) {
          case 'image':
            return <LpImage key={index} src={media.link} />;
          case 'video':
            return (
              <TbdPlayer
                onPlay={onMediaPlay}
                isPlaying={isPlaying}
                key={index}
                url={media.link}
                hlsUrl={media.hlsVideoMainManifestUrl}
                featureName="lesson"
                eventData={{ epMediaId: media.videoAssetId, epLpId: lpId }}
              />
            );
          case 'audio':
            return (
              <LpAudio
                key={index}
                src={media.link}
                isPlaying={isPlaying}
                onPlay={onMediaPlay}
              />
            );
          default:
            return null;
        }
      })}
    </MediaWrapperStyled>
  );
};

const MediaWrapperStyled = styled.div<{ isUrdu: boolean }>`
  direction: ${(props) => (props.isUrdu ? 'rtl' : 'ltr')};
`;
