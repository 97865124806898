// @ts-nocheck
import { PropsWithChildren } from 'react';
import { SerializedStyles } from '@emotion/react';

import '@material/web/progress/linear-progress.js';
import '@material/web/progress/circular-progress.js';

export interface ProgressProps extends PropsWithChildren {
  type: 'linear' | 'circular';
  buffer?: number;
  value?: number;
  max?: number;
  indeterminate?: boolean;
  fourColor?: boolean;
  emotionCss?: SerializedStyles;
}

export const Progress = ({
  type,
  buffer = 1,
  value = 0,
  max = 1,
  indeterminate,
  fourColor,
  emotionCss,
}: ProgressProps) => {
  const props = {};
  if (indeterminate) {
    props.indeterminate = true;
  }
  if (fourColor) {
    props['four-color'] = true;
  }
  if (value) {
    props.value = value;
  }
  if (max) {
    props.max = max;
  }
  if (buffer) {
    props.buffer = buffer;
  }

  if (type === 'linear') {
    return (
      <div css={emotionCss} className="rk-progress-container">
        <md-linear-progress
          className="rk-progress rk-linear-progress"
          {...props}
        ></md-linear-progress>
      </div>
    );
  }

  if (type === 'circular') {
    return (
      <div css={emotionCss} className="rk-progress-container">
        <md-circular-progress
          className="rk-progress rk-circular-progress"
          {...props}
        ></md-circular-progress>
      </div>
    );
  }
};
