// @ts-nocheck

import '@material/web/list/list';
import '@material/web/list/list-item';
import '@material/web/icon/icon.js';
import 'material-symbols/rounded.scss';
import { Icon } from '../../atoms/icon/icon';
import { Switch } from '../../atoms/switch/switch';
import { Checkbox } from '../../atoms/checkbox/checkbox';
import { Radio } from '../../atoms/radio/radio';
import styled from '@emotion/styled';
import { Monogram } from '../../atoms/monogram/monogram';
import { CardContainer } from '../card/card-container';
import { Button } from '../../atoms/button/button';
import { Spacing } from '../../atoms/spacing/spacing';
import { Typography } from '../../atoms/typography/typography';
import React from 'react';

const StyledLeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export interface ListProps {
  heading: string;
  supportingText?: string;
  trailingSupportingText?: string;
  overlineText?: string | React.ReactNode;
  type?: string;
  leadingIcon?: PrefixSufixContentProps;
  trailingIcon?: PrefixSufixContentProps;
  buttonEnabled?: boolean;
  buttonText?: string;
  onClick?: () => void;
  onButtonClick?: () => void;
}

export const List = ({
  heading,
  supportingText,
  trailingSupportingText,
  overlineText,
  leadingIcon,
  trailingIcon,
  buttonEnabled,
  buttonText = 'Open',
  onClick,
  onButtonClick,
}: ListProps) => {
  return (
    <StyledListCardWrapper variant="outline" minHeight="fit-content">
      <md-list>
        <md-list-item onClick={() => onClick?.()}>
          <StyledListCard>
            <StyledLeadingWrapper>
              {leadingIcon && (
                <StyledIcon direction={leadingIcon.direction}>
                  <PrefixSufixContent
                    direction="leading"
                    direction={leadingIcon.direction}
                    source={leadingIcon?.source}
                    type={leadingIcon?.type}
                  />
                </StyledIcon>
              )}
              <StyledLeadingContainer>
                {overlineText && (
                  <div slot="overline">
                    <Typography
                      ellipseLine={1}
                      variant="textLabelMediumSurfaceVariant"
                    >
                      {overlineText}
                    </Typography>
                  </div>
                )}
                {heading && (
                  <div slot="headline">
                    <Typography
                      ellipseLine={2}
                      variant="textBodyLargeOnSurface"
                    >
                      {heading}
                    </Typography>
                  </div>
                )}
                {supportingText && (
                  <div slot="supporting-text">
                    <Typography
                      ellipseLine={2}
                      variant="textBodyMediumOnSurfaceVariant"
                    >
                      {supportingText}
                    </Typography>
                  </div>
                )}
              </StyledLeadingContainer>
            </StyledLeadingWrapper>
            {trailingSupportingText && (
              <StyledTrailingContainer>
                <div slot="trailing-supporting-text">
                  <Typography
                    ellipseLine={2}
                    variant="textLabelMediumSurfaceVariant"
                  >
                    {trailingSupportingText}
                  </Typography>
                </div>
              </StyledTrailingContainer>
            )}

            {trailingIcon && (
              <StyledIcon direction={trailingIcon.direction}>
                <PrefixSufixContent
                  direction={trailingIcon.direction}
                  source={trailingIcon.source}
                  type={trailingIcon.type}
                />
              </StyledIcon>
            )}
          </StyledListCard>
        </md-list-item>
      </md-list>
      {buttonEnabled && (
        <Spacing marginLeft="xl" marginRight="xl" marginBottom="xl">
          <Button
            onClick={() => {
              onButtonClick?.();
            }}
            width="100%"
            slot="trailing"
            children={buttonText}
          />
        </Spacing>
      )}
    </StyledListCardWrapper>
  );
};

const StyledListCard = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: var(--rk-spacing-xl) var(--rk-spacing-xl) var(--rk-spacing-xl) 0;
  align-items: flex-start;
`;
const StyledLeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledTrailingContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

interface PrefixPaddingProps {
  direction?: 'leading' | 'trailing';
}

interface PrefixSufixContentProps {
  direction?: 'leading' | 'trailing';
  type: 'monogram' | 'icon' | 'image' | 'switch' | 'checkbox' | 'radio';
  source: string;
  name?: string;
}

const PrefixSufixContent = ({
  direction = 'left',
  source,
  type,
  name,
}: PrefixSufixContentProps) => {
  const renderContent = () => {
    switch (type) {
      case 'monogram':
        return <Monogram name={name} />;
      case 'icon':
        return (
          <Icon
            name={source}
            slot={direction === 'leading' ? 'start' : 'end'}
          />
        );
      case 'image':
        return (
          <img
            slot={direction === 'leading' ? 'start' : 'end'}
            height={56}
            width={56}
            src={source}
            alt="dynamic content"
          />
        );
      case 'switch':
        return <Switch />;
      case 'checkbox':
        return <Checkbox />;
      case 'radio':
        return <Radio option={{ content: '', value: '' }} />;
      default:
        return null;
    }
  };

  return renderContent();
};

const StyledIcon = styled.div<PrefixPaddingProps>`
  padding: ${(props) =>
    props.direction === 'leading'
      ? '0 var(--rk-spacing-xl) 0 0'
      : '0 0 0 var(--rk-spacing-xl)'};
`;

const StyledListCardWrapper = styled(CardContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
