import React from 'react';
import { Button, Spacing, Typography } from '@taleemabad/dsm/components';
import { CardContainer } from '../card/card-container';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { ILessonPlan } from '@taleemabad/db';
import { formatUtcDate } from '@taleemabad/shared';

interface LpCardProps {
  id: ILessonPlan;
  preTitle: string;
  title: string;
  status?: string | null;
  btnText?: string;
  onLpSelected?: (id: ILessonPlan) => void;
}

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 176px;
  justify-content: space-between;
  padding: var(--rk-spacing-xl);

  ${mq(BreakpointSizes.xl)} {
    min-height: 168px;
  }
`;

const TextContainer = styled.div``;

const StyledButtonWrapper = styled.div`
  margin-top: var(--rk-spacing-xl);
  md-filled-button {
    width: 100%;
  }
`;

const StyledTitleWrapper = styled.div`
  margin-top: var(--rk-spacing-sm);
  margin-bottom: var(--rk-spacing-lg);
`;

export const LpCard: React.FC<LpCardProps> = ({
  id,
  preTitle,
  title,
  status,
  btnText,
  onLpSelected,
}) => {
  return (
    <CardContainer
      variant="outline"
      emotionCss={css`
        min-height: 148px;
        max-width: 100%;
      `}
    >
      <ContainerWrapper>
        <TextContainer>
          <Typography variant="textTitleMediumSurface">{preTitle}</Typography>
          <StyledTitleWrapper>
            <Typography variant="textTitleLargeOnSurface" ellipseLine={2}>
              {title}
            </Typography>
          </StyledTitleWrapper>
        </TextContainer>

        <StyledButtonWrapper>
          <Spacing marginBottom="xl">
            {status ? (
              <Typography variant="textLabelLargeSecondary">
                {`Last Open ${formatUtcDate(status)}`}
              </Typography>
            ) : (
              <Typography variant="textLabelLargeNeutral70">
                {status === null ? 'Pending' : ''}
              </Typography>
            )}
          </Spacing>

          {btnText && (
            <Button
              variant="filled"
              onClick={() => {
                onLpSelected && onLpSelected(id);
              }}
            >
              {btnText}
            </Button>
          )}
        </StyledButtonWrapper>
      </ContainerWrapper>
    </CardContainer>
  );
};
