import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { ChipItem } from '@taleemabad/dsm/components';

interface ClassSubjectChipState {
  selectedItem: ChipItem | null;
  setSelectedItem: Dispatch<SetStateAction<ChipItem | null>>;
  resetSelectedItem: () => void;
}

export const ClassSubjectChipContext = createContext<ClassSubjectChipState>(
  null!,
);

export const ClassSubjectChipProvider = ({ children }: PropsWithChildren) => {
  const [selectedItem, setSelectedItem] = useState<ChipItem | null>(null);

  const resetSelectedItem = () => {
    setSelectedItem(null);
  };

  const value = useMemo(
    () => ({
      selectedItem,
      setSelectedItem,
      resetSelectedItem,
    }),
    [selectedItem],
  );

  return (
    <ClassSubjectChipContext.Provider value={value}>
      {children}
    </ClassSubjectChipContext.Provider>
  );
};
