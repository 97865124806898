import styled from '@emotion/styled';
import {
  EventNames,
  analyticsService,
  lessonPlanDB,
  sections,
} from '@taleemabad/db';
import {
  AppBar,
  Button,
  Dialog,
  RadioGroup,
  RadioOption,
  Spacing,
  TimetableSectionSelectionScreen,
  Typography,
} from '@taleemabad/dsm/components';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useLiveQuery } from 'dexie-react-hooks';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const SelectSectionPage = () => {
  let { gradeId } = useParams();
  const [openPopup, setOpenPopup] = useState(false);
  const [sectionId, setSectionId] = useState<number | null>();
  let shift!: string | null;
  const selectedGrade = parseInt(gradeId || '0');
  const grade = useLiveQuery(() =>
    lessonPlanDB.getSelectedGrade(selectedGrade),
  );
  const navigate = useNavigate();
  return (
    <StyledContent isTitle={true}>
      <AppBar
        pageTitle="Back"
        leftIcon="arrow_back"
        onLeftIconClick={() => {
          navigate(-1);
        }}
        hasPadding={false}
        titleFullWidth={true}
        titleMedium={true}
        isTitleClickable={true}
      />
      <TimetableSectionSelectionScreen
        selectedGrade={grade ? grade[0].label : ''}
        heading="Select section you teach"
        items={sections}
        onGradeSelect={(id: number) => {
          setSectionId(id);
          analyticsService.trackEvent(EventNames.dashboardSectionSelected, {
            epSectionSelected: id,
          });
          setOpenPopup(true);
        }}
        onNoSectionSelect={() => {
          setSectionId(1);
          setOpenPopup(true);
        }}
      />
      <Dialog
        open={openPopup}
        hasBackdropClick={false}
        title="Class Shift"
        onClose={() => {
          setOpenPopup(false);
        }}
      >
        <Typography variant="textBodyLargeOnSurface">
          {`Select your shift for ${grade ? grade[0].label : ''}`}
        </Typography>
        <Spacing marginTop="xl">
          <RadioGroup
            direction="column"
            options={[
              { value: '1', content: 'Morning' },
              { value: '2', content: 'Evening' },
            ]}
            name="shift"
            onChange={(option: RadioOption) => {
              analyticsService.trackEvent(EventNames.dashboardShiftSelected, {
                epShiftSelected: option.content.toUpperCase(),
              });
              shift = option.content;
            }}
          />
        </Spacing>
        <StyledPopupButton>
          <Button
            variant="text"
            children="Cancel"
            onClick={() => {
              setOpenPopup(false);
            }}
          />
          <Button
            variant="text"
            children="Done"
            onClick={() => {
              if (shift) {
                setOpenPopup(false);
                navigate(
                  `/create-class/subject/${selectedGrade}/${sectionId}/${shift}`,
                );
              }
            }}
          />
        </StyledPopupButton>
      </Dialog>
    </StyledContent>
  );
};

const StyledPopupButton = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
  md-filled-button {
    width: 100%;
  }
`;
interface StyledDiv {
  isTitle?: boolean;
}
const StyledContent = styled.div<StyledDiv>`
  padding: ${(props) => (props.isTitle ? '16px 24px 0px 24px' : '0px')};
  display: flex;
  flex-direction: column;
  ${mq(BreakpointSizes.xs)} {
    padding: ${(props) => (props.isTitle ? '16px' : '')};
    gap: 6px;
  }
`;
