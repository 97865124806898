// // @ts-nocheck
import { PropsWithChildren, ReactElement } from 'react';
import styled from '@emotion/styled';
import { Ripple } from '@taleemabad/dsm/components';
import { CardTitle } from './card-title';
import { CardSubhead } from './card-subhead';
import { CardSupportingText } from './card-supporting-text';
import { CardMedia } from './card-media';
import { SerializedStyles } from '@emotion/react';
import { CardContainer } from './card-container';

interface CardContentDiv {
  smallPadding?: boolean;
}

const StyledCardContent = styled.div<CardContentDiv>`
  padding: var(
    ${(props) =>
      props.smallPadding ? '--rk-card-padding-small' : '--rk-card-padding'}
  );
`;

export interface CardProps extends PropsWithChildren {
  variant: 'filled' | 'outline';
  disabled?: boolean;
  preTitle?: ReactElement;
  title?: string;
  postTitle?: ReactElement;
  subheading?: string;
  supportingText?: string;
  imageSrc?: string;
  onClick?: () => void;
  primaryBtnText?: string;
  onPrimaryBtnClick?: () => void;
  secondaryBtnText?: string;
  onSecondaryBtnClick?: () => void;
  emotionCss?: SerializedStyles;
  className?: string;
  minHeight?: string;
  smallPadding?: boolean;
  width?: string;
}
//TODO: Fix card width on mobile devices
export const Card = ({
  variant,
  disabled = false,
  preTitle,
  title,
  postTitle,
  subheading,
  supportingText,
  imageSrc,
  onClick,
  primaryBtnText,
  onPrimaryBtnClick,
  secondaryBtnText,
  onSecondaryBtnClick,
  emotionCss,
  className,
  children,
  minHeight = '',
  smallPadding = false,
  width = '',
}: CardProps) => {
  return (
    <CardContainer
      disabled={disabled}
      css={emotionCss}
      className={className}
      onClick={onClick}
      variant={variant}
      minHeight={minHeight}
      width={width}
    >
      <Ripple />
      {imageSrc && <CardMedia src={imageSrc} />}
      <StyledCardContent smallPadding={smallPadding}>
        {/* Card Media */}
        {/* Title */}
        {preTitle && preTitle}
        {title && <CardTitle title={title} />}
        {postTitle && postTitle}
        {/* Subheading */}
        {subheading && <CardSubhead subheading={subheading} />}
        {/* Supporting Text */}
        {supportingText && <CardSupportingText text={supportingText} />}
        {/* Actions */}
        {children}
      </StyledCardContent>
    </CardContainer>
  );
};
