import styled from '@emotion/styled';
import { Typography } from '../../atoms/typography/typography';
import { Icon } from '../../atoms/icon/icon';
import { Spacing } from '../../atoms/spacing/spacing';

const StyledStartScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--md-sys-color-on-primary);
  z-index: 1001;
`;
export const StartScreen = () => {
  return (
    <StyledStartScreenContainer>
      <Spacing marginBottom="xxl">
        <Icon
          color="--md-sys-color-primary"
          size="largest"
          name="calendar_month"
        ></Icon>
      </Spacing>
      <Typography variant="textTitleLargePrimary">
        Let’s start with creating a timetable!
      </Typography>
    </StyledStartScreenContainer>
  );
};
