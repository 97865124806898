import { teachersRoutes } from '../features/teachers/routes';
import { authRoutes } from '../features/authentication/routes';
import { lessonPlanRoutes } from '../features/lessonplan/routes';
import { createBrowserRouter } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { downloadsRoutes } from '../features/offline-downloads/routes';
import { reportCardRoutes } from '../features/report-card/routes';

const routes = [
  ...authRoutes,
  ...teachersRoutes,
  ...lessonPlanRoutes,
  ...downloadsRoutes,
  ...reportCardRoutes,
  { path: '*', element: <Navigate to="/training" /> },
];

const appRouter = createBrowserRouter(routes);

export default appRouter;
