import styled from '@emotion/styled';
import {
  Button,
  ListItem,
  SelectedSubject,
  SelectionList,
  Spacing,
  StyledTimetabelGradeSelectionScreen,
  TimetabelViewContainerScreen,
  TimetableHeading,
} from '@taleemabad/dsm/components';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useState } from 'react';

export interface TimetableDaysSelectionScreenProps {
  selectedGrade: string;
  heading: string;
  items: ListItem[];
  selectedSubject: SelectedSubject;
  onDaysListSelection: (id: ListItem[]) => void;
}
export const DaysSelectionScreen = ({
  selectedGrade,
  heading,
  items,
  selectedSubject,
  onDaysListSelection,
}: TimetableDaysSelectionScreenProps) => {
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [listItems, setListItems] = useState<ListItem[]>([]);

  const handleSelectionChange = (day: ListItem[]) => {
    setListItems(day);
    const isAnyItemSelected = day.some((item) => item.isChecked);
    setButtonDisabled(!isAnyItemSelected);
  };
  const props: any = {};
  if (selectedSubject) {
    props.subject = {
      icon: selectedSubject.icon,
      text: selectedSubject.text,
    };
  }
  return (
    <>
      <TimetabelViewContainerScreen
        steps={[
          { step: 1, isActive: true, isCompleted: true },
          { step: 2, isActive: true, isCompleted: true },
          { step: 3, isActive: true, isCompleted: true },
          { step: 4, isActive: false, isCompleted: false },
        ]}
      ></TimetabelViewContainerScreen>
      <StyledTimetabelGradeSelectionScreen>
        <TimetableHeading
          subject={selectedSubject}
          heading={heading}
          grade={selectedGrade}
        />
      </StyledTimetabelGradeSelectionScreen>
      <StyledDaysContainer
        marginBottom="xxxxxl"
        marginTop="xxxl"
        paddingLeft="xxxxl"
        paddingRight="xxxxl"
      >
        <SelectionList
          days={items}
          onSelection={(day: ListItem[]) => {
            handleSelectionChange(day);
          }}
        />
      </StyledDaysContainer>
      <ActionButtonContainer>
        {' '}
        <Button
          width="100%"
          onClick={() => {
            onDaysListSelection(listItems);
          }}
          disabled={isButtonDisabled}
          children="Next"
        />
      </ActionButtonContainer>
    </>
  );
};

const StyledDaysContainer = styled(Spacing)`
  ${mq(BreakpointSizes.desktop)} {
    padding-left: calc(var(--rk-spacing-xxxxxxxl) * 2);
    padding-right: calc(var(--rk-spacing-xxxxxxxl) * 2);
  }
`;
const ActionButtonContainer = styled.div`
  ${mq(BreakpointSizes.desktop)} {
    padding-left: calc(var(--rk-spacing-xxxxxxxl) * 2);
    padding-right: calc(var(--rk-spacing-xxxxxxxl) * 2);
  }
`;
