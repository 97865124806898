import {
  AppBar,
  LessonPlanChapters,
  ChipItem,
  LoadingScreen,
  Dialog,
  Typography,
  Button,
  AlertDialog,
} from '@taleemabad/dsm/components';
import styled from '@emotion/styled';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import {
  GradeSubjectRecord,
  SyncManagerStatus,
  fetchSelectedChapters,
  lessonPlanDB,
  syncStateManager,
  BookChapterRecord,
  analyticsService,
  EventNames,
} from '@taleemabad/db';
import { useSyncManager } from '@taleemabad/shared';

const StyledContent = styled.div`
  padding: var(--rk-spacing-xl);
  display: flex;
  flex-direction: column;
  ${mq(BreakpointSizes.xs)} {
    gap: 6px;
  }
`;

const StyledPopupErrorButton = styled.div<ButtonProps>`
  margin-top: ${(props) => props.margin}px;
  md-filled-button {
    width: 100%;
  }
  md-outlined-button {
    width: 100%;
  }
`;
let selectedGradeSubject: ChipItem;
let gradeSubject: GradeSubjectRecord;
const StyledChaperWrapper = styled.div`
  margin-top: 24px;
  ${mq(BreakpointSizes.xs)} {
    margin-top: 26px;
  }
`;

interface ButtonProps {
  margin: number;
}
export const LessonPage = () => {
  const [showNoInternetPopup, setShowNoInternetPopup] =
    useState<boolean>(false);
  const [showRetryErrorPopup, setRetryErrorPopup] = useState<boolean>(false);
  const [chapters, setChapters] = useState<BookChapterRecord[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const syncStatus = useSyncManager();

  const gradeSubjectSection: any =
    useLiveQuery(() => lessonPlanDB.getGradeSubjectClass()) || [];
  const navigate = useNavigate();
  const handleLoadChapter = async (id: number) => {
    const chapters = await lessonPlanDB.getChapterByGradeSubjectId(id);
    setIsLoading(true);
    if (!syncStateManager.isConnected) {
      if (chapters.length === 0) {
        setShowNoInternetPopup(true);
        analyticsService.trackEvent(EventNames.noInternetNoInternetPopup, {});
      } else {
        setChapters(chapters);
      }
    } else {
      try {
        await fetchSelectedChapters([id], null);
        const fetchedChapters =
          await lessonPlanDB.getChapterByGradeSubjectId(id);
        setChapters(fetchedChapters);
      } catch (e: any) {
        analyticsService.trackEvent(EventNames.dataLoadingDataLoadingFailed, {
          ep_screenName: 'chapterList',
        });
        setRetryErrorPopup(true);
        setChapters(chapters);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <StyledContent>
      <AppBar
        pageTitle="Lesson Plans"
        leftIcon="import_contacts"
        onLeftIconClick={() => {}}
        hasPadding={false}
        titleFullWidth={true}
        isLeftIconButton={false}
      />
      {syncStatus !== SyncManagerStatus.synced ? (
        <LoadingScreen />
      ) : (
        <StyledChaperWrapper>
          <LessonPlanChapters
            tbChipSelectorTitle="My Classes"
            tbChipSelectorItems={gradeSubjectSection}
            tbChipSelectorNoItemsText="No classes assigned."
            iconTextText="Chapter List"
            iconTextIcon="import_contacts"
            isLoading={isLoading}
            chapters={chapters}
            isExplore={gradeSubjectSection.length <= 0}
            onSubjectSelected={async (data) => {
              console.log(data);
              selectedGradeSubject = data;
              const res = await lessonPlanDB.getBookChapters(
                data.schoolClass,
                data.gradeSubject,
              );
              setChapters(res);
              setIsLoading(false);
            }}
            onChapterSelected={(data) => {
              gradeSubjectSection.length > 0
                ? navigate(
                    `/lessons/${data.id}/grade/${selectedGradeSubject.gradeSubject}`,
                  )
                : navigate(`/lessons/${data.id}/grade/${gradeSubject?.id}`);
            }}
            onClassSubjectSelected={async (data) => {
              if (data?.classId?.content && data?.subjectId?.content) {
                setChapters([]);
                const [gradeSubjectRes] =
                  await lessonPlanDB.getSelectedGradeSubjectByLabels(
                    data.classId.content,
                    data.subjectId.content,
                  );
                gradeSubject = gradeSubjectRes;
                if (gradeSubjectRes) {
                  handleLoadChapter(gradeSubject.id);
                } else {
                  setChapters([]);
                }
              } else {
                setChapters([]);
              }
            }}
          />
        </StyledChaperWrapper>
      )}
      <AlertDialog
        title="No Internet Connection"
        description="Seems like your internet is not connected. Connect and try again."
        showPopup={showNoInternetPopup}
        onClosePopup={() => setShowNoInternetPopup(false)}
      />

      <Dialog
        open={showRetryErrorPopup}
        title="Error Occurred"
        onClose={() => {
          setRetryErrorPopup(false);
        }}
      >
        <Typography variant="textBodyMedium">
          Seems like you ran into some error.
        </Typography>
        <StyledPopupErrorButton margin={24}>
          <Button
            children="Retry"
            onClick={() => {
              setRetryErrorPopup(false);
              analyticsService.trackEvent(
                EventNames.retryDataRetryDataClicked,
                {},
              );
              handleLoadChapter(gradeSubject.id);
            }}
          />
        </StyledPopupErrorButton>
        <StyledPopupErrorButton margin={0}>
          <Button
            variant="outlined"
            children="Close"
            onClick={() => {
              setRetryErrorPopup(false);
            }}
          />
        </StyledPopupErrorButton>
      </Dialog>
    </StyledContent>
  );
};
