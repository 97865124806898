import { FC, PropsWithChildren, useEffect } from 'react';
import { ConfigProvider } from './config-provider';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ClassSubjectProvider } from './lesson-plan-providers/class-subject-provider';
import { ClassSubjectChipProvider } from './lesson-plan-providers/class-subject-chip-provider';
import { downloadManagerService } from '@taleemabad/db';

// Add additional global providers here
const Providers: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      const navBackUrls = ['/login', '/training'];
      const capBackHandler = CapacitorApp.addListener(
        'backButton',
        ({ canGoBack }) => {
          if (!canGoBack || navBackUrls.includes(window.location.pathname)) {
            CapacitorApp.exitApp();
          } else {
            window.history.back();
          }
        },
      );
      // sync downloads from native platforms when app is opened
      downloadManagerService.syncDownloads();
      return () => {
        capBackHandler.remove();
      };
    }
  }, []);
  return (
    <ConfigProvider>
      <ClassSubjectProvider>
        <ClassSubjectChipProvider>{children}</ClassSubjectChipProvider>
      </ClassSubjectProvider>
    </ConfigProvider>
  );
};

export function AppProvider({ children }: PropsWithChildren) {
  return <Providers>{children}</Providers>;
}
