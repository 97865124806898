import {
  Button,
  ChapterCard,
  ChipAssist,
  OverlayeComponent,
  Spacing,
  StartScreen,
  Tab,
  Tabs,
  Typography,
} from '@taleemabad/dsm/components';
import { useEffect, useState } from 'react';
import { ChipFilterWithContainer } from '../../atoms/chip/chip-filter';
import styled from '@emotion/styled';
import { useWindowSize } from '@taleemabad/shared';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { IClassTimeTable } from '@taleemabad/db';

export const FtueTimetableNewUser = 'ftue-timetable-new-user';
export const FtueTimetable = 'ftue-timetable';

export const shifts: Tab[] = [
  {
    key: 1,
    label: 'Morning',
    icon: 'light_mode',
  },
  {
    key: 2,
    label: 'Evening',
    icon: 'dark_mode',
  },
];
interface Days {
  id: number;
  name: string;
  initials: string;
}

export interface AssignedClasses {
  id: number;
  schoolClass: number;
  label: string;
}

export interface TimetableAssignedSubjectProps {
  assignedClasses: IClassTimeTable[];
  days: Days[];
  onSelectedClass: (selectedClass: AssignedClasses) => void;
  onDaySelected: (id: number) => void;
  onShiftSelected: (id: number) => void;
  onAddClass: () => void;
  onFirstItem?: () => void;
}
export const TimetableAssignedSubjectScreen = ({
  assignedClasses = [],
  days,
  onSelectedClass,
  onShiftSelected,
  onDaySelected,
  onAddClass,
  onFirstItem,
}: TimetableAssignedSubjectProps) => {
  const [width] = useWindowSize();
  const [activeTab, setActiveTab] = useState(1);
  const [selectedDay, setSelectedDay] = useState<Days>(
    getSelectedDay(days) || days[0],
  );
  const [assignClasses, setAssignClasses] = useState(assignedClasses);
  const [shift, setShift] = useState(shifts[0].label);
  useEffect(() => {
    const data = assignedClasses?.filter(
      (item) =>
        item?.daysOfWeek?.includes(selectedDay.id) &&
        item.shift === shift.toUpperCase(),
    );
    setAssignClasses(data);
  }, [selectedDay, assignedClasses, shift]);
  const [showStartScreen, setShowStartScreen] = useState(
    assignedClasses.length === 0,
  );
  useEffect(() => {
    const time = setTimeout(() => {
      setShowStartScreen(false);
    }, 2500);
    return () => clearTimeout(time);
  }, []);

  return (
    <>
      {showStartScreen && localStorage.getItem(FtueTimetable) === null && (
        <StartScreen />
      )}
      <Tabs
        variant="secondary"
        activeTabKey={activeTab}
        tabs={shifts}
        onChange={(value: number) => {
          setActiveTab(value);
          onShiftSelected(value);
          setSelectedDay(days[0]);
          setShift(
            shifts
              .find((data) => data.key === value)
              ?.label.toLocaleUpperCase() as string,
          );
        }}
      />
      <StyledSelectedDaysContainer>
        {days.map((item) => (
          <ChipFilterWithContainer
            selected={selectedDay === item}
            onClick={() => {
              setSelectedDay(item);
              onDaySelected(item.id);
            }}
            key={item.name}
            label={width < 575 ? item.initials : item.name}
          />
        ))}
      </StyledSelectedDaysContainer>
      <StyledSpaceContainer marginTop="xl" marginBottom="xl">
        <Typography variant="textTitleLargePrimary40">
          {selectedDay?.name}
        </Typography>
      </StyledSpaceContainer>
      <StyledCardContainer>
        {assignClasses.map((assignedClass, index) => (
          <Spacing
            key={assignedClass.gradeSubject + assignedClass.gradeLabel + index}
            style={{ position: 'relative' }}
            marginBottom="md"
          >
            <ChapterCard
              onClick={() => {
                if (assignedClass.id) {
                  onSelectedClass?.({
                    id: assignedClass.gradeSubject,
                    schoolClass: assignedClass.schoolClass,
                    label: assignedClass.gradeLabel,
                  });
                }
              }}
              variant="filledWithBorder"
              key={
                assignedClass.gradeSubject +
                assignedClass.gradeLabel +
                assignedClass.section +
                assignedClass.shift +
                selectedDay.name
              }
              title={`${assignedClass.gradeLabel} - ${assignedClass.section} ${assignedClass.subjectLabel}`}
            />
            {assignedClasses.filter((data) =>
              data?.daysOfWeek.some((day) => day === getSelectedDay(days)?.id),
            ).length === 1 &&
              assignedClasses.length === 1 &&
              localStorage.getItem(FtueTimetable) === null && (
                <StyledChip>
                  <ChipAssist
                    label="Click here to start teaching"
                    alwaysFocusable={true}
                    onClick={() => {
                      onFirstItem?.();
                    }}
                  />
                </StyledChip>
              )}
          </Spacing>
        ))}
      </StyledCardContainer>

      <StyledAddClassButton>
        <Button onClick={onAddClass} variant="filled" children="+ Add Class" />
      </StyledAddClassButton>
      <>
        {localStorage.getItem(FtueTimetable) === null &&
          assignedClasses.length > 0 && (
            <>
              <OverlayeComponent
                onClick={() => {
                  onFirstItem?.();
                }}
                isActive={assignedClasses?.length > 0}
              />
              <StyledFirstTimeWithClass>
                <ChipAssist label="Your classes are in your timetable" />
              </StyledFirstTimeWithClass>
            </>
          )}
      </>
    </>
  );
};

const StyledSelectedDaysContainer = styled.div`
  display: flex;
  inline-flex: 1;
  flex-direction: row;
  justify-content: center;
  gap: var(--rk-spacing-lg);
  ${mq(BreakpointSizes.xs)} {
    justify-content: space-between;
    gap: unset;
  }
`;
const StyledSpaceContainer = styled(Spacing)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: overlay;
  overflow-x: hidden;
  height: calc(100vh - 235px);
  ${mq(BreakpointSizes.desktop)} {
    padding-left: calc(var(--rk-spacing-xxxxxxxl) * 2);
    padding-right: calc(var(--rk-spacing-xxxxxxxl) * 2);
  }
`;

const StyledAddClassButton = styled.div`
  position: absolute;
  bottom: 90px;
  left: var(--rk-spacing-xxxxxxxl) * 2;
  ${mq(BreakpointSizes.xs)} {
    left: var(--rk-spacing-xl);
    width: calc(100% - var(--rk-spacing-xl));
    bottom: 78px;
    height: 78px;
    align-content: center;
    background-color: var(--md-sys-color-on-primary);
  }
`;

const StyledChip = styled.div`
  position: absolute;
  top: 80px;
  width: 100%;
  text-align: center;
  z-index: 100;
  md-assist-chip {
    background-color: var(--md-sys-color-on-primary);
  }
  ${mq(BreakpointSizes.xs)} {
    top: 80px;
  }
`;

const StyledFirstTimeWithClass = styled.div`
  position: absolute;
  top: 200px;
  left: 278px;
  z-index: 100;
  md-assist-chip {
    background-color: var(--md-sys-color-on-primary);
  }
  ${mq(BreakpointSizes.xs)} {
    top: unset;
    bottom: 120px;
    left: var(--rk-spacing-xl);
  }
`;
export const getSelectedDay = (days: Days[]) => {
  const currentDate = new Date();
  let currentDayIndex = currentDate.getDay();
  if (currentDayIndex === 0) {
    currentDayIndex = 1;
  }
  const currentDay = days.find((day) => day.id === currentDayIndex);
  return currentDay;
};
