import styled from '@emotion/styled';
import {
  EventNames,
  analyticsService,
  lessonPlanDB,
  sections,
  subjectIcons,
  syncManager,
  syncStateManager,
} from '@taleemabad/db';
import {
  AlertDialog,
  AppBar,
  DaysSelectionScreen,
  ListItem,
  LoadingScreen,
} from '@taleemabad/dsm/components';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useLiveQuery } from 'dexie-react-hooks';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const DaysSelectionPage = () => {
  const navigation = useNavigate();
  const [showNoInternetPopup, setShowNoInternetPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { gradeId, sectionId, subjectId, shiftId } = useParams();
  const selectedGrade = parseInt(gradeId || '0');
  const selectedSection = parseInt(sectionId || '0');
  const selectedSubject = parseInt(subjectId || '0');
  const grade =
    useLiveQuery(() => lessonPlanDB.getSelectedGrade(selectedGrade)) || [];
  const subjects = useLiveQuery(() => lessonPlanDB.getSubjects()) || [];
  const addNewClass = async (days: number[]) => {
    if (syncStateManager.isConnected) {
      try {
        setIsLoading(true);
        lessonPlanDB.addRecordLessonPlanTimetable({
          daysOfWeek: days,
          grade: selectedGrade,
          section:
            sections.find((data) => data.id === selectedSection)?.label || 'A',
          subject: selectedSubject,
          shift: shiftId?.toLocaleUpperCase(),
        });
        const method = 'SyncManager/performSync';

        await syncManager.pushChanges(method);
        lessonPlanDB.removeTimetableEntriesWithSchoolClass();
        await syncManager.pullChanges(method);
        await syncManager.updateSyncedAt();
        navigation('/timetable/view-timetable');
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setShowNoInternetPopup(true);
    }
  };

  return (
    grade.length > 0 && (
      <StyledContent isTitle={true}>
        <AppBar
          pageTitle="Back"
          leftIcon="arrow_back"
          onLeftIconClick={() => {
            navigation(-1);
          }}
          hasPadding={false}
          titleFullWidth={true}
          titleMedium={true}
          isTitleClickable={true}
        />
        <DaysSelectionScreen
          selectedGrade={`${grade[0].label}- ${sections.find((data) => data.id === selectedSection)?.label} (${shiftId?.toLocaleUpperCase()})`}
          heading="Select all days you teach"
          items={lessonPlanDB.getDays().map((day) => {
            return {
              id: day.id,
              day: day.name,
              isChecked: false,
            };
          })}
          selectedSubject={{
            icon:
              subjectIcons.find((data) => data.id === selectedSubject)?.icon ||
              '',
            text:
              subjects.find((data) => data.id === selectedSubject)?.label || '',
          }}
          onDaysListSelection={(days: ListItem[]) => {
            analyticsService.trackEvent(EventNames.dashboardDaySelected, {
              epDaysSelected: days
                .filter((day) => day.isChecked)
                .map((day) => day.id),
            });
            addNewClass(
              days.filter((day) => day.isChecked).map((day) => day.id),
            );
          }}
        />
        <AlertDialog
          title="No Internet Connection"
          description="Seems like your internet is not connected. Connect and try again."
          showPopup={showNoInternetPopup}
          onClosePopup={() => setShowNoInternetPopup(false)}
        />
        {isLoading && <LoadingScreen />}
      </StyledContent>
    )
  );
};

interface StyledDiv {
  isTitle?: boolean;
}
const StyledContent = styled.div<StyledDiv>`
  padding: ${(props) => (props.isTitle ? '16px 24px 0px 24px' : '0px')};
  display: flex;
  flex-direction: column;
  ${mq(BreakpointSizes.xs)} {
    padding: ${(props) => (props.isTitle ? '16px' : '')};
    gap: 6px;
  }
`;
