import styled from '@emotion/styled';
import { Progress } from '../../atoms/progress/progress';
import { Typography } from '../../atoms/typography/typography';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { Spacing } from '../../atoms/spacing/spacing';

export const LoadingScreen = () => {
  return (
    <LoaderWrapper>
      <Progress type="circular" indeterminate={true} />
      <ContentWrapper>
        <Typography variant="textTitleLargeOnSurface">
          Loading Content
        </Typography>
        <Spacing marginTop="md">
          <Typography variant="textBodyLargeOnSurfaceVariant">
            Please wait while the <br />
            content is loading
          </Typography>
        </Spacing>
      </ContentWrapper>
    </LoaderWrapper>
  );
};
const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  ${mq(BreakpointSizes.desktop)} {
    width: 50%;
    height: 50%;
  }
  z-index: 100;
  background-color: var(--md-sys-color-surface-bright);
`;
const ContentWrapper = styled.div`
  margin-top: var(--rk-spacing-xl);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
