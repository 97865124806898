import { BaseModel, BaseModelForPush } from '../models/base-model';
import { SyncChanges, SyncRecord, SyncRecordStatus } from '../types/sync-state';
import { syncedDBModel } from '../models/synced';
import { tlLogger } from '@taleemabad/shared';
import { lessonPlanDB } from '../models/lessonplan';
import { teacherTrainingDB } from '../models/teachertraining';
import { analyticsDB } from '../models/analytics';
import { fetchCourseData } from '../api/teachertraining';
import { fetchBookChapters, fetchBookChaptersV2 } from '../api/lessonplan';
import { userDetails } from '../api/auth';
import { apiService } from './request';
import { type AxiosError } from 'axios';
import { userCache } from './user-cache';
import { analyticsService, EventNames } from '../api/analytics';
import { FeatureFlagNames, userDB } from '../models/user';

/**
 * This class is the generic glue between the
 * models and worker. It helps pull and push the
 * data from the different tables we have. Each time
 * a new table is added, we should add it here. Read
 * tables are only for pulls, and write tbales are for
 * pushes.
 * */
class SyncManager<T extends SyncRecord> {
  private pushRetry: boolean = false;
  constructor(
    private readTables: { name: string; model: BaseModel }[],
    private writeTables: { name: string; model: BaseModelForPush }[],
  ) {}

  async hasUnsyncedChanges(): Promise<boolean> {
    const method = 'SyncManager/hasUnsyncedChanges';
    tlLogger.start(method);
    const result = await syncedDBModel.hasUnsyncedChanges();
    tlLogger.end(method, { result });
    return result;
  }

  async getChangesToSync(): Promise<SyncChanges> {
    const method = 'SyncManager/getChangesToSync';
    tlLogger.start(method);
    const syncChanges: SyncChanges = {};
    const promises = this.writeTables.map(async (table) => {
      const tableChanges = await table.model.getAllUnsyncedRecords();
      const keys = Object.keys(tableChanges);
      return keys.map(async (key) => {
        const records = tableChanges[key];
        if (records.length) {
          /**
           * In analytics the events are generated before the user is logged
           * in - so we need to set these manually here.
           */
          for (const record of records) {
            if (record.user === undefined) {
              console.log('Setting user and profile.', JSON.stringify(record));
              const profile = userCache.getUserFromCache();
              console.log('profile is available', JSON.stringify(profile));
              if (profile) {
                record.user = profile.id;
                const selectedProfile = profile.profiles.filter(
                  (p) => p.isSelected,
                )[0];
                record.profile = selectedProfile?.id;
              }
            }
          }
          // TODO configure for deletions and updations.
          syncChanges[key] = {
            created: records.filter(
              (record) => record.syncStatus === SyncRecordStatus.created,
            ),
            // updated: records.filter(
            //   (records) => records.syncStatus === SyncRecordStatus.updated
            // ),
            // deleted: records
            //   .filter(
            //     (records) => records.syncStatus === SyncRecordStatus.deleted
            //   )
            //   .map((record) => record.id!),
          };
        }
      });
    });

    await Promise.allSettled(promises);
    tlLogger.end(method, { syncChanges });
    return Promise.resolve(syncChanges);
  }

  async postSyncChanges(syncChanges: SyncChanges, status: SyncRecordStatus) {
    const method = 'SyncManager/postSyncChanges';
    tlLogger.start(method);

    const promises = this.writeTables.map((table) => {
      const { model } = table;
      // extract ids of records to be mark 'synced'
      // so we can ignore them in the next sync
      // and eventually remove them.
      return model.updateRecordsSyncStatus(syncChanges, status);
    });
    await Promise.allSettled(promises);
    tlLogger.end(method);
    return Promise.resolve(promises);
  }

  async getLastSyncedAt() {
    return await syncedDBModel.getUpdateSyncedAt();
  }

  updateSyncedAt() {
    const method = 'SyncManager/updateSyncedAt';
    tlLogger.start(method);
    const result = syncedDBModel.updateSyncedAt();
    tlLogger.end(method, { result });
    return result;
  }

  async pullChanges(method: string) {
    /**
     * Right now the functions below trigger update
     * the database directly. In future we might
     * extract it to this level.
     */
    tlLogger.log(method, { message: 'Pulling changes.' });
    const lastSyncedAt = await this.getLastSyncedAt();
    const fetchUserProfile = await userDetails();
    const courseResult = await fetchCourseData(lastSyncedAt);
    const timetable = await lessonPlanDB.getTimetableCreatedRecords();
    const isTimetabelEnabled =
      (await userDB.getFeatureFlag(FeatureFlagNames.timetableEnabled)) || false;
    if (!isTimetabelEnabled && timetable.length > 0) {
      lessonPlanDB.clearData();
    }
    const bookChapterResult = isTimetabelEnabled
      ? await fetchBookChaptersV2(timetable.length === 0 ? null : lastSyncedAt)
      : await fetchBookChapters(
          (await lessonPlanDB.getGradeSubjects()).length === 0
            ? null
            : lastSyncedAt,
        );
    tlLogger.log(method, {
      courseResult,
      bookChapterResult,
      fetchUserProfile,
    });
  }
  handleNetworkError = async (method: string, error: AxiosError) => {
    tlLogger.error(method, { message: error.message });
    if (!this.pushRetry) {
      // we only want to retry once if there is error
      this.pushRetry = true;
      tlLogger.log(method, { message: 'Retrying to push changing...' });
      await this.pushChanges();
    }
  };
  handleFailedRecords = async (method: string, failedRecords: any) => {
    if (Object.keys(failedRecords).length) {
      tlLogger.verbose(method, {
        message: 'Records with invalid data.',
        failedRecords,
      });
      //Mark failed records as failed so it can't be retried
      const updates = await this.postSyncChanges(
        failedRecords,
        SyncRecordStatus.failed,
      );
      analyticsService.trackEvent(EventNames.recordsFailedToSync, {
        records: JSON.stringify(failedRecords),
      });
      tlLogger.verbose(method, {
        message: 'Marked records failed.',
        updates,
      });
    }
  };
  handleRetryRecords = async (method: string, retryRecords: any) => {
    if (Object.keys(retryRecords).length) {
      tlLogger.verbose(method, {
        message: 'Records with valid data.',
        retryRecords,
      });
      if (!this.pushRetry) {
        tlLogger.log(method, { message: 'Retrying to push changing...' });
        // we only want to retry once if there is validation error
        this.pushRetry = true;
        await this.pushChanges();
        return;
      }
    }
  };

  handleValidationError = async (
    method: string,
    error: AxiosError,
    dataChanges: any,
  ) => {
    const failedRecords: Record<string, any> = {};
    const retryRecords: SyncChanges = {};
    const data: Record<string, any> = error.response?.data || {};
    if (!Object.keys(data).length) return;
    for (const key in data) {
      //TODO: currently just handling the failed changes in created records update to handle other types of changes
      const actualChanges = dataChanges[key].created;
      const invalidRecords: Record<string, any> = [];
      const validRecords: Record<string, any> = [];
      data[key].forEach((record: Record<string, any>, index: number) => {
        if (Object.keys(record).length) {
          invalidRecords.push(actualChanges[index]);
        } else {
          validRecords.push(actualChanges[index]);
        }
      });
      failedRecords[key] = { created: invalidRecords };
      retryRecords[key] = { created: validRecords };
    }
    await this.handleFailedRecords(method, failedRecords);
    await this.handleRetryRecords(method, retryRecords);
  };
  /**
   * The function performs the following steps:
   * 1. Logs the start of the push operation.
   * 2. Retrieves the changes to be synced.
   * 3. If there are no changes to be synced, logs this and returns.
   * 4. Attempts to post the changes to the remote server.
   * 5. If the post is successful, it logs the server response, marks the records as synced, and logs this.
   * 6. If the post fails with a server error, network error, or timeout, it logs the error message. If this is the first retry, it sets a flag to prevent further retries and attempts to push the changes again.
   * 7. If the post fails with a 400 status code (Bad Request), it separates the changes into valid and invalid records. Invalid records are marked as failed and this is logged. Valid records are retried if this is the first retry.
   * 8. If the post fails for any other reason, it logs the error message.
   */
  async pushChanges(method: string = 'SyncManager/performSync') {
    tlLogger.log(method, { message: 'Pushing changes.' });
    const dataChanges = await this.getChangesToSync();
    tlLogger.log(method, { message: 'Got changes to sync.' });
    const keys = Object.keys(dataChanges);
    if (!keys.length) {
      tlLogger.log(method, { message: 'No changes to sync.' });
      return;
    }
    try {
      const response = await apiService.post(
        `api/v1/push-sync/?c=${Date.now()}`,
        dataChanges,
      );
      this.pushRetry = false;
      tlLogger.verbose(method, { message: 'Response from server.', response });
      const updates = await this.postSyncChanges(
        dataChanges,
        SyncRecordStatus.synced,
      );
      tlLogger.verbose(method, { message: 'Marked records synced.', updates });
    } catch (e) {
      const error = e as AxiosError;
      if (
        error.response?.status === 500 ||
        error.message === 'Network Error' ||
        error.code === 'ECONNABORTED'
      ) {
        await this.handleNetworkError(method, error);
        return;
      }
      if (error.response?.status === 400) {
        await this.handleValidationError(method, error, dataChanges);
        return;
      }
      tlLogger.error(method, { message: 'Error pushing changes.', e });
    }
  }

  async performSync(syncWorker: Worker | null) {
    const method = 'SyncManager/performSync';
    tlLogger.start(method);
    if (syncWorker) {
      syncWorker.postMessage({ action: 'pull' });
      tlLogger.log(method);
    } else {
      tlLogger.log(method, {
        message: 'Worker not available, going on main thread.',
      });
      const lastSyncedAt = await this.getLastSyncedAt();
      // we don't want to push anything first time
      // until the first sync is completed.
      if (lastSyncedAt) {
        this.pushRetry = false;
        await this.pushChanges(method);
      }
      await this.pullChanges(method);
      await this.updateSyncedAt();
      tlLogger.log(method, {
        message: 'Changes completed ',
        timestamp: new Date(),
      });
    }
    tlLogger.end(method);
  }
}

export const syncManager = new SyncManager(
  [
    {
      name: teacherTrainingDB.name,
      model: teacherTrainingDB,
    },
    {
      name: lessonPlanDB.name,
      model: lessonPlanDB,
    },
  ],
  [
    {
      name: analyticsDB.name,
      model: analyticsDB,
    },
    {
      name: teacherTrainingDB.name,
      model: teacherTrainingDB,
    },
    {
      name: lessonPlanDB.name,
      model: lessonPlanDB,
    },
  ],
);
