import { Table } from 'dexie';
import { DownloadRequest } from '../types/downloads';
import { BaseModel } from './base-model';

const SCHEMA = {
  requests: 'trainingId, trainingURL, downloadId',
};
const SCHEMA_VERSION = 1;
export class DownloadRequestModel extends BaseModel {
  downloads!: Table<DownloadRequest>;

  constructor() {
    super('downloads');
    this.version(SCHEMA_VERSION).stores(SCHEMA);
    this.downloads = this.table('requests');
  }
  async addDownloadRequest(request: DownloadRequest) {
    return this.downloads.add(request);
  }
  async getDownloadRequest(id: number) {
    return this.downloads?.get(id) || null;
  }
  async getDownloadRequests() {
    return this.downloads?.toArray() || [];
  }
  async removeDownloadRequest(id: number) {
    return this.downloads?.delete(id);
  }
  async clearDownloadRequests() {
    return this.downloads?.clear();
  }
  async getDownloadRequestByTrainingURL(trainingURL: string) {
    return (
      this.downloads?.where('trainingURL').equals(trainingURL).first() || null
    );
  }
}

export const downloadRequestModel = new DownloadRequestModel();
