import styled from '@emotion/styled';
import { Steps } from '@taleemabad/dsm/components';

interface ActiveStepsProps {
  step: number;
  isActive: boolean;
  isCompleted: boolean;
}

export interface StepperProps {
  steps: ActiveStepsProps[];
}
export const StepperComponent = ({ steps }: StepperProps) => {
  return (
    <StyledStepper key="steps">
      {steps.map((step, index) => (
        <>
          <Steps
            step={step.step}
            isActive={step.isActive}
            isCompleted={step.isCompleted}
          />
          {index < steps.length - 1 && (
            <StyledDivider
              isActive={step.isActive}
              isCompleted={step.isCompleted}
              size={steps.length}
            ></StyledDivider>
          )}
        </>
      ))}
    </StyledStepper>
  );
};

const StyledStepper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;
interface ActiveProps {
  isActive: boolean;
  isCompleted?: boolean;
  size?: number;
}
const StyledDivider = styled.div<ActiveProps>`
  border: 1px solid
    ${(props) =>
      props.isActive && props.isCompleted
        ? 'var(--md-sys-color-primary)'
        : 'var(--md-ref-neutral-70)'};
  flex-grow: 1;
  max-width: calc((100% / ${(props) => props.size}) - 28px);
  display: flex;
`;
