import {
  Typography,
  AppBar,
  ChapterCardList,
  StyledCardListContainer,
  ChapterCard,
  List,
  LoadingScreen,
  Spacing,
} from '@taleemabad/dsm/components';
import styled from '@emotion/styled';
import { FC } from 'react';
import { lessonPlanDB } from '@taleemabad/db';
import { useLiveQuery } from 'dexie-react-hooks';

interface ReportCardProps {}

const AppBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--md-sys-color-surface);
  padding-bottom: var(--rk-spacing-xl);
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--rk-spacing-sm);
`;

export const ReportCard: FC<ReportCardProps> = () => {
  const assignedClasses = useLiveQuery(() => lessonPlanDB.getGradeSubjects());

  if (!assignedClasses) {
    return <LoadingScreen />;
  }

  return (
    <Spacing padding="xl" marginBottom="xxxxxxl">
      <AppBarContainer>
        <AppBar
          pageTitle="Report Card"
          leftIcon="assignment"
          hasPadding={false}
          titleFullWidth={true}
          isLeftIconButton={false}
        />
      </AppBarContainer>
      <Typography variant="textTitleMedium">
        Select a class to see assessment results
      </Typography>
      <ListContainer>
        {assignedClasses?.map((item) => (
          <ChapterCard variant="filledWithBorder" title={item.gradeLabel} />
        ))}
      </ListContainer>
    </Spacing>
  );
};
