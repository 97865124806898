import styled from '@emotion/styled';
import { Ripple } from '@taleemabad/dsm/components';
import { Icon } from '../../atoms/icon/icon';
import { css } from '@emotion/react';
import { Typography } from '../../atoms/typography/typography';
import { PropsWithChildren, ReactNode } from 'react';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';

export interface SideNavBarItem {
  label: string;
  analyticsKey: string;
  icon: string;
  key: number;
  path: string;
}
export interface SidebarProps {
  items: SideNavBarItem[];
  activeItemKey: number;
  fixed?: boolean;
  onChange: (item: SideNavBarItem) => void;
  children?: ReactNode;
}

export const SidebarItem = ({
  items,
  activeItemKey,
  fixed,
  onChange,
  children = null,
}: SidebarProps) => {
  return (
    <StyledNavBarContainer>
      {children ? children : null}
      {items.map((item) => {
        const isActive = activeItemKey === item.key;
        return (
          <NavBarItemContainer
            key={item.key}
            className={`rk-nav-bar-item-container${isActive ? ' active' : ''}`}
            onClick={() => onChange(item)}
          >
            <Ripple />
            <StyledActiveIndicator isActive={isActive}>
              <SideBarItemIcon isActive={isActive} name={item.icon} />
              <NavBarItemLabel isActive={isActive}>
                {item.label}
              </NavBarItemLabel>
            </StyledActiveIndicator>
          </NavBarItemContainer>
        );
      })}
    </StyledNavBarContainer>
  );
};

interface NavBarItemContainerProps extends PropsWithChildren {
  className?: string;
  onClick: () => void;
}
const NavBarItemContainer = ({
  children,
  className = '',
  onClick,
}: NavBarItemContainerProps) => {
  return (
    <StyledNavBarItemContainer className={className} onClick={onClick}>
      {children}
    </StyledNavBarItemContainer>
  );
};
const StyledNavBarItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  width: calc(100% - 32px);
  justify-content: flex-start;
  padding: 4px 16px 4px 16px;
  position: relative;
  cursor: pointer;
`;

const StyledNavBarContainer = styled.div`
  width: 272px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  background-color: var(--md-sys-color-surface-container-high);
  position: relative;
  ${mq(BreakpointSizes.xs)} {
    display: none;
  }
`;
interface NavBarItemLabelProps extends PropsWithChildren {
  isActive?: boolean;
}
const NavBarItemLabel = ({ isActive, children }: NavBarItemLabelProps) => {
  return (
    <Typography
      variant={
        isActive ? 'textTitleMediumSecondaryFixed' : 'textTitleMediumSurface'
      }
    >
      {children}
    </Typography>
  );
};
interface StyledActiveIndicatorProps {
  isActive?: boolean;
}
const StyledActiveIndicator = styled.div<StyledActiveIndicatorProps>`
  height: 28px;
  width: inherit;
  border-radius: 28px;
  background-color: ${(props) =>
    !props.isActive ? 'transparent' : 'var(--md-ref-secondary-95)'};
  padding: 14px;
  display: flex;
  justify-content: left;
  align-items: center;
`;

interface NavBarItemIconProps {
  name: string;
  isActive?: boolean;
}
const SideBarItemIcon = ({ name, isActive = false }: NavBarItemIconProps) => {
  return (
    <Icon
      name={name}
      emotionCss={css`
        color: ${isActive
          ? 'var(--md-sys-color-on-secondary-container)'
          : 'var(--md-sys-color-on-surface-variant)'};
        margin-right: 16px;
      `}
    />
  );
};
