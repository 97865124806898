import { FC } from 'react';
import {
  BaseOption,
  BaseOptionCard,
  CardStatus,
  Radio,
} from '@taleemabad/dsm/components';

export interface MCQCardProps {
  option: BaseOption;
  status?: CardStatus;
  groupName: string;
  onSelect?: (option: BaseOption) => void;
}

export const MCQCard: FC<MCQCardProps> = ({
  option,
  status = CardStatus.Unselected,
  groupName,
  onSelect,
}) => {
  return (
    <BaseOptionCard option={option} status={status}>
      <Radio
        name={groupName}
        option={option}
        disabled={status === CardStatus.Disabled}
        onSelect={() => onSelect?.(option)}
      />
    </BaseOptionCard>
  );
};
