import {
  ProgressStatus,
  teacherTrainingDB,
  TrainingCourseProgressInfo,
  TrainingStatusRecord,
} from '@taleemabad/db';

class TeacherTrainingService {
  async getNextTrainingAndCourse(): Promise<TrainingCourseProgressInfo | null> {
    const trainingStatusExists =
      await teacherTrainingDB.trainingStatusV2.count();
    if (!trainingStatusExists) {
      return this.getTrainingForFirstTimeUser();
    }
    return this.getTrainingForExistingUser();
  }

  private async getTrainingForFirstTimeUser() {
    const firstCourse = await teacherTrainingDB.courses
      .orderBy('index')
      .first();
    if (!firstCourse) {
      return null;
    }

    const trainings = await teacherTrainingDB.trainings
      .where({ course: firstCourse.id, index: 1 })
      .toArray();
    const firstTraining = trainings[0] || null;

    return {
      course: firstCourse,
      training: firstTraining,
      cta: 'Start Training',
      progressText: 'Let’s start your first training!',
      allTrainingsCompleted: false,
    };
  }

  private async getTrainingForExistingUser() {
    const allTrainingStatuses =
      await teacherTrainingDB.trainingStatusV2.toArray();
    // we cant query lastModifiedAt since it is not indexed, we are not indexing it since we will need to write an
    // upgrade logic to update the lastModifiedAt for all the records. Since that upgrade logic lives in the code forever
    // and we won't have that many records, we can just sort the array here.
    const latestTrainingStatus = allTrainingStatuses.sort((a, b) => {
      const lastModifiedA = a.lastModifiedAt || 0;
      const lastModifiedB = b.lastModifiedAt || 0;
      return lastModifiedB - lastModifiedA;
    })[0];

    // if the latestTraining is IN_PROGRESS then we can just return the same course and training
    if (
      latestTrainingStatus &&
      latestTrainingStatus.status === ProgressStatus.IN_PROGRESS
    ) {
      const course = await teacherTrainingDB.courses.get(
        latestTrainingStatus.courseId,
      );
      const training = await teacherTrainingDB.trainings.get(
        latestTrainingStatus.training,
      );

      if (course && training) {
        course.courseCompletionPercentage =
          await this.getCourseCompletionPercentage(course.id);

        return {
          course,
          training,
          cta: 'Resume Training',
          progressText: 'Let’s continue where you left!',
          allTrainingsCompleted: false,
        };
      }
    }
    // else we return the next training
    return this.nextTrainingForCompletedTraining(latestTrainingStatus);
  }

  private async nextTrainingForCompletedTraining(
    latestTrainingStatus: TrainingStatusRecord,
  ): Promise<TrainingCourseProgressInfo | null> {
    // go through all the trainings in currentCourse and get the next training
    const currentCourse = await teacherTrainingDB.courses.get(
      latestTrainingStatus.courseId,
    );
    if (!currentCourse) {
      return null;
    }

    const allTrainingsForCurrentCourse = await teacherTrainingDB.trainings
      .where({ course: currentCourse.id })
      .sortBy('index');
    const nextTraining = allTrainingsForCurrentCourse.find(
      (t) => t.trainingStatus !== ProgressStatus.COMPLETED,
    );
    if (nextTraining) {
      currentCourse.courseCompletionPercentage =
        await this.getCourseCompletionPercentage(currentCourse.id);
      return {
        course: currentCourse,
        training: nextTraining,
        cta: 'Resume Training',
        progressText: 'Let’s continue where you left!',
        allTrainingsCompleted: false,
      };
    }
    // now there might be cases where all the trainings are completed for currentCourse, in that case we return the next training in next course
    return this.getFirstIncompleteTrainingAndCourse(latestTrainingStatus);
  }

  private async getFirstIncompleteTrainingAndCourse(
    latestTrainingStatus: TrainingStatusRecord,
  ): Promise<TrainingCourseProgressInfo | null> {
    // if the course which was previously completed a general course, then we need to get the next training in the next general course
    // here we divide the courses into two groups, general and subject courses on the basis of gradeGroup
    const currentCourse = await teacherTrainingDB.courses.get(
      latestTrainingStatus.courseId,
    );
    const currentCourseGradeGroup =
      currentCourse?.gradeGroup === null ? 'General' : 'Subject';

    const allCourses = await teacherTrainingDB.courses
      .orderBy('index')
      .toArray();
    const generalCourses = allCourses.filter(
      (course) => course.gradeGroup === null,
    );
    const subjectCourses = allCourses.filter(
      (course) => course.gradeGroup !== null,
    );

    // if the current course is a general course, then we need to get the next general course
    // if the current course is a subject course, then we need to get the next subject course
    // the following logic will get the next course in the same group
    const courseGroups =
      currentCourseGradeGroup === 'General'
        ? [generalCourses, subjectCourses]
        : [subjectCourses, generalCourses];

    for (const courseGroup of courseGroups) {
      for (const course of courseGroup) {
        const allTrainingsInCourse = await teacherTrainingDB.trainings
          .where({ course: course.id })
          .sortBy('index');

        for (const training of allTrainingsInCourse) {
          if (training.trainingStatus !== ProgressStatus.COMPLETED) {
            course.courseCompletionPercentage =
              await this.getCourseCompletionPercentage(course.id);
            return {
              course,
              training,
              cta: 'Resume Training',
              progressText: 'Let’s continue where you left!',
              allTrainingsCompleted: false,
            };
          }
        }
      }
    }
    // all trainings are completed
    return {
      course: null,
      training: null,
      cta: null,
      progressText: null,
      allTrainingsCompleted: true,
    };
  }

  async getCourseCompletionPercentage(courseId: number): Promise<number> {
    const trainings = await teacherTrainingDB.trainings
      .where({ course: courseId })
      .toArray();
    const completedTrainings = trainings.filter(
      (t) => t.trainingStatus === ProgressStatus.COMPLETED,
    ).length;
    const percentage =
      trainings.length > 0 ? (completedTrainings / trainings.length) * 100 : 0;
    return Math.round(percentage);
  }
}

export const teacherTrainingService = new TeacherTrainingService();
