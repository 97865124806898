import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { SerializedStyles } from '@emotion/react/dist/emotion-react.cjs';

interface StyledDivProps {
  gap?: number;
  marginTop?: number;
  scroll?: boolean;
  flexDirection?: string;
  justifyContent?: string;
  alignItem?: string;
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  background?: string;
  color?: string;
  radius?: string;
  borderRadius?: string;
  border?: string;
  borderColor?: string;
  cursor?: string;
  opacity?: string;
  style?: SerializedStyles;
}

export const StyledDiv = styled.div<StyledDivProps>`
  display: flex;
  ${(props) =>
    props.flexDirection
      ? css`
          flex-direction: ${props.flexDirection};
        `
      : ''}
  ${(props) =>
    props.justifyContent
      ? css`
          justify-content: ${props.justifyContent};
        `
      : ''}
  ${(props) =>
    props.alignItem
      ? css`
          align-items: ${props.alignItem};
        `
      : ''}
  ${(props) =>
    props.width
      ? css`
          width: ${props.width};
        `
      : ''}
  ${(props) =>
    props.opacity
      ? css`
          opacity: ${props.opacity};
        `
      : ''}
  ${(props) =>
    props.height
      ? css`
          height: ${props.height};
        `
      : ''}
  ${(props) =>
    props.borderRadius
      ? css`
          border-radius: var(${props.borderRadius});
        `
      : ''}
  ${(props) =>
    props.border
      ? css`
          border: ${props.border};
        `
      : ''}
  ${(props) =>
    props.borderColor
      ? css`
          border-color: var(${props.borderColor});
        `
      : ''}
  ${(props) =>
    props.cursor
      ? css`
          cursor: ${props.cursor};
        `
      : ''}
  ${(props) =>
    props.padding
      ? css`
          padding: ${props.padding};
        `
      : ''}
  ${(props) =>
    props.margin
      ? css`
          margin: ${props.margin};
        `
      : ''}
  ${(props) =>
    props.background
      ? css`
          background: var(${props.background});
        `
      : ''}
  ${(props) =>
    props.color
      ? css`
          color: var(${props.color});
        `
      : ''}
  ${(props) =>
    props.radius
      ? css`
          border-radius: ${props.radius};
        `
      : ''}
  gap: ${(props) => props.gap || 0}px;
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : '0px')};
  ${(props) =>
    props.scroll
      ? css`
          max-height: calc(100vh - 100px);
          overflow-y: scroll;

          ${mq(BreakpointSizes.xs)} {
            max-height: calc(100vh - 180px);
            padding-bottom: 16px;
          }
        `
      : ''};

  ${(props) => props.style}
`;
