import styled from '@emotion/styled';
import OtpInput from 'react-otp-input';
import { Typography } from '../../atoms/typography/typography';
import { useEffect, useState } from 'react';

interface OtpScreenProps {
  code: string;
  otpLength?: number;
  error: string | null;
  handleChange?: (otp: string) => void;
}

export const Otp: React.FC<OtpScreenProps> = ({
  code,
  otpLength,
  error,
  handleChange,
  ...otherProps
}) => {
  const focusStyle = {
    border: `2.5px solid ${error ? 'var(--md-sys-color-schemes-error)' : 'var(--md-sys-light-primary)'}`,
    outline: 'none',
  };
  const [codeEntered, setCodeEntered] = useState('');
  useEffect(() => {
    if (code) {
      setCodeEntered(code);
    }
  }, [code]);

  return (
    <>
      <OtpWrapper>
        <OtpInput
          value={codeEntered}
          onChange={(code) => {
            setCodeEntered(code);
            handleChange?.(code);
          }}
          numInputs={otpLength}
          inputType="tel"
          renderInput={(props, index) => (
            <input
              {...props}
              {...otherProps}
              placeholder="-"
              style={{
                border: `1.5px solid ${error ? 'var(--md-sys-color-schemes-error)' : 'var(--md-sys-light-primary)'}`,
                borderRadius: 'var(--rk-spacing-md)',
                maxWidth: '49px',
                minWidth: '49px',
                height: '60px',
                fontSize: '24px',
                textAlign: 'center',
                backgroundColor: `${error ? 'var(--md-sys-color-schemes-error-container)' : 'var(--md-sys-color-light-primary-container)'}`,

                ...(index === codeEntered.length
                  ? { ...props.style, ...focusStyle }
                  : {}),
              }}
            />
          )}
          renderSeparator={
            <span style={{ width: 'var(--rk-spacing-md)' }}></span>
          }
          shouldAutoFocus={false}
        />
      </OtpWrapper>
      {error && (
        <ErrorMessage>
          <Typography data-cy='invalid-otp-error' variant="textBodySmallSchemesError">{error}</Typography>
        </ErrorMessage>
      )}
    </>
  );
};

const OtpWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--rk-spacing-xl);
`;
