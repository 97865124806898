import React from 'react';
import { TrainingRecord } from '@taleemabad/db';
import { HorizontalCard } from '@taleemabad/dsm/components';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useWindowSize } from '@taleemabad/shared';

const DownloadCardListWrapper = styled.div`
  display: grid;
  gap: var(--rk-spacing-xl);
  grid-template-columns: repeat(3, 1fr);
  ${mq(BreakpointSizes.xs)} {
    grid-template-columns: repeat(1, 1fr);
  }
  ${mq(BreakpointSizes.sm)} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

interface DownloadCardListProps {
  trainings: TrainingRecord[];
  editUIState: boolean;
  onCardClicked: (item: TrainingRecord) => void;
  onIconClicked: (item: TrainingRecord) => void;
}

enum Icon {
  REMOVE = 'delete',
  PLAY = 'play_circle',
}

export const DownloadCardList: React.FC<DownloadCardListProps> = ({
  trainings,
  editUIState,
  onCardClicked,
  onIconClicked,
}) => {
  const [width] = useWindowSize();

  const setCardIcon = (): string | undefined => {
    if (editUIState) {
      return Icon.REMOVE;
    } else if (!editUIState) {
      return Icon.PLAY;
    }
  };

  return (
    <DownloadCardListWrapper>
      {trainings?.map((training: TrainingRecord) => (
        <HorizontalCard
          key={training.id}
          title={training.title}
          variant="outline"
          icon={setCardIcon()}
          iconSize={width > 575 ? 'largest' : 'large'}
          titleContentCss={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
          `}
          onCardClick={() => onCardClicked(training)}
          onIconClick={() => onIconClicked(training)}
          downloadUIState={editUIState}
        />
      ))}
    </DownloadCardListWrapper>
  );
};
