import styled from '@emotion/styled';
import { Typography } from '../typography/typography';
import { Ripple } from '../ripple/ripple';

interface ActiveProps {
  step?: number;
  isActive: boolean;
  isCompleted?: boolean;
}
const StyledCirlce = styled.div<ActiveProps>`
  position: relative;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  background-color: ${(props) =>
    props.isCompleted
      ? 'var(--md-sys-color-primary)'
      : 'var(--md-sys-color-on-primary)'};
  border: 2px solid
    ${(props) =>
      props.isActive
        ? 'var(--md-sys-color-primary)'
        : 'var(--md-ref-neutral-70)'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Steps = ({ isActive, isCompleted, step }: ActiveProps) => {
  return (
    <StyledCirlce isActive={isActive} isCompleted={isCompleted}>
      <Typography
        variant={
          isActive && isCompleted
            ? 'textLabelLargeOnPrimary'
            : 'textLabelLargePrimary'
        }
      >
        {step}
      </Typography>
      <Ripple></Ripple>
    </StyledCirlce>
  );
};
