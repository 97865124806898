import { PropsWithChildren } from 'react';
import { Spacing } from '../../atoms/spacing/spacing';
import { Typography } from '../../atoms/typography/typography';
import { StepperComponent } from '../../molecules/stepper/stepper';
import styled from '@emotion/styled';
export interface TimetabelViewContainerScreenProps extends PropsWithChildren {
  steps: any[];
}
export const TimetabelViewContainerScreen = ({
  steps,
  children,
}: TimetabelViewContainerScreenProps) => {
  return (
    <>
      <StyledTimetableStepper>
        <Typography variant="textLabelLargePrimary">ADD CLASS</Typography>
      </StyledTimetableStepper>
      <Spacing marginTop="xl">
        <StepperComponent steps={steps}></StepperComponent>
      </Spacing>
    </>
  );
};

const StyledTimetableStepper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
