/**
 * The basic purpose of this class is to
 * manage a memory cache for the tokens
 * and user data. This should make the fetch
 * much faster and also allow us to inject this
 * in the create base model hook.
 */

import { loadIntercom } from '@taleemabad/shared';
import { GenericProfile, UserProfile, UserToken } from '../api/auth';
import { FeatureFlagNames, userDB } from '..';

class UserCache {
  private tokenCache: UserToken | null = null;
  private userCache: UserProfile | null = null;
  private deviceId: string | null = null;

  getDeviceId(): string {
    return this.deviceId || '';
  }

  setDeviceId(deviceId: string | null): void {
    this.deviceId = deviceId;
  }

  getTokenFromCache(): UserToken | null {
    return this.tokenCache;
  }

  setTokenToCache(token: UserToken): void {
    this.tokenCache = token;
  }

  getUserFromCache(): UserProfile | null {
    return this.userCache;
  }

  async setUserToCache(user: UserProfile) {
    console.log('Setting userCache', user);
    this.userCache = user;
    const isIntercomEnabled = await userDB.getFeatureFlag(
      FeatureFlagNames.intercomEnabled,
    );
    if (isIntercomEnabled) {
      loadIntercom(user);
    }
  }

  getActiveProfile(): GenericProfile {
    return this.userCache?.profiles.find(
      (profile) => profile.isSelected,
    ) as GenericProfile;
  }

  resetCache(): void {
    this.tokenCache = null;
    this.userCache = null;
  }
}

export const userCache = new UserCache();
