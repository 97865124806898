import {
  AppBar,
  LessonPlanChapterTopics,
  LpSelected,
} from '@taleemabad/dsm/components';
import styled from '@emotion/styled';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { lessonPlanDB } from '@taleemabad/db';
import { useLiveQuery } from 'dexie-react-hooks';

const StyledContent = styled.div`
  padding: var(--rk-spacing-xl);
  display: flex;
  flex-direction: column;
  ${mq(BreakpointSizes.xs)} {
    gap: 6px;
  }
`;

export const LessonPlanListPage = () => {
  let { chapterId, gradeSubjectId } = useParams();
  const selectedChapter = parseInt(chapterId || '0');
  const selectedgradeSubject = parseInt(gradeSubjectId || '0');
  const lessonPlans = useLiveQuery(() =>
    lessonPlanDB.getLessonPlans(selectedChapter),
  );
  const selectedChapterData = useLiveQuery(() =>
    lessonPlanDB.getSelectedBookChapter(selectedChapter),
  );
  const selectedGradeSubjectData = useLiveQuery(
    async () =>
      await lessonPlanDB.getSelectedGradeSubject(selectedgradeSubject),
  );
  const navigate = useNavigate();
  return (
    <>
      <AppBar
        pageTitle="Back"
        leftIcon="arrow_back"
        onLeftIconClick={() => {
          navigate(-1);
        }}
        hasPadding={false}
        titleFullWidth={true}
        titleMedium={true}
        isTitleClickable={true}
      />
      <StyledContent>
        <LessonPlanChapterTopics
          topicName={selectedChapterData?.[0]?.title || ''}
          topicIndex={selectedChapterData?.[0]?.chapterNumber || 1}
          className={`${selectedGradeSubjectData?.[0]?.gradeShortCode} - ${selectedGradeSubjectData?.[0]?.subjectLabel}`}
          lessonPlans={lessonPlans || []}
          onSingleLpSelected={(res: LpSelected) => {
            navigate(
              `/lessons/${chapterId}/lesson-preparation/${res.data.id}/lesson-number/${res.index}`,
            );
          }}
        ></LessonPlanChapterTopics>
      </StyledContent>
    </>
  );
};
