import 'material-symbols/rounded.scss';
import styled from '@emotion/styled';
import { SerializedStyles } from '@emotion/react';

export type iconSizes =
  | 'tiniest'
  | 'tiny'
  | 'regular'
  | 'normal'
  | 'large'
  | 'largest';

interface StyledSpanProps {
  size?: iconSizes;
  color?: string;
  iconFill?: boolean;
  cursor?: string;
}

const StyledIcon = styled.span<StyledSpanProps>`
  &.material-symbols-rounded[slot='icon'] {
    font-size: var(${(props) => `--rk-icon-${props.size}`});
  }
  ${(props) => props.iconFill && "font-variation-settings: 'FILL' 1;"}
  color: var(
    ${(props) => (props.color ? props.color : '--md-sys-color-on-surface')}
  );
  font-size: var(${(props) => `--rk-icon-${props.size}`}) !important;
  height: var(${(props) => `--rk-icon-${props.size}`});
  width: var(${(props) => `--rk-icon-${props.size}`});
  cursor: ${(props) => props.cursor || 'default'};
`;

interface IconProps {
  name: string;
  slot?: string;
  emotionCss?: SerializedStyles;
  color?: string;
  size?: iconSizes;
  iconFill?: boolean;
  onClick?: () => void;
  // for cases where the click event needs to be controlled by the parent e.g. to prevent event propagation
  onControlledClick?: (event: React.MouseEvent<HTMLElement>) => void;
  cursor?: string;
}

export const Icon = ({
  name,
  slot,
  emotionCss,
  color = '',
  size = 'normal',
  iconFill = false,
  onClick = () => {},
  onControlledClick,
  cursor = 'default',
}: IconProps) => {
  const props: { slot?: string } = {};
  if (slot) {
    props.slot = slot;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onControlledClick) {
      onControlledClick(event);
    } else {
      onClick();
    }
  };

  return (
    <StyledIcon
      className="material-symbols-rounded"
      {...props}
      css={emotionCss}
      color={color}
      size={size}
      iconFill={iconFill}
      onClick={handleClick}
      cursor={cursor}
    >
      {name}
    </StyledIcon>
  );
};
