import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import {
  CardContainer,
  Icon,
  Spacing,
  Typography,
} from '@taleemabad/dsm/components';
import { css } from '@emotion/react';

export enum CardStatus {
  Correct = 'correct',
  Incorrect = 'incorrect',
  Selected = 'selected',
  Unselected = 'unselected',
  Disabled = 'disabled',
}

export interface BaseOption {
  // TODO change this to number
  value: string;
  content: string;
}

export interface BaseOptionCardProps {
  option: BaseOption;
  status: CardStatus;
  children: ReactNode;
}

const BorderColor = {
  [CardStatus.Selected]: '--md-sys-color-primary',
  [CardStatus.Unselected]: '--md-sys-color-outline-variant',
  [CardStatus.Correct]: '--md-sys-color-secondary',
  [CardStatus.Incorrect]: '--md-sys-color-error',
  [CardStatus.Disabled]: '--md-sys-color-outline-variant',
};

const BackgroundColor = {
  [CardStatus.Selected]: '--md-ref-neutral-99',
  [CardStatus.Unselected]: 'transparent',
  [CardStatus.Correct]: '--md-ref-secondary-99',
  [CardStatus.Incorrect]: '--md-ref-error-99',
  [CardStatus.Disabled]: '--md-sys-color-outline-variant',
};

export const BaseOptionCard: FC<BaseOptionCardProps> = ({
  option,
  status,
  children,
}) => {
  return (
    <CardContainer
      variant="outline"
      minHeight="68px"
      emotionCss={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 2px solid var(${BorderColor[status]});
        background: var(${BackgroundColor[status]});
        max-width: 475px;
      `}
    >
      <Spacing padding="md">
        {status === CardStatus.Correct || status === CardStatus.Incorrect ? (
          <StatusContainer>
            <Icon
              name={status === CardStatus.Correct ? 'check' : 'close'}
              size="normal"
              color={BorderColor[status]}
            />
            <Typography variant="textTitleSmall">{option.content}</Typography>
          </StatusContainer>
        ) : (
          children
        )}
      </Spacing>
    </CardContainer>
  );
};

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--rk-spacing-lg);
  padding: var(--rk-spacing-sm);
  margin-left: var(--rk-spacing-sm);
`;
