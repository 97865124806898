import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Typography } from '../../atoms/typography/typography';
import { CardContainer } from '../card/card-container';
import { Icon, Ripple } from '@taleemabad/dsm/components';

export interface ProfileProps {
  avatarSrc?: string;
  name: string;
  subhead: string;
  variant?: 'filled' | 'outline';
  noContainer?: boolean;
}

export const Profile = ({
  avatarSrc,
  name,
  subhead,
  variant = 'filled',
  noContainer = false,
}: ProfileProps) => {
  if (noContainer) {
    return (
      <ProfileContent avatarSrc={avatarSrc} name={name} subhead={subhead} />
    );
  }

  return (
    <CardContainer
      variant={variant}
      emotionCss={css`
        min-height: auto;
        border-radius: 40px;
      `}
    >
      <Ripple />
      <ProfileContent avatarSrc={avatarSrc} name={name} subhead={subhead} />
    </CardContainer>
  );
};

const ProfileContent = ({
  avatarSrc,
  name,
  subhead,
}: Omit<ProfileProps, 'variant'>) => {
  return (
    <StyledProfileContainer className="rk-profile">
      {avatarSrc !== undefined ? (
        <StyledImg src={avatarSrc} alt="avatar" />
      ) : (
        <Icon
          name="person"
          color="--md-sys-color-on-primary-fixed-variant"
          size="large"
        />
      )}
      <StyledInfo>
        <Typography variant="textTitleMediumPrimary">{name}</Typography>
        <Typography variant="textBodySmallOnSurface">{subhead}</Typography>
      </StyledInfo>
    </StyledProfileContainer>
  );
};

const StyledProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledImg = styled.img`
  width: 40px;
  object-fit: contain;
  border-radius: 50%;
`;

const StyledInfo = styled.div`
  padding-left: var(--rk-spacing-md);
  flex: 1;
`;
