// @ts-nocheck
import { PropsWithChildren } from 'react';

import '@material/web/button/filled-button.js';
import '@material/web/button/outlined-button.js';
import '@material/web/button/filled-tonal-button.js';
import '@material/web/button/elevated-button.js';
import '@material/web/button/text-button.js';
import { Icon } from '../icon/icon';

export interface ButtonProps extends PropsWithChildren {
  variant?: 'filled' | 'outlined' | 'filled-tonal' | 'elevated' | 'text';
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  icon?: string;
  trailingIcon?: boolean;
  onClick?: () => void;
  iconSize?: number;
  iconColor?: string;
  width?: string;
}

export const Button = ({
  children,
  variant = 'filled',
  disabled = false,
  type = 'button',
  icon,
  trailingIcon,
  onClick,
  iconSize = 'normal',
  iconColor = '--md-sys-color-on-primary',
  width = '',
  ...otherProps
}: ButtonProps) => {
  const props = {};
  if (disabled) {
    props.disabled = true;
  }
  if (onClick) {
    props.onClick = onClick;
  }
  if (icon) {
    props.hasIcon = true;
    if (trailingIcon) {
      props['trailing-icon'] = true;
    }
  }
  if (width) {
    props.style = { width };
  }

  switch (variant) {
    case 'filled':
      return (
        <md-filled-button {...otherProps} {...props}>
          {icon && (
            <Icon name={icon} slot="icon" size={iconSize} color={iconColor} />
          )}
          {children}
        </md-filled-button>
      );
    case 'outlined':
      return (
        <md-outlined-button {...otherProps} {...props}>
          {children}
        </md-outlined-button>
      );
    case 'filled-tonal':
      return (
        <md-filled-tonal-button {...otherProps} {...props}>
          {children}
        </md-filled-tonal-button>
      );
    case 'elevated':
      return (
        <md-elevated-button {...otherProps} {...props}>
          {children}
        </md-elevated-button>
      );
    case 'text':
      return (
        <md-text-button {...otherProps} {...props}>
          {children}
        </md-text-button>
      );
  }
};
