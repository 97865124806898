import {
  AppBar,
  Button,
  Dialog,
  LessonPlanPreparation,
  LoadingScreen,
  AlertDialog,
  Typography,
} from '@taleemabad/dsm/components';
import styled from '@emotion/styled';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';
import { useNavigate } from 'react-router-dom';
import {
  EventNames,
  FeatureFlagNames,
  ILessonPlanDetail,
  analyticsService,
  fetchSelectedLessonPlan,
  lessonPlanDB,
  syncStateManager,
  userDB,
} from '@taleemabad/db';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';

const StyledContent = styled.div`
  padding: var(--rk-spacing-xl);
  display: flex;
  flex-direction: column;
  ${mq(BreakpointSizes.xs)} {
    gap: 6px;
  }
`;
const StyledPopupErrorButton = styled.div<ButtonProps>`
  margin-top: ${(props) => props.margin}px;
  md-filled-button {
    width: 100%;
  }
  md-outlined-button {
    width: 100%;
  }
`;

interface ButtonProps {
  margin: number;
}
let FETCHED_LESSON = false;
export const LessonPlanPrepPage = () => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showErrorPopup, setErrorPopup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let { lessonPlanId, chapterId, lessonNumber } = useParams();
  const selectedLessonPlan = parseInt(lessonPlanId || '0');
  const selectedChapter = parseInt(chapterId || '0');
  const selectedLessonNumber = parseInt(lessonNumber || '0');

  const lessonDetails = useLiveQuery(() =>
    lessonPlanDB.getLessonPlanDetail(selectedLessonPlan),
  );
  const lessonInformation = useLiveQuery(() =>
    lessonPlanDB.getLessonPlanMetaData(selectedLessonPlan),
  );
  const selectedChapterDetail = useLiveQuery(() =>
    lessonPlanDB.getSelectedBookChapter(selectedChapter),
  );

  const timetableEnabled = useLiveQuery(() =>
    userDB.getFeatureFlag(FeatureFlagNames.timetableEnabled),
  );
  let mockLessonPlanDetail!: ILessonPlanDetail;
  const fetchLessonData = async () => {
    setIsLoading(true);
    if (!FETCHED_LESSON) {
      FETCHED_LESSON = true;
      try {
        const count =
          await lessonPlanDB.getLessonPlanDetailCount(selectedLessonPlan);
        if (count <= 0) {
          await fetchSelectedLessonPlan([selectedLessonPlan], null);
        }
      } catch (e: any) {
        analyticsService.trackEvent(EventNames.dataLoadingDataLoadingFailed, {
          ep_screenName: 'prepscreen',
        });
        if (syncStateManager.isConnected === false) {
          setShowPopup(true);
          analyticsService.trackEvent(EventNames.noInternetNoInternetPopup, {});
        } else {
          setErrorPopup(true);
        }
      } finally {
        setIsLoading(false);
      }
      FETCHED_LESSON = false;
    }
  };

  useEffect(() => {
    fetchLessonData();
  }, [lessonDetails]);
  const navigate = useNavigate();
  if (lessonDetails && lessonDetails?.length > 0) {
    mockLessonPlanDetail = {
      lessonPlanStatusClass: lessonInformation?.[0].lessonPlanStatusClass || [],
      topic: lessonInformation?.[0].topic || '',
      index: selectedLessonNumber + 1 || 0,
      lpSlo: lessonDetails?.[0]?.lpSlo || [],
      summary: lessonDetails?.[0].summary || [],
      resourcesNeeded: lessonDetails?.[0].resourcesNeeded || [],
      classroomSetupInstructions:
        lessonDetails?.[0].classroomSetupInstructions || [],
      homeworkInstructions: [],
      conclusionTime: 0,
      independentPracticeTime: 0,
      openingTime: 0,
      explainTime: 0,
      practiceTime: 0,
      conclusionSteps: [],
      videos: [],
      explainSteps: [],
      practiceSteps: [],
      openingSteps: [],
      independentPracticeSteps: [],
      id: 0,
      type: '',
      bookChapter: 0,
      schoolClass: 0,
      gradeSubject: 0,
      book: 0,
      lessonPlanStatus: '',
    };
  }
  return (
    <>
      <AppBar
        pageTitle="Back"
        leftIcon="arrow_back"
        onLeftIconClick={() => {
          navigate(-1);
        }}
        hasPadding={false}
        titleFullWidth={true}
        titleMedium={true}
        isTitleClickable={true}
      />
      <StyledContent>
        <AlertDialog
          title="No Internet Connection"
          description="Seems like your internet is not connected. Connect and try again."
          showPopup={showPopup}
          onClosePopup={() => setShowPopup(false)}
        />

        <Dialog
          open={showErrorPopup}
          title="Error Occurred"
          onClose={() => {
            setErrorPopup(false);
          }}
        >
          <Typography variant="textBodyMedium">
            Seems like you ran into some error.
          </Typography>
          <StyledPopupErrorButton margin={24}>
            <Button
              children="Retry"
              onClick={async () => {
                analyticsService.trackEvent(
                  EventNames.retryDataRetryDataClicked,
                  {
                    ep_screenName:
                      'lessons_chapterslist_lessonlist_prepscreen_lessonview',
                  },
                );
                setErrorPopup(false);
                fetchLessonData();
              }}
            />
          </StyledPopupErrorButton>
          <StyledPopupErrorButton margin={0}>
            <Button
              variant="outlined"
              children="Close"
              onClick={() => {
                setErrorPopup(false);
              }}
            />
          </StyledPopupErrorButton>
        </Dialog>
        {mockLessonPlanDetail && lessonDetails ? (
          <LessonPlanPreparation
            lpDetail={mockLessonPlanDetail}
            chapterIndex={selectedChapterDetail?.[0]?.chapterNumber || 1}
            startLessonPlan={async () => {
              const [subjectGrade] =
                await lessonPlanDB.getSchoolClassSubjectGrade();
              if (selectedChapterDetail && lessonInformation) {
                const { gradeSubject, book, title } = selectedChapterDetail[0];
                analyticsService.trackEvent(EventNames.lessonLessonStarted, {
                  epGradesubjectId: gradeSubject,
                  epBookId: book,
                  epChapterName: title,
                  epLpId: lessonDetails?.[0].id,
                  epLpType: lessonInformation?.[0].type,
                  epSource: subjectGrade ? 'myClasses' : 'listSelect',
                });
              }
              navigate(
                `/lessons/lesson-view/${selectedLessonPlan}/${selectedChapter}`,
              );
              if (subjectGrade) {
                if (timetableEnabled) {
                  lessonPlanDB.updateLessonPlanStatusClass(
                    selectedChapter,
                    selectedLessonPlan,
                    lessonInformation?.[0]?.schoolClass as number[],
                  );
                } else {
                  lessonPlanDB.updateLessonPlanStatus(
                    selectedChapter,
                    selectedLessonPlan,
                  );
                }
              }
            }}
          />
        ) : (
          isLoading && <LoadingScreen />
        )}
      </StyledContent>
    </>
  );
};
