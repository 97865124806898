import styled from '@emotion/styled';
import { Typography } from '../../atoms/typography/typography';
import { Card } from '../card/card';
import { Logo } from '../../atoms/logo/logo';
import { Spacing } from '../../atoms/spacing/spacing';
import { IGrades } from '@taleemabad/db';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';

export interface TimeTableGradeCardProps extends IGrades {
  icon?: string;
  height?: string;
  width?: string;
  onClick?: (id: number) => void;
}

const StyledCardContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--md-sys-color-light-surface);
`;
export const SquareCard = ({
  label,
  id,
  icon,
  height = '100px',
  width = '99px',
  onClick,
}: TimeTableGradeCardProps) => {
  return (
    <StyledCardContainer
      minHeight={height}
      width={width}
      variant="outline"
      onClick={() => {
        onClick?.(id);
      }}
    >
      {icon && (
        <Spacing marginBottom="xl" style={{ textAlign: 'center' }}>
          <Logo height={69} width={69} src={icon} altName="image" />
        </Spacing>
      )}
      <StyledText>
        <Typography variant={'textBodyLargeOnSurface'}>{label}</Typography>
      </StyledText>
    </StyledCardContainer>
  );
};
const StyledText = styled.div`
  text-align: center;
`;
export interface TimetableGradeListProps {
  items: TimeTableGradeCardProps[];
  height?: string;
  width?: string;
  onSelect: (id: number) => void;
}
export const StyledCardListContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--rk-spacing-xxxxl);
  margin-bottom: var(--rk-spacing-xxxxxxxl);
  ${mq(BreakpointSizes.xs)} {
    gap: var(--rk-spacing-xxl);
  }
`;
export const TimeTableCardList = ({
  items,
  height,
  width,
  onSelect,
}: TimetableGradeListProps) => {
  return (
    <StyledCardListContainer>
      {items.map((item) => (
        <SquareCard
          height={height}
          width={width}
          key={item.label}
          label={item.label}
          icon={item.icon}
          id={item.id}
          onClick={onSelect}
        />
      ))}
    </StyledCardListContainer>
  );
};
