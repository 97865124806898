import { PropsWithChildren, useEffect, useState, useContext } from 'react';
import styled from '@emotion/styled';
import {
  NavBar,
  NavBarItem,
  SidebarItem,
  SideNavBarItem,
} from '@taleemabad/dsm/components';
import { useNavigate } from 'react-router-dom';
import {
  EventNames,
  FeatureFlagNames,
  analyticsService,
  userDB,
} from '@taleemabad/db';
import { useWindowSize } from '@taleemabad/shared';
import { ConfigContext } from '../providers/config-provider';
import { useLiveQuery } from 'dexie-react-hooks';
import { Capacitor } from '@capacitor/core';
interface StyledDivProp {
  scroll?: boolean;
}

const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
`;

const StyledImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 42px 0px 32px 0px;
`;

const StyledContent = styled.div<StyledDivProp>`
  flex: 1;
  height: 100%;
  overflow-y: ${(props) => (props.scroll ? 'overlay' : 'unset')};
`;

const sideNavItems: SideNavBarItem[] = [
  {
    icon: 'school',
    label: 'Teacher Training',
    analyticsKey: 'teacherTraining',
    path: '/training',
    key: 0,
  },
  {
    icon: 'import_contacts',
    label: 'Lessons Plan',
    path: '/lessons',
    analyticsKey: 'lessonPlans',
    key: 1,
  },
  {
    icon: 'person',
    analyticsKey: 'profile',
    label: 'Profile',
    path: '/profile',
    key: 2,
  },
];
const profileSideNavItems: SideNavBarItem[] = [
  {
    icon: 'school',
    label: 'Home',
    path: '/Training',
    key: 3,
    analyticsKey: 'home',
  },
  {
    icon: 'import_contacts',
    label: 'Results',
    path: '/results',
    key: 4,
    analyticsKey: 'results',
  },
];

const navItems: NavBarItem[] = [
  {
    icon: 'school',
    label: 'Training',
    path: '/training',
    key: 0,
    analyticsKey: 'teacherTraining',
  },
  {
    icon: 'import_contacts',
    label: 'Lessons',
    path: '/lessons',
    key: 1,
    analyticsKey: 'lessonPlans',
  },
  {
    icon: 'download',
    label: 'Downloads',
    path: '/downloads',
    key: 2,
    analyticsKey: 'downloads',
  },
  {
    icon: 'assignment',
    label: 'Report Card',
    path: '/report-cards',
    key: 3,
    analyticsKey: 'reportCard',
  },
  {
    icon: 'person',
    label: 'Profile',
    path: '/profile',
    key: 4,
    analyticsKey: 'profile',
  },
];
const profileNavItems: NavBarItem[] = [
  {
    icon: 'school',
    label: 'Home',
    path: '/Training',
    key: 3,
    analyticsKey: 'home',
  },
  {
    icon: 'import_contacts',
    label: 'Results',
    path: '/results',
    key: 4,
    analyticsKey: 'results',
  },
];

interface PageLayoutProps extends PropsWithChildren {
  isNavBarVisible?: boolean;
  scroll?: boolean;
  isSidebarVisible?: boolean;
  isProfileScreen?: boolean;
}
const changeOrderSiderBar = (enabled: boolean, items: any[]) => {
  const lessonsPlanIndex = items.findIndex(
    (item) =>
      item.analyticsKey === 'lessonPlans' || item.analyticsKey === 'timetable',
  );
  if (enabled) {
    if (lessonsPlanIndex !== -1) {
      const lessonsPlanItem = items.splice(lessonsPlanIndex, 1)[0];
      items.unshift(lessonsPlanItem);
      items.forEach((item, index) => {
        item.key = index;
      });
    } else {
      if (lessonsPlanIndex !== -1 && lessonsPlanIndex !== 1) {
        const lessonsPlanItem = items.splice(lessonsPlanIndex, 1)[1];
        items.splice(1, 0, lessonsPlanItem);
      }
    }
    return items;
  }
  return items;
};

export function PageLayout({
  children,
  isNavBarVisible = true,
  isSidebarVisible = true,
  isProfileScreen = false,
  scroll = false,
}: PageLayoutProps) {
  const isTimetableEnabled =
    useLiveQuery(() =>
      userDB.getFeatureFlag(FeatureFlagNames.timetableEnabled),
    ) || false;
  const sideNavItemOrder = changeOrderSiderBar(
    isTimetableEnabled,
    sideNavItems,
  ) as SideNavBarItem[];
  const navItemsOrder = changeOrderSiderBar(
    isTimetableEnabled,
    navItems,
  ) as NavBarItem[];
  const [activeNav, setActiveNav] = useState(0);
  const [activeSideNav, setActiveSideNav] = useState(0);
  const [timetableEnabled, setTimetableEnabled] = useState(isTimetableEnabled);
  const navigate = useNavigate();
  const { appLogoPath, appLogoWidthNavbar } = useContext(ConfigContext);
  const [width] = useWindowSize();
  const downloadEnabled =
    useLiveQuery(() =>
      userDB.getFeatureFlag(FeatureFlagNames.downloadEnabled),
    ) || false;

  const reportCardEnabled =
    useLiveQuery(() =>
      userDB.getFeatureFlag(FeatureFlagNames.reportCardEnabled),
    ) || false;

  useEffect(() => {
    setTimetableEnabled(isTimetableEnabled);
  }, [isTimetableEnabled, sideNavItemOrder]);
  useEffect(() => {
    const path: string = window.location.pathname;
    const pathName = path.split('/')[1];
    if (!isTimetableEnabled && pathName === `create-class`) {
      navigate('/lessons');
    }

    if (isTimetableEnabled && pathName === `lessons`) {
      navigate('/timeTable/view-timetable');
    }
    if (sideNavItemOrder) {
      const indexSide: number = (
        isProfileScreen ? profileSideNavItems : sideNavItemOrder
      ).findIndex((item: SideNavBarItem) => item?.path.includes(pathName));

      if (indexSide !== activeSideNav) {
        setActiveSideNav(indexSide);
      }
    }
    if (navItemsOrder) {
      const index: number = (
        isProfileScreen ? profileNavItems : navItemsOrder
      ).findIndex((item: NavBarItem) => item?.path?.includes(pathName));

      if (index !== activeNav) {
        setActiveNav(index);
      }
    }
  }, [width, sideNavItemOrder]);

  interface VisibilityFlags {
    [key: string]: boolean | true;
    downloads: boolean;
    reportCard: boolean;
  }
  const visibilityFlags: VisibilityFlags = {
    downloads: downloadEnabled,
    reportCard: reportCardEnabled,
    // Add more keys of feature flag values as needed
  };

  return (
    <StyledContainer>
      {isSidebarVisible && (
        <SidebarItem // for webview
          items={
            !isProfileScreen
              ? sideNavItemOrder.filter((data) => {
                  if (
                    data.analyticsKey === 'lessonPlans' ||
                    data.analyticsKey === 'timetable'
                  ) {
                    if (isTimetableEnabled) {
                      data.label = 'Timetable';
                      data.path = '/timetable/view-timetable';
                      data.analyticsKey = 'timetable';
                    } else {
                      data.label = 'Lessons Plan';
                      data.path = '/lessons';
                      data.analyticsKey = 'lessonPlans';
                    }
                  }
                  return data;
                })
              : profileSideNavItems
          }
          activeItemKey={activeSideNav}
          onChange={(item) => {
            setActiveSideNav(item.key);
            analyticsService.trackEvent(EventNames.menuBarSelected, {
              selectedMenu: item.analyticsKey,
            });
            navigate(item.path || '');
          }}
        >
          <StyledImageWrapper>
            <img
              width={appLogoWidthNavbar}
              src={`../../assets/${appLogoPath}`}
            ></img>
          </StyledImageWrapper>
        </SidebarItem>
      )}
      <StyledContent scroll={scroll}>
        {/*<Header />*/}
        {children}
      </StyledContent>
      {isNavBarVisible && (
        <NavBar // for mobile view
          items={
            !isProfileScreen
              ? navItemsOrder.filter((data) => {
                  if (
                    data.analyticsKey === 'lessonPlans' ||
                    data.analyticsKey === 'timetable'
                  )
                    if (isTimetableEnabled) {
                      data.label = 'Timetable';
                      data.path = '/timetable/view-timetable';
                      data.analyticsKey = 'timetable';
                    } else {
                      data.label = 'Lessons Plan';
                      data.path = '/lessons';
                      data.analyticsKey = 'lessonPlans';
                    }
                  data.visible = visibilityFlags[data.analyticsKey] ?? true;
                  return data;
                })
              : profileNavItems
          }
          activeItemKey={activeNav}
          onChange={(item) => {
            setActiveNav(item.key);
            analyticsService.trackEvent(EventNames.menuBarSelected, {
              selectedMenu: item.analyticsKey,
            });
            navigate(item?.path || '');
          }}
          fixed={true}
        />
      )}
    </StyledContainer>
  );
}
