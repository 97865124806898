// @ts-nocheck
import { PropsWithChildren } from 'react';

import '@material/web/iconbutton/icon-button.js';
import '@material/web/iconbutton/filled-icon-button.js';
import '@material/web/iconbutton/filled-tonal-icon-button.js';
import '@material/web/iconbutton/outlined-icon-button.js';
import { Icon, iconSizes } from '../icon/icon';
import { SerializedStyles } from '@emotion/react';

export interface IconButtonProps extends PropsWithChildren {
  variant?: 'filled' | 'outlined' | 'filled-tonal';
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  icon: string;
  toggledIcon?: string;
  selected?: boolean;
  iconEmotionCss?: SerializedStyles;
  slot?: string;
  toggle?: boolean;
  color?: string;
  onClick?: () => void;
  iconSize?: iconSizes;
}

export const IconButton = ({
  variant,
  disabled = false,
  type = 'button',
  icon,
  toggledIcon,
  selected,
  iconEmotionCss,
  slot,
  toggle,
  color,
  onClick,
  iconSize = 'normal',
  ...otherProps
}: IconButtonProps) => {
  const props = {};
  if (disabled) {
    props.disabled = true;
  }
  if (onClick) {
    props.onClick = onClick;
  }
  if (toggledIcon) {
    props.toggle = true;
  }
  if (selected) {
    props.selected = selected;
  }
  if (slot) {
    props.slot = slot;
  }
  if (toggle) {
    props.toggle = toggle;
  }
  switch (variant) {
    case 'filled':
      return (
        <md-filled-icon-button {...otherProps} {...props}>
          <Icon name={icon} />
          {toggledIcon && (
            <Icon
              name={toggledIcon}
              slot="selected"
              emotionCss={iconEmotionCss}
            />
          )}
        </md-filled-icon-button>
      );
    case 'outlined':
      return (
        <md-outlined-icon-button {...otherProps} {...props}>
          <Icon name={icon} />
          {toggledIcon && (
            <Icon
              name={toggledIcon}
              slot="selected"
              emotionCss={iconEmotionCss}
            />
          )}
        </md-outlined-icon-button>
      );
    case 'filled-tonal':
      return (
        <md-filled-tonal-icon-button {...otherProps} {...props}>
          <Icon name={icon} />
          {toggledIcon && (
            <Icon
              name={toggledIcon}
              slot="selected"
              emotionCss={iconEmotionCss}
            />
          )}
        </md-filled-tonal-icon-button>
      );
    default:
      return (
        <md-icon-button {...otherProps} {...props}>
          <Icon
            color={color}
            name={icon}
            size={iconSize ? iconSize : window.screen.width > 575 ? 32 : 24}
          />
          {toggledIcon && (
            <Icon
              color={color}
              name={toggledIcon}
              slot="selected"
              emotionCss={iconEmotionCss}
            />
          )}
        </md-icon-button>
      );
  }
};
