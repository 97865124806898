import { useEffect, useState } from 'react';
import {
  Breakpoints,
  BreakpointSizes,
  ScreenMap,
} from '@taleemabad/dsm/themes';
import { tlLogger } from '@taleemabad/shared';

export function useBreakpoint(): ScreenMap {
  const [windowBreakpointSize, setWindowBreakpointSize] = useState<ScreenMap>(
    () => {
      return getScreenMap(window.innerWidth)!;
    }
  );

  useEffect(() => {
    const addEvent = () => {
      window.addEventListener('resize', onResize);
    };

    addEvent();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [windowBreakpointSize]);

  const onResize = (e: Event) => {
    if (!e || !e.target) {
      return;
    }

    const width = (e.target as Window).innerWidth;

    const currentBreakpoint = getScreenMap(width);

    if (
      currentBreakpoint &&
      JSON.stringify(windowBreakpointSize) !== JSON.stringify(currentBreakpoint)
    ) {
      setWindowBreakpointSize(currentBreakpoint);
    }
  };

  return windowBreakpointSize;
}

function getScreenMap(width: number): ScreenMap | undefined {
  const method = 'getScreenMap';

  try {
    const screenMap: ScreenMap = Object.entries(Breakpoints)
      .reverse()
      .reduce((pV, cV) => {
        const [bp, size] = cV as [BreakpointSizes, { minWidth?: number; maxWidth?: number }];

        if (
          size.minWidth &&
          size.maxWidth &&
          width >= size.minWidth &&
          width <= size.maxWidth
        ) {
          pV[bp as BreakpointSizes] = true;
        }

        if (!size.minWidth && size.maxWidth && width <= size.maxWidth) {
          pV[bp as BreakpointSizes] = true;
        }

        if (!size.maxWidth && size.minWidth && width >= size.minWidth) {
          pV[bp as BreakpointSizes] = true;
        }

        return pV;
      }, {} as Record<BreakpointSizes, boolean>);

    return screenMap;
  } catch (err) {
    tlLogger.error(method, { err });
  }
}
