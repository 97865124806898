import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '../../atoms/typography/typography';
import { ILessonPlanStep } from '@taleemabad/db';
import { isUrduText } from '@taleemabad/dsm/utils';

export enum LpStatementTypes {
  ask = 'ask',
  ask_urdu = 'پوچھیں',
  say = 'say',
  say_urdu = 'کہیں',
  instruction = 'instruction',
  instruction_urdu = 'ہدایت',
  ans = 'ans',
  ans_urdu = 'جواب',
}

const StatementStyledWrapper = styled.div<{ isUrdu: boolean }>`
  direction: ${(props) => (props.isUrdu ? 'rtl' : 'ltr')};
`;

const StatementTypeContainer = styled.div<{ isUrdu: boolean }>`
  display: inline;
  text-transform: capitalize;
  margin-right: ${(props) => (props.isUrdu ? 'unset' : 'var(--rk-spacing-sm)')};
  margin-left: ${(props) => (props.isUrdu ? 'var(--rk-spacing-md)' : 'unset')};
`;

const getTypographyVariant = (type: string) => {
  const typeLowerCase = type.toLowerCase();
  switch (typeLowerCase) {
    case LpStatementTypes.ask:
    case LpStatementTypes.ask_urdu:
      return 'textBodyLargeNeutral40';
    case LpStatementTypes.say:
    case LpStatementTypes.say_urdu:
      return 'textBodyLargeError50';
    case LpStatementTypes.instruction:
    case LpStatementTypes.instruction_urdu:
      return 'textBodyLargeError60';
    case LpStatementTypes.ans:
    case LpStatementTypes.ans_urdu:
      return 'textBodyLargeSecondary';
    default:
      return 'textBodyLargeNeutral50';
  }
};
const displayUrduType = (type: string) => {
  switch (type.toLowerCase()) {
    case LpStatementTypes.ask:
      return LpStatementTypes.ask_urdu;
    case LpStatementTypes.say:
      return LpStatementTypes.say_urdu;
    case LpStatementTypes.instruction:
      return LpStatementTypes.instruction_urdu;
    case LpStatementTypes.ans:
      return LpStatementTypes.ans_urdu;
    default:
      return type;
  }
};

const displayEnglishType = (type: string) => {
  switch (type.toLowerCase()) {
    case LpStatementTypes.ask_urdu:
      return LpStatementTypes.ask;
    case LpStatementTypes.say_urdu:
      return LpStatementTypes.say;
    case LpStatementTypes.instruction_urdu:
      return LpStatementTypes.instruction;
    case LpStatementTypes.ans_urdu:
      return LpStatementTypes.ans;
    default:
      return type;
  }
};

export const LpStatement: React.FC<ILessonPlanStep> = ({ statement, type }) => {
  const isUrdu = isUrduText(statement);
  const typographyVariant = getTypographyVariant(type);
  const displayedType = isUrdu
    ? displayUrduType(type)
    : displayEnglishType(type);
  return (
    <StatementStyledWrapper isUrdu={isUrdu}>
      <StatementTypeContainer isUrdu={isUrdu}>
        <Typography variant={typographyVariant} inline={true}>
          {`${displayedType}:`}
        </Typography>
      </StatementTypeContainer>
      <Typography variant="textBodyLargeNeutral40" inline={true}>
        {statement}
      </Typography>
    </StatementStyledWrapper>
  );
};
