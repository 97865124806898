// @ts-nocheck
import { PropsWithChildren } from 'react';

import '@material/web/slider/slider.js';

export interface SliderProps extends PropsWithChildren {
  disabled?: boolean;
  min?: number;
  max?: number;
  value?: number;
  valueStart?: number;
  valueEnd?: number;
  step?: number;
  ticks?: boolean;
  labeled?: boolean;
  range?: boolean;
}

export const Slider = ({
  disabled = false,
  min = 0,
  max = 100,
  value = undefined,
  valueStart = undefined,
  valueEnd = undefined,
  step = 1,
  ticks = false,
  labeled = false,
  range = false,
}: SliderProps) => {
  const props = {};
  if (disabled) {
    props.disabled = true;
  }
  if (step) {
    props.step = step;
  }
  if (ticks) {
    props.ticks = ticks;
  }
  if (labeled) {
    props.labeled = labeled;
  }

  if (range) {
    props.range = true;
    props.valueStart = valueStart || 0;
    props.valueEnd = valueEnd || 100;
  } else {
    props.value = value;
    props.min = min;
    props.max = max;
  }

  return <md-slider {...props}></md-slider>;
};
