import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  TBProgressBar,
  HorizontalCard,
  IconText,
} from '@taleemabad/dsm/components';

export interface TBHorizontalCardProps {
  title: string;
  progress: number; // 1 to 100
  duration: number; // in milliseconds
  imageSrc: string;
  maxWidth?: string;
  icon?: string;
  onClick?: () => void;
}

export const TBHorizontalCard = ({
  title,
  progress,
  duration,
  imageSrc,
  maxWidth = '328px',
  onClick = undefined,
  icon,
}: TBHorizontalCardProps) => {
  return (
    <HorizontalCard
      variant="outline"
      title={title}
      thumbnailSrc={imageSrc}
      onCardClick={onClick}
      className="tb-horizontal-card"
      icon={icon}
      emotionCss={css`
        max-width: ${maxWidth};
      `}
      postTitle={
        <StyledPostTitleContainer>
          <IconText icon="play_circle" text="15 mins" />
          <TBProgressBar
            progress={progress}
            emotionCss={css`
              margin-bottom: var(--rk-spacing-lg);
            `}
          />
        </StyledPostTitleContainer>
      }
    />
  );
};

const StyledPostTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
`;
