import { AuthCardContainer } from '../components/auth-card';
import styled from '@emotion/styled';
import { Button, Logo, Typography, IconText } from '@taleemabad/dsm/components';
import { useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useBreakpoint } from '@taleemabad/shared/hooks';
import { useNavigate } from 'react-router-dom';
import { EventNames, analyticsService, userDetails } from '@taleemabad/db';
import { ConfigContext } from 'apps/school-app/src/providers/config-provider';

const StyledProfileCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
  gaps: 20px;
  margin-top: 20px;
  > div {
    flex: 1; /* Set initial flex to allow for 2 items in a row */
    min-width: calc(
      50% - 10px
    ); /* Calculate minimum width for 2 items with gap */
  }

  > div:last-child() {
    flex: 2; /* Set the last item to take full width */
    min-width: 100%; /* Set the minimum width to 100% for the last item */
  }
  margin-bottom: 24px;
`;
// TODO duplicated on user-profile clean up
const Names: any = {
  name: 'Name',
  phone: 'Phone number',
  cnic: 'CNIC',
  role: 'Designation',
  joining_date: 'Date joined',
  years_of_service: 'Years of Service',
  school: 'School',
};
const Icons: any = {
  name: 'person',
  phone: 'phone',
  cnic: 'portrait',
  role: 'card_travel',
  joining_date: 'calendar_today',
  years_of_service: 'calendar_month',
  school: 'business_center',
};

const StyledIconItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;
const StyledNoticeText = styled.div`
  margin-bottom: 42px;
`;
interface ProfileDataItem {
  name: string;
  icon: string;
  value: any;
}
export const ProfileDetailsPage = () => {
  const navigate = useNavigate();
  const [dataArray, setDataArray] = useState<ProfileDataItem[]>([]);
  const { tablet, mobileHorizontal, mobile } = useBreakpoint();
  const isLowResDevice = tablet || mobileHorizontal || mobile;
  const { appLogoPath } = useContext(ConfigContext);

  useEffect(() => {
    (async () => {
      const data = await userDetails();
      if (data) {
        const teacherProfile = data?.profiles[0];
        const initialArray: ProfileDataItem[] = [
          { name: 'Name', icon: 'person', value: data.name },
          { name: 'Phone', icon: 'phone', value: data.username },
        ];
        for (const key in Names) {
          if (
            Names.hasOwnProperty(key) &&
            Icons.hasOwnProperty(key) &&
            teacherProfile.hasOwnProperty(key)
          ) {
            initialArray.push({
              name: Names[key],
              icon: Icons[key],
              value: teacherProfile[key],
            });
          }
        }
        setDataArray(initialArray);
      }
    })();
  }, []);
  const StyledImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 68px;
  `;
  const StyledIconText = styled.div`
    margin-top: 10px;
  `;

  const handleNavigate = () => {
    analyticsService.trackEvent(EventNames.signupUserDetails);
    analyticsService.trackEvent(EventNames.signupCompleted);
    navigate('/training');
  };

  return (
    <AuthCardContainer>
      {isLowResDevice && (
        <StyledImageWrapper>
          <Logo
            altName="outer-layer"
            width={275}
            src={`../../assets/${appLogoPath}`}
          />
        </StyledImageWrapper>
      )}
      <Typography variant="textHeadlineLargeSurface">Welcome! </Typography>
      <Typography variant="textBodyLargeSurface">
        Confirm your profile details.
      </Typography>
      <StyledProfileCardWrapper>
        {dataArray.map((item, index) => (
          <StyledIconItem key={index}>
            <IconText
              icon={item.icon}
              text={item.name}
              textVariant="textBodyMediumOnSurfaceVariant"
              iconColor="--md-sys-color-on-surface-variant"
            />
            <StyledIconText>
              <Typography variant="textBodyLargeSurface">
                {item.value}
              </Typography>
            </StyledIconText>
          </StyledIconItem>
        ))}
      </StyledProfileCardWrapper>
      <StyledNoticeText>
        <IconText
          imgStyle={css`
            opacity: 0.7;
          `}
          text="To update your profile details contact your NIETE agent"
          textVariant="textBodyLargePrimary40"
          icon="error"
          iconColor="--md-ref-primary-40"
        />
      </StyledNoticeText>
      <Button children="Done" onClick={handleNavigate}></Button>
    </AuthCardContainer>
  );
};
