// @ts-nocheck
import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import '@material/web/chips/filter-chip.js';

import { ChipSet } from './chip-set';
import { Icon } from '../icon/icon';

export interface ChipFilterProps extends PropsWithChildren {
  elevated?: boolean;
  disabled?: boolean;
  removable?: boolean;
  defaultSelected?: boolean;
  alwaysFocusable?: boolean;
  label: string;
  icon?: string;
  chipFilterSelectedIconSize?: string;
  variant?: 'blue';
  selected?: boolean;
  onClick?: (isSelected: boolean) => void;
  onRemove?: () => void;
}

export const ChipFilter = ({
  elevated = false,
  alwaysFocusable = false,
  label,
  disabled = false,
  removable = false,
  defaultSelected = false,
  icon,
  // there is a 'tick' icon displayed when the chip is selected, set to 0 to hide it
  chipFilterSelectedIconSize = '0',
  variant,
  selected,
  onClick,
  onRemove,
}: ChipFilterProps) => {
  const [isSelected, setIsSelected] = useState(defaultSelected);
  const chipRef = useRef(null);

  const props = {};
  if (elevated) {
    props.elevated = true;
  }
  if (alwaysFocusable) {
    props['always-focusable'] = alwaysFocusable;
  }
  if (label) {
    props.label = label;
  }
  if (disabled) {
    props.disabled = disabled;
  }
  if (removable) {
    props.removable = removable;
  }
  if (defaultSelected) {
    props.selected = defaultSelected;
  }

  if (selected) {
    props.selected = selected;
  }
  if (onClick) {
    props.onClick = () => {
      setIsSelected(!isSelected);
      onClick(!isSelected);
    };
  }

  useEffect(() => {
    let currentRef = null;
    (() => {
      if (chipRef) {
        currentRef = chipRef;
      }
      if (onRemove) {
        setTimeout(() => {
          currentRef?.current?.trailingAction?.addEventListener(
            'click',
            onRemove,
          );
        }, 500);
      }
    })();

    return () => {
      currentRef?.trailingAction?.removeEventListener('click', onRemove);
    };
  }, [chipRef, onRemove]);

  const getStyles = () => {
    switch (variant) {
      case 'blue':
        return chipStylesBlue;
      default:
        return {};
    }
  };

  const chipStylesBlue = {
    // label text colors
    '--md-filter-chip-label-text-color': 'var(--md-sys-color-on-primary-fixed)',
    '--md-filter-chip-selected-label-text-color':
      'var(--md-sys-color-on-primary)',
    '--md-filter-chip-focus-label-text-color':
      'var(--md-sys-color-on-primary-fixed)',
    '--md-filter-chip-hover-label-text-color':
      'var(--md-sys-color-on-primary-fixed)',
    '--md-filter-chip-pressed-label-text-color':
      'var(--md-sys-color-on-primary-fixed)',
    '--md-filter-chip-selected-hover-label-text-color':
      'var(--md-sys-color-on-primary)',
    '--md-filter-chip-selected-pressed-label-text-color':
      'var(--md-sys-color-on-primary)',

    // outline and container colors
    '--md-filter-chip-outline-color': 'var(--md-sys-color-on-primary-fixed)',
    '--md-filter-chip-selected-container-color': 'var(--md-sys-color-primary)',
    '--md-filter-chip-pressed-state-layer-color': 'var(--md-sys-color-primary)',

    // icon size for when chip is selected
    '--md-filter-chip-icon-size': chipFilterSelectedIconSize,
  };

  return (
    <md-filter-chip {...props} ref={chipRef} style={getStyles()}>
      {icon && <Icon name={icon} slot="icon" />}
    </md-filter-chip>
  );
};

export const ChipFilterWithContainer = (props: ChipFilterProps) => {
  return (
    <ChipSet>
      <ChipFilter {...props} />
    </ChipSet>
  );
};
