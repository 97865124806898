import { Capacitor } from '@capacitor/core';
import { TeacherProfile, analyticsDB, userCache } from '..';
type EventProperties = Record<string, number | string | boolean | number[]>;
import UAParser from 'ua-parser-js';
import { Device } from '@capacitor/device';

export interface AnalyticsEvent {
  name: string;
  sentAt: Date;
  properties: EventProperties;
}

export enum EventNames {
  signupStarted = 'signupStarted',
  signupLogin = 'signupLogin',
  signupPhoneNumberEntered = 'signupPhoneNumberEntered',
  signupLoginError = 'signupLoginError',
  signupPasswordError = 'signupPasswordError',
  signupPasswordComplete = 'signupPasswordComplete',
  signupUserDetails = 'signupUserDetails',
  signupCompleted = 'signupCompleted',
  profileScreenLogout = 'profileScreenLogout',
  systemBackendError = 'systemBackendError',
  menuBarSelected = 'menuBarSelected',
  trainingTrainingSelected = 'trainingTrainingSelected',
  trainingQuizStarted = 'trainingQuizStarted',
  trainingQuizIncomplete = 'trainingQuizIncomplete',
  trainingQuizComplete = 'trainingQuizComplete',
  trainingRetakePopup = 'trainingRetakePopup',
  trainingCourseSelected = 'trainingCourseSelected',
  lessonLessonStarted = 'lessonLessonStarted',
  lessonLessonCompleted = 'lessonLessonCompleted',
  lessonBackClicked = 'lessonBackClicked',
  lessonLessonSection = 'lessonLessonSection',
  trainingProgressBanner = 'trainingProgressBanner',
  refreshDataRefreshDataClicked = 'refreshDataRefreshDataClicked',
  dataLoadingDataLoadingFailed = 'dataLoadingDataLoadingFailed',
  retryDataRetryDataClicked = 'retryDataRetryDataClicked',
  syncDataSyncDataFailedError = 'syncDataSyncDataFailedError',
  resetPasswordForgetPasswordClicked = 'resetPasswordForgetPasswordClicked',
  resetPasswordOTPVerified = 'resetPasswordOTPVerified',
  resetPasswordResendOTPClicked = 'resetPasswordResendOTPClicked',
  resetPasswordChoosePasswordNextClicked = 'resetPasswordChoosePasswordNextClicked',
  resetPasswordAccountVerificationNextClicked = 'resetPasswordAccountVerificationNextClicked',
  noInternetNoInternetPopup = 'noInternetNoInternetPopup',
  recordsFailedToSync = 'recordsFailedToSync',
  chatWidgetClicked = 'chatWidgetClicked',
  lessonTranslateClicked = 'lessonTranslateClicked',
  lessonTranslateError = 'lessonTranslateError',
  userProfileDelete = 'userProfileDelete',
  dashboardAddClassClicked = 'dashboardAddClassClicked',
  dashboardGradeSelected = 'dashboardGradeSelected',
  dashboardSectionSelected = 'dashboardSectionSelected',
  dashboardShiftSelected = 'dashboardShiftSelected',
  dashboardSubjectSelected = 'dashboardSubjectSelected',
  dashboardDaySelected = 'dashboardDaySelected',
  dashboardClassSelected = 'dashboardClassSelected',
  trainingDownloadClicked = 'trainingDownloadClicked',
  trainingDownloadStarted = 'trainingDownloadStarted',
  trainingDownloadVideoPlayed = 'trainingDownloadVideoPlayed',
  trainingDownloadDelete = 'trainingDownloadDelete',
  trainingDownloadDeleteAll = 'trainingDownloadDeleteAll',
}

class AnalyticsService {
  async trackEvent(name: EventNames, properties: EventProperties = {}) {
    try {
      const parser = new UAParser();
      const device = parser.getDevice();
      const browser = parser.getBrowser();
      const os = parser.getOS();
      const info = await Device.getInfo();
      const isNative = Capacitor.isNativePlatform();
      //Todo implement better approach for desktop detection. currently using code snippet from: https://github.com/faisalman/ua-parser-js/issues/182#issuecomment-1708138242
      const isDesktop =
        device.type === undefined ||
        !['wearable', 'mobile'].includes(device.type);
      await analyticsDB.trackEvent({
        name,
        sentAt: new Date(),
        properties: {
          isNativeApp: isNative,
          // TODO configure BUILD_VERSION using Realtime updater.
          buildVersion: '1.0.0',
          deviceId: userCache.getDeviceId(),
          currentUrl: window.location.href,
          browser: browser.name!,
          browserVersion: browser.version!,
          os: isNative ? info.operatingSystem : os.name!,
          osVersion: isNative ? info.osVersion : os.version!,
          device: Capacitor.getPlatform(),
          deviceType: isDesktop ? 'desktop' : device.type!, //todo check device.type works on older phones or not.
          deviceName: info.name || 'N/A',
          screenHeight: window.screen.height,
          screenWidth: window.screen.width,
          viewportHeight: window.innerHeight,
          viewportWidth: window.innerWidth,
          ...properties,
        },
      });
    } catch (error) {
      console.error('Unable to add event to analyticsDB', error);
    }
  }
}

export const analyticsService = new AnalyticsService();
