export interface ILogger {
  start: (name: string, params?: Record<string, unknown>) => void;
  end: (name: string, params?: Record<string, unknown>) => void;
  error: (name: string, params?: Record<string, unknown>) => void;
  verbose: (name: string, params?: Record<string, unknown>) => void;
}

const VERBOSE = false;

// TODO: log based on environment
export class Logger implements ILogger {
  start(name: string, params?: Record<string, unknown>): void {
    console.log(`${name}:START${params ? ` – ${JSON.stringify(params)}` : ''}`);
  }

  end(name: string, params?: Record<string, unknown>): void {
    console.log(`${name}:END${params ? ` – ${JSON.stringify(params)}` : ''}`);
  }

  log(name: string, params?: Record<string, unknown>): void {
    console.log(`${name}:LOG${params ? ` – ${JSON.stringify(params)}` : ''}`);
  }

  error(name: string, params?: Record<string, unknown>): void {
    console.log(`${name}:ERROR${params ? ` – ${JSON.stringify(params)}` : ''}`);
  }

  verbose(name: string, params?: Record<string, unknown>): void {
    if (VERBOSE) {
      console.log(
        `${name}:VERBOSE${params ? ` – ${JSON.stringify(params)}` : ''}`,
      );
    }
  }
}

export const tlLogger = new Logger();
