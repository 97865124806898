import { useEffect, useState } from 'react';
import { SyncManagerStatus, syncStateManager } from '@taleemabad/db';

export const useSyncManager = () => {
  const [syncStatus, setSyncStatus] = useState<SyncManagerStatus>(
    syncStateManager.syncStatus,
  );

  useEffect(() => {
    const handleSyncStatusChange = (status: SyncManagerStatus) => {
      setSyncStatus(status);
    };

    syncStateManager.subscribe(handleSyncStatusChange);
    return () => {
      syncStateManager.unsubscribe(handleSyncStatusChange);
    };
  }, []);

  return syncStatus;
};
