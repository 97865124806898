import { Button } from '../../atoms/button/button';
import { Spacing } from '../../atoms/spacing/spacing';
import { Icon } from '../../atoms/icon/icon';
import styled from '@emotion/styled';

const StyledAddClassScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
`;

export interface AddClassScreenProps {
  onAddClass: () => void;
}
export const AddClassScreen = ({ onAddClass }: AddClassScreenProps) => {
  return (
    <StyledAddClassScreen>
      <Spacing marginBottom="xl">
        <Icon
          onClick={() => {
            onAddClass();
          }}
          size="largest"
          color="--md-sys-color-on-primary"
          name="add"
        ></Icon>
      </Spacing>
      <Button
        icon="add"
        iconSize={18}
        iconColor="--md-sys-color-on-primary"
        onClick={() => {
          onAddClass();
        }}
        type="button"
        children="Add Class"
      ></Button>
    </StyledAddClassScreen>
  );
};
