import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import {
  Icon,
  iconSizes,
  ProgressWithLabel,
  Ripple,
  Spacing,
} from '@taleemabad/dsm/components';
import { CardTitle } from './card-title';
import { CardSubhead } from './card-subhead';
import { CardMedia } from './card-media';
import { SerializedStyles } from '@emotion/react/dist/emotion-react.cjs';
import { css } from '@emotion/react';

interface StyledFilledContainerProps {
  disabled?: boolean;
  pointer?: boolean;
  maxWidth?: number;
}

const StyledFilledContainer = styled.div<StyledFilledContainerProps>`
  display: flex;
  width: 100%;
  height: 92px;
  position: relative;
  background-color: ${(props) =>
    props.disabled
      ? 'var(--md-sys-color-surface-variant)'
      : 'var(--md-sys-color-surface-container-highest)'};
  border-radius: var(--md-sys-shape-corner-lg);
  box-shadow: var(--md-sys-color-shadow);
  opacity: ${(props) => (props.disabled ? 0.38 : 1)};
  overflow: hidden;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : 'auto')};
  ${(props) => props.pointer && { cursor: 'pointer' }}
`;

interface StyledOutlinedContainerProps {
  disabled?: boolean;
  pointer?: boolean;
  maxWidth?: number;
}

const StyledOutlineContainer = styled.div<StyledOutlinedContainerProps>`
  display: flex;
  width: 100%;
  min-height: 92px;
  position: relative;
  background-color: var(--md-sys-color-surface);
  border-radius: var(--md-sys-shape-corner-lg);
  box-shadow: var(--md-sys-color-shadow);
  opacity: ${(props) => (props.disabled ? 0.12 : 1)};
  overflow: hidden;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : 'auto')};
  ${(props) => props.pointer && { cursor: 'pointer' }}
  border: 1px solid var(
    ${(props) =>
    props.disabled
      ? '--md-sys-color-outline'
      : '--md-sys-color-outline-variant'}
  );
`;

export interface HorizontalCardProps extends PropsWithChildren {
  variant: 'filled' | 'outline' | 'elevated';
  disabled?: boolean;
  preTitle?: ReactElement;
  title?: string;
  postTitle?: ReactElement;
  subheading?: string;
  thumbnailSrc?: string;
  icon?: string;
  onCardClick?: () => void;
  onIconClick?: (event: React.MouseEvent<HTMLElement>) => void;
  emotionCss?: SerializedStyles;
  className?: string;
  maxWidth?: number;
  iconSize?: iconSizes;
  titleContentCss?: SerializedStyles;
  tailComponent?: ReactNode;
  downloadProgress?: number;
  downloadUIState?: boolean;
}

export const HorizontalCard = ({
  variant,
  disabled = false,
  preTitle,
  title,
  postTitle,
  subheading,
  thumbnailSrc,
  icon,
  onCardClick,
  onIconClick,
  emotionCss,
  className,
  maxWidth,
  iconSize,
  titleContentCss,
  tailComponent = null,
  children,
  downloadProgress,
  downloadUIState,
}: HorizontalCardProps) => {
  const Container =
    variant === 'filled' ? StyledFilledContainer : StyledOutlineContainer;
  const isProgressVisible =
    downloadUIState && downloadProgress !== undefined && downloadProgress < 100;
  return (
    <Container
      disabled={disabled}
      css={emotionCss}
      pointer={!!onCardClick}
      className={`rk-card rk-horizontal-card${
        className ? ` ${className}` : ''
      }`}
      maxWidth={maxWidth}
      onClick={onCardClick}
    >
      <Ripple />
      {thumbnailSrc && (
        <CardMedia
          src={thumbnailSrc}
          emotionCss={css`
            width: 80px;
          `}
        />
      )}
      <StyledCardContent css={titleContentCss}>
        {preTitle && preTitle}
        {title && <CardTitle title={title} />}
        <Spacing marginTop="sm">{postTitle}</Spacing>
        {subheading && <CardSubhead subheading={subheading} />}
        {children}
      </StyledCardContent>
      {isProgressVisible && (
        <ProgressWithLabel
          value={downloadProgress}
          text={`${downloadProgress}%`}
          size="sm"
        />
      )}
      {icon && !isProgressVisible && (
        <StyledIconContainer>
          <Icon
            name={icon}
            emotionCss={css`
              color: var(--md-sys-color-primary);
            `}
            size={iconSize}
            // we don't want the card click event to be triggered when the icon is clicked
            onControlledClick={(event) => {
              event.stopPropagation();
              if (onIconClick) {
                onIconClick(event);
              }
            }}
          />
        </StyledIconContainer>
      )}
      {tailComponent && (
        <StyledTailContainer>{tailComponent}</StyledTailContainer>
      )}
    </Container>
  );
};

const StyledCardContent = styled.div`
  padding: var(--rk-horizontal-card-padding);
  flex: 1;
`;

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 var(--rk-spacing-xl);
`;

const StyledTailContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 var(--rk-spacing-xl);
`;
