import {
  UserToken,
  UserProfile,
  GenericProfile,
  userCache,
  downloadManagerService,
} from '..';
import { posthogIdentify } from '../utils/posthog';
import { BaseModel } from './base-model';
import { Table } from 'dexie';
import posthog from 'posthog-js';
const SCHEMA_VERSION = 1;
const SCHEMA = {
  token: 'id',
  user: 'recordId',
};

const TOKEN_RECORD_ID = 10;
const USER_RECORD_ID = 11;
export enum FeatureFlagNames {
  timetableEnabled = 'timetableEnabled',
  intercomEnabled = 'intercomEnabled',
  translationEnabled = 'translationEnabled',
  downloadEnabled = 'downloadEnabled',
  reportCardEnabled = 'reportCardEnabled',
}
/**
 * This table stores the user and
 * its related profiles and config in this db.
 */
export class UserDB extends BaseModel {
  token!: Table<UserToken>;
  user!: Table<UserProfile>;

  constructor() {
    super('userdb');
    this.version(SCHEMA_VERSION).stores(SCHEMA);
    this.init();
  }

  async init() {
    const profile = await this.getUserProfileFromDB();
    const token = await this.getTokenFromDB();
    if (token) {
      userCache.setTokenToCache(token);
    }
    if (profile) {
      userCache.setUserToCache(profile);
      posthogIdentify();
    }
  }

  async updateRecord(data: UserProfile) {
    return this.user.where('recordId').equals(USER_RECORD_ID).modify(data);
  }

  async setUserProfile(profile: UserProfile) {
    let result = null;
    // TODO have a preferred profile option in the future
    for (const p of profile.profiles) {
      p.isSelected = false;
    }
    profile.profiles[0].isSelected = true;

    const record = await this.user.count();
    if (!record) {
      result = await this.user.add({ ...profile, recordId: USER_RECORD_ID });
    } else {
      result = await this.updateRecord(profile);
    }
    userCache.setUserToCache(profile);
    posthogIdentify();
    return result;
  }

  async setUserActiveProfile(profileId: number): Promise<boolean> {
    const user = await this.getUserProfileFromDB();
    if (user?.profiles) {
      user.profiles.forEach((profile) => {
        profile.isSelected = profile.id === profileId;
      });
      await this.updateRecord(user);
      userCache.setUserToCache(user);
      return true;
    }
    return false;
  }

  async getUserActiveProfile(): Promise<GenericProfile | undefined> {
    const user = await this.getUserProfileFromDB();
    if (user?.profiles) {
      return user.profiles.find((profile) => profile.isSelected);
    }
  }

  getUserProfileFromDB(): Promise<UserProfile | undefined> {
    return this.user
      .filter((record) => record.recordId === USER_RECORD_ID)
      .first();
  }

  async setToken(token: UserToken) {
    let result = null;
    const record = await this.token.count();
    if (!record) {
      result = await this.token.add({ ...token, id: TOKEN_RECORD_ID });
    } else {
      result = await this.token.update(TOKEN_RECORD_ID, token);
    }
    userCache.setTokenToCache(token);
    console.log('Setting the user token', result);
    // sync downloads from native platforms when token is set after login
    downloadManagerService.syncDownloads();
    return result;
  }

  getTokenFromDB(): Promise<UserToken | undefined> {
    return this.token.get(TOKEN_RECORD_ID);
  }

  async resetToken() {
    userCache.resetCache();
    return Promise.all([this.token.clear(), this.user.clear()]);
  }

  async getFeatureFlag(name: string) {
    const data = await this.user
      .filter((record) => record.featureFlags != null)
      .toArray();
    return data[0]?.featureFlags[name];
  }
}

export const userDB = new UserDB();
