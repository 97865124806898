import styled from '@emotion/styled';

/* eslint-disable-next-line */
export interface DsmUtilsProps {}

const StyledDsmUtils = styled.div`
  color: pink;
`;

export function DsmUtils(props: DsmUtilsProps) {
  return (
    <StyledDsmUtils>
      <h1>Welcome to DsmUtils!</h1>
    </StyledDsmUtils>
  );
}

export const isUrduText = (text: string): boolean => {
  const urduRegex = /[\u0600-\u06FF]/;
  return urduRegex.test(text);
};

export const decodeHtmlEntities = (text: string) => {
  const doc = new DOMParser().parseFromString(text, 'text/html');
  return doc.documentElement.textContent || text;
};
