import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { Typography } from '../typography/typography';

export interface MonogramProps extends PropsWithChildren {
  name: string;
}

const ImageWrapper = styled.div`
  width: 61px;
  height: 61px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--md-sys-color-light-primary);
`;

export const Monogram = ({ name }: MonogramProps) => {
  return (
    <ImageWrapper>
      {name && (
        <Typography variant="textDisplaySmallLightSurface">
          {name.charAt(0).toUpperCase()}
        </Typography>
      )}
    </ImageWrapper>
  );
};
