import {
  isValueEmpty,
  nonInitials,
  validatePhone,
  validatePassword,
} from '@taleemabad/dsm/components';

export const validatePhoneNumber = (value: string) => {
  if (!nonInitials(value)) {
    return 'Phone number must be start 3 or 0';
  } else if (isValueEmpty(value)) {
    return 'Please enter your phone number';
  } else if (!validatePhone(value)) {
    return 'Please enter a valid phone number';
  } else {
    return '';
  }
};

export const validatePasswords = (value: string) => {
  if (isValueEmpty(value)) {
    return 'Please enter your password';
  } else if (!validatePassword(value)) {
    return 'Password must be at least 10 characters and have at least 1 number (alpha-numeric) in it';
  } else {
    return '';
  }
};
