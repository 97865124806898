// For Emotion CSS

export enum BreakpointSizes {
  xxl = 'xxl',
  desktopXXlarge = 'desktopXXlarge',
  xl = 'xl',
  desktopXlarge = 'desktopXlarge',
  lg = 'lg',
  desktopLg = 'desktopLg',
  desktop = 'desktop',
  md = 'md',
  tablet = 'tablet',
  sm = 'sm',
  mobileHorizontal = 'mobileHorizontal',
  xs = 'xs',
  mobile = 'mobile',
}

export type ScreenMap = Record<BreakpointSizes, boolean>;

type Breakpoints = {
  [BreakpointSizes.xxl]: { minWidth: number };
  [BreakpointSizes.desktopXXlarge]: { minWidth: number };
  [BreakpointSizes.xl]: { minWidth: number; maxWidth: number };
  [BreakpointSizes.desktopXlarge]: { minWidth: number; maxWidth: number };
  [BreakpointSizes.lg]: { minWidth: number; maxWidth: number };
  [BreakpointSizes.desktopLg]: { minWidth: number; maxWidth: number };
  [BreakpointSizes.desktop]: { minWidth: number };
  [BreakpointSizes.md]: { minWidth: number; maxWidth: number };
  [BreakpointSizes.tablet]: { minWidth: number; maxWidth: number };
  [BreakpointSizes.sm]: { minWidth: number; maxWidth: number };
  [BreakpointSizes.mobileHorizontal]: { minWidth: number; maxWidth: number };
  [BreakpointSizes.xs]: { maxWidth: number };
  [BreakpointSizes.mobile]: { maxWidth: number };
};

export const Breakpoints: Breakpoints = {
  xxl: { minWidth: 1600 },
  desktopXXlarge: { minWidth: 1600 },
  xl: { minWidth: 1200, maxWidth: 1599 },
  desktopXlarge: { minWidth: 1200, maxWidth: 1599 },
  lg: { minWidth: 992, maxWidth: 1199 },
  desktopLg: { minWidth: 992, maxWidth: 1199 },
  desktop: { minWidth: 992 },
  md: { minWidth: 768, maxWidth: 991 },
  tablet: { minWidth: 768, maxWidth: 991 },
  sm: { minWidth: 576, maxWidth: 767 },
  mobileHorizontal: { minWidth: 576, maxWidth: 767 },
  xs: { maxWidth: 575 },
  mobile: { maxWidth: 575 },
};

export const BreakpointsMediaQueries: Record<BreakpointSizes, string> = {
  xxl: '(min-width: 1600px)',
  desktopXXlarge: '(min-width: 1600px)',

  xl: '(min-width: 1200px) and (max-width: 1599px)',
  desktopXlarge: '(min-width: 1200px) and (max-width: 1599px)',

  lg: '(min-width: 992px) and (max-width: 1199px)',
  desktopLg: '(min-width: 992px) and (max-width: 1199px)',
  desktop: '(min-width: 992px)',

  md: '(min-width: 768px) and (max-width: 991px)',
  tablet: '(min-width: 768px) and (max-width: 991px)',

  sm: '(min-width: 576px) and (max-width: 767px)',
  mobileHorizontal: '(min-width: 576px) and (max-width: 767px)',

  xs: '(max-width: 575px)',
  mobile: '(max-width: 575px)',
};

export const mq = (breakpointSize: BreakpointSizes) => {
  return `@media ${BreakpointsMediaQueries[breakpointSize]}`;
};
