import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import { AppProvider } from './providers/app-provider';
import { RouterProvider } from 'react-router-dom';
import appRouter from './routes';

import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';
import { posthogIdentify, posthogInit } from '@taleemabad/db';

posthogInit();
posthogIdentify();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StrictMode>
    <PostHogProvider client={posthog}>
      <AppProvider>
        <RouterProvider router={appRouter} />
      </AppProvider>
    </PostHogProvider>
  </StrictMode>,
);
