import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon, iconSizes } from '../../atoms/icon/icon';
import { Typography, TypographyProps } from '../../atoms/typography/typography';
import { Spacing } from '@taleemabad/dsm/components';

const StyledIconTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export interface IconTextProps {
  icon: string;
  iconColor?: string;
  iconFontSize?: string;
  text: string;
  textVariant?: TypographyProps['variant'];
  textColor?: string;
  img?: string;
  imgStyle?: any;
  size?: iconSizes;
}

export const IconText = ({
  icon,
  iconColor = '--md-sys-color-on-surface-variant',
  iconFontSize = '20px',
  text,
  img = '',
  textVariant = 'textBodyMediumOnSurfaceVariant',
  size = 'normal',
  imgStyle,
}: IconTextProps) => {
  return (
    <StyledIconTextContainer className="rk-icon-text">
      {img ? (
        <img style={imgStyle} src={img} alt="icon" />
      ) : (
        <Icon
          name={icon}
          emotionCss={css`
            color: var(${iconColor});
            font-size: ${iconFontSize};
          `}
          size={size}
        />
      )}
      <Spacing marginLeft="md">
        <Typography variant={textVariant}>{text}</Typography>
      </Spacing>
    </StyledIconTextContainer>
  );
};
