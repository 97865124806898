import React from 'react';
import styled from '@emotion/styled';
import { ChapterCard } from '@taleemabad/dsm/components';
import { IBookChapter } from '@taleemabad/db';
import { BreakpointSizes, mq } from '@taleemabad/dsm/themes';

export interface Chapter {
  id: string;
  preTitle: string;
  title: string;
  isClicked: boolean;
  chapterNumber: number;
  gradeSubjectId: string;
  schoolClassId: string;
  bookId: string;
  index: number;
}

interface ChapterCardListProps {
  chapters: IBookChapter[];
  onChapterClick?: (arg: IBookChapter) => void;
}

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--rk-spacing-md);
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 260px);
  ${mq(BreakpointSizes.xs)} {
    height: calc(100vh - 434px);
  }
`;

export const ChapterCardList: React.FC<ChapterCardListProps> = ({
  chapters,
  onChapterClick,
}) => {
  return (
    <ListContainer>
      {chapters.map((chapter, index) => (
        <ChapterCard
          key={chapter.id}
          preTitle={`Chapter ${index + 1}`}
          title={chapter.title}
          onClick={() => {
            onChapterClick && onChapterClick(chapter);
          }}
        />
      ))}
    </ListContainer>
  );
};
