import { EventNames, analyticsService, updatePassword } from '@taleemabad/db';
import {
  CreateNewPassword,
  ForgotPasswordResult,
} from '@taleemabad/dsm/components';
import { ConfigContext } from 'apps/school-app/src/providers/config-provider';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
export const SetUpdatePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const usersPhone = localStorage.getItem('tempLSPhoneKey') ?? '';
  const { appLogoPath } = useContext(ConfigContext);
  const updatedPassword = async (data: ForgotPasswordResult) => {
    try {
      await updatePassword(usersPhone, data.password, data.newPassword);
      analyticsService.trackEvent(
        EventNames.resetPasswordChoosePasswordNextClicked,
      );
      setShowPopup(true);
    } catch (error) {}
  };
  useEffect(() => {
    const from = location.state?.from;
    if (from !== '/send-otp') {
      navigate('/login', { replace: true });
    }
  }, []);
  return (
    <CreateNewPassword
      logo={appLogoPath}
      onSubmit={(data) => {
        updatedPassword(data);
      }}
      title="Choose Password"
      description="Must be at least 10 characters."
      showPopup={showPopup}
      closePopup={() => {
        setShowPopup(false);
        navigate('/set-password', { replace: true });
      }}
    />
  );
};
