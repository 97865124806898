import React, { useEffect, useState } from 'react';
import {
  PhoneFieldResult,
  TextField,
  TextFieldProps,
} from '../../atoms/text-field/text-field';
import { Icon } from '../../atoms/icon/icon';
import { IconButton } from '@taleemabad/dsm/components';

export interface PasswordFieldProps extends TextFieldProps {
  onChange?: (data: PhoneFieldResult) => void;
  onToggleVisibility?: () => void;
}

export const PasswordField = ({ onChange, ...props }: PasswordFieldProps) => {
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const handleTyping = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value || inputValue || '';
    setInputValue(newValue);
    if (onChange) {
      onChange({
        name: props.name ?? '',
        value: newValue,
        hasError: hasError,
      });
    }
  };
  useEffect(() => {
    setHasError(errorMessage !== '');
    setErrorMessage(props.errorText ?? '');
  }, [inputValue, hasError, errorMessage, props.errorText]);

  return (
    <TextField
      onInput={handleTyping}
      error={!hasError ? undefined : true}
      errorText={errorMessage}
      value={inputValue}
      variant={props.variant}
      type={props.type}
      children={
        hasError ? (
          <Icon
            slot="trailing-icon"
            name="error"
            color="var(--md-sys-color-error)"
          />
        ) : (
          <IconButton
            toggledIcon="visibility_off"
            icon="visibility"
            slot="trailing-icon"
            onClick={props.onToggleVisibility}
            iconSize="normal"
            data-cy="sign-in-password-eye-icon"
          ></IconButton>
        )
      }
      {...props}
    />
  );
};
export const validatePassword = (value: string): boolean => {
  const hasAtleastOneNumeric = /\d/.test(value);
  const hasAtleastOneAlphabet = /[a-zA-Z]/.test(value);
  return value.length >= 10 && hasAtleastOneNumeric && hasAtleastOneAlphabet;
};
