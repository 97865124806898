import { userDB } from '@taleemabad/db';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
type ProtectedRouteProps = PropsWithChildren;

export function ProtectedRoute({ children }: ProtectedRouteProps) {
  const [token, setToken] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchToken = async () => {
      const result = await userDB.getTokenFromDB();
      setToken(result?.access);
      setLoading(false);
    };
    fetchToken();
  }, []);

  if (!token && !loading) {
    return <Navigate to="/login" replace />;
  }

  return children;
}
